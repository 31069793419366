import React from 'react';
import { StyleSheet } from 'react-native';
import { DocumentORM } from 'src/types/types';
import { DNARowsComponent } from 'src/components/DNA/FlatList/Rows/types';
import { useDNADocumentActions } from 'src/components/DNA/Document/DNADocument.actions';
import { DNABox, DNACard, DNAText, DNAButton } from '@alucio/lux-ui';
import DNADocumentThumbnail from 'src/components/DNA/Document/DNADocumentThumbnail';
import DNADocumentContextMenu from 'src/components/DNA/Document/DNADocumentContextMenu/DNADocumentContextMenu';
import { DNADocumentDefaultRowProps } from '../default/DNADocumentDefaultRow';

const styles = StyleSheet.create({
  container: {
    paddingVertical: 12,
  },
});

const DNADocumentDashboardRow: DNARowsComponent<DocumentORM, DNADocumentDefaultRowProps> = (props) => {
  const { ORM: documentORM, onPin } = props
  const isBookmarked = documentORM.meta.bookmark.isBookmarked;
  const documentActions = useDNADocumentActions();
  const documentVersionORM = documentORM.relations.version.latestPublishedDocumentVersionORM ||
    documentORM.relations.version.latestDocumentVersionORM;

  const onBookmark = (): void => {
    documentActions.bookmark(documentORM)()
    onPin?.()
  };

  const onPress = (): void => {
    props.onPress?.(documentORM);
  };

  return (
    <DNACard
      appearance="flat"
      interactive="pressable"
      onPress={onPress}
      style={styles.container}
    >
      <DNABox testID="dashboard-document-row" alignY="center" spacing="between" childFill={0}>
        <DNABox alignY="center" spacing="md" childFill={1}>
          <DNABox appearance="row">
            {/* BOOKMARK */}
            <DNAButton
              status="gray"
              appearance="ghost"
              onPress={onBookmark}
              iconLeft={isBookmarked ? 'bookmark' : 'bookmark-outline'}
              size="md"
              padding="sm"
            />
            {/* THUMBNAIL */}
            <DNADocumentThumbnail
              documentVersionORM={documentVersionORM}
              width={76}
              height={44}
              showProcessing
            />
          </DNABox>
          {/* TITLE */}
          <DNAText testID="folder-item-row-name" b1 numberOfLines={1}>
            {documentVersionORM.model.title}
          </DNAText>
          <DNABox />
        </DNABox>
        <DNABox spacing="sm" alignY="center">
          {/* ELLIPSIS */}
          <DNADocumentContextMenu documentORM={documentORM}>
            <DNAButton
              status="gray"
              appearance="ghost"
              iconLeft="dots-vertical"
              size="md"
              padding="sm"
            />
          </DNADocumentContextMenu>
        </DNABox>
      </DNABox>
    </DNACard>
  )
}

export default DNADocumentDashboardRow
