import { parse, print, OperationDefinitionNode } from 'graphql'
import { GraphQLOptions } from '@aws-amplify/api-graphql';
import { API, GraphQLResult, GraphQLSubscription, GraphQLQuery } from '@aws-amplify/api';
import type { AWSAppSyncRealTimeProvider } from '@aws-amplify/pubsub';
import type Observable from 'zen-observable-ts';
import { Auth } from '@aws-amplify/auth';
import * as logger from './logger';

const refreshSession = async (): Promise<void> => {
  try {
    logger.graphqlClient.info('Refreshing Auth session');
    const [cognitoUser, currentSession] = await Promise.all([
      Auth.currentAuthenticatedUser(),
      Auth.currentSession(),
    ]);
    await cognitoUser.refreshSession(currentSession.getRefreshToken());
  } catch (e) {
    logger.graphqlClient.error(e);
    throw Error('An error occurred when refreshing the session');
  }
};

type OriginalGraphQLParameters = Parameters<typeof API.graphql>

const MAX_RETRIES = 5

// [NOTE] - Matches overloaded signature from @aws-amplify/api/src/API.ts
function gracefulQuery<T>(
  options: GraphQLOptions,
  additionalHeaders?: { [key: string]: string } | undefined,
  backoff?: boolean,
  currentRetry?: number,
): T extends GraphQLQuery<T>
  ? Promise<GraphQLResult<T>>
  : T extends GraphQLSubscription<T>
  ? Observable<{
    provider: AWSAppSyncRealTimeProvider;
    value: GraphQLResult<T>;
  }>
  : Promise<GraphQLResult<any>> | Observable<object>;

/**
 *
 * @param options - GraphQLOptions
 * @param headers - Additional GQL Headers
 * @param backoff - Attempt to retry failed attempts, defaults to `true`
 * @param currentRetry - The current retry (used internally for recursion purposes)
 * @returns Promise<GraphQLResult<any>> | Observable<object>
 *
 * A wrapper around `API.graphql` that allows graceful retries for Query/Mutation operations that slowly backs off.
 * Does not apply for subscriptions (observables) currently
 * Attempts to maintain (mostly) the same function signature as `API.graphql`
 * NOTE: This function throws an error whereas the normal version does not, please handle appropriately
 */
function gracefulQuery<T = any>(
  options: OriginalGraphQLParameters[0],
  headers: OriginalGraphQLParameters[1] | undefined = undefined,
  backoff: boolean = true,
  currentRetry: number = 0,
): Promise<GraphQLResult<any>> | Observable<object> {
  const numbersOfRetries = currentRetry
  const delayForNextIteration = Math.pow(2, numbersOfRetries) * 1000

  // [NOTE] - Matches implementation from @aws-amplify/api-graphql/src/GraphQLAPI.ts
  //        - We need to parse this in order to know whether or not to retry for subscriptions
  // [TODO] - Ideally, we could also determine auth issues during subscription and just push new values as we retry
  //          but for now, do not add additional backoff support for subscriptions
  const paramQuery = options.query
  const query = typeof paramQuery === 'string'
    ? parse(paramQuery)
    : parse(print(paramQuery));

  const [operationDef = {}] = query.definitions.filter(
    def => def.kind === 'OperationDefinition',
  );
  const { operation: operationType } = operationDef as OperationDefinitionNode;

  logger.graphqlClient.debug('Performing Graphql request', options.query)

  // [NOTE] - We don't support graceful retries for subscriptions since it involves promises
  //          and observables should return a subscription value immediately
  if (operationType === 'subscription') {
    const rv = API.graphql(options, headers)
    return rv
  }

  // [NOTE] - Inline Async wrapper, which is a bit easier to work with than `Promise.resolve` syntax
  const rv = (async () => {
    try {
      logger.graphqlClient.debug(`Attempting GQL request ${numbersOfRetries}` )
      return await API.graphql(options, headers) as Promise<GraphQLResult<any>>
    }
    catch (e: any) {
      logger.graphqlClient.error('Error attemping GQL request', e);

      if (numbersOfRetries >= MAX_RETRIES) {
        logger.graphqlClient.error(`Failed GQL call after ${numbersOfRetries} attempts`)

        // [NOTE] - Since these errors are thrown (not exposed as soft errors under the response.errors)
        //          We should throw here as well
        throw new Error(
          `Failed GQL call after more than ${MAX_RETRIES} tries`,
          { cause: e },
        )
      }

      const isAuthError = Array.isArray(e.errors)
        ? e.errors.some((error) => error.message?.includes('401'))
        : false

      if (isAuthError) {
        logger.graphqlClient.error('Auth error when performing Graphql operation');
        await refreshSession();
      }

      logger.graphqlClient.debug(`Retrying Graphql request in ${delayForNextIteration}`);
      await new Promise((resolve) => setTimeout(resolve, delayForNextIteration));
      return await gracefulQuery<T>(options, headers, backoff, currentRetry + 1) as Promise<GraphQLResult<T>>
    }
  })();

  return rv
}

export default {
  gracefulQuery,
}
