import React from 'react'

import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { createReducer } from 'src/state/redux/util/createImmerlessSlice'
import type { AppDispatch, RootState } from 'src/state/redux'
import { datastoreSave, SliceStatus } from 'src/state/redux/slice/common'

import {
  Document,
  DocumentVersion,
  GetSharedFoldersOutput,
  CustomDeck,
} from '@alucio/aws-beacon-amplify/src/models'
import { DocumentVersionORM } from 'src/types/orms'
import type { CreateUserNotationsPayload } from './userNotations'
import { DNAModalActions, initialState as DNAModalInitialState } from './DNAModal/DNAModal'
import {
  LibraryNotationMigration,
  CustomDeckNotationMigration,
  FolderDocVerUpgrade,
} from 'src/components/DNA/Modal/DNANotationMigrationDebug'
import type { PageData } from 'src/types/staticAssets'
import type { DocumentORM, CustomDeckORM } from 'src/types/orms'
import { TargetFolderDocVerUpgrades } from '../saga'

export const multiSliceActions = {
  deleteDocumentDraft: createAction<DocumentVersionORM>('multiSlice/deleteDocumentDraft'),
  replaceSharedFolders: createAction<GetSharedFoldersOutput>('multiSlice/replaceSharedFolders'),
  promptLibraryMigration: createAsyncThunk<
    void,
    {
      newNotations: CreateUserNotationsPayload[],
      documentORMs: DocumentORM[],
      pageData: Record<string, PageData>
    },
    { state: RootState, dispatch: AppDispatch }
  >(
    'multiSlice/promptLibraryMigration',
    async (payload, thunkAPI) => {
      const controlFlowPromise = new Promise((resolve, reject) => {
        thunkAPI.dispatch(DNAModalActions.pushModal(
          (props) => (<LibraryNotationMigration
            documentORMs={payload.documentORMs}
            newNotations={payload.newNotations}
            controlFlow={{ resolve, reject }}
            pageData={payload.pageData}
            {...props}
          />),
        ))
      })

      await controlFlowPromise
    },
  ),
  promptCustomDeckMigration: createAsyncThunk<
    void,
    {
      newNotations: CreateUserNotationsPayload[],
      customDeckORMs: CustomDeckORM[],
      newCustomDecks: Partial<CustomDeck>[],
      pageData: Record<string, PageData>
    },
    { state: RootState, dispatch: AppDispatch }
  >(
    'multiSlice/promptCustomDeckMigration',
    async (payload, thunkAPI) => {
      const controlFlowPromise = new Promise((resolve, reject) => {
        thunkAPI.dispatch(DNAModalActions.pushModal(
          (props) => (<CustomDeckNotationMigration
            customDeckORMs={payload.customDeckORMs}
            newNotations={payload.newNotations}
            newCustomDecks={payload.newCustomDecks}
            controlFlow={{ resolve, reject }}
            pageData={payload.pageData}
            {...props}
          />),
        ))
      })

      await controlFlowPromise
    },
  ),
  promptFolderDocVerUpgrade: createAsyncThunk<
    void,
    {
      folderTargets: Array<TargetFolderDocVerUpgrades>
    },
    { state: RootState, dispatch: AppDispatch }
  >(
    'multiSlice/promptFolderDocVerUpgrade',
    async (payload, thunkAPI) => {
      const controlFlowPromise = new Promise((resolve, reject) => {
        thunkAPI.dispatch(DNAModalActions.pushModal(
          (props) => (
            <FolderDocVerUpgrade
              folderTargets={payload.folderTargets}
              controlFlow={{ resolve, reject }}
              {...props}
            />
          ),
        ))
      })

      await controlFlowPromise
    },
  ),
}

export const multiSliceReducer = createReducer<RootState>(
  /** TODO: Investigate whether this is the best approach here. Ideally we would have an initial RootState object to pass
   * in but that may require adjusting to use a partial wrapped RootState which would necessitate reliant components.  */
  null!,
  builder => {
    builder.addCase(
      multiSliceActions.promptLibraryMigration.pending,
      () => { },
    );

    builder.addCase(
      multiSliceActions.promptLibraryMigration.fulfilled,
      (state) => {
        return {
          ...state,
          DNAModal: {
            ...DNAModalInitialState,
            isVisible: !(state.DNAModal.component.length === 1),
            component: state.DNAModal.component.slice(0, -1),
          },
        }
      },
    );

    builder.addCase(
      multiSliceActions.promptLibraryMigration.rejected,
      (state) => {
        return {
          ...state,
          DNAModal: {
            ...DNAModalInitialState,
            isVisible: !(state.DNAModal.component.length === 1),
            component: state.DNAModal.component.slice(0, -1),
          },
        }
      },
    );

    builder.addCase(
      multiSliceActions.promptCustomDeckMigration.pending,
      () => { },
    );

    builder.addCase(
      multiSliceActions.promptCustomDeckMigration.fulfilled,
      (state) => {
        return {
          ...state,
          DNAModal: {
            ...DNAModalInitialState,
            isVisible: !(state.DNAModal.component.length === 1),
            component: state.DNAModal.component.slice(0, -1),
          },
        }
      },
    );

    builder.addCase(
      multiSliceActions.promptCustomDeckMigration.rejected,
      (state) => {
        return {
          ...state,
          DNAModal: {
            ...DNAModalInitialState,
            isVisible: !(state.DNAModal.component.length === 1),
            component: state.DNAModal.component.slice(0, -1),
          },
        }
      },
    );

    builder.addCase(
      multiSliceActions.promptFolderDocVerUpgrade.pending,
      () => { },
    );

    builder.addCase(
      multiSliceActions.promptFolderDocVerUpgrade.fulfilled,
      (state) => {
        return {
          ...state,
          DNAModal: {
            ...DNAModalInitialState,
            isVisible: !(state.DNAModal.component.length === 1),
            component: state.DNAModal.component.slice(0, -1),
          },
        }
      },
    );

    builder.addCase(
      multiSliceActions.promptFolderDocVerUpgrade.rejected,
      (state) => {
        return {
          ...state,
          DNAModal: {
            ...DNAModalInitialState,
            isVisible: !(state.DNAModal.component.length === 1),
            component: state.DNAModal.component.slice(0, -1),
          },
        }
      },
    );

    builder.addCase(
      multiSliceActions.deleteDocumentDraft,
      (state, action) => {
        const documentVersionORM = action.payload
        const parentDocumentORM = documentVersionORM.relations.documentORM
        const isVersionPublished = documentVersionORM.model.status === 'PUBLISHED'
        const isDocumentPublished = parentDocumentORM.model.status === 'PUBLISHED'

        if (isVersionPublished) { throw new Error('Cannot delete Published document version') }

        if (!isDocumentPublished) {
          datastoreSave(
            Document,
            parentDocumentORM.model,
            { status: 'DELETED' },
          )

          return state
        }

        if (!isVersionPublished && isDocumentPublished) {
          datastoreSave(
            DocumentVersion,
            documentVersionORM.model,
            { status: 'DELETED' },
          )

          return state
        }

        throw new Error(`Could not determine deletion status for DocumentVersion ${documentVersionORM.model.id}`)
      },
    );
    builder.addCase(
      multiSliceActions.replaceSharedFolders,
      (state, action) => {
        const newSharedFolder = { ...state.sharedFolder }
        newSharedFolder.records = action.payload.folders;
        newSharedFolder.hydrated = true;
        newSharedFolder.status = SliceStatus.OK;

        const newFolderPermissions = { ...state.folderPermission }
        newFolderPermissions.records = action.payload.permissions
        newFolderPermissions.hydrated = true;
        newFolderPermissions.status = SliceStatus.OK;

        return {
          ...state,
          sharedFolder: newSharedFolder,
          folderPermission: newFolderPermissions,
        };
      },
    );
  },
)
