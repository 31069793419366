
import { v4 as uuid } from 'uuid';
import { Logger as AmplifyLogger } from '@aws-amplify/core';
import { createStore } from 'src/utils/analytics/queueIdb';
import { LogLevel } from './logger.types';
import * as Logger from './logger.types';
import loggers, { loggerNames, BroadcastLogger } from 'src/utils/logger';
import { API, graphqlOperation, GraphQLQuery } from '@aws-amplify/api';
import { pushLogs } from '@alucio/aws-beacon-amplify/src/graphql/mutations';
import { PushLogsMutation, PushLogsMutationVariables } from '@alucio/aws-beacon-amplify/src/API';
import { removeCircularDependencies } from 'src/utils/objectHelpers';

// From low to high level
const LOGGER_LEVELS = [
  LogLevel.VERBOSE,
  LogLevel.DEBUG,
  LogLevel.INFO,
  LogLevel.WARN,
  LogLevel.ERROR,
]

interface PatchedAmplifyLogger extends AmplifyLogger {
  originalInfo?: ((...msg: any[]) => void)
  originalWarn?: ((...msg: any[]) => void)
  originalError?: ((...msg: any[]) => void)
  originalDebug?: ((...msg: any[]) => void)
  originalVerbose?: ((...msg: any[]) => void)
}

interface PatchedBroadcastLogger extends BroadcastLogger {
  originalInfo?: ((...msg: any[]) => void)
  originalWarn?: ((...msg: any[]) => void)
  originalError?: ((...msg: any[]) => void)
  originalDebug?: ((...msg: any[]) => void)
  originalVerbose?: ((...msg: any[]) => void)
}

type PatchedLogger = PatchedAmplifyLogger | PatchedBroadcastLogger;

export type LoggerErrorName =
  | 'UNKNOWN_ERROR'
  | 'USER_NOT_FOUND'

export type ErrorSettings = {
  name: LoggerErrorName,
  message: string,
  subMessage?: string,
}

export class LoggerError extends Error {
  name: LoggerErrorName;
  message: string;
  subMessage?: string;
  cause?: any;

  constructor(err: LoggerErrorName, cause?: any)
  constructor(
    err: unknown,
    cause?: any,
  ) {
    super()
    const parsed = this.parseError(err)

    this.name = parsed.name
    this.message = parsed.message
    this.subMessage = parsed.subMessage
    this.cause = cause
  }

  parseError(err: any): ErrorSettings {
    const appSyncError = typeof err === 'string'
      ? { message: err }
      : err?.errors?.at(0)

    switch (appSyncError?.message) {
      case 'NOT_FOUND': {
        return {
          name: 'USER_NOT_FOUND',
          message: 'Cannot find session user',
        }
      }
      default: {
        return {
          name: appSyncError?.message ?? 'UNKNOWN_ERROR',
          message: 'An unknown error has occured',
        }
      }
    }
  }
}

export const retentionConfig = { maxNumber: 25000, batchEvictionNumber: 1000 };
export const withStore = createStore('loggerOffline', 'loggerOffline', 'key');
export * from 'src/utils/analytics/queueIdb';

/**
  * This function extend the behavior of existing Logger without modifying its source directly
*/
function _patchLogger(
  machineFunction: (loggerLevel: LogLevel, logLevel: LogLevel, logCategory: string, msg: any[]) => void,
  logger: PatchedLogger,
) {
  const originalInfo = logger.info.bind(logger);
  const originalWarn = logger.warn.bind(logger);
  const originalError = logger.error.bind(logger);
  const originalDebug = logger.debug.bind(logger);
  const originalVerbose = logger.verbose.bind(logger);

  logger.originalInfo = originalInfo;
  logger.originalWarn = originalWarn;
  logger.originalError = originalError;
  logger.originalDebug = originalDebug;
  logger.originalVerbose = originalVerbose;

  const logCategory = logger.name;
  const loggerLevel = logger.level as LogLevel;

  const globalLogLevelValue = LOGGER_LEVELS.findIndex(level => level === AmplifyLogger.LOG_LEVEL)
  const loggerLevelValue = LOGGER_LEVELS.findIndex(level => level === loggerLevel)
  if (loggerLevelValue === -1) throw new Error('Invalid logger level')
  const restrictedLevelValue = globalLogLevelValue < loggerLevelValue ? globalLogLevelValue : loggerLevelValue
  const filteredLevels = LOGGER_LEVELS.slice(restrictedLevelValue)

  // Monkey patch the log methods
  // Only patch the log levels that are greater than or equal to the logger level
  if (filteredLevels.includes(LogLevel.VERBOSE)) {
    logger.verbose = (...msg: any[]) => {
      originalVerbose(...msg);
      machineFunction(loggerLevel, LogLevel.VERBOSE, logCategory, msg)
    };
  }

  if (filteredLevels.includes(LogLevel.DEBUG)) {
    logger.debug = (...msg: any[]) => {
      originalDebug(...msg);
      machineFunction(loggerLevel, LogLevel.DEBUG, logCategory, msg)
    };
  }

  if (filteredLevels.includes(LogLevel.INFO)) {
    logger.info = (...msg: any[]) => {
      originalInfo(...msg);
      machineFunction(loggerLevel, LogLevel.INFO, logCategory, msg)
    };
  }

  if (filteredLevels.includes(LogLevel.WARN)) {
    logger.warn = (...msg: any[]) => {
      originalWarn(...msg);
      machineFunction(loggerLevel, LogLevel.WARN, logCategory, msg)
    };
  }

  if (filteredLevels.includes(LogLevel.ERROR)) {
    logger.error = (...msg: any[]) => {
      originalError(...msg);
      machineFunction(loggerLevel, LogLevel.ERROR, logCategory, msg)
    };
  }
}

function _unpatchLogger(
  logger: PatchedLogger,
) {
  if (logger.originalInfo) logger.info = logger.originalInfo;
  if (logger.originalWarn) logger.warn = logger.originalWarn;
  if (logger.originalError) logger.error = logger.originalError;
  if (logger.originalDebug) logger.debug = logger.originalDebug;
  if (logger.originalVerbose) logger.verbose = logger.originalVerbose;
}

export function patchAllLoggers (
  machineFunction: (loggerLevel: LogLevel, logLevel: LogLevel, logCategory: string, msg: any[]) => void,
) {
  loggerNames.forEach(loggerName => {
    const loggerToPatch = loggers[loggerName]
    _patchLogger(machineFunction, loggerToPatch)
  })
}

export function unpatchAllLoggers () {
  loggerNames.forEach(loggerName => {
    const loggerToUnpatch = loggers[loggerName]
    _unpatchLogger(loggerToUnpatch)
  })
}

export async function callPushLogsLambda (itemsToSend: string[], shouldSendEmail?: boolean) {
  const input: PushLogsMutationVariables = {
    input: itemsToSend,
    shouldSendEmail: !!shouldSendEmail,
  }
  const { data, errors } = await API.graphql<GraphQLQuery<PushLogsMutation>>(
    graphqlOperation(pushLogs, input),
  )

  if (errors?.length) throw new LoggerError('UNKNOWN_ERROR', errors)

  return data
}

export function createLog (ctx: Logger.SMContext, evt: Logger.EVT_LOG, sendError?: boolean) {
  if (!ctx.user) {
    throw new LoggerError('UNKNOWN_ERROR', 'Failed to get current user')
  }

  // If is error log, we only want to send the first 2 item (string) in the log here
  const logMessage = sendError ? evt.payload.logMessage.slice(0, 2) : evt.payload.logMessage

  const now = Date.now();
  const ISOString = new Date(now).toISOString();
  const uniqueKey = `${now}_${uuid()}`;
  const payload: Logger.logPayload = {
    tenantId: ctx.user['custom:org_id'],
    userId: ctx.user['custom:user_id'],
    email: ctx.user.email,
    connectionStatus: navigator.onLine ? 'online' : 'offline',
    timeStamp: ISOString,
    timeZone: ctx.timeZone,
    deviceMode: ctx.deviceMode,
    platform: ctx.platform,
    ...evt.payload,
    logMessage,
    logLevel: evt.payload.logLevel,
  }

  const logPayload = removeCircularDependencies(payload, 20);
  return { logPayload, key: uniqueKey }
}

export function truncateLogMessage (message: any, length: number) {
  if (typeof message === 'string') return message.slice(0, length)
  else return JSON.stringify(message).slice(0, length)
}
