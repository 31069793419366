import React from 'react';
import { StyleSheet } from 'react-native';
import { DNABox, DNASlider } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import useMachineSelector, { composite } from 'src/hooks/useSelector';
import * as slideSettingsSelector from 'src/state/machines/publisherVersioning/SlideSettings/slideSettings.selectors';
import { useSlideSettingsState } from 'src/state/machines/publisherVersioning/SlideSettings/SlideSettingsProvider';
import { SlideSettingsStateProvider }
  from 'src/state/machines/publisherVersioning/SlideSettings/SlideSettingsProvider.proxy';
import Header from './Header';
import Content from './Content';

export const S = StyleSheet.create({
  modalBackground: {
    backgroundColor: colors['color-text-white'],
  },
});

const AddAssociatedSlidesModal: React.FC = () => {
  const slideSettingsState = useSlideSettingsState()
  const {
    service,
    backToIdle,
  } = slideSettingsState

  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      slideSettingsSelector.isAddAssociatedSlidesMode,
    ),
  )

  return (
    <DNASlider
      visible={cond.isAddAssociatedSlidesMode}
      setVisible={backToIdle}
      orientation="horizontal"
    >
      <SlideSettingsStateProvider value={slideSettingsState}>
        <DNABox fill appearance="col" style={S.modalBackground}>
          <Header />
          <Content />
        </DNABox>
      </SlideSettingsStateProvider>
    </DNASlider>
  )
}

export default AddAssociatedSlidesModal;
