import React, { useEffect, useRef, useState } from 'react'
import { StyleSheet, Image } from 'react-native'
import { DNABox, DNAButton, DNAText, Iffy } from '@alucio/lux-ui'
import { reloadApp } from 'src/utils'
import useScreenNav from '../DNA/hooks/useScreenNav'

const styles = StyleSheet.create({
  resultsLoadingImage: {
    width: 200,
    height: 200,
  },
})

interface LoadingProps {
  variant?: 'meetings',
  warningTimeout?: number
}

// [NOTE] - Pretty similar to DNALoader / Loading, we should consider consolidating theses
export const LoadingAnimation: React.FC<LoadingProps> = ({ variant, warningTimeout }) => {
  const [displayWarningTimeout, setDisplayWarningTimeout] = useState<boolean>(false)
  const isMounted = useRef<boolean>(false)
  const nav = useScreenNav()

  useEffect(
    () => {
      isMounted.current = true
      return () => {
        isMounted.current = false
      }
    },
    [],
  )

  useEffect(
    () => {
      if (warningTimeout !== undefined) {
        setTimeout(
          () => {
            if (isMounted.current) setDisplayWarningTimeout(true)
          },
          warningTimeout,
        )
      }
    },
    [warningTimeout],
  )

  return (
    <DNABox fill alignY="center">
      <DNABox
        fill
        appearance="col"
        alignX="center"
        alignY="center"
        spacing="md"
        style={[{ padding: 48 }]}
      >
        <Image
          style={styles.resultsLoadingImage}
          source={variant === 'meetings'
            ? require('../../../assets/images/LoaderDNA_dark.gif')
            : require('../../../assets/images/LoaderDNA.gif')}
        />
        <Iffy is={displayWarningTimeout}>
          <DNABox appearance="col" alignY="center">
            <DNAText bold h2 style={{ textAlign: 'center' }}>
              This seems to be taking a while
            </DNAText>
            <DNAText h4 style={{ textAlign: 'center' }}>
              In order to have the best meeting experience possible, please reload the app
            </DNAText>
          </DNABox>
          <DNABox spacing="md" appearance="row">
            <DNAButton onPress={reloadApp}>
              Reload
            </DNAButton>
            <DNAButton
              onPress={() => nav.goTo.HOME()}
              appearance="outline"
            >
              Return home
            </DNAButton>
          </DNABox>
        </Iffy>
      </DNABox>
    </DNABox>
  )
}
