import { State as StateDef, ResolveTypegenMeta, BaseActionObject } from 'xstate';
import type * as MatchSlides from './matchSlides.types';
import { Typegen0 } from './matchSlides.machine.typegen';

// [NOTE] - Normally we can use some util helpers, other TS access to get this
//          but it's possible to run into a circular dependency error when using selector as machine guards
//        - This probably creates a 2nd instance of the State type but it's needed for now
type State = StateDef<
  MatchSlides.SMContext,
  MatchSlides.SMEvents,
  any,
  { value: any, context: MatchSlides.SMContext },
  ResolveTypegenMeta<
    Typegen0,
    MatchSlides.SMEvents,
    BaseActionObject,
    MatchSlides.SMServices
  >
>

// CONTEXT
export const slidesGroupPoolVisible =
  (state: State) => ({ slidesGroupPoolVisible: state.context.slidesGroupPoolVisible })
export const slideCount =
  (state: State) => ({ slideCount: state.context.slideCount })
