import React from 'react'
import { FlatList, ListRenderItem, StyleSheet } from 'react-native'
import { DocumentORM } from 'src/types/types'
import { ContentComponent, ContentORMs, ContentVariant } from 'src/components/DNA/types'
import { DNAFlatListRows } from 'src/components/DNA/FlatList/Rows/types'

import { Iffy, DNACard, DNABox } from '@alucio/lux-ui'
import DNAEmpty from 'src/components/DNA/Empty/DNAEmpty'
import DNADefaultRows from 'src/components/DNA/FlatList/Rows/default'
import DNAVirtualRows from 'src/components/DNA/FlatList/Rows/virtual'
import DNASearchRows from 'src/components/DNA/FlatList/Rows/search'
import DNACustomDeckRows from 'src/components/DNA/FlatList/Rows/customDeck'
import DNAFileDrawerRows from 'src/components/DNA/FlatList/Rows/fileDrawer'
import DNADashboardRows from 'src/components/DNA/FlatList/Rows/dashboard'
import { useAppSettings } from 'src/state/context/AppSettings'
import { isCustomDeckORM, isFolderORM, isFolderItemORM } from 'src/types/typeguards'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'
import { DNACardAppearance } from '@alucio/lux-ui/src/components/layout/DNACard/DNACard'
import DNAMeetingsRows from './Rows/meetings'

export type DNAListProps = {
  children?: any,
  disableHeader?: boolean,
  emptyWithHeader?: boolean,
  useEmptyContainer?: boolean,
  appearance?
  isSearch?: boolean,
}

// Last items should have rounded borders
const defaultLastItemStyle = {
  borderRadius: 4,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
}

type RowVariants = Record<ContentVariant, DNAFlatListRows>
// [TODO] - Consider passing this in as a prop instead?
const rowVariants: RowVariants = {
  default: DNADefaultRows,
  dashboard: DNADashboardRows,
  virtual: DNAVirtualRows,
  search: DNASearchRows,
  customDeck: DNACustomDeckRows,
  publisher: DNADefaultRows,
  meetings: DNAMeetingsRows,
  fileDrawer: DNAFileDrawerRows,
}

const renderItem: (
  items: ContentORMs[],
  variant: ContentVariant,
  deviceMode,
  onPress?: (orm: ContentORMs) => void,
  isOnline?: boolean,
  isSearch?: boolean,
) => ListRenderItem<ContentORMs> = (items, variant, deviceMode, onPress, isOnline, isSearch) =>
  (arg) => {
    const { item, index } = arg
    const isDesktop = deviceMode === 'desktop';
    const variantStyle = variant === 'default' && index === items.length - 1
      ? defaultLastItemStyle
      : undefined

    const style = [variantStyle]
    const RowVariants = rowVariants[variant]

    if (isFolderORM(item)) {
      return (
        <RowVariants.DNAFolderRow
          onPress={(onPress)}
          ORM={item}
          style={style}
          isDesktop={isDesktop}
        />
      )
    } else if (isFolderItemORM(item)) {
      if (isFolderORM(item.relations.itemORM)) {
        return (
          <RowVariants.DNAFolderRow
            onPress={onPress}
            ORM={item.relations.itemORM}
            style={style}
            isDesktop={isDesktop}
          />
        )
      } else if (isCustomDeckORM(item.relations.itemORM)) {
        return (
          <RowVariants.DNADocumentFolderItemCustomDeckRow
            onPress={onPress}
            ORM={item}
            isOnline={isOnline}
            isDesktop={isDesktop}
          />
        )
      } else {
        return (
          <RowVariants.DNADocumentFolderItemRow
            onPress={onPress}
            ORM={item}
            style={style}
            isOnline={isOnline}
            isDesktop={isDesktop}
          />
        )
      }
    } else if (item.type === 'DOCUMENT') {
      if (variant === 'fileDrawer') {
        return (
          <RowVariants.DNAFileDrawerRow
            onPress={onPress}
            ORM={item}
            style={style}
            isOnline={isOnline}
            isDesktop={isDesktop}
            isSearch={isSearch}
          />
        )
      } else {
        return (
          <RowVariants.DNADocumentRow
            onPress={onPress as (orm: DocumentORM) => void}
            ORM={item}
            style={style}
            isOnline={isOnline}
            isDesktop={isDesktop}
            isSearch={isSearch}
          />
        )
      }
    }
    else {
      return null
    }
  }

type CardVariants = Record<ContentVariant, DNACardAppearance>

const cardVariants:CardVariants = {
  default: 'flat',
  dashboard: 'flat',
  virtual: 'flat',
  search: 'flat',
  customDeck: 'flat',
  publisher: 'flat',
  meetings: 'flat',
  fileDrawer: 'flat',
}

const style = StyleSheet.create({
  myFolders: {
    borderWidth: 1,
    borderRadius: 4,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderColor: colors['color-gray-100'],
    backgroundColor: 'white',
  },
  myFoldersHome: {
    borderWidth: 1,
    borderRadius: 4,
    borderColor: colors['color-gray-100'],
    backgroundColor: 'white',
  },
})

export const DNAFlatList: ContentComponent<DNAListProps> = (props) => {
  const { deviceMode } = useAppSettings()
  const { isOnline } = useAppSettings()

  const {
    items,
    limit,
    emptyVariant,
    emptyWithHeader,
    useEmptyContainer,
    variant = 'default',
    onPress,
    children,
    disableHeader,
    isSearch,
  } = props
  const Header = disableHeader ? null : (children || null) as React.ReactElement
  const itemsToRender = items.slice(0, limit || items.length)

  const isDesktop = deviceMode === 'desktop'
  // [TODO] : Do a better job on styling/extracting styles for possible combinations
  const centerEmptyForTablet = {
    alignY: !items.length && !isDesktop ? 'center' : undefined,
    childFill: !items.length ? 0 : false,
  }

  const deviceModeProps = isDesktop
    ? {
      as: DNACard,
      appearance: cardVariants[variant],
      style: props.emptyWithHeader ? style.myFoldersHome
        : variant === 'default' ? style.myFolders : { backgroundColor: 'transparent' },
    }
    : {
      appearance: 'col',
      fill: true,
      ...centerEmptyForTablet,
    }

  const DeviceModeContainer = isDesktop
    ? DNACard
    : DNABox

  return (

  /** TODO: Consider refactoring this to discrete containers rather than trying to stuff all props
   * into a single entity. Shouldn't need to use an ignore here if structured appropriately */

  // @ts-ignore - currently using the `as` prop will not allow typings to be properly detected.
  // In this case the appearance values of 'float' & 'flat' are causing errors because the element is still being evaluated as a DNABox for typings.
  // [TODO] - DNACard appearance conflicts with DNABox Col

    <DeviceModeContainer {...deviceModeProps}>
      <Iffy is={!items.length}>
        <Iffy is={emptyWithHeader}>{ Header }</Iffy>
        <DNAEmpty
          useContainer={useEmptyContainer ?? !emptyWithHeader}
          emptyVariant={emptyVariant}
          variant={variant}
        />
      </Iffy>
      <Iffy is={items.length}>
        <FlatList
          testID="folder-archived-list-container"
          keyExtractor={(item, idx) => {
            const id = item.type === 'FOLDER_ITEM'
              ? item.model?.itemId
              : item.model.id
            return id + idx
          }}
          data={itemsToRender}
          renderItem={renderItem(itemsToRender, variant, deviceMode, onPress as any, isOnline, isSearch)}
          ItemSeparatorComponent={rowVariants[variant].DNAListDivider}
          ListHeaderComponent={Header}
          contentContainerStyle={{ borderRadius: 4 }}
        />
      </Iffy>
    </DeviceModeContainer>
  )
}

export default DNAFlatList
