import React, { useCallback, useEffect, useMemo } from 'react';
import { StyleSheet } from 'react-native';
import {
  DNABox,
  DNASelect,
  DNAText,
  TextField,
  RadioGroup,
  RadioItem,
  DatePicker as UIDatePicker,
  DNACheckbox,
  Iffy,
  DNAIcon,
} from '@alucio/lux-ui';
import { IndexPath } from '@ui-kitten/components';
import DNAPopover from 'src/components/DNA/Popover/DNAPopover';

import { Controller, ControllerRenderProps, FieldErrors, FieldValues, useWatch } from 'react-hook-form';
import {
  FieldDataType,
  ControlType,
  CustomFieldDefinition,
  CustomFieldValueDefinition,
  User,
  DateRestriction,
} from '@alucio/aws-beacon-amplify/src/models';
import { FIELD_DATA_TYPE, USER_STATUS } from '@alucio/aws-beacon-amplify/src/API';
import { CustomFieldValuesMap, UserORM } from 'src/types/orms';
import { usePublisherList } from 'src/state/redux/selector/user';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import {
  ComposableStyles,
  ComposableVariant,
  composableVariantStyles,
  FormValuesType,
  RHForm,
  useComposableForm,
} from './ComposableForm';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

import { dependencyCFHasAvailableValues, ObjectWithId, validFieldControlTypes } from './ComposableFormUtilities';
import {
  getErrorFromChildField,
  getValueFromChildField,
  HiddenField,
  ObjectFieldWrapper,
} from './ObjectField';
import { CRMAttendeeList } from './CRM/CRMAttendeeList/CRMAttendeeList';
import CRMAccountSearcher from './CRM/CRMAccountSearcher/CRMAccountSearcher';

import type { DNASelectProps } from '@alucio/lux-ui/src/components/controls/DNASelect/DNASelect';

interface DependencySettings {
  canBeRendered: boolean
  parentSelectedValues: string[]
}

const styles = StyleSheet.create({
  labelFieldWrapper: {
    paddingHorizontal: 16,
    paddingVertical: 8,
    backgroundColor: colors['color-gray-10'],
    borderRadius: 6,
    marginTop: 8,
  },
});

// [TODO-2780] - Move Types to separate file
// [TODO-2780] - find a way to extend the original enumerator
export enum CustomControlType {
  CONTENT_PRESENTER = 'CONTENT_PRESENTER',
}

type FormRenderProps<T = any> = Omit<
  ControllerRenderProps<
    Record<string, T extends undefined ? string | undefined : T>
  >,
  'value'
>

export type IFieldComponentProps<T = any, P extends Object = any> = FormRenderProps<T>
  & {
    field: CustomFieldDefinition,
    values: CustomFieldValuesMap,
    value?: T | undefined,
    disabled?: boolean,
    isReadOnly?: boolean,
    placeholder?: string,
    onSubmit?: () => void,
    dependencyValues: DependencySettings,
    childFields?: CustomFieldDefinition[],
    variant?: ComposableVariant
  }
  & P

export const useFilterVaDef = (
  field: CustomFieldDefinition,
  valueId: string | string[] | undefined,
  dependencyValues: DependencySettings,
) => {
  const { parentSelectedValues } = dependencyValues

  /*
    // Used to filter out values that are not dependent on the parent field
    @param fieldValDef - The field value definition
    @param parentSelectedValues - The values of the parent field
    @param filterDisabled - Whether to filter out disabled values
  */
  const filterValDef = useCallback(
    (
      fieldValDef: CustomFieldValueDefinition,
      parentSelectedValues: string[],
      filterDisabled: boolean = true,
    ) : boolean => {
      if (fieldValDef.dependentValueIds.length && parentSelectedValues) {
        return !fieldValDef.disabled &&
      fieldValDef.dependentValueIds.some(dependentValId => parentSelectedValues.includes(dependentValId))
      }
      else {
        return filterDisabled ? !fieldValDef.disabled : true
      }
    }, [])

  const activeFieldValueDefs = field
    .fieldValueDefinitions
    .filter(fieldValDef => filterValDef(fieldValDef, parentSelectedValues))

  const selectedFieldValueIdx = field
    .fieldValueDefinitions // includes both disabled + non-disabled
    .filter(fieldValDef => filterValDef(fieldValDef, parentSelectedValues, false))
    .findIndex(fieldValDef => fieldValDef.id === valueId)

  return useMemo(() => {
    return {
      activeFieldValueDefs,
      selectedFieldValueIdx,
      filterValDef,
      parentSelectedValues,
    }
  }, [activeFieldValueDefs, selectedFieldValueIdx, filterValDef, parentSelectedValues])
}

const CategoricalSelect: React.FC<
  IFieldComponentProps<string | undefined, { status: DNASelectProps['status'] }>
> = (props) => {
  const { onChange, value: valueId, field, disabled, dependencyValues, status, isReadOnly } = props
  const { activeFieldValueDefs, selectedFieldValueIdx } = useFilterVaDef(field, valueId, dependencyValues)
  type ActiveFieldValueDefsList = [undefined, ...Array<CustomFieldValueDefinition>]
  const activeFieldValueDefsList: ActiveFieldValueDefsList = [undefined, ...activeFieldValueDefs]

  const selectedIdx = selectedFieldValueIdx + 1
  // [NOTE] - This value can be an active or non-active fields
  const selectedFieldValue: CustomFieldValueDefinition | undefined = activeFieldValueDefsList[selectedIdx]
  const selectedIdxPath = new IndexPath(selectedIdx)

  const handleSelect = (index: IndexPath | IndexPath[]): void => {
    const idx = index as IndexPath
    const targetFieldValDefId = activeFieldValueDefsList[idx.row]?.id;
    onChange(targetFieldValDefId ?? '')
  }

  const val = selectedIdx <= 0
    ? undefined
    : valueId
      ? selectedIdxPath : undefined

  if (isReadOnly) {
    const value = selectedFieldValue?.label || selectedFieldValue?.value
    return (<ReadOnly {...props} value={value} />)
  }

  const isDropdownDisabled = disabled || !activeFieldValueDefsList?.some((value) => !!value);

  return (
    <DNASelect
      selectedIndex={val}
      onSelect={handleSelect}
      value={selectedFieldValue?.label || selectedFieldValue?.value}
      disabled={isDropdownDisabled}
      placeholder={'Select...'}
      status={status}
    >
      {
        activeFieldValueDefsList
          .map(fieldValueDef => {
            if (!fieldValueDef) {
              return (
                <DNASelect.Item
                  key="default"
                  title={() => (
                    <DNABox testID="select-item-default-value" appearance="col" style={{ marginHorizontal: 8 }}>
                      <DNAText status="subtle">Select...</DNAText>
                    </DNABox>
                  )}
                />
              )
            } else {
              return (
                <DNASelect.Item
                  disabled={fieldValueDef.disabled || disabled}
                  key={fieldValueDef.id}
                  title={fieldValueDef.label ?? fieldValueDef.value}
                  testID={`select-item-${fieldValueDef.value.toLowerCase().replace(/ /g, '-')}`}
                />
              )
            }
          })
      }
    </DNASelect>
  )
}

const MultiCategoricalSelect: React.FC<
  IFieldComponentProps<string[], { status: DNASelectProps['status'] }>
> = (props) => {
  const { onChange, value, field, disabled, dependencyValues, status, isReadOnly } = props
  const {
    activeFieldValueDefs,
    filterValDef,
    parentSelectedValues,
  } = useFilterVaDef(field, value, dependencyValues)

  const selectedDisplayValue = field
    .fieldValueDefinitions
    .filter(fieldValDef => filterValDef(fieldValDef, parentSelectedValues, false))
    .filter(fieldValDef => value?.includes(fieldValDef.id))
    .map(fieldValDef => fieldValDef.label ?? fieldValDef.value ?? '')
    .join(', ')

  const selectedFieldValueIdx = activeFieldValueDefs
    .map((fieldValDef, index) => {
      if (value?.includes(fieldValDef.id)) {
        return new IndexPath(index)
      }
      return undefined
    })
    .filter(idx => idx !== undefined) as IndexPath[]

  const handleSelect = (index: IndexPath | IndexPath[]): void => {
    if (Array.isArray(index)) {
      !isEmpty(index)
        ? onChange(index.map(indx => {
          const idx = indx as IndexPath
          const targetFieldValDefId = activeFieldValueDefs[idx.row].id
          return targetFieldValDefId
        }))
        : onChange([])
    }
    else {
      console.warn('[WARN] - MultiCategoricalSelect: onSelect called with non-array index')
    }
  }

  if (isReadOnly) return <ReadOnly {...props} value={selectedDisplayValue} />

  const isDropdownDisabled = disabled || !activeFieldValueDefs?.some((value) => !!value);

  return (
    <DNASelect
      multiSelect={true}
      selectedIndex={selectedFieldValueIdx}
      onSelect={handleSelect}
      value={selectedDisplayValue}
      disabled={isDropdownDisabled}
      placeholder={'Select...'}
      status={status}
    >
      {
        activeFieldValueDefs
          .map(fieldValueDef => (
            <DNASelect.Item
              disabled={disabled || fieldValueDef.disabled}
              key={fieldValueDef.id}
              title={fieldValueDef.label ?? fieldValueDef.value}
              testID={`select-item-${fieldValueDef.value.toLowerCase().replace(/ /g, '-')}`}
            />
          ))
    }
    </DNASelect>
  )
}

const UserListSelect: React.FC<
  IFieldComponentProps<string, { status: DNASelectProps['status'] }>
> = (props) => {
  const { disabled, onChange, value, status, isReadOnly } = props
  const publishers = usePublisherList()
  const filteredPublishers = publishers.filter((pub) => pub.model.status !== USER_STATUS.DEACTIVATED)
  type PublisherList = [undefined, ...Array<UserORM>]
  const publisherList: PublisherList = [undefined, ...filteredPublishers]
  const publishersFormatted = publishers.reduce<Record<string, { email: string, name: string, status: User['status']}>>(
    (acc, publisher) => {
      if (!acc[publisher.model.email]) {
        acc[publisher.model.email] = {
          email: publisher.model.email,
          name: publisher.meta.formattedName ?? '',
          status: publisher.model.status,
        }
      }

      return acc
    },
    {},
  )

  const handleSelect = (index: IndexPath | IndexPath[]) => {
    const idx = index as IndexPath
    onChange(publisherList[idx.row]?.model.email ?? '')
  }

  const selectedIndex = new IndexPath(
    filteredPublishers.findIndex(publisher => publisher.model.email === value),
  );
  const selectedUserLabel = publishersFormatted[value ?? '']?.name

  if (isReadOnly) {
    return (<ReadOnly {...props} value={selectedUserLabel} />)
  }

  return (
    <DNASelect
      /** TODO: set status prop based on field validity. This should be set to one of the
       * options from the EvaStatus type. (Not sure why intellisense is not working here)  */
      size="lg"
      disabled={disabled}
      selectedIndex={selectedIndex.row <= 0 ? undefined : selectedIndex}
      onSelect={handleSelect}
      value={selectedUserLabel}
      placeholder={'Select...'}
      status={status}
    >
      {
        publisherList
          .map((publisher) => {
            if (!publisher) {
              return (
                <DNASelect.Item
                  key="default"
                  title={() => (
                    <DNABox appearance="col" style={{ marginHorizontal: 14, paddingVertical: 8 }}>
                      <DNAText status="subtle">Select...</DNAText>
                    </DNABox>
                  )}
                />
              )
            } else {
              return (
                <DNASelect.Item
                  key={publisher.model.id}
                  title={() => (
                    <DNABox appearance="col" style={{ marginHorizontal: 14 }}>
                      <DNAText>{publishersFormatted[publisher.model.email]?.name}</DNAText>
                      <DNAText status="subtle" b2>
                        {publishersFormatted[publisher.model.email]?.email}
                      </DNAText>
                    </DNABox>
                  )}
                />
              )
            }
          })
      }
    </DNASelect>
  )
}

const Select: React.FC<
  IFieldComponentProps<string[] | string, { status: DNASelectProps['status'] }>
> = (props) => {
  const { value, ...rest } = props;

  if (props.field.fieldType === FieldDataType.CATEGORICAL) {
    return <CategoricalSelect value={Array.isArray(value) ? value[0] : value} {...rest} />
  }
  else if (props.field.fieldType === FieldDataType.MULTICATEGORICAL) {
    return <MultiCategoricalSelect value={Array.isArray(value) ? value : value ? [value] : []} {...rest} />
  }
  else {
    return null
  }
}

const CheckBox: React.FC<IFieldComponentProps<string>> = (props) => {
  const { onChange, value, field, disabled, variant, isReadOnly } = props

  const isVirtual = variant === ComposableVariant.VIRTUAL

  const fieldvalueDefs = field.fieldValueDefinitions
  const checkedFieldValueDef = fieldvalueDefs?.find(valDef => valDef.value === 'YES')
  const uncheckedFieldValueDef = fieldvalueDefs?.find(valDef => valDef.value === 'NO')
  const isChecked = value === checkedFieldValueDef?.id

  const handleSelect = (checked: boolean) => {
    // [NOTE] - We sort of check the valididty of the config
    //        - It should atleast have 2 values of YES and NO
    if (!checkedFieldValueDef || !uncheckedFieldValueDef) {
      console.warn('Invalid tenant config')
      return
    }

    onChange(checked
      ? checkedFieldValueDef.id
      : uncheckedFieldValueDef.id,
    )
  }

  return (
    <DNABox appearance="col">
      <DNABox appearance="row" style={{ margin: 8 }}>
        <DNACheckbox
          disabled={disabled || isReadOnly}
          checked={isChecked}
          onChange={handleSelect}
          status={isVirtual ? 'gray' : undefined}
        />
        <DNAText style={{ marginLeft: 8 }} status={isVirtual ? 'basic' : undefined}>
          { checkedFieldValueDef?.label ?? '(Invalid field config)' }
        </DNAText>
      </DNABox>

    </DNABox>
  )
};

const CheckBoxList: React.FC<IFieldComponentProps<string[]>> = (props) => {
  const { onChange, value, field, disabled, dependencyValues, variant, isReadOnly } = props
  const { activeFieldValueDefs } = useFilterVaDef(field, value, dependencyValues)

  const isVirtual = variant === ComposableVariant.VIRTUAL

  const selectedValues =  Array.isArray(value)
    ? value?.reduce<Record<string, boolean>>(
      (acc, val) => ({ ...acc, [val]: true }),
      { },
    ) ?? { }
    : { }

  const handleSelect = (fieldValueId: string) => (checked: boolean) => {
    const nextValues = checked
      ? value
        ? [...value, fieldValueId]
        : [fieldValueId]
      : value
        ? value.filter(prevId => prevId !== fieldValueId)
        : []

    onChange(nextValues)
  }

  return (
    <DNABox appearance="col">
      {
        activeFieldValueDefs.map(valDef => {
          return (
            <DNABox key={valDef.id} appearance="row" style={{ margin: 8 }}>
              <DNACheckbox
                disabled={disabled || valDef.disabled || isReadOnly}
                key={valDef.id}
                checked={selectedValues[valDef.id]}
                onChange={handleSelect(valDef.id)}
                status={isVirtual ? 'gray' : undefined}
              />
              <DNAText style={{ marginLeft: 8 }} status={isVirtual ? 'basic' : undefined}>{valDef.value}</DNAText>
            </DNABox>
          )
        })
      }
    </DNABox>
  )
}

const RadioList: React.FC<IFieldComponentProps<string>> = (props) => {
  const { onChange, value, field, disabled, dependencyValues, variant, isReadOnly } = props
  const { activeFieldValueDefs } = useFilterVaDef(field, value, dependencyValues)
  const isVirtual = variant === ComposableVariant.VIRTUAL

  const selectedFieldValueIdx = activeFieldValueDefs
    .findIndex(fieldValDef => fieldValDef.id === value)

  const selectedDisplayValue = activeFieldValueDefs[selectedFieldValueIdx]?.label ??
    activeFieldValueDefs[selectedFieldValueIdx]?.value

  const handleSelect = (index: number): void => {
    const targetFieldValDefId = activeFieldValueDefs[index]
    !targetFieldValDefId.disabled && onChange(targetFieldValDefId.id)
  }

  if (isReadOnly) return <ReadOnly {...props} value={selectedDisplayValue} />

  return (
    <RadioGroup.Kitten
      selectedIndex={selectedFieldValueIdx}
      onChange={handleSelect}
    >
      {activeFieldValueDefs
        .map(fieldValueDef => (
          <RadioItem.Kitten
            key={`${fieldValueDef.id}`}
            disabled={disabled || fieldValueDef.disabled}
          >
            {() => (
              <DNAText
                status={isVirtual ? 'basic' : undefined}
                style={{ marginLeft: 8 }}
              >
                {fieldValueDef.label ?? fieldValueDef.value}
              </DNAText>
            )}
          </RadioItem.Kitten>
        ))}
    </RadioGroup.Kitten>
  )
}

// [TODO-3073] - Consider adding support for scrolling calendar into view
//             - See older DocumentInfo commits for example
const DatePicker: React.FC<IFieldComponentProps<string>> = (props) => {
  const { onChange, value, field, disabled, status, isReadOnly } = props

  const onDateSelected = (date: Date) => {
    onChange(date.toISOString())
  }
  const dateRestriction = getDateRestriction(field.dateRestriction)

  const selectedDate = value
    ? new Date(value)
    : undefined

  if (isReadOnly) {
    return (<ReadOnly {...props} value={selectedDate?.toLocaleDateString()} />)
  }

  return (<UIDatePicker
    key={field.id}
    onDateSelected={onDateSelected}
    selectedDate={selectedDate}
    disabled={disabled}
    {...dateRestriction}
    showMonthYearDropDown
    status={status}
  />
  )
}

const Input: React.FC<IFieldComponentProps<string>> = (props) => {
  const { onChange, onBlur, value, field, disabled, placeholder, onSubmit, status, isReadOnly } = props

  if (isReadOnly) {
    return (<ReadOnly {...props} />)
  }

  const onChangeText = (text: string): void => {
    if (field.fieldType === FIELD_DATA_TYPE.NUMBER) {
      const number = Number(text)
      if (isNaN(number)) return
    }
    onChange(text.trimStart());
  }

  return (
    <TextField.Kitten
      key={field.id}
      onBlur={onBlur}
      onChangeText={onChangeText}
      value={value}
      maxLength={field.maxLength}
      numberOfLines={1}
      disabled={disabled}
      placeholder={placeholder}
      onSubmitEditing={onSubmit}
      status={status}
    />
  )
}

const TextArea: React.FC<IFieldComponentProps<string>> = (props) => {
  const { onChange, onBlur, value, field, status, isReadOnly } = props

  if (isReadOnly) {
    return (<ReadOnly {...props} />)
  }

  const isDocTitle = field.id === 'title'

  const onChangeText = (text: string): void => {
    onChange(text.trimStart());
  }

  return (
    // [TODO] - Find a better way to address thi, currently the idea is fix an overflow when this component renders at last
    <DNABox testID="text-area-box" appearance="col" fill>
      <TextField.Kitten
        key={field.id}
        onBlur={onBlur}
        onChangeText={onChangeText}
        value={value}
        multiline={true}
        disabled={props.disabled}
        maxLength={field.maxLength}
        numberOfLines={isDocTitle ? 2 : 10}
        placeholder={field.fieldLabel}
        onKeyPress={e => {
          if (isDocTitle && e.nativeEvent.key === 'Enter') e.preventDefault()
        }}
        status={status}
      />
    </DNABox>
  )
}

export const ReadOnly = (props: IFieldComponentProps<string|string[]>) => {
  const { value, field } = props
  const textValue = value ?? 'NA'
  return (
    <DNAText
      key={field.id}
    >
      {textValue}
    </DNAText>
  )
}

const CharCounter: React.FC<{
  field: CustomFieldDefinition,
  variant: ComposableVariant
}> = (props) => {
  const { field, variant } = props
  const { rhForm } = useComposableForm()
  const variantStyle = composableVariantStyles[variant]

  const fieldControl = useWatch<FieldValues>({
    control: rhForm.control,
    name: field.id,
  })

  if (!field.maxLength) return null;

  return (
    <DNAText style={variantStyle.defaultCharCount} >
      {fieldControl?.length ?? 0}/{field.maxLength ?? 1}
    </DNAText>
  )
}

const getDateRestriction = (restriction : DateRestriction | keyof typeof DateRestriction | undefined ) => {
  return !restriction ? {}
    : restriction === DateRestriction.ONLY_FUTURE ? { minDate: new Date() } : { maxDate: new Date() };
}

// [TODO-3073] - Can probably just use DatePicker above but forward UIDatePicker props and spread
export const ComposableDateTimePicker: React.FC<IFieldComponentProps<string>> = (props) => {
  const { onChange, value, field, disabled, status, isReadOnly } = props

  const localValue = value ? new Date(value).toLocaleString() : ''

  if (isReadOnly) {
    return (<ReadOnly {...props} value={localValue} />)
  }

  const dateRestriction = getDateRestriction(field.dateRestriction)

  const onDateSelected = (date: Date) => {
    onChange(date.toISOString())
  }

  const selectedDate = value
    ? new Date(value)
    : undefined

  return (
    <UIDatePicker
      key={field.id}
      onDateSelected={onDateSelected}
      selectedDate={selectedDate}
      disabled={disabled}
      includeTimePicker
      timeIntervals={15}
      {...dateRestriction}
      dateFormat={'MM/dd/yyyy h:mm aa'}
      status={status}
    />
  )
}

// [NOTE] - Currently we only use the `status` prop for DNASelect usecases
//        - We asssume this `status' will work for all components, but we might have to support more/generic types
//          since `FieldComponents` expects a uniform prop definition if we need to support more `status` values
const FieldComponents: Record<
  ControlType,
  React.FC<IFieldComponentProps<any, { status: DNASelectProps['status'] }>>
> = ({
  [ControlType.CHECKBOXLIST]: CheckBoxList,
  [ControlType.CHECKBOX]: CheckBox,
  [ControlType.DATEPICKER]: DatePicker,
  [ControlType.SELECT]: Select,
  [ControlType.RADIOLIST]: RadioList,
  [ControlType.INPUT]: Input,
  [ControlType.TEXTAREA]: TextArea,
  [ControlType.USERLIST]: UserListSelect,
  [ControlType.CUSTOM]: () => null,
  [ControlType.OBJECT]: () => null,
  [ControlType.LABEL]: () => null,
  [ControlType.CRMATTENDEELIST]: CRMAttendeeList,
  [ControlType.CRMACCOUNTSEARCHER]: CRMAccountSearcher,
  [ControlType.DATETIMEPICKER]: ComposableDateTimePicker,
})

/*
  Converts a field definition into a map of field value definitions
*/
const fieldDefToMap = (fieldDef: CustomFieldDefinition) => {
  return fieldDef.fieldValueDefinitions.reduce<Record<string, CustomFieldValueDefinition>>((acc, fieldValDef) => {
    acc[fieldValDef.id] = fieldValDef
    return acc
  }, {})
}

/*
  This method is used to determine if we should reset children values based on the parent values
  @params rhForm - the form instance
  @params field - the field definition
  @params dependencyValues - the current values of the parent field
*/
const unselectDependentValues = (rhForm: RHForm, field: CustomFieldDefinition, dependencyValues: string | string[]) => {
  const selectedIds = !field.id.includes('.')
    ? rhForm.getValues()[field.id] as string | string[]
    : getValueFromChildField(rhForm.getValues(), field.id)

  const hasAvailableValues = dependencyCFHasAvailableValues(field, dependencyValues)

  if (!hasAvailableValues) {
    // check if there is some errors on rhForm
    const errors = rhForm.formState.errors
    const fieldIdErrors = errors[field.id]
    if (fieldIdErrors) {
      // if there is some errors, we need to clear the errors
      rhForm.clearErrors(field.id)
    }
  }

  if (!field.dependentFieldId) return
  if (!selectedIds) return

  const fieldValueDefMap = fieldDefToMap(field)
  const parentHasValue = !field.dependentFieldId.includes('.')
    ? rhForm.getValues()[field.dependentFieldId]
    : getValueFromChildField(rhForm.getValues(), field.dependentFieldId)

  if (!parentHasValue) {
    rhForm.setValue(field.id, '', { shouldDirty: false })
    return
  }

  if (Array.isArray(selectedIds)) {
    if (!selectedIds.length) return

    const allowedValues = selectedIds
      .filter(id => {
        if (!fieldValueDefMap[id]) return false

        const dependentValueIds = fieldValueDefMap[id].dependentValueIds
        if (!dependentValueIds.length) return true

        return dependentValueIds.some(e => dependencyValues.includes(e))
      })

    const fieldIdValues = get(rhForm.getValues(), field.id, undefined)
    const areEquals = isEqual(fieldIdValues, allowedValues)
    if (areEquals) return

    // when the field is categorical and the allowedValues is empty, we need to clear the field with empty string
    if (field.fieldType === FIELD_DATA_TYPE.CATEGORICAL && !allowedValues.length) {
      rhForm.setValue(field.id, '', { shouldDirty: true })
      return
    }

    // when the field is multicategorical and the allowedValues is empty, we need to clear the field with empty array
    rhForm.setValue(field.id, allowedValues, { shouldDirty: true })
  }
  else {
    if (isEmpty(fieldValueDefMap)) return

    const selectedId = selectedIds
    if (!fieldValueDefMap[selectedId]) {
      rhForm.setValue(field.id, '', { shouldDirty: true })
      return
    }

    const dependentValueIds = fieldValueDefMap[selectedId].dependentValueIds
    if (!dependentValueIds.length) return

    if (!dependentValueIds.some(e => dependencyValues.includes(e))) {
      rhForm.setValue(field.id, '', { shouldDirty: true })
    }
  }
}

/*
  This method will throw an error if the selected values for a child field are not valid
*/
const validateDependencyIntegrity = (
  rhForm: RHForm,
  field: CustomFieldDefinition,
  dependencyValues: string | string[],
) => {
  if (!field.dependentFieldId) return

  if (!dependencyValues.length) {
    const selectedIds = !field.id.includes('.')
      ? rhForm.getValues()[field.id] as string | string[]
      : getValueFromChildField(rhForm.getValues(), field.id)

    if (!selectedIds) return
    if (!Array.isArray(selectedIds)) return

    const fieldDefMap = fieldDefToMap(field)

    // each id in selectedIds should have a dependentValueId in the parent
    const invalidValues = selectedIds
      .filter(id => {
        if (!fieldDefMap[id]) return false

        const dependentValueIds = fieldDefMap[id].dependentValueIds
        if (!dependentValueIds.length) return true

        return dependentValueIds.some(e => dependencyValues.includes(e))
      },
      )

    if (invalidValues.length) {
      throw new Error(`Invalid values selected for ${field.fieldLabel}`)
    }
  }
}

/*
  This method fisrt unselects any values that are not valid based on the parent values (this is required due the component may be hide but the value in the form may still be selected)
  Then it validates the integrity of the child values
*/
const handleFormSideEffects = (
  rhForm: RHForm,
  field: CustomFieldDefinition,
  dependencyValues: string | string[]|undefined) => {
  if (dependencyValues === undefined) return

  unselectDependentValues(rhForm, field, dependencyValues)
  validateDependencyIntegrity(rhForm, field, dependencyValues)
}

/*
  This hook is used to determine if we should render a field based on the parent values
  @params rhForm - the form instance
  @params field - the field definition
*/
const useDependencyUtilities = (rhForm: RHForm, field: CustomFieldDefinition): DependencySettings => {
  const { dependentFieldId } = field
  const dependencyValues = useWatch<FieldValues>({
    control: rhForm.control,
    name: dependentFieldId || 'no-watch-form-value',
  })

  useEffect(() => handleFormSideEffects(rhForm, field, dependencyValues), [dependencyValues, rhForm, field])

  return useMemo(() => {
    const parentSelectedValues = dependencyValues
      ? (Array.isArray(dependencyValues)
        ? dependencyValues : [dependencyValues])
      : []

    let canBeRendered = field.displayOnParentSelection === undefined
      ? true
      : field.displayOnParentSelection === true
        ? !!parentSelectedValues.length
        : true

    if (field.displayOnValueSelection?.length) {
      // check if the parent selected values are in the displayOnValueSelection
      canBeRendered = field.displayOnValueSelection.some(e => parentSelectedValues.includes(e))
      if (!canBeRendered) {
        return {
          canBeRendered: false,
          parentSelectedValues,
        }
      }
    }

    return {
      canBeRendered,
      parentSelectedValues,
    }
  }, [dependencyValues, field, rhForm])
};

export type ComposableFieldProps = {
  field: CustomFieldDefinition;
  // [TODO-TS] - CustomFormComponents should probably accept refs as the `<Controller>` renderProps will attempt to pass one in
  CustomFormComponent?: React.FC<IFieldComponentProps>;
  hideRequiredLabel?: boolean;
  variant?: ComposableVariant;
  disabled?: boolean;
  hideError?: boolean;
  hintMessage?: string;
  placeholder?: string;
  onSubmit?: () => void;
  childFields?: CustomFieldDefinition[];
  defaultChildValue?: string | string[],
}

const IsRequiredLabel : React.FC<{
  field: CustomFieldDefinition,
  hideRequiredLabel?: boolean | '' | undefined,
  variant: ComposableStyles,
}> = (props) => {
  const { field, hideRequiredLabel, variant } = props
  const { rhForm, isReadOnly } = useComposableForm()

  const shouldRenderRequiredMark = useCallback((field: CustomFieldDefinition) => {
    if (!field.required) return false
    const parentValues = get(rhForm.getValues(), field.dependentFieldId || '', undefined)
    const hasAvailableValues = field
      .fieldValueDefinitions.some(fieldValDef => fieldValDef.dependentValueIds.some(e =>
        (Array.isArray(parentValues) ? parentValues : [parentValues]).some((value) => value === e),
      ));

    return hasAvailableValues
  }, [])

  if (field.dependentFieldId) {
    return shouldRenderRequiredMark(field) ? <DNAText style={variant.required}>*</DNAText> : null
  }

  return (
    <Iffy is={field.required && !isReadOnly && !hideRequiredLabel}>
      <DNAText style={variant.required}>*</DNAText>
    </Iffy>
  )
}

interface SectionLabelProps {
  description?: string,
  label: string,
  variant?: ComposableVariant,
}

export const LabelField = (props: SectionLabelProps) => {
  const { description, label, variant = ComposableVariant.DEFAULT } = props;
  const variantStyle = composableVariantStyles[variant];

  return (
    <DNABox fill appearance="col" style={styles.labelFieldWrapper}>
      <DNAText style={{ ...variantStyle.title, fontSize: 14, textTransform: 'capitalize' }}>{label}
      </DNAText>
      <Iffy is={description}>
        <DNAText style={{ ...variantStyle.description }}>{description}</DNAText>
      </Iffy>
    </DNABox>)
};

export const ComposableField: React.FC<ComposableFieldProps> = (props) => {
  const {
    field,
    CustomFormComponent,
    variant = ComposableVariant.DEFAULT,
    disabled: disabledProp,
    hintMessage,
    placeholder,
    hideError,
    onSubmit,
    defaultChildValue,
  } = props

  const { rhForm, values, disabled, getChildFields, isReadOnly } = useComposableForm()
  const childFields = useMemo(() => getChildFields(field.id), [field.id, field.createdAt]);

  const variantStyle = composableVariantStyles[variant]
  const dependencyValues = useDependencyUtilities(rhForm, field);

  // [NOTE] - or check against validFieldControlTypes for a config
  const fieldConfig = validFieldControlTypes[field.fieldType][field.controlType]

  const hideRequiredLabel = useMemo(() => {
    const hidelRequiredLabelDueToParent =
    field.dependentFieldId && !dependencyCFHasAvailableValues(field, rhForm.getValues()[field.dependentFieldId]);
    return props.hideRequiredLabel || hidelRequiredLabelDueToParent;
  }, [field, props.hideRequiredLabel, rhForm]);

  if ((field.isChildField && !(field.id.includes('.')))) {
    return null;
  } else if (!dependencyValues.canBeRendered) {
    const defaultValue = getValueFromObject(values, field);
    return (
      <HiddenField value={defaultValue} field={field} control={rhForm.control} />
    );
  }

  const error = !field.isChildField
    ? rhForm.formState.errors[field.id]
    : getErrorFromChildField(rhForm.formState.errors, field.id)

  if (field.controlType === ControlType.LABEL) {
    return (
      <LabelField
        variant={variant}
        label={field.fieldLabel}
        description={field.description}
      />
    );
  }

  return (
    <DNABox
      testID={`title-${field.fieldLabel.toLowerCase().replace(/ /g, '-')}`}
      appearance="col"
    >
      <Iffy is={field.controlType !== 'OBJECT'}>
        <DNABox alignY="center">
          <DNABox alignY="center" fill>
            <IsRequiredLabel
              field={field}
              hideRequiredLabel={hideRequiredLabel}
              variant={variantStyle}
            />

            {/* FIELD NAME LABEL */}
            <DNAText style={variantStyle.title}>{field.fieldLabel}</DNAText>

            {/* DESCRIPTION */}
            <Iffy is={field.description}>
              <DNAPopover >
                <DNAPopover.Anchor>
                  <DNAIcon
                    testID={`icon-help-circle-outline-${field.fieldLabel.toLowerCase().replace(/ /g, '-')}`}
                    style={variantStyle.helpToolTipIconStyle}
                    name="help-circle-outline"
                  />
                </DNAPopover.Anchor>
                <DNAPopover.Content>
                  <DNAText
                    testID={`${field.fieldLabel.toLowerCase().replace(/ /g, '-')}-tooltip-text`}
                    style={{ color: colors['color-text-white'], marginHorizontal: 12 }}
                  >
                    {field.description}
                  </DNAText>
                </DNAPopover.Content>
              </DNAPopover>
            </Iffy>
          </DNABox>

          {/* CHAR COUNTER */}
          <Iffy is={!isReadOnly}>
            <DNABox alignX="end" alignY="center">
              <Iffy is={fieldConfig?.showCharCount}>
                <CharCounter field={field} variant={variant} />
              </Iffy>
            </DNABox>
          </Iffy>
        </DNABox>
      </Iffy>

      {/* FORM COMPONENT */}
      <DNABox
        style={{ marginTop: 4 }}
        fill
        appearance="col"
        testID={`${field.fieldLabel.toLowerCase().replace(/ /g, '-')}-input`}
      >
        <Iffy is={field.controlType !== 'OBJECT'}>
          <Controller
            name={field.id}
            control={rhForm.control}
            defaultValue={defaultChildValue}
            render={(renderProps) => {
              const ErrorStatus = (!!error && !hideError && !isReadOnly)
                ? 'danger'
                : undefined
              if (CustomFormComponent) {
                return (
                  <CustomFormComponent
                    isReadOnly={isReadOnly}
                    disabled={disabledProp || disabled}
                    field={field}
                    values={values}
                    dependencyValues={dependencyValues}
                    status={ErrorStatus}
                    variant={variant}
                    {...renderProps.field}
                  />
                )
              }
              const FieldComponent = FieldComponents[field.controlType]
              return (<FieldComponent
                dependencyValues={dependencyValues}
                field={field}
                childFields={childFields}
                isReadOnly={isReadOnly}
                values={values}
                placeholder={placeholder}
                onSubmit={onSubmit}
                status={ErrorStatus}
                variant={variant}
                {...renderProps.field}
                disabled={disabledProp || disabled}
              />)
            }}
          />
        </Iffy>
        <Iffy is={field.controlType === 'OBJECT'}>
          <ObjectFieldWrapper
            disabled={disabledProp || disabled}
            field={field}
            childFields={childFields}
            isReadOnly={isReadOnly}
            control={rhForm.control}
            variant={variant}
          />
        </Iffy>

      </DNABox>

      {/* HINT MESSAGE */}
      <Iffy is={hintMessage && !isReadOnly}>
        <DNAText p2 status="flatAlt">{hintMessage}</DNAText>
      </Iffy>

      {/* ERROR LABEL */}
      {error && !hideError && !isReadOnly &&
        <DNAText testID="error-message-text" style={{ color: colors['color-danger-500'] }}>
          <ErrorMessage errors={rhForm.formState.errors} fieldId={field.id} />
        </DNAText>
      }
    </DNABox>
  )
}

const getValueFromObject = (
  object: CustomFieldValuesMap,
  field: CustomFieldDefinition): string | string[] | ObjectWithId[] | undefined => {
  if (field.id in object) {
    return object[field.id].values;
  } else if (field.isChildField) {
    const [parentId, position, fieldName] = field.id.split('.');
    return object[parentId]?.objectValues?.[Number.parseInt(position, 10)]?.customFieldValues[fieldName]?.values;
  }

  return undefined;
}

const ErrorMessage: React.FC<{
  errors: FieldErrors<FormValuesType>
  fieldId: string
}> = (props) => {
  const { errors, fieldId } = props

  const error = !fieldId.includes('.') ? errors[fieldId] : getErrorFromChildField(errors, fieldId)

  return (
    <>
      {error &&
        <DNAText style={{ color: colors['color-danger-500'], marginTop: 4 }}>
          <>
            {Array.isArray(error)
              ? error.map((e, idx) => <DNABox key={`${fieldId}_${idx}_error_message`}>{e.message}</DNABox>)
              : error.message}
          </>
        </DNAText>
      }
    </>
  )
}
