import { State as StateDef, ResolveTypegenMeta, BaseActionObject } from 'xstate';
import type * as SlideSettings from './slideSettings.types';
import { Typegen0 } from './slideSettings.machine.typegen';

// [NOTE] - Normally we can use some util helpers, other TS access to get this
//          but it's possible to run into a circular dependency error when using selector as machine guards
//        - This probably creates a 2nd instance of the State type but it's needed for now
type State = StateDef<
  SlideSettings.SMContext,
  SlideSettings.SMEvents,
  any,
  { value: any, context: SlideSettings.SMContext },
  ResolveTypegenMeta<
    Typegen0,
    SlideSettings.SMEvents,
    BaseActionObject,
    SlideSettings.SMServices
  >
>

// CONTEXT
export const versionDraft = (state: State) => ({ versionDraft: state.context.versionDraft })
export const associatedSlides = (state: State) => ({ associatedSlides: state.context.associatedSlides })
export const selectedRemoveAssociatedSlides = (state: State) =>
  ({ selectedRemoveAssociatedSlides: state.context.selectedRemoveAssociatedSlides })
export const selectedCoverThumbnail = (state: State) =>
  ({ selectedCoverThumbnail: state.context.selectedCoverThumbnail })
export const selectedRequiredSlides = (state: State) =>
  ({ selectedRequiredSlides: state.context.selectedRequiredSlides })
export const groupings = (state: State) => ({ groupings: state.context.groupings })

// CHECK STATE
export const isIdleState = (state: State) => ({ isIdleState: state.matches('idle') })
export const isAddAssociatedSlidesMode = (state: State) =>
  ({ isAddAssociatedSlidesMode: state.matches('addAssociatedSlides') })
export const isAddAssociatedSlidesStepOne = (state: State) =>
  ({ isAddAssociatedSlidesStepOne: state.matches('addAssociatedSlides.stepOne') })
export const isAddAssociatedSlidesStepTwo = (state: State) =>
  ({ isAddAssociatedSlidesStepTwo: state.matches('addAssociatedSlides.stepTwo') })
export const isRemoveAssociatedSlidesMode = (state: State) =>
  ({ isRemoveAssociatedSlidesMode: state.matches('removeAssociatedSlides') })
export const isSetCoverThumbnailMode = (state: State) =>
  ({ isSetCoverThumbnailMode: state.matches('setCoverThumbnail') })
export const isSetRequiredSlidesMode = (state: State) =>
  ({ isSetRequiredSlidesMode: state.matches('editRequiredSlides') })
export const isSetGroupSlidesMode = (state: State) =>
  ({ isSetGroupSlidesMode: state.matches('editGroupSlides') })

// TAGS
export const isAssociatedSlidesMode = (state: State) =>
  ({ isAssociatedSlidesMode: state.tags.has('SLIDES_SETTING_MODAL') })

// CAN
export const canGoToNextStepInAssociatedSlidesModal = (state: State) =>
  ({ canGoToNextStepInAssociatedSlidesModal: state.can({ type: 'ASSOCIATED_SLIDES_NEXT_STEP' }) })
export const canSaveAssociatedSlides = (state: State) =>
  ({ canSaveAssociatedSlides: state.can({ type: 'SAVE_ASSOCIATED_SLIDES' }) })
export const canSaveCoverThumbnail = (state: State) =>
  ({ canSaveCoverThumbnail: state.can({ type: 'SAVE_COVER_THUMBNAIL' }) })
export const canSaveRequiredSlides = (state: State) =>
  ({ canSaveRequiredSlides: state.can({ type: 'SAVE_REQUIRED_SLIDES' }) })
export const canAddGroupSlide = (state: State) =>
  ({ canAddGroupSlide: state.can({ type: 'ADD_GROUP_SLIDES', payload: { groupName: 'NA' } }) })
export const canSaveGroupSlide = (state: State) =>
  ({ canSaveGroupSlide: state.can({ type: 'SAVE_GROUP_SLIDES' }) })

// ADD ASSOCIATED SLIDES MODAL
export const addAssociatedSlidesSelectedChild = (state: State) => {
  const _associatedSlides = associatedSlides(state).associatedSlides;
  const allSelectedSlides: string[] = [];
  Object.keys(_associatedSlides.child).forEach(id => {
    if (_associatedSlides.child[id]) allSelectedSlides.push(id)
  })
  return { addAssociatedSlidesSelectedChild: allSelectedSlides.sort() }
}

export const addAssociatedSlidesNumberOfSlidesSelected = (state: State) => {
  const _associatedSlides = associatedSlides(state).associatedSlides;
  const _isAddAssociatedSlidesStepOne = isAddAssociatedSlidesStepOne(state).isAddAssociatedSlidesStepOne;
  const numberOfSlidesSelected = _isAddAssociatedSlidesStepOne
    ? Object.values(_associatedSlides.child).filter((value) => value)?.length || 0
    : Object.values(_associatedSlides.parent).filter((value) => value)?.length || 0
  return { addAssociatedSlidesNumberOfSlidesSelected: numberOfSlidesSelected }
}

export const addAssociatedSlidesSelectableNumberOfSlides = (state: State) => {
  const _versionDraft = versionDraft(state).versionDraft;
  const _isAddAssociatedSlidesStepOne = isAddAssociatedSlidesStepOne(state).isAddAssociatedSlidesStepOne;
  const _isAddAssociatedSlidesStepTwo = isAddAssociatedSlidesStepTwo(state).isAddAssociatedSlidesStepTwo;
  const _associatedSlides = associatedSlides(state).associatedSlides;
  const SelectableNumberOfSlides = _versionDraft.pages?.reduce((acc, page) => {
    const allChildSlide = {}
    _versionDraft.pages?.forEach(page => {
      page.linkedSlides?.forEach(id => {
        allChildSlide[id] = true
      })
    })
    const disabled = (_isAddAssociatedSlidesStepOne && !!page.linkedSlides?.length) ||
      (_isAddAssociatedSlidesStepTwo && !!allChildSlide[page.pageId]) ||
      (_isAddAssociatedSlidesStepTwo && _associatedSlides.child[page.pageId])
    if (disabled) return acc
    else return acc + 1
  }, 0)
  return { addAssociatedSlidesSelectableNumberOfSlides: SelectableNumberOfSlides }
}
