/* eslint-disable max-len */
import * as React from 'react';
import Svg, { Path, Circle, Rect } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgBeaconEmptyState = (props: SvgProps) => (
  <Svg
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 352 352"
    width="100%"
    height="100%"
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <Path fill="#fff" d="M0 0h352v352H0z" />
    <Circle cx={176} cy={176} r={39.5} stroke="#EEF2FF" />
    <Circle cx={176} cy={176} r={69.5} stroke="#EEF2FF" opacity={0.8} />
    <Circle cx={176} cy={176} r={99.5} stroke="#EEF2FF" opacity={0.6} />
    <Circle cx={176} cy={176} r={129.5} stroke="#EEF2FF" opacity={0.4} />
    <Circle cx={176} cy={176} r={159.5} stroke="#EEF2FF" opacity={0.3} />
    <Path fill="#fff" d="M131 136h32v32h-32z" />
    <Path
      fill="#4F46E5"
      d="M157 145v14a.997.997 0 0 1-1 1h-3v-11l-5-5h-7v-3a.997.997 0 0 1 1-1h10z"
      opacity={0.2}
    />
    <Path
      fill="#4F46E5"
      d="m157.708 144.293-5-5A1 1 0 0 0 152 139h-10a2 2 0 0 0-2 2v2h-2a2 2 0 0 0-2 2v18a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-2h2a2 2 0 0 0 2-2v-14a1 1 0 0 0-.292-.707M152 163h-14v-18h9.586l4.414 4.414V163m4-4h-2v-10a1 1 0 0 0-.292-.708l-5-4.999A1 1 0 0 0 148 143h-6v-2h9.586l4.414 4.414zm-7-4a.997.997 0 0 1-1 1h-6a.997.997 0 0 1-1-1 .997.997 0 0 1 1-1h6a.997.997 0 0 1 1 1m0 4a.997.997 0 0 1-1 1h-6a.997.997 0 0 1-1-1 .997.997 0 0 1 1-1h6a.997.997 0 0 1 1 1"
    />
    <Path fill="#fff" d="M194 174h32v32h-32z" />
    <Path
      fill="#4F46E5"
      d="M220 185v3h-17.279a.995.995 0 0 0-.948.684L198 200v-18a.997.997 0 0 1 1-1h6.666c.217 0 .427.07.6.2L210 184h9a.997.997 0 0 1 1 1"
      opacity={0.2}
    />
    <Path
      fill="#4F46E5"
      d="M224.625 187.83A2 2 0 0 0 223 187h-2v-2a2 2 0 0 0-2-2h-8.666l-3.466-2.6a2 2 0 0 0-1.201-.4H199a2 2 0 0 0-2 2v18a.997.997 0 0 0 1 1h22.388a1 1 0 0 0 .948-.684l3.561-10.684a2 2 0 0 0-.272-1.802M205.667 182l3.733 2.8a1 1 0 0 0 .6.2h9v2h-16.279a2 2 0 0 0-1.897 1.368L199 193.837V182zm14 17h-20.28l3.334-10H223z"
    />
    <Rect width={28} height={28} x={34} y={145} fill="#EEF2FF" rx={6} />
    <Path
      fill="#4F46E5"
      d="M55.5 153.375v9.536l-3.103-3.103a.626.626 0 0 0-.884 0l-2.004 2.004-3.88-3.879a.633.633 0 0 0-.884 0l-4.245 4.246v-8.804a.624.624 0 0 1 .625-.625h13.75a.626.626 0 0 1 .625.625"
      opacity={0.2}
    />
    <Path
      fill="#4F46E5"
      d="M54.875 152.125h-13.75a1.25 1.25 0 0 0-1.25 1.25v11.25a1.25 1.25 0 0 0 1.25 1.25h13.75a1.25 1.25 0 0 0 1.25-1.25v-11.25a1.25 1.25 0 0 0-1.25-1.25m0 1.25v8.027l-2.037-2.036a1.25 1.25 0 0 0-1.362-.271q-.23.096-.406.271l-1.562 1.563-3.438-3.438a1.25 1.25 0 0 0-1.767 0l-3.178 3.179v-7.295zm-13.75 9.063 4.063-4.063 6.25 6.25H41.125zm13.75 2.187h-1.67l-2.812-2.813 1.563-1.562 2.919 2.92zm-5.625-7.813a.935.935 0 0 1 1.12-.919.937.937 0 1 1-1.12.919"
    />
    <Rect width={28} height={28} x={93} y={90} fill="#EEF2FF" rx={6} />
    <Path
      fill="#4F46E5"
      d="M113.875 97.75h-13.75a.624.624 0 0 0-.625.625v8.75a.624.624 0 0 0 .625.625h13.75a.625.625 0 0 0 .625-.625v-8.75a.626.626 0 0 0-.625-.625m-8.125 7.5v-5l3.75 2.5z"
      opacity={0.2}
    />
    <Path
      fill="#4F46E5"
      d="m109.847 102.23-3.75-2.5a.626.626 0 0 0-.972.52v5a.626.626 0 0 0 .972.52l3.75-2.5a.624.624 0 0 0 0-1.04m-3.472 1.852v-2.66l1.998 1.328zm7.5-6.957h-13.75a1.25 1.25 0 0 0-1.25 1.25v8.75a1.25 1.25 0 0 0 1.25 1.25h13.75a1.25 1.25 0 0 0 1.25-1.25v-8.75a1.25 1.25 0 0 0-1.25-1.25m0 10h-13.75v-8.75h13.75zm1.25 3.125a.625.625 0 0 1-.625.625h-15a.626.626 0 0 1 0-1.25h15a.625.625 0 0 1 .625.625"
    />
    <Rect width={28} height={28} x={254} y={201} fill="#EEF2FF" rx={6} />
    <Path
      fill="#4F46E5"
      d="M273 212.5a4.995 4.995 0 0 1-3.087 4.619 5.007 5.007 0 0 1-5.449-1.083 5.01 5.01 0 0 1-1.083-5.449A4.99 4.99 0 0 1 268 207.5c1.326 0 2.598.527 3.536 1.464A5 5 0 0 1 273 212.5"
      opacity={0.2}
    />
    <Path
      fill="#4F46E5"
      d="M276.041 221.563c-1.19-2.057-3.024-3.532-5.164-4.232a5.62 5.62 0 0 0 2.546-6.325 5.62 5.62 0 0 0-5.423-4.133 5.62 5.62 0 0 0-5.423 4.133 5.62 5.62 0 0 0 2.546 6.325c-2.14.699-3.974 2.174-5.164 4.232a.6.6 0 0 0-.085.232.616.616 0 0 0 .12.471.61.61 0 0 0 .421.243.6.6 0 0 0 .467-.131.6.6 0 0 0 .159-.19c1.472-2.544 4.074-4.063 6.959-4.063s5.487 1.519 6.959 4.063a.62.62 0 0 0 .626.321.63.63 0 0 0 .529-.466.7.7 0 0 0 .012-.248.6.6 0 0 0-.085-.232m-12.416-9.063a4.376 4.376 0 1 1 6.806 3.638c-.72.48-1.566.737-2.431.737a4.38 4.38 0 0 1-4.375-4.375"
    />
    <Rect width={28} height={28} x={169} y={60} fill="#EEF2FF" rx={6} />
    <Path
      fill="#4F46E5"
      d="m190.895 72.5-3.804 3.28 1.162 4.906a.65.65 0 0 1-.25.685.65.65 0 0 1-.728.031L183 78.772l-4.275 2.63a.656.656 0 0 1-.978-.717l1.162-4.905-3.804-3.281a.66.66 0 0 1 .022-1.013.66.66 0 0 1 .352-.142l4.993-.406 1.923-4.657a.653.653 0 0 1 1.21 0l1.923 4.656 4.993.407a.66.66 0 0 1 .374 1.155"
      opacity={0.2}
    />
    <Path
      fill="#4F46E5"
      d="M191.686 71.599a1.28 1.28 0 0 0-1.114-.88l-4.61-.372-1.779-4.304A1.275 1.275 0 0 0 183 65.25a1.275 1.275 0 0 0-1.182.793l-1.778 4.303-4.612.373a1.29 1.29 0 0 0-1.115.884 1.29 1.29 0 0 0 .383 1.37l3.516 3.034-1.071 4.536a1.27 1.27 0 0 0 .49 1.337 1.28 1.28 0 0 0 1.424.055L183 79.507l3.948 2.428a1.28 1.28 0 0 0 1.911-1.392l-1.075-4.537 3.516-3.033a1.29 1.29 0 0 0 .386-1.374m-1.199.427-3.804 3.281a.62.62 0 0 0-.2.618l1.162 4.906a.03.03 0 0 1 .001.022.03.03 0 0 1-.014.016c-.014.01-.018.008-.03 0l-4.275-2.63a.62.62 0 0 0-.654 0l-4.275 2.631c-.012.007-.015.01-.03 0a.03.03 0 0 1-.014-.016.03.03 0 0 1 .001-.021l1.162-4.906a.62.62 0 0 0-.2-.618l-3.805-3.282c-.009-.008-.017-.014-.01-.039.008-.024.014-.02.026-.022l4.994-.403a.63.63 0 0 0 .525-.386l1.923-4.658c.007-.013.009-.019.028-.019.018 0 .021.006.027.02l1.928 4.657a.63.63 0 0 0 .527.384l4.994.403c.012 0 .019 0 .026.023.007.022 0 .031-.013.039"
    />
    <Rect width={32} height={32} x={214} y={111} fill="#EEF2FF" rx={8} />
    <Path
      fill="#4F46E5"
      d="M238.25 119.5v3.75h-16.5v-3.75c0-.199.079-.39.22-.53a.75.75 0 0 1 .53-.22h15c.199 0 .39.079.53.22.141.14.22.331.22.53"
      opacity={0.2}
    />
    <Path
      fill="#4F46E5"
      d="M237.5 118h-2.25v-.75a.75.75 0 0 0-.22-.53.747.747 0 0 0-1.06 0 .75.75 0 0 0-.22.53v.75h-7.5v-.75a.75.75 0 0 0-.22-.53.747.747 0 0 0-1.06 0 .75.75 0 0 0-.22.53v.75h-2.25a1.503 1.503 0 0 0-1.5 1.5v15a1.503 1.503 0 0 0 1.5 1.5h15a1.503 1.503 0 0 0 1.5-1.5v-15a1.503 1.503 0 0 0-1.5-1.5m-12.75 1.5v.75c0 .199.079.39.22.53a.747.747 0 0 0 1.06 0 .75.75 0 0 0 .22-.53v-.75h7.5v.75c0 .199.079.39.22.53a.747.747 0 0 0 1.06 0 .75.75 0 0 0 .22-.53v-.75h2.25v3h-15v-3zm12.75 15h-15V124h15zm-6.375-7.125a1.12 1.12 0 0 1-.694 1.039 1.1 1.1 0 0 1-.65.064 1.1 1.1 0 0 1-.576-.308 1.1 1.1 0 0 1-.308-.576A1.12 1.12 0 0 1 230 126.25a1.123 1.123 0 0 1 1.125 1.125m4.125 0a1.12 1.12 0 0 1-.694 1.039 1.1 1.1 0 0 1-.65.064 1.1 1.1 0 0 1-.576-.308 1.1 1.1 0 0 1-.308-.576 1.12 1.12 0 0 1 1.103-1.344 1.123 1.123 0 0 1 1.125 1.125m-8.25 3.75a1.12 1.12 0 0 1-.694 1.039 1.1 1.1 0 0 1-.65.064 1.1 1.1 0 0 1-.576-.308 1.1 1.1 0 0 1-.308-.576 1.12 1.12 0 0 1 1.103-1.344 1.123 1.123 0 0 1 1.125 1.125m4.125 0a1.12 1.12 0 0 1-.694 1.039 1.1 1.1 0 0 1-.65.064 1.1 1.1 0 0 1-.576-.308 1.1 1.1 0 0 1-.308-.576A1.12 1.12 0 0 1 230 130a1.123 1.123 0 0 1 1.125 1.125m4.125 0a1.12 1.12 0 0 1-.694 1.039 1.1 1.1 0 0 1-.65.064 1.1 1.1 0 0 1-.576-.308 1.1 1.1 0 0 1-.308-.576 1.12 1.12 0 0 1 1.103-1.344 1.123 1.123 0 0 1 1.125 1.125"
    />
    <Rect width={32} height={32} x={101} y={198} fill="#EEF2FF" rx={8} />
    <Path fill="#4F46E5" d="M124.5 205.75v15.75h-5.25v-15.75z" opacity={0.2} />
    <Path
      fill="#4F46E5"
      d="M126 220.75h-.75v-15a.75.75 0 0 0-.22-.53.75.75 0 0 0-.53-.22h-5.25a.75.75 0 0 0-.53.22.75.75 0 0 0-.22.53v3.75H114a.75.75 0 0 0-.53.22.75.75 0 0 0-.22.53V214h-3.75a.75.75 0 0 0-.53.22.75.75 0 0 0-.22.53v6H108a.75.75 0 0 0-.53.22.747.747 0 0 0 0 1.06c.14.141.331.22.53.22h18c.199 0 .39-.079.53-.22a.747.747 0 0 0 0-1.06.75.75 0 0 0-.53-.22m-6-14.25h3.75v14.25H120zm-5.25 4.5h3.75v9.75h-3.75zm-4.5 4.5h3v5.25h-3z"
    />
    <Rect width={28} height={28} x={290} y={132} fill="#EEF2FF" rx={6} />
    <Path
      fill="#4F46E5"
      d="M300.875 141a1.877 1.877 0 0 1-2.241 1.839 1.87 1.87 0 0 1-1.473-1.473 1.88 1.88 0 0 1 .797-1.925 1.88 1.88 0 0 1 2.368.233c.351.352.549.829.549 1.326m8.125-1.875a1.877 1.877 0 0 0-1.839 2.241 1.87 1.87 0 0 0 1.473 1.473 1.88 1.88 0 0 0 1.925-.797 1.88 1.88 0 0 0-.233-2.368 1.88 1.88 0 0 0-1.326-.549"
      opacity={0.2}
    />
    <Path
      fill="#4F46E5"
      d="M311.5 141a2.499 2.499 0 0 0-4.031-1.975 2.503 2.503 0 0 0-.28 3.7c.322.338.733.58 1.186.697v1.328a.625.625 0 0 1-.625.625h-7.5a.625.625 0 0 1-.625-.625v-1.328a2.497 2.497 0 0 0 1.855-2.736 2.504 2.504 0 0 0-4.133-1.561c-.456.403-.75.958-.827 1.561a2.497 2.497 0 0 0 1.855 2.736v1.328c0 .497.198.974.549 1.326.352.351.829.549 1.326.549h3.125v1.953a2.497 2.497 0 0 0-1.855 2.736 2.504 2.504 0 0 0 4.133 1.561c.456-.403.75-.958.827-1.561a2.497 2.497 0 0 0-1.855-2.736v-1.953h3.125c.497 0 .974-.198 1.326-.549.351-.352.549-.829.549-1.326v-1.328A2.51 2.51 0 0 0 311.5 141m-13.75 0a1.249 1.249 0 0 1 2.134-.884 1.25 1.25 0 1 1-2.134.884m7.5 10a1.246 1.246 0 0 1-1.494 1.226A1.25 1.25 0 1 1 305.25 151m3.75-8.75a1.246 1.246 0 0 1-1.226-1.494A1.25 1.25 0 1 1 309 142.25"
    />
  </Svg>
);
export default SvgBeaconEmptyState;
