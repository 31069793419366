import React from 'react';
import { ImageBackground, StyleSheet } from 'react-native';
import { DNABox, Iffy, Stack, util } from '@alucio/lux-ui';
import { DNALoaderEvents } from 'src/components/DNA/Loader/DNALoader';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider';
import InPersonActionBar from './InPersonActionBar';
import PlayerWrapper from 'src/components/Presentation/PlayerWrapper';
import TextSearchPanel from 'src/components/TextSearchPanel/TextSearchPanel';
import SlideRollWrapper from 'src/screens/Meetings/SharedComponents/SlideRollWrapper';
import Notes from 'src/screens/Meetings/SharedComponents/Notes/AllNotes';

const S = StyleSheet.create({
  contentContainer: {
    backgroundColor: 'black',
  },
  slidebarContainer: {
    borderRightWidth: 1,
    borderColor: '#383838',
    display: 'none',
  },
  slidebarVisible: {
    display: 'flex',
  },
  contentStack: {
    flex: 1,
    width: '100%',
    height: '100%',
  },
  sidebarStackLayer: {
    height: '100%',
  },
  frameContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  backgroundImage: {
    flex: 1,
  },
})

const InPersonContent: React.FC = () => {
  const { activePresentation, isCustomDeck } = useContent()
  const {
    meetingORM,
    slideRollVisible,
    setSlideRollVisible,
    textSearchVisible,
    setTextSearchVisible,
    openNotes,
  } = useMeetingsState()

  if (!meetingORM) return null

  return (
    <DNABox fill appearance="col" style={S.contentContainer} testID="presentation-content">
      <DNABox fill>
        {/* LEFT SIDEBAR */}
        <Iffy is={activePresentation}>
          {/* SLIDE ROLL */}
          <DNABox
            style={util.mergeStyles(
              undefined,
              S.slidebarContainer,
              [S.slidebarVisible, slideRollVisible],
            )}
          >
            <SlideRollWrapper closeButtonOnPress={() => setSlideRollVisible(false)}/>
          </DNABox>
          {/* TEXT SEARCH */}
          <DNABox
            style={util.mergeStyles(
              undefined,
              S.slidebarContainer,
              [S.slidebarVisible, textSearchVisible],
            )}
          >
            <TextSearchPanel
              onClose={() => { setTextSearchVisible(false) }}
              contentHookVariant="proxied"
            />
          </DNABox>
        </Iffy>
        {/* CONTENT */}
        <Stack anchor="bottom" style={S.contentStack}>
          <Stack.Layer style={S.contentStack}>
            <DNABox fill style={S.frameContainer}>
              {/* BACKGROUND IMAGE */}
              <Iffy is={!activePresentation}>
                <ImageBackground
                  source={require('../../../../assets/images/beacon-presentation-bg.jpg')}
                  style={S.backgroundImage}
                />
              </Iffy>
              {/* IFRAME */}
              <Iffy is={activePresentation}>
                <PlayerWrapper.Highlighter
                  isFullWindow
                  meetingId={meetingORM.model.id}
                  mode="INTERACTIVE"
                  lockAspectRatio={false}
                  isTabletMeetingMode
                  isFromMeetings={true}
                  highlighterVisible
                  analyticsEventType={DNALoaderEvents.MEETING}
                  isCustomDeck={isCustomDeck}
                />
              </Iffy>
              {/* MY NOTES */}
              <Iffy is={openNotes.length}>
                <Notes openNotes={openNotes}/>
              </Iffy>
            </DNABox>
          </Stack.Layer>
        </Stack>
      </DNABox>
      {/* FOOTER / CONTROLS */}
      <InPersonActionBar />
    </DNABox>
  );
};

export default InPersonContent
