import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { DocumentStatus, Page } from '@alucio/aws-beacon-amplify/src/models';
import { getSlideTitle as getSlideTitleHelper } from 'src/utils/thumbnailHelpers';
import useThumbnailSize, { useThumbnailSizeValue } from 'src/hooks/useThumbnailSize/useThumbnailSize';
import useMachineSelector, { composite } from 'src/hooks/useSelector';
import { hasSlideTextInsertionEnabled } from 'src/hooks/useContentPageData/useContentPageData';
import { SlideSettingsService } from 'src/state/machines/publisherVersioning/SlideSettings/slideSettings.types';
import * as slideSettingsSelector from 'src/state/machines/publisherVersioning/SlideSettings/slideSettings.selectors';
import { DocumentVersionORM } from 'src/types/orms';
import { GroupedTargetItems } from 'src/components/DnD/DnDWrapper';
import { usePublisherVersioningState } from '../PublisherVersioningProvider';
import { calculatesImportSlideSettingsFromPreviousVersion } from '../util';

export interface SlideSettingsStateType {
  service: SlideSettingsService,
  currentDocumentVersionORM: DocumentVersionORM,
  isVersionPublishedOrSealed: boolean,
  isGroupingDisabled: boolean,
  isRequiredSlidesDisabled: boolean,
  isAssociatedSlidesModalVisible: boolean,
  setIsAssociatedSlidesModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  thumbnailSizes: useThumbnailSizeValue,
  pageMap: Record<string, Page>,
  pages: Page[],
  getSlideTitle: (pageNumber?: number) => string,
  getHasSlideTextInsertionEnabled: (pageNumber?: number) => boolean,
  backToIdle: () => void,
  // COVER THUMBNAIL RELATED FUNCTIONS
  setCoverThumbnail: () => void,
  saveCoverThumbnail: () => void,
  selectCoverThumbnail: (pageNumber: number) => void,
  // REQUIRED SLIDES MODAL RELATED FUNCTIONS
  editRequiredSlides: () => void,
  saveRequiredSlides: () => void,
  selectAllRequiredSlide: () => void,
  selectRequiredSlide: (pageId: string) => void,
  // ADD ASSOCIATED SLIDES MODAL RELATED FUNCTIONS
  addAssociatedSlides: () => void,
  saveAssociatedSlides: () => void,
  navToPreviousStepAssociatedSlides: () => void,
  navToNextStepAssociatedSlides: () => void,
  selectAllAssociatedSlides: () => void,
  selectAssociatedSlide: (pageId: string) => void,
  // REMOVE ASSOCIATED SLIDES MODAL RELATED FUNCTIONS
  removeAssociatedSlides: () => void,
  saveRemoveAssociatedSlides: () => void,
  selectAllAssociatedSlidesToRemove: (parentPageId: string) => void,
  selectAssociatedSlidesToRemove: (parentPageId: string, pageId: string) => void,
  // GROUP MODAL RELATED FUNCTIONS
  editGroupSlides: () => void,
  saveGroupSlides: () => void,
  syncGroupSlides: (groupItems: GroupedTargetItems) => void,
  addGroup: (name: string, pageIds?: string[]) => void,
  renameGroup: (oldName: string, newName: string) => void,
  removeGroup: (name: string) => void,
  toggleLockGroup: (name: string) => void,
  removeAllGroupSlides: (name: string) => void,
  removeGroupSlide: (groupName: string, itemIdx: number, pageId: string) => void,
  selectAllGroupSlide: () => void,
  selectGroupSlide: (itemId: string, forceActive?: boolean) => void,
  addSlideToGroup: (groupName: string) => void,
  // CONTENT PAGE FUNCTIONS
  removeImportedGroups: (groupName?: string) => void,
  importSlideSettingsFromPreviousVersion: () => void,
}

export const SlideSettingsStateContext = createContext<SlideSettingsStateType | null>(null!);
SlideSettingsStateContext.displayName = 'SlideSettingsContext';

const SlideSettingsStateProvider: React.FC<PropsWithChildren> = (props) => {
  const {
    service: publisherVersioningService,
    currentDocumentVersionORM,
    latestContentPageData,
    isSlideTextInsertionEnabled,
    setLatestContentPageData,
    latestPublishedContentPageData,
    latestPublishedDocumentVersionORM,
    matchSlideStates,
  } = usePublisherVersioningState();

  const [isAssociatedSlidesModalVisible, setIsAssociatedSlidesModalVisible] = useState<boolean>(false)
  const thumbnailSizes = useThumbnailSize(['lg', 'xl', 'xxl'], 'lg')

  const isVersionPublished = currentDocumentVersionORM
    ?.model
    .status === DocumentStatus.PUBLISHED
  const isDocSealed = ['REVOKED', 'ARCHIVED', 'DELETED'].includes(
    currentDocumentVersionORM
      .relations
      .documentORM
      .model
      .status,
  )
  const isVersionPublishedOrSealed = (isVersionPublished || isDocSealed)

  const pageMap = useMemo(() => {
    return currentDocumentVersionORM.meta.allPages.reduce<Record<string, Page>>((acc, page) => {
      acc[page.pageId] = page
      return acc
    }, {})
  }, [currentDocumentVersionORM?.model])

  /** STATE MACHINE */
  const service = useMachineSelector(
    publisherVersioningService,
    ctx => ctx.context.slideSettingsActor,
  ) as SlideSettingsService

  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      slideSettingsSelector.versionDraft,
    ),
  )

  const hasGroupings = isVersionPublishedOrSealed
    ? !!currentDocumentVersionORM.model.pageGroups?.length
    : !!cond.versionDraft.pageGroups?.length

  const hasRequiredSlides = isVersionPublishedOrSealed
    ? currentDocumentVersionORM.meta.allPages.some(page => page.isRequired || page.linkedSlides?.length)
    : cond.versionDraft.pages.some(page => page.isRequired || page.linkedSlides?.length)

  const pages = isVersionPublishedOrSealed
    ? currentDocumentVersionORM.meta.allPages
    : cond.versionDraft.pages

  const getSlideTitle = (pageNumber?: number) : string => {
    if (!currentDocumentVersionORM) return ''
    return getSlideTitleHelper(currentDocumentVersionORM, pageNumber, latestContentPageData)
  }

  const getHasSlideTextInsertionEnabled = (pageNumber?: number): boolean => {
    if (typeof pageNumber !== 'number' || !isSlideTextInsertionEnabled) return false;
    return hasSlideTextInsertionEnabled(pageNumber - 1, latestContentPageData);
  }

  /** STATE MACHINE FUNCTIONS START */

  const backToIdle = useCallback(() => {
    service.send({ type: 'BACK_TO_IDLE' });
  }, [service])

  // COVER THUMBNAIL RELATED FUNCTIONS
  const setCoverThumbnail = useCallback(() => {
    service.send({ type: 'SET_COVER_THUMBNAIL' });
  }, [service])

  const saveCoverThumbnail = useCallback(() => {
    service.send({ type: 'SAVE_COVER_THUMBNAIL' });
  }, [service])

  const selectCoverThumbnail = useCallback((pageNumber: number) => {
    service.send({ type: 'UPDATE_COVER_THUMBNAIL', payload: pageNumber });
  }, [service])

  // REQUIRED SLIDES MODAL RELATED FUNCTIONS
  const editRequiredSlides = useCallback(() => {
    service.send({ type: 'EDIT_REQUIRED_SLIDES' });
  }, [service])

  const saveRequiredSlides = useCallback(() => {
    service.send({ type: 'SAVE_REQUIRED_SLIDES' });
  }, [service])

  const selectAllRequiredSlide = useCallback(() => {
    service.send({ type: 'UPDATE_REQUIRED_SLIDES', payload: 'all' });
  }, [service])

  const selectRequiredSlide = useCallback((pageId: string) => {
    service.send({ type: 'UPDATE_REQUIRED_SLIDES', payload: pageId });
  }, [service])

  // ADD ASSOCIATED SLIDES MODAL RELATED FUNCTIONS
  const addAssociatedSlides = useCallback(() => {
    service.send({ type: 'ADD_ASSOCIATED_SLIDES' });
  }, [service])

  const saveAssociatedSlides = useCallback(() => {
    service.send({ type: 'SAVE_ASSOCIATED_SLIDES' });
  }, [service])

  const navToPreviousStepAssociatedSlides = useCallback(() => {
    service.send({ type: 'ASSOCIATED_SLIDES_PREV_STEP' });
  }, [service])

  const navToNextStepAssociatedSlides = useCallback(() => {
    service.send({ type: 'ASSOCIATED_SLIDES_NEXT_STEP' });
  }, [service])

  const selectAllAssociatedSlides = useCallback(() => {
    service.send({ type: 'UPDATE_SELECTED_SLIDES', payload: { selection: 'all' } });
  }, [service])

  const selectAssociatedSlide = useCallback((pageId: string) => {
    service.send({ type: 'UPDATE_SELECTED_SLIDES', payload: { selection: pageId } });
  }, [service])

  // REMOVE ASSOCIATED SLIDES MODAL RELATED FUNCTIONS
  const removeAssociatedSlides = useCallback(() => {
    service.send({ type: 'REMOVE_ASSOCIATED_SLIDES' });
  }, [service])

  const saveRemoveAssociatedSlides = useCallback(() => {
    service.send({ type: 'SAVE_REMOVED_SLIDES' });
  }, [service])

  const selectAllAssociatedSlidesToRemove = useCallback((parentPageId: string) => {
    service.send({ type: 'UPDATE_REMOVED_SLIDES', payload: { parent: parentPageId, removed: 'all' } });
  }, [service])

  const selectAssociatedSlidesToRemove = useCallback((parentPageId: string, pageId: string) => {
    service.send({ type: 'UPDATE_REMOVED_SLIDES', payload: { parent: parentPageId, removed: pageId } });
  }, [service])

  // GROUP MODAL RELATED FUNCTIONS
  const editGroupSlides = useCallback(() => {
    service.send({ type: 'EDIT_GROUP_SLIDES' });
  }, [service])

  const saveGroupSlides = useCallback(() => {
    service.send({ type: 'SAVE_GROUP_SLIDES' });
  }, [service])

  const syncGroupSlides = useCallback((groupItems: GroupedTargetItems) => {
    service.send({ type: 'SYNC_GROUPS', payload: { groupItems } });
  }, [service])

  const addGroup = useCallback((name: string, pageIds?: string[]) => {
    service.send({ type: 'ADD_GROUP', payload: { name, pageIds } });
  }, [service])

  const renameGroup = useCallback((oldName: string, newName: string) => {
    service.send({ type: 'RENAME_GROUP', payload: { oldName, newName } });
  }, [service])

  const removeGroup = useCallback((name: string) => {
    service.send({ type: 'REMOVE_GROUP', payload: name });
  }, [service])

  const toggleLockGroup = useCallback((name: string) => {
    service.send({ type: 'TOGGLE_LOCK_GROUP', payload: name });
  }, [service])

  const removeAllGroupSlides = useCallback((name: string) => {
    service.send({ type: 'REMOVE_GROUP_SLIDES', payload: { groupName: name, itemIdx: 'all' } });
  }, [service])

  const removeGroupSlide = useCallback((groupName, itemIdx: number, pageId: string) => {
    service.send({ type: 'REMOVE_GROUP_SLIDES', payload: { groupName, itemIdx, pageId } });
  }, [service])

  const selectAllGroupSlide = useCallback(() => {
    service.send({ type: 'UPDATE_GROUP_SLIDES', payload: { scope: 'all' } });
  }, [service])

  const selectGroupSlide = useCallback((itemId: string, forceActive?: boolean) => {
    service.send({ type: 'UPDATE_GROUP_SLIDES', payload: { scope: itemId, forceActive } });
  }, [service])

  const addSlideToGroup = useCallback((groupName: string) => {
    service.send({ type: 'ADD_GROUP_SLIDES', payload: { groupName } });
  }, [service])

  const removeImportedGroups = useCallback((groupName?: string) => {
    service.send({
      type: 'REMOVE_IMPORTED_GROUPS',
      ...(groupName && { payload: { groupName } }),
    });
  }, [service]);

  /** STATE MACHINE FUNCTIONS END */

  /**
  * This function calculates and applies slide settings from the previously published version to
  * the current draft, based on the following rules:
  * 1. Apply slide-level required slides to individual slides for every required slide that has
  *    been matched, and drop those that have not.
  * 2. Apply presentation-level required slides to entire presentation when all required slides
  *    have a match.
  * 3. Carry forward slide groups when all slides within the group have a match UNLESS slide
  *    groups are imported, in which case the imported ones take precedence.
      *  - Groups will carry forward containing the equivalent slide from the previous version as
      *    long as all slides have an identified match by the system.
  * 4. Apply publisher-edited speaker notes & slide titles when the newly uploaded document does
  *    not have any speaker notes and/or slide titles.
  */
  const importSlideSettingsFromPreviousVersion = useCallback(() => {
    const {
      newContentPageData,
      linkedSlidesMapping,
      requiredSlidePageIds,
      newGroups,
    } = calculatesImportSlideSettingsFromPreviousVersion(
      matchSlideStates,
      currentDocumentVersionORM.model.pageGroups,
      latestContentPageData,
      latestPublishedDocumentVersionORM?.model.pageGroups,
      latestPublishedDocumentVersionORM?.meta.allPages,
      latestPublishedContentPageData,
    )

    if (newContentPageData) setLatestContentPageData(newContentPageData)

    // Apply the new slide setting to version draft
    service.send({
      type: 'OVERWRITE_SLIDE_SETTINGS',
      payload: {
        linkedSlidesMapping,
        requiredSlidePageIds,
        groups: newGroups,
        contentPageData: newContentPageData,
      },
    })
  }, [
    service,
    matchSlideStates,
    currentDocumentVersionORM,
    latestContentPageData,
    setLatestContentPageData,
    latestPublishedDocumentVersionORM,
    latestPublishedContentPageData,
  ])

  const contextValue: SlideSettingsStateType = {
    service,
    currentDocumentVersionORM,
    isVersionPublishedOrSealed,
    isGroupingDisabled: hasRequiredSlides,
    isRequiredSlidesDisabled: hasGroupings,
    isAssociatedSlidesModalVisible,
    setIsAssociatedSlidesModalVisible,
    thumbnailSizes,
    pageMap,
    pages,
    getSlideTitle,
    getHasSlideTextInsertionEnabled,
    backToIdle,
    // COVER THUMBNAIL RELATED FUNCTIONS
    setCoverThumbnail,
    saveCoverThumbnail,
    selectCoverThumbnail,
    // REQUIRED SLIDES MODAL RELATED FUNCTIONS
    editRequiredSlides,
    saveRequiredSlides,
    selectAllRequiredSlide,
    selectRequiredSlide,
    // ADD ASSOCIATED SLIDES MODAL RELATED FUNCTIONS
    addAssociatedSlides,
    saveAssociatedSlides,
    navToPreviousStepAssociatedSlides,
    navToNextStepAssociatedSlides,
    selectAllAssociatedSlides,
    selectAssociatedSlide,
    // REMOVE ASSOCIATED SLIDES MODAL RELATED FUNCTIONS
    removeAssociatedSlides,
    saveRemoveAssociatedSlides,
    selectAllAssociatedSlidesToRemove,
    selectAssociatedSlidesToRemove,
    // GROUP MODAL RELATED FUNCTIONS
    editGroupSlides,
    saveGroupSlides,
    syncGroupSlides,
    addGroup,
    renameGroup,
    removeGroup,
    toggleLockGroup,
    removeAllGroupSlides,
    removeGroupSlide,
    selectAllGroupSlide,
    selectGroupSlide,
    addSlideToGroup,
    removeImportedGroups,
    importSlideSettingsFromPreviousVersion,
  };

  return (
    <SlideSettingsStateContext.Provider value={contextValue}>
      {props.children}
    </SlideSettingsStateContext.Provider>
  );
};

SlideSettingsStateProvider.displayName = 'SlideSettingsStateProvider';

export const useSlideSettingsState = () => {
  const context = useContext(SlideSettingsStateContext);
  if (!context) {
    throw new Error('useSlideSettingsState must be used within the SlideSettingsStateProvider');
  }
  return context;
};

export default SlideSettingsStateProvider;
