import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import { DNABox, Hoverable, util } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import { useInPersonActionBarSharedComponents } from './useInPersonActionBarSharedComponents';

const S = StyleSheet.create({
  actionsContainer: {
    backgroundColor: colors['color-black'],
    paddingHorizontal: 8,
    height: 40,
    borderTopWidth: 1,
    borderTopColor: colors['color-gray-400'],
  },
})

const DesktopVariant: React.FC = () => {
  const {
    presentationNav,
    myContentButton,
    presentationsButton,
    slidesButton,
    highlighterContextMenu,
    textSearchButton,
    sentimentsContextMenu,
    myNotesButton,
    fullScreenButton,
    endMeetingButton,
    changeToVirtualModeButton,
  } = useInPersonActionBarSharedComponents();
  const [isHovered, setIsHovered] = useState<boolean>(true)
  const toggleHover = (isHovered?: boolean) => {
    setIsHovered(!!isHovered);
  }

  return (
    <Hoverable onHoverIn={() => toggleHover(true)} onHoverOut={toggleHover}>
      <DNABox
        spacing="between"
        style={util.mergeStyles(
          undefined,
          S.actionsContainer,
          [{ opacity: 0.32 }, !isHovered],
        )}
        alignY="center"
      >
        {/* LEFT */}
        <DNABox spacing="sm">
          {myContentButton}
          {presentationsButton}
          {slidesButton}
        </DNABox>
        {/* MIDDLE */}
        <DNABox>
          {presentationNav}
        </DNABox>
        {/* RIGHT */}
        <DNABox spacing="sm">
          {highlighterContextMenu}
          {textSearchButton}
          {sentimentsContextMenu}
          {myNotesButton}
          {fullScreenButton}
          {endMeetingButton}
          {changeToVirtualModeButton}
        </DNABox>
      </DNABox>
    </Hoverable>
  )
}

export default DesktopVariant
