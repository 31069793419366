import React from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import {
  DNABox,
  DNAIcon,
  DNAText,
  Iffy,
} from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail';
import { getSlideTitle, getThumbURL } from 'src/utils/thumbnailHelpers';
import { usePublisherVersioningState }
  from 'src/state/machines/publisherVersioning/PublisherVersioningProvider';
import { useMatchSlidesState } from 'src/state/machines/publisherVersioning/MatchSlides/MatchSlidesProvider';
import { LuxSizeEnum } from '@alucio/lux-ui/src/typings';
import { thumbnailSizeDimensions } from 'src/hooks/useThumbnailSize/useThumbnailSize';

const S = StyleSheet.create({
  container: {
    padding: 32,
  },
  emptyMatchingContainer: {
    width: thumbnailSizeDimensions.lg.width,
    maxHeight: thumbnailSizeDimensions.lg.height,
    borderStyle: 'dashed',
    borderWidth: 3,
    borderColor: colors['color-gray-200'],
    borderRadius: 4,
    backgroundColor: colors['color-gray-10'],
  },
  emptyMatchingContainerText: {
    color: colors['color-text-tertiary'],
  },
  columnHeaderContainer: {
    backgroundColor: colors['color-gray-80'],
    borderRadius: 8,
    width: 342,
  },
  columnHeaderText: {
    color: colors['color-text-tertiary'],
    paddingVertical: 8,
  },
  iconContainer: {
    backgroundColor: colors['color-gray-400'],
    borderRadius: 32,
    padding: 4,
  },
  iconHeight: {
    maxHeight: 184,
  },
})

interface PreviousVersionContainerProps {
  pageId: string,
  pageNumber: number,
}

interface NewVersionContainerProps {
  pageId: string,
  pageNumber: number
}

const PreviousVersionContainer: React.FC<PreviousVersionContainerProps> = ({ pageId }) => {
  const {
    mappedValues,
    latestPublishedContentPageData,
  } = usePublisherVersioningState();
  const { latestPublishedDocumentVersionORM } = useMatchSlidesState();

  const mappedValue = mappedValues[pageId]?.mapping;
  const hasMapping = mappedValue && mappedValue !== 'USER_UNMATCHED';
  const mappingPageNum = Number(mappedValue?.split('_').pop()) ?? 0

  const thumbURL = getThumbURL(latestPublishedDocumentVersionORM, mappingPageNum);
  const thumbnailTitle = getSlideTitle(
    latestPublishedDocumentVersionORM,
    mappingPageNum,
    latestPublishedContentPageData,
  );

  return (
    <>
      <Iffy is={hasMapping}>
        <DNABox
          childFill={1}
          appearance="col"
          alignX="center"
          alignY="start"
          key={`last-version-thumbnail-${pageId}`}
          testID={`last-version-thumbnail-${pageId}`}
        >
          <DNAThumbnail.Lazy
            variant={DNAThumbnail.Variants.INFO}
            key={thumbURL}
            s3URL={thumbURL}
            useLoadingIndicator
            thumbnailTitle={thumbnailTitle}
            size="lg"
            pageNumber={mappingPageNum}
          />
        </DNABox>
      </Iffy>
      <Iffy is={!hasMapping}>
        <DNABox
          fill
          appearance="col"
          alignX="center"
          alignY="center"
          spacing="md"
          style={S.emptyMatchingContainer}
        >
          <DNABox fill appearance="col" alignX="center" spacing="sm">
            <DNAText b1 bold style={S.emptyMatchingContainerText}>
              No matching slide
            </DNAText>
          </DNABox>
        </DNABox>
      </Iffy>
    </>
  )
}

const NewVersionContainer: React.FC<NewVersionContainerProps> = ({ pageId, pageNumber }) => {
  const {
    currentDocumentVersionORM,
    latestContentPageData,
  } = usePublisherVersioningState();

  const thumbURL = getThumbURL(currentDocumentVersionORM, pageNumber)
  const thumbnailTitle = getSlideTitle(
    currentDocumentVersionORM,
    pageNumber,
    latestContentPageData,
  )
  return (
    <DNABox
      fill
      childFill={1}
      appearance="col"
      alignX="center"
      alignY="start"
      key={`last-version-thumbnail-${pageId}`}
      testID={`last-version-thumbnail-${pageId}`}
      style={{ paddingBottom: 78 }}
    >
      <DNAThumbnail.Lazy
        variant={DNAThumbnail.Variants.INFO}
        key={thumbURL}
        s3URL={thumbURL}
        useLoadingIndicator
        thumbnailTitle={thumbnailTitle}
        size="lg"
        pageNumber={pageNumber}
      />
    </DNABox>
  )
}

const SlideMatchingIcon: React.FC = () => {
  return (
    <DNABox fill alignX="center" alignY="center" style={S.iconHeight}>
      <DNABox
        alignX="center"
        alignY="center"
        style={{ backgroundColor: colors['color-gray-400'], borderRadius: 32, padding: 4 }}
      >
        <DNAIcon.Styled
          name="arrow-right"
          size={LuxSizeEnum.md}
        />
      </DNABox>
    </DNABox>
  )
}

const MatchSlidesReadonly: React.FC = () => {
  const { currentDocumentVersionORM, previousVersionORM } = usePublisherVersioningState();

  return (
    <DNABox fill appearance="col">
      <ScrollView contentContainerStyle={S.container}>
        <DNABox spacing="md" appearance="col">
          <DNABox appearance="row" spacing="xl" alignX="center">
            <DNABox style={S.columnHeaderContainer} alignX="center" spacing="sm">
              <DNAText style={S.columnHeaderText}>
                {`Version ${previousVersionORM?.meta.version.semVerLabel}`}
              </DNAText>
            </DNABox>
            <DNABox style={S.columnHeaderContainer} alignX="center" spacing="sm">
              <DNAText style={S.columnHeaderText}>
                {`Version ${currentDocumentVersionORM?.meta.version.semVerLabel}`}
              </DNAText>
            </DNABox>
          </DNABox>
          {currentDocumentVersionORM.relations.pages.map((page) => {
            const { pageId: newVersionPageId, number } = page.model
            return (
              <DNABox
                fill
                appearance="col"
                alignX="center"
                spacing="md"
                key={page.model.pageId}
              >
                <DNABox appearance="col" fill spacing="md">
                  <DNABox appearance="row" spacing="md">
                    <PreviousVersionContainer pageId={newVersionPageId} pageNumber={page.model.number} />
                    <SlideMatchingIcon />
                    <NewVersionContainer
                      pageId={newVersionPageId}
                      pageNumber={number}
                    />
                  </DNABox>
                </DNABox>
              </DNABox>

            )
          })}
        </DNABox>
      </ScrollView>
    </DNABox>
  )
}

export default MatchSlidesReadonly;
