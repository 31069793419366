import React, { createContext, useContext } from 'react'
import { useInterpret } from '@xstate/react'
import useLazyRef from 'src/hooks/useLazyRef'

import shareMachine from './shareMachine.machine'
import type { ShareStateMachine } from './shareMachine.types'
import { FormValuesType, useComposableForm } from 'src/components/CustomFields/ComposableForm'
import { useToast } from '@alucio/lux-ui'
import { formToModel } from 'src/components/CustomFields/ComposableFormUtilities'
import { ShareVariantType } from 'src/components/DNA/Modal/ContentShareModal/ContentShareModal'
import { UseFormReturn } from 'react-hook-form'
import { ContentShareORM } from 'src/types/orms'
import { ShareNotificationScope } from '@alucio/aws-beacon-amplify/src/models'
import { isIOS, isSafari } from 'react-device-detect'

export type ShareMachineContextValue = {
  service: ShareStateMachine.Service,
  validateAndSubmit?: (ShareStateMachine: string) => void
  closeModal: () => void,
  entityShareIds: string[],
  rhForm?: UseFormReturn<FormValuesType>
}

export const ShareMachineContext = createContext<ShareMachineContextValue>({
  service: undefined!,
  closeModal: () => {},
  entityShareIds: [],
})

export const ShareMachineProviderUnmanaged: React.FC<React.PropsWithChildren<{
  closeModal: () => void
  entityShareIds: string[],
  variant: ShareVariantType
  existingContentShareORM?: ContentShareORM,
  hubTitle?: string,
  meetingId?: string,
}>> = (props) => {
  const {
    children,
    closeModal,
    entityShareIds,
    variant,
    existingContentShareORM,
    hubTitle,
    meetingId,
  } = props
  const { rhForm } = useComposableForm()
  const toast = useToast()
  const skipClipboardWrite = isIOS || isSafari

  const machineInstance = useLazyRef<ShareStateMachine.Machine>(() =>
    shareMachine.withContext({
      ...props,
      // either array of HubORM id or DocumentVersionORM id
      entityShareIds,
      variant,
      toast,
      existingContentShareORM,
      hubTitle,
      meetingId,
      notificationScope: ShareNotificationScope.NONE,
      skipClipboardWrite,
    }),
  )

  const service = useInterpret(machineInstance.current!)

  const validateAndSubmit = (stateMachineEvent: string) => {
    rhForm.trigger().then(() => {
      rhForm.formState.isValid && service.send({
        type: stateMachineEvent,
        customFieldsValues: formToModel(rhForm.getValues()),
      })
    })
  }

  const contextValue: ShareMachineContextValue = {
    service,
    validateAndSubmit,
    closeModal,
    entityShareIds,
    rhForm,
  }

  return (
    <ShareMachineContext.Provider value={contextValue}>
      { children }
    </ShareMachineContext.Provider>
  )
}

export const useShareMachine = () => useContext(ShareMachineContext)

export default {
  Unmanaged: ShareMachineProviderUnmanaged,
}
