import { InvokeCallback } from 'xstate'
import { v4 } from 'uuid'
import {
  PDFChannelMessage,
  PDFMessageTypes,
  PlayerEventSource,
  VideoAnalyticsPayload,
  NotationCoordinateData,
} from '@alucio/core'
import { AlucioChannel } from '@alucio/lux-ui';
import * as PW from './playerWrapperTypes'
import * as logger from 'src/utils/logger'

export * as PW from './playerWrapperTypes'

type ChannelObserverCallback<T extends PW.PlayerWrapperEvents> = InvokeCallback<T, PW.PlayerWrapperEvents>

// [TODO] - Consider bumping XState for some new features
//          - Arbitrary arguments in guards
//          - Better TS experience

// [TODO] - Consider creating a generic observer interface (zen-observable -- set workerChannel)
export const presentationStateObserver = (
  channel: PW.PlayerWrapperContext['presentationStateChannel'],
  meetingId: string,
):
  ChannelObserverCallback<PW.EVT_PRESENTATION_STATE_SYNC> =>
  (send) => {
    const id = v4()
    const handler = (msg: PW.PresentationChannelMessage) => {
      if (meetingId !== msg.meetingId) return
      // We only pay attention to PRESENTATION_STATE_SYNC events which are broadcast
      // by the PresentationBroadCastProvider
      if (msg.type === 'PRESENTATION_STATE_SYNC') {
        logger.playerWrapperMachine
          .debug('Received message from presentation channel: PRESENTATION_STATE_SYNC', { meetingId });
        send({
          isFromSameSource: msg.isFromSameSource,
          type: 'PRESENTATION_STATE_SYNC',
          meetingId: msg.meetingId,
          payload: msg.payload,
          messageNumber: msg.messageNumber,
          shouldUpdatePlayer: msg.shouldUpdatePlayer,
        })
      }
      else if (msg.type === 'PRESENTATION_STATE_IDLE') {
        logger.playerWrapperMachine
          .debug('Received message from presentation channel: PRESENTATION_STATE_IDLE', { meetingId })
        send({
          type: 'PRESENTATION_STATE_IDLE',
          meetingId: msg.meetingId,
        })
      }
      else if (msg.type === 'SYNC_PRESENTABLE') {
        logger.playerWrapperMachine.debug('Received message from presentation channel: SYNC_PRESENTABLE', { meetingId })
        send({
          type: 'SEND_IFRAME_READY',
        })
      }
      else if (msg.type === 'TOGGLE_NOTATIONS') {
        logger.playerWrapperMachine.debug('Received message from presentation channel: TOGGLE_NOTATIONS', { meetingId })
        send({
          type: 'TOGGLE_NOTATIONS',
          notationType: msg.notationType,
          toggleOn: msg.toggleOn,
        })
      }
      else if (msg.type === 'TOGGLE_COORDINATE_MODE') {
        logger.playerWrapperMachine
          .debug('Received message from presentation channel: TOGGLE_COORDINATE_MODE', { meetingId })
        send({
          type: 'TOGGLE_COORDINATE_MODE',
          toggleOn: msg.toggleOn,
        })
      }
      else if (msg.type === 'SYNC_CURRENT_ACTIVE_NOTATION') {
        logger.playerWrapperMachine
          .debug('Received message from presentation channel: SYNC_CURRENT_ACTIVE_NOTATION', { meetingId })
        send({
          type: 'SYNC_CURRENT_ACTIVE_NOTATION',
          notationId: msg.notationId,
        })
      }
      else {
        logger.playerWrapperMachine.warn(`Received unknown message from presentation channel: ${msg.type}`, msg)
      }
    }

    logger.playerWrapperMachine.debug(`attaching listener to presentation channel ${id}`, channel)
    channel.addEventListener(
      'message',
      handler,
    )
    return () => {
      logger.playerWrapperMachine.debug('Cleanup Called for presentation channel observer')
      // We close the channel which also releases the handler
      channel.close()
    }
  }

export const playerStateObserver = (
  channel: PW.PlayerWrapperContext['playerStateChannel'],
  meetingId: string,
  playerContext: PlayerEventSource,
):
  ChannelObserverCallback<PW.EVT_PLAYER_ACTION> =>
  (send) => {
    const handler = (msg: PDFChannelMessage) => {
      /* We need to check whether the incoming msg's frameId is matching the frameId in context.
      Since we reassign frameId every time we load a new document (custom deck), we cannot pass frameId here in the observer as guard.
      If Adding new msg type below in this observer, please remember to add a guard to check framId before firing the action. */

      if (msg.type === PDFMessageTypes.ANALYTICS) {
        logger.playerWrapperMachine.debug('Received message from player channel: ANALYTICS', { meetingId })
        const values = msg.value as VideoAnalyticsPayload
        analytics?.track('VIDEO_PRESENTED', {
          documentId: values.documentId,
          documentVersionId: values.documentVersionId,
          meetingId,
          context: playerContext,
          videoStartTime: values.videoStartTime,
          videoEndTime: values.videoEndTime,
        })
        return;
      }
      else if (msg.type === PDFMessageTypes.SEND_NOTATION_COORDINATE) {
        logger.playerWrapperMachine
          .debug('Received message from player channel: SEND_NOTATION_COORDINATE', { meetingId })
        const values = msg.value as NotationCoordinateData
        send({
          type: 'SEND_NOTATION_COORDINATE',
          notationId: values.notationId,
          coordinate: values.coordinate,
          pageId: values.pageId,
          frameId: msg.frameId,
        })
      }
      else if (msg.type === PDFMessageTypes.SYNC_CURRENT_ACTIVE_NOTATION) {
        logger.playerWrapperMachine
          .debug('Received message from player channel: SYNC_CURRENT_ACTIVE_NOTATION', { meetingId })
        const value = msg.value as string
        send({
          type: 'SYNC_CURRENT_ACTIVE_NOTATION',
          notationId: value,
          fromPlayer: true,
          frameId:  msg.frameId,
        })
      }
      else if (msg.type === PDFMessageTypes.SET_EDIT_NOTATION) {
        logger.playerWrapperMachine
          .debug('Received message from player channel: SET_EDIT_NOTATION', { meetingId })
        const value = msg.value as string
        send({
          type: 'SET_EDIT_NOTATION',
          notationId: value,
          fromPlayer: true,
          frameId:  msg.frameId,
        })
      }

      send({
        type: 'PLAYER_ACTION',
        payload: msg,
      })
    }
    logger.playerWrapperMachine.debug('attaching listener to channel', channel)
    channel.addEventListener(
      'message',
      handler,
    )
    return async () => {
      logger.playerWrapperMachine.debug('Cleanup Called for player channel observer')
      // [TODO] - We have to becareful here since this closes the channel for anything that has a ref to it
      //        - Generally, the main initializer of the channel should maintain closing it
      //          instead of a one (of potentially many) subscribers/observers
      // We close the channel which also releases the handler
      await AlucioChannel.remove(AlucioChannel.commonChannels.PDF_CHANNEL)
    }
  }
