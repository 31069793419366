import React, { useState } from 'react';
import { useDispatch } from 'src/state/redux';

import { DNABox, DNAButton, DNACheckbox, DNAModal, DNAText, Iffy, luxColors } from '@alucio/lux-ui';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import { useAppSettings } from 'src/state/context/AppSettings';

interface Props {
  onConfirmAction?: (clearData: boolean) => void;
  onCancelAction?: () => void;
}

const DNACommonConfirmationSignOut = (props: Props) => {
  const { isPWAStandalone } = useAppSettings()
  const { onConfirmAction, onCancelAction } = props;
  const dispatch = useDispatch();
  const [clearData, setClearData] = useState(false)

  function closeModal(): void {
    dispatch(DNAModalActions.closeModal());
    onCancelAction?.();
  }

  function onAction(): void {
    onConfirmAction?.(clearData);
  }

  const headerText = isPWAStandalone ? 'Log out from Beacon app' : 'Log out from all tabs and the Beacon app';

  return (
    <DNAModal>
      <DNAModal.Header onClose={closeModal}>
        <DNABox style={{ padding: 8, minWidth: 384 }}>
          <DNAText h5>{headerText}</DNAText>
        </DNABox>
      </DNAModal.Header>
      <DNAModal.Body>
        <DNABox style={{ padding: 8, minWidth: 384 }} appearance="col">
          { /* Keep data */}
          <DNABox style={{ marginTop: 16 }}>
            <DNACheckbox checked={clearData} onChange={setClearData} />
            <DNAText style={{ color: luxColors.flat.primary, marginLeft: 8 }}>
              Clear all stored data
            </DNAText>
          </DNABox>
        </DNABox>
      </DNAModal.Body>
      <DNAModal.Confirmation>
        <DNAButton testID="cancel-button" status="tertiary" onPress={closeModal} size="sm">
          <DNAText status="flat">Cancel</DNAText>
        </DNAButton>
        <Iffy is={props.onConfirmAction}>
          <DNAButton
            testID="confirmation-button"
            onPress={onAction}
            status="primary"
            size="sm"
          >Log out</DNAButton>
        </Iffy>
      </DNAModal.Confirmation>
    </DNAModal>
  )
};

export default DNACommonConfirmationSignOut;
