import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import {
  Iffy,
  Page,
  responsive,
  util,
} from '@alucio/lux-ui'
import ROUTES from 'src/router/routeDef'
import LoadingErrorMessage from '../components/ErrorMessages/LoadingErrorMessage';
import DNALoader, { DNALoaderEvents } from 'src/components/DNA/Loader/DNALoader'
import logger from 'src/utils/logger';

const S = {
  Page: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  PageMobile: {
    justifyContent: 'center',
  },
  LoaderImage: {
    width: 200,
    height: 200,
  },
}

/**
 * This hook is used to determine if the current window location is for an external player.
 *
 * Note: We cannot use React Router here because this hook is used before the Router component is initialized.
 *
 * @returns {boolean} - Returns true if the current window location is for an external player, otherwise false.
 */
export const useIsExternalPlayer = () => {
  const pathToken = ROUTES
    .MEETING_CONTENT
    .PATH
    .split('/')
    .filter(token => token)
    .at(0)

  const externalWindow = pathToken
    ? window.location.pathname.includes(pathToken)
    : false

  return !!externalWindow;
}

interface Props {
  showRefreshComponent?: boolean
  analyticsEventType?: DNALoaderEvents
  context?: string
}

const Loading = (props: Props) => {
  const { showRefreshComponent, analyticsEventType, context } = props;
  const [showLongTimeLoadingMessage, setShowLongTimeLoadingMessage] = useState<boolean>(false);

  useEffect(() => {
    const handle = setTimeout(() => {
      logger.appInit.info('Setting LONG_HYDRATE', { context })
      analytics?.track('APP_LONG_HYDRATE', {
        action: 'LONG_HYDRATE',
        category: 'APP',
      });
      setShowLongTimeLoadingMessage(true);
    }, 25000)
    return () => {
      clearTimeout(handle)
    }
  }, [])

  return (
    <Page
      style={util.mergeStyles(
        undefined,
        S.Page,
        [S.PageMobile, !responsive.device.isWeb],
      )}
    >
      <View style={{ justifyContent: 'center', alignItems: 'center' }}>
        <View style={{ height: 200 }}>
          <DNALoader analyticsEventType={analyticsEventType}/>
        </View>
        <Iffy is={showLongTimeLoadingMessage || showRefreshComponent}>
          <LoadingErrorMessage hideLogoutOption={showRefreshComponent} />
        </Iffy>
      </View>
    </Page>
  );
}

export default Loading;
