/**
 * This function takes in either an array or object
 * and will recursively convert all undefined value to null
 */
export function convertUndefinedToNull<T>(input: T): T {
  if (input === undefined) {
    return null as any; // Return null with the same type as input
  }

  if (Array.isArray(input)) {
    return input.map((item) => convertUndefinedToNull(item)) as any;
  }

  if (typeof input === 'object') {
    const result: Record<string, any> = {};
    for (const key in input) {
      result[key] = convertUndefinedToNull(input[key]);
    }
    return result as T;
  }

  return input;
}

/**
 * Remove circular dependencies, use with JSON.stringify
 * `JSON.stringify(object, circularDependencyRemover())`
 * (Remember to execute the function inline)
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value
 * @param cutOffAmount: Not required. All array and object will be cut off at this amount if passed in
 * @returns object
 */
export const circularDependencyRemover = (cutOffAmount?: number) => {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);

      if (cutOffAmount && Array.isArray(value)) {
        if (value.length > cutOffAmount) {
          return value.slice(0, cutOffAmount);
        }
      } else if (cutOffAmount) {
        const keys = Object.keys(value);
        if (keys.length > cutOffAmount) {
          const limitedObject = {};
          keys.slice(0, cutOffAmount).forEach(k => {
            limitedObject[k] = value[k];
          });
          return limitedObject;
        }
      }
    }
    return value;
  };
};

/**
 * Removes circular dependencies and truncates arrays or objects if `cutOffAmount` is specified.
 * Returns a deep-cloned object/array without circular references.
 *
 * @param input The object or array to process.
 * @param cutOffAmount Optional. Limits the number of items or keys for arrays and objects.
 * @returns A new object or array without circular references.
 */
export const removeCircularDependencies = <T extends object>(
  input: T,
  cutOffAmount?: number,
): T => {
  const seen = new WeakMap<object, object>();

  const processValue = (value: unknown): unknown => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return seen.get(value);
      }

      if (Array.isArray(value)) {
        const newArray = cutOffAmount
          ? value.slice(0, cutOffAmount).map(processValue)
          : value.map(processValue);
        seen.set(value, newArray);
        return newArray;
      }

      const newObject: Record<string, unknown> = {};
      seen.set(value, newObject);
      const keys = Object.keys(value);
      const limitedKeys = cutOffAmount ? keys.slice(0, cutOffAmount) : keys;

      for (const key of limitedKeys) {
        newObject[key] = processValue((value as Record<string, unknown>)[key]);
      }
      return newObject;
    }
    return value;
  };

  return processValue(input) as T;
};
