import { InvokeCallback } from 'xstate';
import { PW } from 'src/state/machines/presentation/playerWrapper';
import * as logger from 'src/utils/logger'
import type * as CustomNote from './customNote.types';

export const HIGHLIGHT_AVAILABLE_DOC_TYPE = ['PDF', 'PPTX']
export const TEXT_INSERTION_AVAILABLE_DOC_TYPE = ['PDF', 'PPTX']

type ChannelObserverCallback<T extends CustomNote.SMEvents> = InvokeCallback<T, CustomNote.SMEvents>

export const presentationStateObserver = (
  channel: CustomNote.SMContext['presentationStateChannel'],
  meetingId: string,
):
  ChannelObserverCallback<PW.EVT_PRESENTATION_STATE_SYNC> => (send) =>
{
  const handler = (msg: PW.PresentationChannelMessage) => {
    if (meetingId !== msg.meetingId) return
    logger.userNotations.debug(`Presentation state observer received: ${msg.type}`)

    switch (msg.type) {
      case 'PRESENTATION_STATE_SYNC':
        send({
          type: 'PRESENTATION_STATE_SYNC',
          meetingId: msg.meetingId,
          payload: msg.payload,
          messageNumber: msg.messageNumber,
        })
        return;

      case 'IFRAME_READY':
        send({
          type: 'IFRAME_READY',
        })
        return;

      case 'SEND_NOTATION_COORDINATE':
        if (msg.coordinate) {
          if (msg.notationId) {
            send({
              type: 'UPDATE_NOTE_COORDINATES',
              coordinate: [msg.coordinate],
              notationId: msg.notationId,
            })
          } else {
            send({
              type: 'SET_COORDINATE',
              coordinate: [msg.coordinate],
              pageId: msg.pageId,
            })
          }
        } else send({ type: 'BACK_TO_IDLE' })
        return;

      case 'SYNC_CURRENT_ACTIVE_NOTATION':
        send({
          type: 'SET_CURRENT_ACTIVE_NOTATION',
          notationId: msg.notationId,
        })
        return;

      case 'SET_EDIT_NOTATION':
        send({
          type: 'EDIT_NOTE',
          notationId: msg.notationId,
        })
    }
  }

  logger.userNotations.debug(`Attaching listener to presentation channel ${meetingId}`, channel)
  channel.addEventListener(
    'message',
    handler,
  )
  return () => {
    logger.userNotations.debug('Cleanup Called for presentation channel observer')
    channel.removeEventListener('message', handler);
  }
}
