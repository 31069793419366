import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { StyleSheet } from 'react-native';
import {
  DNABox,
  DNADivider,
  DNAText,
  InformationMessage,
  Iffy,
} from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { DocumentVersionORM } from 'src/types/orms';
import { GroupStatus, usePresentationBuilderState }
  from 'src/components/PresentationBuilder/state/PresentationBuilderStateProvider';

const styles = StyleSheet.create({
  container: {
    paddingTop: 24,
    paddingHorizontal: 24,
    backgroundColor: colors['color-gray-10'],
  },
});

type InformationRowProps = {
  title?: string,
  unresolvedCount: number,
  latestPublishedDocumentVersionORM?: DocumentVersionORM,
}

const InformationRow: React.FC<InformationRowProps> = (props) => {
  const {
    title,
    latestPublishedDocumentVersionORM,
    unresolvedCount,
  } = props;
  const [isInfoHidden, setIsInfoHidden] = useState(true);

  return (unresolvedCount > 0
    ? <InformationMessage.Collapsible.Item
        key={title}
        text={
          <DNABox fill appearance="col" spacing="sm">
            <DNAText status="success" numberOfLines={5}>
              <DNAText status="success" bold>{`${title} `}</DNAText>
              was recently modified - {unresolvedCount} item(s) need to be resolved
            </DNAText>
            {!isInfoHidden &&
              <DNAText status="success">
                {latestPublishedDocumentVersionORM?.model.releaseNotes}
              </DNAText>
            }
          </DNABox>
        }
        actionButtonFunction={() => setIsInfoHidden(p => !p)}
        actionButtonText={isInfoHidden ? 'View release notes' : 'Hide notes'}
    />
    : <></>
  )
};

const InformationBannerAutoUpdate: React.FC = () => {
  const {
    selectedGroups,
    setDisablePreivew,
    acceptAutoUpdateSlides,
    autoUpdateMapping,
  } = usePresentationBuilderState();

  const unresolvedArr = useMemo(() => {
    const resMap = new Map<string, { documentVersionORM: DocumentVersionORM, unresolvedCount: number }>();
    let disablePrev = false;
    selectedGroups.forEach(group => {
      const { documentVersionORM, groupStatus } = group;
      const hasUnresolvedOrAutoMappingSlides =
        [GroupStatus.MAJOR_UPDATE, GroupStatus.MINOR_UPDATE_WITH_AUTO_MAPPING_SLIDES].includes(groupStatus);
      const isUnresolvedSingleSlideGroup = documentVersionORM &&
        hasUnresolvedOrAutoMappingSlides &&
        group.pages.length === 1 &&
        autoUpdateMapping[group.pages[0].pageId];
      const isUnresolvedMultiSlideGroup = documentVersionORM &&
        hasUnresolvedOrAutoMappingSlides &&
        group.pages.length > 1 &&
        autoUpdateMapping[group.id];
      if (isUnresolvedSingleSlideGroup || isUnresolvedMultiSlideGroup) {
        const id = documentVersionORM.model.id;
        const unresolvedCount = (resMap.get(id)?.unresolvedCount ?? 0) + group.pages.length;
        resMap.set(id, {
          documentVersionORM,
          unresolvedCount,
        });
        disablePrev = true;
      }
    });
    disablePrev = disablePrev || !selectedGroups.some(({ visible }) => visible);
    setDisablePreivew(disablePrev);
    return Array.from(resMap.values());
  }, [autoUpdateMapping, selectedGroups]);

  const unresolvedSlideCount = useMemo(() => {
    return unresolvedArr.reduce((accumulator, currVal) => accumulator + currVal.unresolvedCount, 0);
  }, [unresolvedArr]);

  const renderItems = useCallback(() => {
    return unresolvedArr.map(({
      documentVersionORM:{
        model:{ title },
        relations: { documentORM: { relations: { version: { latestPublishedDocumentVersionORM } } } },
      },
      unresolvedCount,
    }, index) => {
      const isLastItem = index === unresolvedArr.length - 1;
      return (
        <Fragment key={title}>
          <InformationRow
            title={title}
            unresolvedCount={unresolvedCount}
            latestPublishedDocumentVersionORM={latestPublishedDocumentVersionORM}
          />
          {!isLastItem && <DNADivider style={{ backgroundColor: colors['color-success-20'] }}/>}
        </Fragment>
      )
    })
  }, [unresolvedArr])

  if (!unresolvedSlideCount) return null;
  return (
    <DNABox appearance="col" style={styles.container} spacing="md">
      <Iffy is={unresolvedSlideCount}>
        <InformationMessage.Collapsible
          variance="success"
          collapsedDefault
        >
          <InformationMessage.Collapsible.Parent
            text={`${unresolvedSlideCount} slide(s) were recently modified and need your review`}
            secondaryActionButtonText="Accept all"
            secondaryActionButtonFunction={acceptAutoUpdateSlides}
          />
          <InformationMessage.Collapsible.Items>
            {renderItems()}
          </InformationMessage.Collapsible.Items>
        </InformationMessage.Collapsible>
      </Iffy>
    </DNABox>
  )
};

export default InformationBannerAutoUpdate;
