import React, { useMemo } from 'react';
import { DNABox, DNAButton, DNAText } from '@alucio/lux-ui';
import folderQuery from 'src/state/redux/folder/query';
import { useAllFoldersUpdatedDocsMap } from 'src/state/redux/selector/folder';
import { useHistory } from 'src/router';
import WidgetContainer from '../WidgetContainer';

const MyUpdates: React.FC = () => {
  const history = useHistory();
  const currentActiveFilter = useMemo(() => folderQuery.merge(folderQuery.filters.active), []);
  const updatedDocumentsMap = useAllFoldersUpdatedDocsMap(currentActiveFilter);
  const updatedItemsCount = useMemo(() => updatedDocumentsMap.size, [updatedDocumentsMap]);
  const redirectToMyUpdatesPage = () => {
    history.push('/folders/updates')
  };

  if (!updatedItemsCount) return null;
  return (
    <WidgetContainer title="My Updates" actionsCount={updatedItemsCount}>
      <DNABox fill appearance="col" spacing="md">
        <DNAText status="neutral">
          {`You have ${updatedItemsCount} documents need to be reviewed.`}
        </DNAText>
        <DNAButton
          appearance="outline"
          padding="sm"
          children="View updates"
          onPress={redirectToMyUpdatesPage}
        />
      </DNABox>
    </WidgetContainer>
  );
};

export default MyUpdates;
