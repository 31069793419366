import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { StyleSheet, ViewStyle } from 'react-native';
import {
  DNABox,
  DNAButton,
  DNAConditionalWrapper,
  DNAContextMenu,
  DNAIcon,
  DNAText,
  useOrientationChange,
} from '@alucio/lux-ui';
import { DNAButtonProps } from '@alucio/lux-ui/src/components/controls/DNAButton/DNAButton';
import DNAPopover from 'src/components/DNA/Popover/DNAPopover';
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import { FileType, MeetingType, Sentiment } from '@alucio/aws-beacon-amplify/src/models';
import { isDocumentVersionORM, isFolderItemORM } from 'src/types/typeguards';
import { DeviceMode, useAppSettings } from 'src/state/context/AppSettings';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider';
import { useHighlighter } from 'src/components/Highlighter/HighlighterProvider';
import useFullScreen from 'src/hooks/useFullScreen/useFullScreen';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import PresentationNav from 'src/screens/Meetings/SharedComponents/PresentationNav';
import OpenedPresentations from
  'src/screens/Meetings/PresentationControls/TabContent/OpenedPresentations/OpenedPresentations';
import { ActionBarState } from 'src/screens/Meetings/SharedComponents/PresentationMenu';
import { CanvasVariantEnum } from 'src/components/Highlighter/UseCanvas';
import { NOTES } from 'src/screens/Meetings/SharedComponents/Notes/AllNotes';
import SentimentsIcon from 'src/assets/icons/SentimentsIcon';
import { getPresentationVirtualStatus } from '../../util';

type ButtonDefaultVariants = Record<DeviceMode, DNAButtonProps>
type InPersonActionBarStyleVariants = Record<DeviceMode, StyleSheet.NamedStyles<{ [keys: string]: StyleSheet }>>
type AdditionalActionGroups = 'default' | 'tabletSentiments'

const inPersonActionBarStyleVariants: InPersonActionBarStyleVariants = {
  desktop: StyleSheet.create({
    icon: {
      width: 16,
      height: 16,
    },
    activePositiveIcon: {
      backgroundColor: colors['color-success-500'],
    },
    activeNegativeIcon: {
      backgroundColor: colors['color-warning-500'],
    },
  }),
  tablet: StyleSheet.create({
    icon: {
      width: 24,
      height: 24,
    },
    activePositiveIcon: {
      backgroundColor: colors['color-success-500'],
    },
    activeNegativeIcon: {
      backgroundColor: colors['color-warning-500'],
    },
  }),
}

export const useInPersonActionBarSharedComponents = () => {
  const {
    meetingORM,
    endMeeting,
    setPresentationControlsVisible,
    slideRollVisible,
    toggleSlideRollVisibility,
    setSlideRollVisible,
    textSearchVisible,
    toggleTextSearchVisibility,
    canEndMeeting,
    openNotes,
    toggleMeetingNotes,
    toggleMeetingType,
    updateReaction,
    updateFollowUp,
    presentedMeta,
    actionBarState,
    setPresentationMenu,
  } = useMeetingsState();

  const featureFlags = useFeatureFlags(
    'enableMeetingNotes',
    'enableMeetingReactions',
    'enableMeetingFollowUp',
  );

  const {
    highlighterVisible,
    setHighlighterMode,
    selectedType,
    setHighlighterVisible,
  } = useHighlighter();

  const { presentations, activePresentation, isPlayerReady } = useContent();
  const { orm } = { ...activePresentation?.presentable };
  const { isFullScreen, toggleFullScreen } = useFullScreen();
  const { deviceMode, platform } = useAppSettings()
  const isTablet = deviceMode === 'tablet';
  const orientation = useOrientationChange()
  const [showPresentations, setShowPresentations] = useState<boolean>(false);
  const [currentActionsGroup, setCurrentActionGroups] = useState<AdditionalActionGroups>('default')
  const isMeetingNoteOpen = openNotes.includes(NOTES.MEETING_NOTES)
  const presentationVirtualStatus = useMemo(() => getPresentationVirtualStatus(orm), [orm]);
  const isVirtual = meetingORM?.model.type === MeetingType.VIRTUAL;

  useEffect(() => {
    // WHEN SWITCHING DOCUMENT, RESET THE HIGHLIGHTER MODE
    setHighlighterVisible(false)
  }, [activePresentation?.presentable.id])

  const buttonDefaultVariants: ButtonDefaultVariants = {
    desktop: {
      appearance: 'ghost',
      colorMode: 'dark',
      size: 'sm',
      status: 'dark',
      padding: 'md',
    },
    tablet: {
      colorMode: 'dark',
      status: 'dark',
      size: orientation === 'LANDSCAPE' ? 'lg' : 'sm',
      padding: orientation === 'LANDSCAPE' ? 'lg' : 'sm',
      // /**
      //  * TODO: This should be square context, however, using
      //  * that value causes the size prop to become inoperable.
      //  * Should address this in BEAC-1887 */
      // style: { borderRadius: 4 },
    },
  };

  const iconButtonProps = {
    style: {
      paddingHorizontal: 0,
      height: (isTablet && orientation === 'LANDSCAPE')
        ? 49 : 40,
      width: (isTablet && orientation === 'LANDSCAPE')
        ? 49 : 40,
    },
  };

  const currentORM = isDocumentVersionORM(orm)
    ? orm
    : isFolderItemORM(orm)
      ? orm.relations.itemORM
      : undefined

  const isWebVideoHTMLDoc = isDocumentVersionORM(currentORM) && [
    FileType.WEB,
    FileType.MP4,
    FileType.HTML,
  ].includes(FileType[currentORM?.model.type]);

  const style = inPersonActionBarStyleVariants[deviceMode];

  const openSidePanel = () => {
    setSlideRollVisible(false);
    setPresentationControlsVisible(true);
  };

  const toggleShowPresentations = () => {
    setShowPresentations((showPresentations) => !showPresentations)
  };

  const buttonDefaults = buttonDefaultVariants[deviceMode]
  const presentationNav = !isWebVideoHTMLDoc && !!presentations.length && <PresentationNav />;

  const myContentButton = (
    <DNAButton
      {...buttonDefaults}
      onPress={openSidePanel}
      testID="my-content-button"
    >
      My Content
    </DNAButton>
  );

  const presentationsButton = !!presentations.length && (
    <DNAPopover
      lazyMount
      placement="top"
      interactive={true}
      visible={showPresentations}
      onBackdropPress={toggleShowPresentations}
      type="menu"
      offset={30}
    >
      <DNAPopover.Anchor>
        <DNAButton
          {...buttonDefaults}
          onPress={toggleShowPresentations}
          testID="files-button"
          status={showPresentations ? 'primary' : 'dark'}
        >
          Opened Files
        </DNAButton>
      </DNAPopover.Anchor>
      <DNAPopover.Content>
        <OpenedPresentations
          variant="actionBar"
          onClose={toggleShowPresentations}
        />
      </DNAPopover.Content>
    </DNAPopover>
  );

  const slidesButton = !!activePresentation && !isWebVideoHTMLDoc && !!presentations.length && (
    <DNAButton
      {...buttonDefaults}
      onPress={toggleSlideRollVisibility}
      testID="in-person-meeting-slides-btn"
      status={slideRollVisible ? 'primary' : 'dark'}
    >
      Slides
    </DNAButton>
  );

  const highlighterButton = !!activePresentation && (
    <DNAPopover
      placement="top"
      type="tooltip"
      offset={30}
      disablePopover={['tabletPWA']}
    >
      <DNAPopover.Anchor>
        <DNAButton
          {...buttonDefaults}
          {...iconButtonProps}
          onPress={() => setPresentationMenu(ActionBarState.HighlighterMenu)}
          key="Marker"
          testID="toggle-marker-button"
          iconLeft="marker"
          status={buttonDefaults.status}
        />
      </DNAPopover.Anchor>
      <DNAPopover.Content>
        <DNAText status="basic">Highlighter</DNAText>
      </DNAPopover.Content>
    </DNAPopover>
  );

  const highlighterActions = !!activePresentation && (
    <DNABox spacing="sm">
      <DNAButton
        {...buttonDefaults}
        iconLeft="arrow-left-bold"
        onPress={() => {
          setPresentationMenu(ActionBarState.PresentationMenu)
          setHighlighterVisible(false)
        }}
        testID="back-btn"
      >
        Back
      </DNAButton>
      <DNAButton
        {...buttonDefaults}
        iconLeft="arrow-top-right"
        onPress={() => setHighlighterMode(CanvasVariantEnum.arrow)}
        status={highlighterVisible && selectedType === CanvasVariantEnum.arrow
          ? 'info'
          : buttonDefaults.status
        }
        testID="arrow-btn"
      >
        Arrow
      </DNAButton>
      <DNAButton
        {...buttonDefaults}
        iconLeft="checkbox-blank"
        onPress={() => setHighlighterMode(CanvasVariantEnum.square)}
        testID="box-btn"
        status={highlighterVisible && selectedType === CanvasVariantEnum.square
          ? 'info'
          : buttonDefaults.status
        }
      >
        Box highlighter
      </DNAButton>
    </DNABox>
  );

  const highlighterContextMenu = !!activePresentation && (
    <DNAConditionalWrapper
      condition={!selectedType}
      wrapper={children => (
        <DNAPopover
          placement="top"
          type="tooltip"
          offset={30}
          disablePopover={['tabletPWA']}
        >
          <DNAPopover.Anchor>
            <DNAContextMenu placement="top" style={{ bottom: 8 }}>
              <DNAContextMenu.Anchor>
                {children}
              </DNAContextMenu.Anchor>
              <DNAContextMenu.Items>
                <DNAContextMenu.Item
                  icon="arrow-top-right"
                  title="Arrow"
                  onPress={() => setHighlighterMode(CanvasVariantEnum.arrow)}
                  testID="arrow-btn"
                />
                <DNAContextMenu.Item
                  icon="checkbox-blank"
                  title="Box highlighter"
                  onPress={() => setHighlighterMode(CanvasVariantEnum.square)}
                  testID="box-btn"
                />
              </DNAContextMenu.Items>
            </DNAContextMenu>
          </DNAPopover.Anchor>
          <DNAPopover.Content>
            <DNAText status="basic">Highlighter</DNAText>
          </DNAPopover.Content>
        </DNAPopover>
      )}
    >
      <DNAButton
        {...buttonDefaults}
        {...iconButtonProps}
        key="Marker"
        testID="toggle-marker-button"
        iconLeft="marker"
        status={selectedType ? 'primary' : 'dark'}
        onPress={() => {
          if (selectedType) setHighlighterVisible(false)
        }}
      />
    </DNAConditionalWrapper>
  );

  const textSearchButton = !!activePresentation && !isWebVideoHTMLDoc && !!presentations.length && (
    <DNAPopover
      placement="top"
      type="tooltip"
      offset={30}
      disablePopover={['tabletPWA']}
    >
      <DNAPopover.Anchor>
        <DNAButton
          {...buttonDefaults}
          {...iconButtonProps}
          onPress={toggleTextSearchVisibility}
          testID="in-person-meeting-search-in-document-btn"
          iconLeft="magnify"
          status={textSearchVisible ? 'primary' : 'dark'}
        />
      </DNAPopover.Anchor>
      <DNAPopover.Content>
        <DNAText status="basic">Search</DNAText>
      </DNAPopover.Content>
    </DNAPopover>
  );

  const reactionButtons = !!activePresentation && featureFlags.enableMeetingReactions ? (
    <DNABox spacing="sm">
      <DNAButton
        {...buttonDefaults}
        {...iconButtonProps}
        iconLeft="chevron-up"
        iconStyle={[style.icon, presentedMeta?.sentiment === Sentiment.POSITIVE &&
        { color: colors['color-success-500'] }]}
        onPress={() => updateReaction(Sentiment.POSITIVE)}
        testID="positive-reaction-btn"
      />
      <DNAButton
        {...buttonDefaults}
        {...iconButtonProps}
        iconLeft="chevron-down"
        iconStyle={[style.icon, presentedMeta?.sentiment === Sentiment.NEGATIVE &&
        { color: colors['color-warning-500'] }]}
        onPress={() => updateReaction(Sentiment.NEGATIVE)}
        testID="negative-reaction-btn"
      />
    </DNABox>
  ) : null;

  const followUpButton = !!activePresentation && featureFlags.enableMeetingFollowUp ? (
    <DNAPopover
      placement="top"
      type="tooltip"
      offset={30}
      disablePopover={['tabletPWA']}
    >
      <DNAPopover.Anchor>
        <DNAButton
          {...buttonDefaults}
          {...iconButtonProps}
          iconLeft={presentedMeta?.followUp ? 'flag' : 'flag-outline'}
          onPress={updateFollowUp}
          testID="follow-up-flag-btn"
        />
      </DNAPopover.Anchor>
      <DNAPopover.Content>
        <DNAText status="basic">Flag for follow-up</DNAText>
      </DNAPopover.Content>
    </DNAPopover>
  ) : null;

  const positiveIcon = (
    <DNABox
      alignX="center"
      alignY="center"
      style={presentedMeta?.sentiment === Sentiment.POSITIVE && style.activePositiveIcon}
    >
      <DNAIcon.Styled
        appearance="ghost"
        name={'chevron-up'}
        status={presentedMeta?.sentiment === Sentiment.POSITIVE ? 'dark' : 'tertiary'}
      />
    </DNABox>
  );

  const negativeIcon = (
    <DNABox
      alignX="center"
      alignY="center"
      style={presentedMeta?.sentiment === Sentiment.NEGATIVE && style.activeNegativeIcon}
    >
      <DNAIcon.Styled
        appearance="ghost"
        name={'chevron-down'}
        status={presentedMeta?.sentiment === Sentiment.NEGATIVE ? 'dark' : 'tertiary'}
      />
    </DNABox>
  );

  const sentimentsContextMenu = !!activePresentation &&
    (featureFlags.enableMeetingReactions || featureFlags.enableMeetingFollowUp) ? (
      <DNAPopover
        placement="top"
        type="tooltip"
        offset={30}
        disablePopover={['tabletPWA']}
      >
        <DNAPopover.Anchor>
          <DNAContextMenu placement="top" style={{ bottom: 8 }}>
            <DNAContextMenu.Anchor>
              <DNAButton
                {...buttonDefaults}
                {...iconButtonProps}
                customIconLeft={SentimentsIcon}
                customIconName="sentiments-icon"
                iconStyle={style.icon as ViewStyle}
                status={(presentedMeta?.sentiment || presentedMeta?.followUp) ? 'primary' : 'dark'}
              />
            </DNAContextMenu.Anchor>
            <DNAContextMenu.Items>
              {featureFlags.enableMeetingReactions &&
                <DNAContextMenu.Item
                  icon={positiveIcon}
                  title="Positive"
                  onPress={() => updateReaction(Sentiment.POSITIVE)}
                  testID="positive-reaction-btn"
                />
              }
              {featureFlags.enableMeetingReactions &&
                <DNAContextMenu.Item
                  icon={negativeIcon}
                  title="Negative"
                  onPress={() => updateReaction(Sentiment.NEGATIVE)}
                  testID="negative-reaction-btn"
                />
              }
              {featureFlags.enableMeetingFollowUp &&
                <DNAContextMenu.Item
                  icon={presentedMeta?.followUp ? 'flag' : 'flag-outline'}
                  iconStatus={presentedMeta?.followUp ? 'primary' : 'tertiary'}
                  title="Flag for follow-up"
                  onPress={updateFollowUp}
                  testID="follow-up-flag-btn"
                />
              }
            </DNAContextMenu.Items>
          </DNAContextMenu>
        </DNAPopover.Anchor>
        <DNAPopover.Content>
          <DNAText status="basic">Sentiments</DNAText>
        </DNAPopover.Content>
      </DNAPopover>
    ) : null;

  const myNotesButton = !!activePresentation && !!presentations.length && featureFlags.enableMeetingNotes ? (
    <DNAPopover
      placement="top"
      type="tooltip"
      offset={30}
      disablePopover={['tabletPWA']}
    >
      <DNAPopover.Anchor>
        <DNAButton
          {...buttonDefaults}
          {...iconButtonProps}
          onPress={toggleMeetingNotes}
          key="Comments"
          testID="toggle-comments-button"
          iconLeft="playlist-edit"
          status={isMeetingNoteOpen ? 'primary' : 'dark'}
        />
      </DNAPopover.Anchor>
      <DNAPopover.Content>
        <DNAText status="basic">Comments</DNAText>
      </DNAPopover.Content>
    </DNAPopover>
  ) : null;

  const fullScreenButton = (deviceMode === 'desktop' || platform.includes('desktop')) ? (
    <DNAPopover
      placement="top"
      type="tooltip"
      offset={30}
      disablePopover={['tabletPWA']}
    >
      <DNAPopover.Anchor>
        <DNAButton
          {...buttonDefaults}
          {...iconButtonProps}
          onPress={toggleFullScreen}
          testID="in-person-meeting-full-screen-btn"
          iconRight={isFullScreen ? 'arrow-collapse' : 'arrow-expand'}
          iconStyle={{ margin: 0 }}
        />
      </DNAPopover.Anchor>
      <DNAPopover.Content>
        <DNAText status="basic">Full screen</DNAText>
      </DNAPopover.Content>
    </DNAPopover>
  ) : null;

  const endMeetingButton = (
    <DNAButton
      {...buttonDefaults}
      {...(isTablet ? iconButtonProps : {})}
      status="danger"
      onPress={endMeeting}
      disabled={!canEndMeeting}
      testID="in-person-meeting-end-meeting-btn"
      iconLeft={isTablet ? 'close' : undefined}
      children={isTablet ? undefined : 'End meeting'}
    />
  );

  const changeToVirtualModeTooltipMessage =
    !isPlayerReady
      ? `${isVirtual ? 'Exit' : ''} Presenter View ${!isVirtual ? 'available' : ''} once document is loaded`
      : (presentationVirtualStatus.isHTMLWebPresentation && presentationVirtualStatus.message) ||
      'Use Presenter View';
  const changeToVirtualModeButton = platform.includes('desktop') && (
    <DNAPopover
      placement="top"
      type="tooltip"
      offset={30}
    >
      <DNAPopover.Anchor>
        <DNAButton
          {...buttonDefaults}
          {...iconButtonProps}
          {...((presentationVirtualStatus.isHTMLWebPresentation && isTablet) ? { appearance: 'ghost' } : {})}
          onPress={toggleMeetingType}
          testID="change-meeting-mode-button"
          iconRight={isVirtual ? 'projector-screen-off-outline' : 'projector-screen-outline'}
          disabled={presentationVirtualStatus.isHTMLWebPresentation || !isPlayerReady}
        />
      </DNAPopover.Anchor>
      <DNAPopover.Content>
        <DNAText status="basic">{changeToVirtualModeTooltipMessage}</DNAText>
      </DNAPopover.Content>
    </DNAPopover>
  );

  const collapseButton = (
    <DNAButton
      {...buttonDefaults}
      {...iconButtonProps}
      appearance="filled"
      iconRight={
        actionBarState === ActionBarState.PresentationMenu
          ? 'arrow-collapse-right'
          : 'arrow-collapse-left'
      }
      onPress={() => setPresentationMenu(
        actionBarState === ActionBarState.PresentationMenu ? ActionBarState.Collapsed : ActionBarState.PresentationMenu,
      )}
      testID="direct-share-mode-collapse-btn"
    />
  );

  const CurrentActionsToggle = useCallback<React.FC<{
    group: AdditionalActionGroups,
    isIconButton?: boolean
  } & DNAButtonProps>>(
    (props) => {
      const { group, isIconButton, ...buttonProps } = props
      return (
        <DNAButton
          {...buttonDefaults}
          {...(isIconButton ? iconButtonProps : {})}
          {...buttonProps}
          onPress={() => setCurrentActionGroups(group)}
        />
      )
    },
    [setCurrentActionGroups],
  );

  return {
    presentationNav,
    myContentButton,
    presentationsButton,
    slidesButton,
    highlighterButton,
    highlighterActions,
    highlighterContextMenu,
    textSearchButton,
    reactionButtons,
    followUpButton,
    sentimentsContextMenu,
    myNotesButton,
    fullScreenButton,
    endMeetingButton,
    changeToVirtualModeButton,
    collapseButton,
    actionBarState,
    CurrentActionsToggle,
    currentActionsGroup,
  }
}

export default useInPersonActionBarSharedComponents
