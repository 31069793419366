import React from 'react'
import { DocumentORM, FolderItemORM, FolderORM } from 'src/types/types'
import { ContentComponent, ContentVariant } from 'src/components/DNA/types'
import { DNAGridCards } from 'src/components/DNA/Grid/Cards/types'
import { SelectedDocuments } from 'src/screens/MyLibrary/DocumentSelectManager'

import { DNABox, Iffy } from '@alucio/lux-ui'
import { DNABoxProps } from '@alucio/lux-ui/src/components/layout/DNABox/DNABox'

import DNAEmpty, { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty'
import DNADefaultCards from 'src/components/DNA/Grid/Cards/default'
import DNAVirtualCards from 'src/components/DNA/Grid/Cards/virtual'
import { usePagination } from 'src/components/DNA/Pagination/DNAPagination'
import { useAppSettings } from 'src/state/context/AppSettings';
import { isCustomDeckORM, isFolderORM, isFolderItemORM } from 'src/types/typeguards'

export interface DNAGridProps
  extends Pick<DNABoxProps, 'style' | 'alignX' | 'alignY' | 'spacing' | 'wrap'> {
  emptyVariant: EmptyVariant,
  useEmptyContainer?: boolean,
  selectEnabled?: boolean,
  selectedDocuments?: SelectedDocuments,
  onSelectDocuments?: (documents: DocumentORM[]) => void,
}

export interface DNAGridComponent extends ContentComponent<DNAGridProps> {
  Paginated: typeof DNAGridPaginated
}

type CardVariants = Record<ContentVariant, DNAGridCards>

const cardVariants: CardVariants = {
  default: DNADefaultCards,
  dashboard: DNADefaultCards,
  search: DNADefaultCards,
  virtual: DNAVirtualCards,
  customDeck: DNADefaultCards,
  publisher: DNADefaultCards,
  meetings: DNADefaultCards,
  fileDrawer: DNADefaultCards,
}

const S = {
  emptyStyle: { margin: 0 },
  cardSpacerStyle: { marginBottom: 16 },
}

export const DNAGrid: DNAGridComponent = (props) => {
  const {
    items,
    spacing,
    wrap,
    limit,
    emptyVariant,
    variant = 'default',
    useEmptyContainer,
    onPress,
    selectEnabled,
    selectedDocuments,
    onSelectDocuments,
    ...rest
  } = props

  const { deviceMode } = useAppSettings()
  const isDesktop = deviceMode === 'desktop';
  const { isOnline } = useAppSettings()
  const centerEmptyForTablet = !items.length && !isDesktop
    ? 'center'
    : undefined

  return (
    <DNABox
      childFill={!items.length ? 0 : false} // Needed for empty variant to fill vertically
      fill
      wrap="start"
      alignY={centerEmptyForTablet}
      spacing={spacing || 'md'}
      childStyle={!items.length
        ? S.emptyStyle
        : S.cardSpacerStyle
      }
      {...rest}
    >
      <Iffy is={!items.length}>
        <DNAEmpty
          variant={variant}
          emptyVariant={emptyVariant}
          useContainer={useEmptyContainer}
        />
      </Iffy>
      <Iffy is={items.length}>
        {
          items
            .slice(0, limit || items.length)
            .map(item => {
              const {
                DNAFolderCard,
                DNADocumentCard,
                DNADocumentFolderItemCard,
                DNACustomDeckCard,
              } = cardVariants[variant]

              if (isFolderORM(item)) {
                return (
                  <DNAFolderCard
                    key={item.model.id}
                    ORM={item}
                    onPress={onPress as (f: FolderORM) => void}
                  />
                )
              } else if (isFolderItemORM(item)) {
                if (isFolderORM(item.relations.itemORM )) {
                  return (
                    <DNAFolderCard
                      key={item.model.id}
                      ORM={item.relations.itemORM}
                      onPress={onPress as (f: FolderORM) => void}
                    />
                  )
                } else if (isCustomDeckORM(item.relations.itemORM)) {
                  return (
                    <DNACustomDeckCard
                      key={item.model.id}
                      ORM={item}
                      onPress={onPress as (f: FolderItemORM) => void}
                      isOnline={isOnline}
                    />
                  )
                } else {
                  return (
                    <DNADocumentFolderItemCard
                      key={item.model.id}
                      ORM={item}
                      onPress={onPress as (d: FolderItemORM) => void}
                      isOnline={isOnline}
                    />
                  )
                }
              } else if (item.type === 'DOCUMENT') {
                const checked = selectedDocuments && selectedDocuments[item.model.id]
                return (
                  <DNADocumentCard
                    isDesktop={isDesktop}
                    key={item.model.id}
                    ORM={item}
                    onPress={onPress as (d: DocumentORM) => void}
                    isOnline={isOnline}
                    selectEnabled={selectEnabled}
                    checked={checked}
                    onSelect={onSelectDocuments ? () => onSelectDocuments([item]) : undefined}
                  />

                )
              } else {
                return null
              }
            })
        }
      </Iffy>
    </DNABox>
  )
}

export const DNAGridPaginated: React.FC<Omit<DNAGridProps, 'items'>> = (props) => {
  const { pagedRecords } = usePagination()
  return (<DNAGrid {...props} items={pagedRecords} />)
}

DNAGrid.Paginated = DNAGridPaginated

export default DNAGrid
