import React from 'react'
import { StyleProp, ViewStyle } from 'react-native'
import { ContentVariant } from 'src/components/DNA/types'
import { DNABox, DNAButton, DNAIcon, LuxStatus, util } from '@alucio/lux-ui'
import { useAppSettings } from 'src/state/context/AppSettings'

export interface PinButtonProps {
  onPin?: () => void,
  isHidden?: boolean,
  variant?: ContentVariant,
  interactive?: boolean
}

/**
 * Pinned Button
 */
const S = {
  rotatePin: { transform: [{ rotate: '-20deg' }] },
  hidden: { display: 'none' },
}

type VariantPinStyles = Record<ContentVariant, LuxStatus>
const variantPinStyles: VariantPinStyles = {
  default: 'secondary',
  dashboard: 'secondary',
  search: 'secondary',
  virtual: 'gray',
  customDeck: 'secondary',
  publisher: 'secondary',
  meetings: 'gray',
  fileDrawer: 'secondary',
}

export const PinButton: React.FC<PinButtonProps> = (props) => {
  const { onPin, isHidden, variant = 'default', interactive = true } = props
  const { deviceMode } = useAppSettings();
  const isDesktop: boolean = deviceMode === 'desktop';
  const style = util.mergeStyles(props, [S.hidden, isHidden]) as StyleProp<ViewStyle>

  return (
    <DNABox style={isDesktop ? { minWidth: 34 } : { minWidth: 48 }}>

      {interactive
        ? <DNAButton
            testID="ghost-icon-pin"
            appearance="ghost"
            size={ isDesktop ? 'md' : 'lg' }
            padding={ isDesktop ? 'sm' : 'md' }
            rounded="md"
            iconLeft="pin"
            status={variantPinStyles[variant]}
            onPress={onPin}
            iconStyle={style}
            style={ isHidden && { display: 'none' }}
        />
        : <DNAIcon.Styled
            appearance="ghost"
            status={variantPinStyles[variant]}
            size={ isDesktop ? 'md' : 'lg' }
            padding={ isDesktop ? 'sm' : 'md' }
            rounded="md"
            name="pin"
            style={style}
        />}
    </DNABox>
  )
}

export default PinButton
