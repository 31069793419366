import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import DNAFlatList from 'src/components/DNA/FlatList/DNAFlatList';
import { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty';
import { useAllFoldersInstance } from 'src/state/redux/selector/folder';
import { FolderORM } from 'src/types/orms';
import folderQuery from 'src/state/redux/folder/query';
import WidgetContainer from '../WidgetContainer';

export enum FoldersWidgetVariant {
  RECENTLY_VIEWED = 'Recently Accessed Folders',
}

interface FoldersProps {
  variant: FoldersWidgetVariant,
}

const Folders: React.FC<FoldersProps> = (props) => {
  const history = useHistory();
  const allFolders = useAllFoldersInstance(folderQuery.filters.active);
  const folders = allFolders.filter(folder => folder.relations.parentFolderORM === null);
  const displayFolder = useMemo(() => folders.slice(0, 5), [folders]);

  const onPress = (folderORM: FolderORM): void => {
    history.push(`/folders/${folderORM.model.id}`)
  };

  return (
    <WidgetContainer title={props.variant}>
      <DNAFlatList
        items={displayFolder}
        variant="dashboard"
        onPress={onPress}
        emptyVariant={EmptyVariant.FolderDashBoardEmpty}
      />
    </WidgetContainer>
  );
};

export default Folders;
