import React, { useEffect, useState, useRef } from 'react';
import { StyleSheet } from 'react-native';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { meetingActions } from 'src/state/redux/slice/meeting';
import { DNAButton, Spinner } from '@alucio/lux-ui';
import type { DNAButtonProps } from '@alucio/lux-ui/src/components/controls/DNAButton/DNAButton';
import { MeetingType } from '@alucio/aws-beacon-amplify/src/models';
import { useSyncState } from 'src/state/redux/selector/cache'
import workerChannel from 'src/worker/channels/workerChannel'
import { format } from 'date-fns';
import { useAppSettings } from 'src/state/context/AppSettings';
import { contentPreviewModalActions } from 'src/state/redux/slice/contentPreviewModal';
import { LoadedPresentation } from 'src/state/context/ContentProvider/ContentProvider';
import { DefaultVariant } from './Variants/Default';
import { ContentPreviewModalVariant } from './Variants/ContentPreviewModal';

enum ContextVariantOptions {
  default,
  contentPreviewModal
}
enum AnchorVariantOptions {
  desktop,
  desktopCPM,
  tablet,
  tabletCPM,
}

export interface MeetingButtonProps {
  context?: ContextVariantOption
}

type AnchorVariantOption = keyof typeof AnchorVariantOptions
type ContextVariantOption = keyof typeof ContextVariantOptions
type ContextVariants = Record<ContextVariantOption, React.ElementType>

export const styles = StyleSheet.create({
  buttonContainer: {
    height: 38,
  },
});

const SPINNER_TIMEOUT_MS = 2000

/** This hook allows us to instantiate meeting button variances using shared components
 * based on whether we have access to the useContent hook. The dropdown options are the same but
 * the button anchor can be visually divergant for the various contexts this component is used in.
 * */
export const useMeetingButtonSharedComponents = (loadedPresentation?: LoadedPresentation) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const syncState = useSyncState()
  const [isMeetingQueued, setIsMeetingQueued] = useState<boolean>(false);
  const [showMeetingQueued, setShowMeetingQueued] = useState<boolean>(false);
  const isMounted = useRef<boolean>(false)

  const { deviceMode } = useAppSettings()

  useEffect(
    () => {
      isMounted.current = true
      return () => {
        isMounted.current = false
      }
    },
    [],
  )

  const pauseOfflineSyncing = async() => {
    // check if service worker is registered
    const registration = await navigator.serviceWorker.getRegistration('/')
    if (registration && syncState?.matches('online.sync')) {
      // pause offline syncing
      workerChannel.postMessageExtended({ type: 'PAUSE_SYNC' })
    }
  }

  const startMeeting = () => {
    pauseOfflineSyncing()
    setIsMeetingQueued(true)

    // Avoid a flashing spinner
    setTimeout(
      () => {
        if (isMounted.current) {
          setShowMeetingQueued(true)
        }
      },
      SPINNER_TIMEOUT_MS,
    )

    const onMeetingCreated = (meetingId: string) => {
      if (isMounted.current) {
        setIsMeetingQueued(false)
        setShowMeetingQueued(false)

        if (loadedPresentation) {
          dispatch(contentPreviewModalActions.setInitialContent({
            presentableModelORM: loadedPresentation.presentable.orm,
            page: loadedPresentation.currentPresentablePage.presentationPageNumber,
          }));
          // [NOTE] - Make sure this is done before closing the modal to ensure we skip redirecting from the useCPMDedicatedUrl hook
          // [TODO BEAC-6488] - This probably shouldn't push us into this page until the ORM is in Redux, consider reworking this flow
          history.push(`/meeting-presentation/${meetingId}`)
          dispatch(contentPreviewModalActions.setModalVisibility({ isOpen: false }));
        } else {
          // [TODO BEAC-6488] - This probably shouldn't push us into this page until the ORM is in Redux
          history.push(`/meeting-presentation/${meetingId}`)
        }
      }
    }

    // WE CREATE THE MEETING
    dispatch(meetingActions.startMeeting({
      type: MeetingType.IN_PERSON,
      title: `Meeting Started at ${format(new Date(), 'MM/dd/yyyy h:mm aa')}`,
      callback: onMeetingCreated,
      context: loadedPresentation ? 'PREVIEW_MODAL' : 'TOP_NAV',
    }));
  }

  /** 4 Anchor variants here in 4 possible cases:
   * (currently the only way to determine if we are in
   * CPM is by checking the loaded presentation prop)
   *  - Desktop - Dropdown with "Start meeting" label
   *  - Tablet - Circle play icon
   *  - Desktop Content Preview Modal - Dropdown with "Present" label and play button icon
   *  - Tablet Content Preview Modal - "Present" label with play button icon
  */
  const currentAnchorVariant: AnchorVariantOption =
    deviceMode === 'desktop'
      ? loadedPresentation ? 'desktopCPM' : 'desktop'
      : loadedPresentation ? 'tabletCPM' : 'tablet'

  const isIconAnchor = currentAnchorVariant === 'tablet'

  const buttonIconProps: DNAButtonProps = {
    ...(
      !showMeetingQueued
        ? isIconAnchor
          ? { iconRight: 'play-circle' }
          : { }
        : { customIconRight: () => <Spinner size="sm" /> }
    ),
  }

  const meetingButtonComponents = isIconAnchor
    ? (
      <DNAButton
        padding="sm"
        appearance="ghostLink"
        status="dark"
        size="xl"
        onPress={startMeeting}
        testID="header-start-meeting-button"
        disabled={isMeetingQueued}
        {...buttonIconProps}
      />
    ) : (
      <DNAButton
        testID={loadedPresentation ? 'header-present-button' : 'header-start-meeting-button'}
        onPress={startMeeting}
        appearance="outline"
        status="tertiary"
        size="md"
        padding="sm"
        children={loadedPresentation ? 'Present' : 'Start Meeting'}
        disabled={isMeetingQueued}
        style={styles.buttonContainer}
        {...buttonIconProps}
      />
    )

  return { meetingButtonComponents };
}

const contextVariants: ContextVariants = {
  default: DefaultVariant,
  contentPreviewModal: ContentPreviewModalVariant,
}

const MeetingButton: React.FC<MeetingButtonProps> = ({ context = 'default' }) => {
  /** Currently the goal of this implementation is to allow for the conditional usage of the useContent hook.
   * However, this approach additionally supports completely divergent appearances as well (i.e. the launch
   * meeting button could look/interact completely differnet in the various contexts (default vs cpm) */
  const CurrentContextVariant = contextVariants[context]

  return <CurrentContextVariant />
}

export default MeetingButton;
