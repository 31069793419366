import React from 'react';
import { StyleSheet } from 'react-native';
import { DNABox, DNACard, DNAText, util } from '@alucio/lux-ui';
import { DNARowsComponent } from 'src/components/DNA/FlatList/Rows/types';
import { FolderORM } from 'src/types/types';
import FolderIcon from 'src/assets/images/FolderVector';
import { DNAFolderDefaultRowProps } from '../default/DNAFolderDefaultRow';

const styles = StyleSheet.create({
  folderRow: {
    paddingVertical: 12,
  },
  folderTitle: {
    marginLeft: 12,
  },
});

const DNAFolderDashboardRow: DNARowsComponent<FolderORM, DNAFolderDefaultRowProps> = (props) => {
  const { ORM: folderORM } = props;

  const onPress = (): void => {
    props.onPress?.(folderORM);
  };

  return (
    <DNACard
      appearance="flat"
      interactive="pressable"
      onPress={onPress}
      style={util.mergeStyles(undefined, styles.folderRow)}
    >
      <DNABox appearance="col">
        <DNABox fill>
          <DNABox>
            <FolderIcon />
          </DNABox>
          <DNAText style={styles.folderTitle} numberOfLines={1}>{folderORM.model.name}</DNAText>
        </DNABox>
      </DNABox>
    </DNACard>
  );
}

export default DNAFolderDashboardRow;
