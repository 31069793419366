import im from 'immer';
import { isEmpty } from 'lodash';
import {
  Coordinate,
  UserNotations,
  UserNotationsStatus,
  UserNotationsType,
  HubSectionItemStatus,
  Notation,
} from '@alucio/aws-beacon-amplify/src/models';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataStore } from '@aws-amplify/datastore';
import { convertUndefinedToNull } from 'src/utils/objectHelpers';
import { commonReducers, initialState, SliceState } from './common';
import ActiveUser from '../../global/ActiveUser';
import * as logger from 'src/utils/logger';

const sliceName = 'userNotations'
const { reducers, extraReducers } = commonReducers<UserNotations>(sliceName)

export interface CreateUserNotationsPayload {
  documentId?: string
  documentVersionId?: string
  customDeckId?: string
  notation: Notation[]
  type: UserNotationsType
}

interface UpdateNotationPayload {
  userNotations: UserNotations
  notation?: Notation
  notationId?: string
  notationDescription?: string
  coordinates?: Coordinate[]
}

interface UpdateNotationsPageIdPayload {
  userNotations: UserNotations
  pageIdMap: {
    [oldPageId: string]: string
  }
}

interface DeleteNotationPayload {
  userNotations: UserNotations
  notationId: string
}

interface DuplicateUserNotationsPayload {
  userNotations: UserNotations
  newCustomDeckId: string
}

const userNotationsSlice = createSlice({
  name: sliceName,
  initialState: initialState<UserNotations>(),
  reducers: {
    ...reducers,
    createUserNotations: (
      _state: SliceState<UserNotations>,
      action: PayloadAction<CreateUserNotationsPayload>,
    ): void => {
      const { payload } = action
      const user = ActiveUser.user
      const now = new Date().toISOString()

      if (!user) {
        const errorText = 'Active user is not set when trying to create userNotations';
        logger.userNotations.error(errorText)
        throw new Error(errorText)
      }
      const isCustomDeck = payload.type === UserNotationsType.CUSTOM_DECK
      if (isCustomDeck && !payload.customDeckId) {
        const errorText = 'Something went wrong, cannot find customDeckId when trying to create userNotations';
        logger.userNotations.error(errorText, payload)
        throw new Error(errorText)
      } else if (!isCustomDeck && (!payload.documentId || !payload.documentVersionId)) {
        // eslint-disable-next-line max-len
        const errorText = 'Something went wrong, cannot find documentId or documentVersionId when trying to create userNotations';
        logger.userNotations.error(errorText, payload)
        throw new Error(errorText)
      }

      const userNotations = new UserNotations({
        tenantId: user.tenantId,
        createdAt: now,
        createdBy: user.id,
        updatedAt: now,
        updatedBy: user.id,
        documentId: payload.documentId,
        documentVersionId: payload.documentVersionId,
        customDeckId: payload.customDeckId,
        notation: payload.notation,
        status: UserNotationsStatus.ACTIVE,
        type: payload.type,
      });

      DataStore.save(userNotations)
    },
    updateNotation: {
      prepare: (payload: UpdateNotationPayload) => {
        const { coordinates, userNotations, notation, notationId, notationDescription } = payload
        const user = ActiveUser.user
        const now = new Date().toISOString()
        let updatedNotations: Notation[] = userNotations.notation

        if (notation) {
          // UPSERT NOTATION
          updatedNotations = userNotations.notation
            ? [...userNotations.notation, convertUndefinedToNull(notation)]
            : [convertUndefinedToNull(notation)]
        }
        else if (notationId && notationDescription) {
          // UPDATE NOTATION DESCRIPTION
          const targetNotationIndex = userNotations.notation.findIndex(notation => notation.id === notationId)
          if (targetNotationIndex === -1) {
            const errorText = 'Something went wrong, cannot find the notation you are editing';
            logger.userNotations.error(errorText, payload)
            throw new Error(errorText)
          }
          updatedNotations = im(
            userNotations.notation,
            draft => {
              return draft.map((notation, index) => {
                if (targetNotationIndex === index) {
                  return {
                    ...notation,
                    description: notationDescription,
                    updatedAt: now,
                    coordinate: coordinates || notation.coordinate,
                    updatedBy: user!.id,
                  }
                } else return notation
              })
            },
          )
        }

        return {
          payload: {
            model: UserNotations,
            entity: userNotations,
            updates: {
              updatedAt: now,
              updatedBy: user!.id,
              notation: updatedNotations,
            },
          },
        }
      },
      reducer: reducers.save,
    },
    updateNotationsPageId: {
      prepare: (payload: UpdateNotationsPageIdPayload) => {
        const { userNotations, pageIdMap } = payload
        const user = ActiveUser.user
        const now = new Date().toISOString()
        if (!user) {
          const errorText = 'Something went wrong, cannot find current user';
          logger.userNotations.error(errorText)
          throw new Error(errorText)
        }
        if (isEmpty(pageIdMap)) {
          const errorText = 'Something went wrong, pageIdMap cannot be empty';
          logger.userNotations.error(errorText)
          throw new Error(errorText)
        }

        const updatedNotations: Notation[] = userNotations.notation.map(notation => {
          const newPageId = pageIdMap[notation.pageId]
          if (newPageId) {
            return {
              ...notation,
              pageId: newPageId,
              updatedAt: now,
              updatedBy: user.id,
            }
          } else return notation
        })

        return {
          payload: {
            model: UserNotations,
            entity: userNotations,
            updates: {
              updatedAt: now,
              updatedBy: user.id,
              notation: updatedNotations,
            },
          },
        }
      },
      reducer: reducers.save,
    },
    deleteNotation: {
      prepare: (payload: DeleteNotationPayload) => {
        const { userNotations, notationId } = payload
        const user = ActiveUser.user
        const now = new Date().toISOString()

        const targetNotationIndex = userNotations.notation.findIndex(notation => notation.id === notationId)
        if (targetNotationIndex === -1) {
          const errorText = 'Something went wrong, cannot find the notation you are trying to delete';
          logger.userNotations.error(errorText, payload)
          throw new Error(errorText)
        }
        const updatedNotations = im(
          userNotations.notation,
          draft => {
            return draft.map((notation, index) => {
              if (targetNotationIndex === index) {
                return {
                  ...notation,
                  status: HubSectionItemStatus.DELETED,
                  updatedAt: now,
                  updatedBy: user!.id,
                }
              } else return notation
            })
          },
        )
        return {
          payload: {
            model: UserNotations,
            entity: userNotations,
            updates: {
              updatedAt: now,
              updatedBy: user!.id,
              notation: updatedNotations,
            },
          },
        }
      },
      reducer: reducers.save,
    },
    deleteUserNotations: {
      prepare: (userNotations: UserNotations) => {
        return {
          payload: {
            model: UserNotations,
            entity: userNotations,
            updates: {
              updatedAt: new Date().toISOString(),
              updatedBy: ActiveUser.user!.id,
              status: UserNotationsStatus.DELETED,
            },
          },
        }
      },
      reducer: reducers.save,
    },
    duplicateUserNotations: (
      _state: SliceState<UserNotations>,
      action: PayloadAction<DuplicateUserNotationsPayload>,
    ): void => {
      // When user duplicate a custom deck, we want to also duplicate the UserNotations that ties to the deck
      const { payload } = action
      const user = ActiveUser.user
      const now = new Date().toISOString()

      if (!user) {
        const errorText = 'Active user is not set when trying to duplicate userNotations';
        logger.userNotations.error(errorText)
        throw new Error(errorText)
      }

      if (!payload.userNotations.customDeckId) {
        const errorText = 'Something went wrong, cannot find customDeckId when trying to duplicate userNotations';
        logger.userNotations.error(errorText, payload)
        throw new Error(errorText)
      }

      const duplicateItem = new UserNotations({
        tenantId: user.tenantId,
        createdAt: now,
        createdBy: user.id,
        updatedAt: now,
        updatedBy: user.id,
        customDeckId: payload.newCustomDeckId,
        notation: payload.userNotations.notation,
        status: UserNotationsStatus.ACTIVE,
        type: payload.userNotations.type,
      })

      try {
        DataStore.save(duplicateItem)
      } catch (err) {
        const errorText = 'Unable to duplicate UserNotations';
        logger.userNotations.error(errorText)
        throw new Error(errorText)
      }
    },
  },
  extraReducers,
});

export default userNotationsSlice;
export const userNotationsActions = {
  ...userNotationsSlice.actions,
};
