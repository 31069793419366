import React from 'react';
import { StyleSheet } from 'react-native';
import { DNABox } from '@alucio/lux-ui';
import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail';
import DNAUpdateSlideData from 'src/components/DNA/Modal/DNAUpdateSlideData';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { useDispatch } from 'src/state/redux';
import { ConversionStatus, FileType, Page } from '@alucio/aws-beacon-amplify/src/models';
import useMachineSelector, { composite } from 'src/hooks/useSelector';
import * as slideSettingsSelector from 'src/state/machines/publisherVersioning/SlideSettings/slideSettings.selectors';
import * as publisherVersioningSelector from 'src/state/machines/publisherVersioning/publisherVersioning.selectors';
import { getThumbURL } from 'src/utils/thumbnailHelpers';
import { hasSpeakerNotes } from 'src/hooks/useContentPageData/useContentPageData';
import { usePublisherVersioningState } from 'src/state/machines/publisherVersioning/PublisherVersioningProvider';
import { useSlideSettingsState } from 'src/state/machines/publisherVersioning/SlideSettings/SlideSettingsProvider';
import AssociatedSlidesThumbnails from 'src/components/DNA/Thumbnail/AssociatedSlidesThumbnails';

export const S = StyleSheet.create({
  slideContainer: {
    marginRight: 12,
    marginBottom: 8,
  },
  importedCheckIcon: {
    fontSize: 18,
    color: colors['color-success-500'],
    marginRight: 6,
  },
});

const Slide: React.FC<{page: Page}> = (props) => {
  const { page } = props
  const dispatch = useDispatch()
  const {
    service: parentService,
    latestContentPageData,
    setLatestContentPageData,
    isNonDocumentFile,
  } = usePublisherVersioningState();

  const {
    service,
    currentDocumentVersionORM,
    thumbnailSizes,
    pageMap,
    getSlideTitle,
    getHasSlideTextInsertionEnabled,
  } = useSlideSettingsState()

  const versionPanelCond = useMachineSelector(
    parentService,
    (state) => composite(
      state,
      publisherVersioningSelector.isInDraftingState,
    ),
  )

  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      slideSettingsSelector.versionDraft,
    ),
  )

  // [TODO-ASSOCIATED] - Consider calculating the cover thumbnail index on the meta.assets.coverThumbnailPage
  const coverThumbnailPageNumber = cond.versionDraft.selectedThumbnail ?? 1

  const onEditPageData = (pageNumber?: number, thumbURL?: string ) => {
    if (!latestContentPageData || !pageNumber) return
    const pageData = latestContentPageData[pageNumber - 1]

    dispatch(DNAModalActions.setModal({
      isVisible: true,
      allowBackdropCancel: false,
      component: (props) =>
        (<DNAUpdateSlideData
          { ...props }
          setLatestContentPageData={setLatestContentPageData}
          initialPageData={pageData}
          pageNumber={pageNumber}
          fileType={currentDocumentVersionORM.model.type as FileType}
          thumbURL={thumbURL}
          send={parentService.send}
        />),
    }));
  }

  const thumbURL = isNonDocumentFile
    ? currentDocumentVersionORM.meta.assets.thumbnailKey?.replace('small', 'medium')
    : getThumbURL(currentDocumentVersionORM, page.number)
  const associatedPages = page.linkedSlides?.map(linkedPageId => pageMap[linkedPageId]) ?? []
  const showEditButton = versionPanelCond.isInDraftingState &&
  currentDocumentVersionORM.model.conversionStatus === ConversionStatus.PROCESSED
  const showSpeakerNotesButton = hasSpeakerNotes(
    page.number - 1,
    latestContentPageData,
  ) && showEditButton
  const isConverting = currentDocumentVersionORM.model.conversionStatus !== 'PROCESSED'
  // [TODO-ASSOCIATED] - Make a generic context to synchronize thumbnail loading
  //                   - Add support for generic interactive/badge rendering
  //                   - Add variant supports for different styling types

  return (
    <DNABox
      key={page.pageId}
      appearance="col"
      spacing="sm"
      childFill={1}
      style={S.slideContainer} // this is to replace the spacing on the parent element, because spacing does not apply on the last item
    >
      {/* [TODO-ASSOCIATED] - Figure out a better way to avoid shifting scrollbar */}
      <DNABox style={{ marginRight: 12 }}>
        <DNAThumbnail.Lazy
          deferLoad={isConverting}
          variant={DNAThumbnail.Variants.INFO}
          key={thumbURL}
          s3URL={thumbURL}
          useLoadingIndicator
          thumbnailTitle={getSlideTitle(page.number)}
          isTextInsertionEnabled={getHasSlideTextInsertionEnabled(page.number)}
          size={isNonDocumentFile
            ? 'xl'
            : thumbnailSizes.thumbnailSize
          }
          pageNumber={page.number}
          isCover={isNonDocumentFile ? true : page.number === coverThumbnailPageNumber}
          isRequired={isNonDocumentFile ? true : !!page.isRequired}
          onEditPageData={onEditPageData}
          showEditButton={showEditButton}
          showSpeakerNotesButton={showSpeakerNotesButton}
        />
      </DNABox>
      <AssociatedSlidesThumbnails
        docVerORM={currentDocumentVersionORM}
        pages={associatedPages}
        size={thumbnailSizes.thumbnailSize}
        getSlideTitle={getSlideTitle}
      />
    </DNABox>
  )
}

const Slides: React.FC = () => {
  const { pages } = useSlideSettingsState()

  return (
    <DNABox
      appearance="row"
      wrap="start"
      // [TODO-ASSOCIATED] - Consider adding marginBottom in DNABox when using spacing
      childStyle={{ marginBottom: 32 }}
      alignX="center"
    >
      { pages?.map(page => <Slide key={page.pageId} page={page} />) }
    </DNABox>
  )
}

export default Slides
