import { v4 as uuid } from 'uuid';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import {
  DocumentVersion,
  CustomFieldUsage,
  PageGroupSource,
  PageGroup,
  Page,
} from '@alucio/aws-beacon-amplify/src/models';
import { DocumentORM, DocumentVersionORM, DOCUMENT_ACTIONS_ENUM } from 'src/types/types';

import { store } from 'src/state/redux';
import { canPerformAction, allDocumentsSortedAndFilteredFactory } from 'src/state/redux/selector/document';
import { tenantList } from 'src/state/redux/selector/tenant';
import { getMappedCustomValues } from 'src/state/redux/selector/common';
import { generateAllPagesForVersion } from 'src/utils/documentHelpers';
import { TabOptions } from './publisherVersioning.types';
import { ContentPageData } from 'src/hooks/useContentPageData/useContentPageData';
import { MATCH_SLIDE_STATUS, MappedValues, MappingType, MatchSlideStatesType } from './PublisherVersioningProvider';
import {
  IS_WARNING_THRESHOLD,
  SIMILARITY_SCORE_THRESHOLD,
  NO_RECOMMENDATION_SCORE_THRESHOLD,
} from './MatchSlides/MatchSlidesProvider';
import { GroupedTargetItems } from 'src/components/DnD/DNASingleItemDnd';
import * as logger from 'src/utils/logger';

export const FIRST_MATCH_SLIDE_DISPLAY_DISABLED_THRESHOLD = 0.60;
export const RECOMMENDATION_DIFFERENCE_THRESHOLD = 0.02;

interface VersionChanges {
  isMajorVersionRequired: boolean,
  isMajorVersionProposed: boolean,
}

/**
 * We cannot pass DocumentORMs around via the context or through events
 * due to it not being serializable (circular structure)
 *
 * Instead, we use Redux is a plain JS way with selectors to grab the latest ORM
 * at any given time
 */

export const getDocumentORMFactory = function (id: string): () => DocumentORM {
  const documentSelector = allDocumentsSortedAndFilteredFactory()
  const filter = { filter: { model: { id } } }

  return (): DocumentORM => {
    const [targetDoc] = documentSelector(
      store.getState(),
      undefined,
      filter,
    ) as DocumentORM[]

    if (!targetDoc) throw new Error('Target Document not found')
    return targetDoc
  }
}

export const omitInternalFields = (docVer: Partial<DocumentVersion>) => {
  return omit(docVer,
    'versionNumber',
    '_version',
    '_lastChangedAt',
    '_deleted',
    'contentSource',
    'conversionStatus')
}

/**
 * Compares a working version draft against a previously published version
 * to determine what type of SemVer change should be applied
 */
export const determineSemVerChange = (
  latestDocumentVersion: Partial<DocumentVersion>,
  latestPublishedVersion?: DocumentVersionORM,
): VersionChanges => {
  const minorChange = {
    isMajorVersionRequired: false,
    isMajorVersionProposed: false,
  };

  const majorSuggestedChange = {
    isMajorVersionRequired: false,
    isMajorVersionProposed: true,
  }

  const majorRequiredChange = {
    isMajorVersionRequired: true,
    isMajorVersionProposed: true,
  }

  const draftPages = generateAllPagesForVersion(
    latestDocumentVersion.id ?? '', latestDocumentVersion.numPages ?? 0, latestDocumentVersion.pageSettings ?? [])
  const draftPageGroups = latestDocumentVersion?.pageGroups;
  const latestPublishedDocVersion = latestPublishedVersion?.model
  const latestPublishedPages = latestPublishedDocVersion ? generateAllPagesForVersion(
    latestPublishedDocVersion.id, latestPublishedDocVersion.numPages ?? 0, latestPublishedDocVersion.pageSettings) : []
  const latestPublishedPageGroups = latestPublishedVersion?.model.pageGroups

  // [NOTE] - This really shouldn't happen as pages should always be populated after file processing
  if (!latestPublishedPages || !draftPages) {
    return minorChange
  }

  // 1. A new document (first version to be published)
  //    We don't show this in the UI anyways, but might be nice to have later
  const isFirstDraft = latestDocumentVersion && !latestPublishedVersion
  if (isFirstDraft) {
    return majorRequiredChange
  }

  // 2. Draft version has fewer slides
  const draftVersionHasFewerSlides = draftPages.length < latestPublishedPages.length
  if (draftVersionHasFewerSlides) {
    return majorRequiredChange
  }

  // 3. Draft version grouping has changed due to one of
  //    a. amount of groups not matching (including not having any groups)
  //    b. slide indexes not matching
  const pageGroupLengthChanged = Number(draftPageGroups?.length) !== Number(latestPublishedPageGroups?.length)
  const draftPageGroupSlideIndexes = draftPageGroups
    ?.map(pageGroup => pageGroup.pageIds?.map(pageId => pageId.split('_')[2]))
  const publishedPageGroupSlideIndexes = latestPublishedPageGroups
    ?.map(pageGroup => pageGroup.pageIds?.map(pageId => pageId.split('_')[2]))
  const pageGroupIndexesChanged = !isEqual(draftPageGroupSlideIndexes, publishedPageGroupSlideIndexes)

  if (pageGroupLengthChanged || pageGroupIndexesChanged) {
    return majorRequiredChange
  }

  // 4. Required slides changed
  const draftRequiredSlides = draftPages?.map((page, idx) => ({ [idx]: !!page.isRequired }))
  const publishedRequiredSlides = latestPublishedPages?.map((page, idx) => ({ [idx]: !!page.isRequired }))
  // [NOTE] - We first compare the original range of slides for required changes
  const originalSlidesRequiredCheck = !isEqual(
    draftRequiredSlides.slice(0, publishedRequiredSlides.length),
    publishedRequiredSlides,
  )
  // [NOTE] - If any new additional slides have required, then flag for major change
  const additionalSlidesRequiredCheck = draftRequiredSlides
    .slice(publishedRequiredSlides.length, draftRequiredSlides.length)
    .some(slide => Object.values(slide).some(required => required))

  if (originalSlidesRequiredCheck || additionalSlidesRequiredCheck) {
    return majorRequiredChange
  }

  const {
    MSLSelectSlides: previousModifyPermission,
  } = latestPublishedVersion?.meta.permissions ?? {}

  const [currentUserTenant] = tenantList(store.getState())
  const draftConfigsMap = getMappedCustomValues(
    { internalUsages: [CustomFieldUsage.DOCUMENT] },
    latestDocumentVersion.customValues,
    currentUserTenant.config?.customFields,
  )

  // 5. Document is no longer modifiable
  const draftModifiablePermission = canPerformAction(
    DOCUMENT_ACTIONS_ENUM.modify,
    draftConfigsMap,
    currentUserTenant,
  )
  const noLongerModifiable = (!!previousModifyPermission === true && !draftModifiablePermission)

  if (noLongerModifiable) { return majorRequiredChange }

  // 6. Additional pages without other required/grouping changes
  if (draftPages.length > latestPublishedPages.length) {
    return majorSuggestedChange
  }

  // 7. Check if the linked slides have changed
  const linkedSlidesChange = draftPages.some((page, idx) => {
    const publishedPage = latestPublishedPages[idx]

    // 7b. To be backwards compatible with older records pre-linked slides schema changes, equate an undefined linkedSlides to the draft's empty array
    //     - The better fix is to make the schema non-nullable and run a migration to force all the older records to
    //       have an empty array so we can skip this edge case check
    if (!publishedPage.linkedSlides && !page.linkedSlides?.length) {
      return false
    }

    // check if two arrays are equal (strictly)
    return !isEqual(
      // the id of the pages should be removed
      page.linkedSlides?.map(e => e.split('_')[2]),
      publishedPage.linkedSlides?.map(e => e.split('_')[2]),
    )
  })

  if (linkedSlidesChange) {
    return majorRequiredChange
  }

  // 8. Otherwise anything else is a minor change
  return minorChange;

  // 8. [TODO] - Preserve user's last selection if minor change?
}

export type TabVisibility = { [key in TabOptions]?: boolean };

export const getAvailabletabs = (enableMatchSlides: boolean) => {
  const tabVisibilities: TabVisibility[] = [
    { [TabOptions.DOCUMENT_INFO_TAB]: true },
    { [TabOptions.DOCUMENT_MATCH_SLIDES_TAB]: enableMatchSlides },
    { [TabOptions.DOCUMENT_SETTINGS_TAB]: true },
    { [TabOptions.DOCUMENT_ASSOCIATED_FILES]: true },
    { [TabOptions.DOCUMENT_PUBLISH_TAB]: true },
  ]

  const availabletabs: TabOptions[] = tabVisibilities
    .filter((tabObj: TabVisibility) => Object.values(tabObj)[0])
    .map((tabObj: TabVisibility) => Object.keys(tabObj)[0] as TabOptions);

  return availabletabs
}

/**
  * This function will calculate the value of contentPageData from useContentPageData hook and return
  * a new contentPageData and a status object 'updatedMatchSlideStates' with the following rules:
  * 1. If mapping is 'USER_UNMATCHED':
      * * ContentPageData remains the same & status is NO_MATCH.
  * 2. If mapping is pageId:
      * * ContentPageData remains the same.
      * * If the mapping has been used then status is NEEDS_REVIEW else is MATCHED.
  * 3. If mapping is null/undefined:
      * * If there is no recommendation, assign mapping as 'USER_UNMATCHED' & status is NO_MATCH. (handle in the 1. rule)
      * * If the first 2 recommendations' scores have a difference of abs(x <= .005), keep mapping as null & status is NEEDS_REVIEW.
      * * Assign first recommendation (if exist and above threshold) as mapping.
        * * If the mapping has been used then status is NEEDS_REVIEW else is MATCHED.
      * * If recommendation is below threshold, keep mapping as null & status is NEEDS_REVIEW.
  * @param contentPageData the value from useContentPageData hook
  * @return an object contains updatedContentPageData and updatedMatchSlideStates
*/
export const calculateContentPageDataAndMatchSlideStates = (
  contentPageData: ContentPageData[],
  enableFirstMatchSlideDisplay: boolean,
) => {
  /**
   * A record used to track and check if the same recommendation has appeared more than once.
   * The key is the mapping (last version pageId) and the value is an array of current version pageIds.
  */
  const mappedItemsRecord: Record<string, string[]> = {};
  const updatedMatchSlideStates: MatchSlideStatesType = {};
  const updatedContentPageData: ContentPageData[] = [];

  function updateDuplicateSlides (
    duplicateSlides: string[],
    updatedMatchSlideStates: MatchSlideStatesType,
    pageId: string,
  ) {
    duplicateSlides.forEach(duplicateSlideId => {
      const previousDuplicateSlides = updatedMatchSlideStates[duplicateSlideId]?.duplicateSlides;
      updatedMatchSlideStates[duplicateSlideId] = {
        status: MATCH_SLIDE_STATUS.NEEDS_REVIEW,
        duplicateSlides: previousDuplicateSlides ? [...previousDuplicateSlides, pageId] : [pageId],
      }
    })
  }

  contentPageData.forEach((pageData) => {
    const { mapping, recommendations: _recommendations, pageId } = pageData;
    const recommendations = filterRecommendations(
      _recommendations,
      enableFirstMatchSlideDisplay,
    );
    const isUserUnmatched = mapping === 'USER_UNMATCHED';
    const firstRecommendationId = recommendations[0]?.pageId as string | undefined;
    const noMappingAndNoRecommendations = !mapping && !firstRecommendationId;

    if (isUserUnmatched || noMappingAndNoRecommendations) {
      // Assign mapping to ContentPageData
      const newPageData = {
        ...pageData,
        mapping: 'USER_UNMATCHED',
      }
      updatedContentPageData.push(newPageData);
      // Assign status to MatchSlideStates
      updatedMatchSlideStates[pageId] = {
        status: MATCH_SLIDE_STATUS.NO_MATCH,
      };
    } else if (mapping) {
      const duplicateSlides = mappedItemsRecord[mapping];
      // Assign mapping to ContentPageData
      updatedContentPageData.push(pageData);
      // Assign status & duplicateSlides to MatchSlideStates
      updatedMatchSlideStates[pageId] = {
        status: duplicateSlides ? MATCH_SLIDE_STATUS.NEEDS_REVIEW : MATCH_SLIDE_STATUS.MATCHED,
        duplicateSlides,
      };
      // If we find this slide is a duplicate slide, we need to also update the other item's status & duplicateSlides
      if (duplicateSlides) updateDuplicateSlides(duplicateSlides, updatedMatchSlideStates, pageId)
      // Update mappedItemsRecord
      mappedItemsRecord[mapping] = duplicateSlides ? [...duplicateSlides, pageId] : [pageId]
    } else {
      // We should never get to this if block, if we do the function is broken;
      if (!firstRecommendationId) throw new Error('Cannot find first recommendation Id');
      const duplicateSlides = mappedItemsRecord[firstRecommendationId] as string[] | undefined;
      const recommendationIsBelowThreshold = recommendations[0]?.score < IS_WARNING_THRESHOLD;
      const hasMultipleRecommendations = _recommendations.length > 1;
      const scoreDifference = hasMultipleRecommendations
        ? (Math.abs(_recommendations[0].score - _recommendations[1].score)).toFixed(10)
        : 0;
      const hasSimilarRecommendations = !recommendationIsBelowThreshold &&
        hasMultipleRecommendations &&
        Number(scoreDifference) <= SIMILARITY_SCORE_THRESHOLD;
      const isMatchedStatus = !recommendationIsBelowThreshold && !hasSimilarRecommendations && !duplicateSlides;
      // Assign mapping to contentPageData: we only want to assign mapping when recommendation is not below
      // IS_WARNING_THRESHOLD and the first 2 recommendations score are not too similar
      if (!recommendationIsBelowThreshold && !hasSimilarRecommendations) {
        const newPageData = {
          ...pageData,
          mapping: firstRecommendationId,
        }
        updatedContentPageData.push(newPageData);
      } else {
        updatedContentPageData.push(pageData);
      }
      // Assign status & duplicateSlides to MatchSlideStates
      updatedMatchSlideStates[pageId] = {
        status: isMatchedStatus ? MATCH_SLIDE_STATUS.MATCHED : MATCH_SLIDE_STATUS.NEEDS_REVIEW,
        duplicateSlides,
      };
      // If we find this slide is a duplicate slide, we need to also update the other item's status & duplicateSlides
      if (duplicateSlides) updateDuplicateSlides(duplicateSlides, updatedMatchSlideStates, pageId)
      // Update mappedItemsRecord
      mappedItemsRecord[firstRecommendationId] = duplicateSlides ? [...duplicateSlides, pageId] : [pageId]
    }
  })

  return {
    updatedContentPageData,
    updatedMatchSlideStates,
  }
}

/**
  * This function will calculate targetItems, determines what should be displays in the UI.
  * @param currentDocumentVersionORM
  * @param mappedValues
  * @return targetItems
*/
export const calculateDisplaySlides = (
  currentDocumentVersionORM: DocumentVersionORM,
  mappedValues: MappedValues,
  enableFirstMatchSlideDisplay: boolean,
) => {
  return currentDocumentVersionORM?.relations.pages.reduce<Record<string, string[]>>((acc, page) => {
    const pageId = page.model.pageId;
    const mappedPage = mappedValues[pageId] as MappingType | undefined;
    const firstRecommendationId = filterRecommendations(
      mappedPage?.rawRecommendations ?? [],
      enableFirstMatchSlideDisplay,
    )[0]?.pageId as string | undefined;
    const noMappingAndNoRecommendation = !mappedPage?.mapping && !firstRecommendationId;
    const isUserUnmatched = mappedPage?.mapping === 'USER_UNMATCHED';
    if (
      !mappedValues ||
      !mappedPage ||
      noMappingAndNoRecommendation ||
      isUserUnmatched
    ) return { ...acc, [pageId]: [] }
    if (!mappedPage.mapping) {
      return {
        ...acc,
        [pageId]: [firstRecommendationId!],
      }
    } else {
      return {
        ...acc,
        [pageId] : [mappedPage.mapping],
      }
    }
  }, {})
}

/**
  * The purpose of this function is to remove a specific string in duplicate Slides
  * array when user un-match a slide
*/
export const removeFromDuplicateSlides = (
  matchSlideStates: MatchSlideStatesType,
  stringToRemove: string,
  keys: string[],
  latestContentPageData: ContentPageData[],
) => {
  const updatedState = { ...matchSlideStates };
  keys.forEach(key => {
    if (updatedState[key]) {
      const duplicateSlides = matchSlideStates[key].duplicateSlides?.filter(pageId => pageId !== stringToRemove) || [];
      const mapping = latestContentPageData
        ?.find(pageData => pageData.pageId === key)?.mapping;

      // If there is no mapping, it could be below threshold or has too similar recommendations scores
      const updatedStatus = duplicateSlides.length || !mapping
        ? MATCH_SLIDE_STATUS.NEEDS_REVIEW
        : MATCH_SLIDE_STATUS.MATCHED;

      updatedState[key] = {
        status: updatedStatus,
        ...( duplicateSlides.length ? { duplicateSlides } : undefined),
      }
    }
  })
  return updatedState;
};

/**
  * The purpose of this function is to add a specific string to the duplicateSlides
  * array for the specified keys when a slide is matched.
*/
export const addToDuplicateSlides = (
  matchSlideStates: MatchSlideStatesType,
  stringToAdd: string,
  keys: string[],
) => {
  const updatedState = { ...matchSlideStates };
  keys.forEach(key => {
    if (updatedState[key]) {
      const duplicateSlides = updatedState[key].duplicateSlides || [];
      // Only add the string if it’s not already in the array
      if (!duplicateSlides.includes(stringToAdd)) {
        const updatedSlides = [...duplicateSlides, stringToAdd];
        updatedSlides.sort((a, b) => {
          const pageA = parseInt(a.split('_').pop() ?? '0', 10);
          const pageB = parseInt(b.split('_').pop() ?? '0', 10);
          return pageA - pageB;
        });
        updatedState[key] = {
          status: MATCH_SLIDE_STATUS.NEEDS_REVIEW,
          duplicateSlides: updatedSlides,
        };
      }
    }
  });

  return updatedState;
};

/**
  * The purpose of this function is to check if a specific itemId (previous version id) has
  * been used in groupedTargetItems, if so we will return the current version id in an array
*/
export const findKeysByItemId = (
  groupedTargetItems: GroupedTargetItems,
  targetItemId: string,
) => {
  const result: string[] = [];
  for (const [key, value] of Object.entries(groupedTargetItems)) {
    const found = value.some(item => item.itemId === targetItemId);
    if (found) {
      result.push(key);
    }
  }
  return result;
};

/**
  * The purpose of this function is to clear out any mapping in contentPageData if it has been duplicated
  * and assign them as USER_UNMATCHED. This should be called at publish state.
*/
export const dedupePageDataMapping = (
  contentPageData: ContentPageData[],
  matchSlideStates: MatchSlideStatesType,
) => {
  return contentPageData.map(pageData => {
    const { pageId } = pageData;
    if (matchSlideStates[pageId].status === MATCH_SLIDE_STATUS.NEEDS_REVIEW) {
      return {
        ...pageData,
        mapping: 'USER_UNMATCHED',
      }
    } else return pageData;
  })
};

/**
* This function calculates and applies slide settings from the previously published version to
* the current draft, based on the following rules:
* 1. Apply slide-level required slides to individual slides for every required slide that has
*    been matched, and drop those that have not.
* 2. Apply presentation-level required slides to entire presentation when all required slides
*    have a match.
* 3. Carry forward slide groups when all slides within the group have a match UNLESS slide
*    groups are imported, in which case the imported ones take precedence.
    *  - Groups will carry forward containing the equivalent slide from the previous version as
    *    long as all slides have an identified match by the system.
* 4. Apply publisher-edited speaker notes & slide titles when the newly uploaded document does
*    not have any speaker notes and/or slide titles.
*/
export const calculatesImportSlideSettingsFromPreviousVersion = (
  matchSlideStates: MatchSlideStatesType,
  currentVersionPageGroups?: PageGroup[],
  latestContentPageData?: ContentPageData[],
  latestPublishedDocumentVersionPageGroups?: PageGroup[],
  latestPublishedDocumentVersionPages?: Page[],
  latestPublishedContentPageData?: ContentPageData[],
) => {
  logger.versioning.settings.debug('Import slide settings from previous version...')
  // Reverse Mapping is a mapping from last version -> current version
  const reverseMapping = latestContentPageData?.reduce<Record<string, string>>((acc, pageData) => {
    const matchedStatus = matchSlideStates[pageData.pageId]?.status === MATCH_SLIDE_STATUS.MATCHED;
    if (matchedStatus && pageData.mapping) return { ...acc, [pageData.mapping]: pageData.pageId };
    else return acc;
  }, {}) ?? {};
  const latestPublishedGroups = latestPublishedDocumentVersionPageGroups ?? [];
  const latestPublishedPages = latestPublishedDocumentVersionPages ?? [];
  const lastVersionPresentationLevelRequiredSlideIds = latestPublishedPages.reduce<string[]>((acc, page) => {
    if (page.isRequired) return [...acc, page.pageId];
    else return acc;
  }, []);

  // STEP 1: Slide-level required slides
  logger.versioning.settings.debug('Calculating slide-level required slides...')
  const linkedSlidesMapping = latestPublishedPages.reduce<Record<string, string[]>>((acc, page) => {
    const hasLinkSlides = !!page.linkedSlides?.length;
    const parentSlideHasMapping = !!reverseMapping[page.pageId];
    const newLinkedSlides = page.linkedSlides?.reduce<string[]>((acc, pageId) => {
      const hasMapping = !!reverseMapping[pageId];
      if (hasMapping) return [...acc, reverseMapping[pageId]];
      else return acc;
    }, []) ?? [];
    if (hasLinkSlides && parentSlideHasMapping && newLinkedSlides.length) {
      const parentSlideId = reverseMapping[page.pageId];
      return { ...acc, [parentSlideId]: newLinkedSlides };
    }
    else return acc;
  }, {});
  logger.versioning.settings.debug('The new slide-level required slides: ', linkedSlidesMapping)

  // STEP 2: Presentation-level required slides
  logger.versioning.settings.debug('Calculating presentation-level required slides...')
  const shouldApplyPresentationLevelRequiredSlide = lastVersionPresentationLevelRequiredSlideIds
    .every(pageId => reverseMapping[pageId])
  const requiredSlidePageIds = shouldApplyPresentationLevelRequiredSlide
    ? lastVersionPresentationLevelRequiredSlideIds.map(lastVersionPageId => reverseMapping[lastVersionPageId])
    : [];
  logger.versioning.settings.debug('The new presentation-level required slides: ', requiredSlidePageIds)

  // STEP 3: Carry forward slide groups
  logger.versioning.settings.debug('Calculating slide groups...')
  const currentImportedGroups = currentVersionPageGroups
    ?.filter(group => group.source === PageGroupSource.DOCUMENT) ?? [];
  const newGroups = currentImportedGroups.length
    ? currentImportedGroups
    : latestPublishedGroups.reduce<PageGroup[]>((acc, pageGroup) => {
      const allSlidesHasMapping = !!pageGroup.pageIds?.every(pageId => !!reverseMapping[pageId]);
      if (allSlidesHasMapping) {
        const newPageIds = pageGroup.pageIds?.map(pageId => reverseMapping[pageId]);
        const newPageGroup: PageGroup = {
          id: uuid(),
          pageIds: newPageIds,
          name: pageGroup.name,
          locked: pageGroup.locked,
          source: pageGroup.source,
          sourceID: pageGroup.sourceID,
        };
        return [...acc, newPageGroup];
      }
      else return acc;
    }, []);
  logger.versioning.settings.debug('The new slide groups: ', newGroups)

  // STEP 4: Carry forward speaker notes & slide titles
  logger.versioning.settings.debug('Calculating carry-forward speaker notes & slide titles...')
  const newContentPageData: ContentPageData[] | undefined = latestContentPageData?.map(pageData => {
    const lastVersionPageData = latestPublishedContentPageData
      ?.find(pd => pd.pageId === pageData.mapping)
    const hasNoOriginalTitle = !pageData.originalTitle;
    const hasNoOriginalSpeakerNotes = !pageData.originalSpeakerNotes;
    const matchedStatus = matchSlideStates[pageData.pageId]?.status === MATCH_SLIDE_STATUS.MATCHED;
    const shouldApplyLastVersionTitle = matchedStatus &&
      pageData.mapping &&
      lastVersionPageData &&
      hasNoOriginalTitle;
    const shouldApplyLastVersionSpeakerNotes = matchedStatus &&
      pageData.mapping &&
      lastVersionPageData &&
      hasNoOriginalSpeakerNotes;
    // Assigning speaker notes & slide titles
    return {
      ...pageData,
      title: shouldApplyLastVersionTitle
        ? lastVersionPageData.title
        : pageData.originalTitle,
      speakerNotes: shouldApplyLastVersionSpeakerNotes
        ? lastVersionPageData.speakerNotes
        : pageData.originalSpeakerNotes,
    }
  })

  return {
    newContentPageData,
    linkedSlidesMapping,
    requiredSlidePageIds,
    newGroups,
  };
}

export const filterRecommendations = (
  recommendations: Array<{ score: number; pageId: string }>,
  enableFirstMatchSlideDisplay: boolean = false,
  differenceThreshold: number = RECOMMENDATION_DIFFERENCE_THRESHOLD,
) => {
  if (!enableFirstMatchSlideDisplay) {
    return recommendations.filter(recommendation => recommendation.score >= NO_RECOMMENDATION_SCORE_THRESHOLD);
  }

  return recommendations.filter((recommendation, index) => {
    const score = recommendation.score;
    if (score >= IS_WARNING_THRESHOLD) {
      return true;
    }

    // Only check difference for the first recommendation
    if (enableFirstMatchSlideDisplay && index === 0 && recommendations.length > 1) {
      const firstScore = score;

      if (firstScore > NO_RECOMMENDATION_SCORE_THRESHOLD) {
        return true;
      }

      if (firstScore <= FIRST_MATCH_SLIDE_DISPLAY_DISABLED_THRESHOLD) {
        return false;
      }

      const nextRecommendation = recommendations[1];

      const scoreDifference = (firstScore - nextRecommendation.score).toFixed(10);
      // We only include the first recommendation if the difference is greater than the threshold
      return Number(scoreDifference) >= differenceThreshold;
    }

    // Include all other recommendations that meet the threshold in this case we want to use the original approach NO_RECOMMENDATION_SCORE_THRESHOLD
    return score >= NO_RECOMMENDATION_SCORE_THRESHOLD;
  });
};

export default {
  getDocumentORMFactory,
  omitInternalFields,
  determineSemVerChange,
  getAvailabletabs,
  calculateContentPageDataAndMatchSlideStates,
  calculateDisplaySlides,
  removeFromDuplicateSlides,
  addToDuplicateSlides,
  findKeysByItemId,
  dedupePageDataMapping,
  calculatesImportSlideSettingsFromPreviousVersion,
}
