import React, {
  useReducer,
  useEffect,
  createContext,
  useContext,
  useRef,
  PropsWithChildren,
} from 'react'
import im from 'immer'
import { Auth } from '@aws-amplify/auth'
import { isIOS, isTablet } from 'react-device-detect'
import QueryString from 'qs'

import { Platform, desktopPlatforms, mobilePlatforms } from '@alucio/core'
import workerChannel from 'src/worker/channels/workerChannel'
import config from 'src/config/app.json'
import { useDispatch } from 'src/state/redux'
import { useUserTenant } from '../redux/selector/user'
import { cacheActions } from '../redux/slice/Cache/cache'
import { useInterpret, useSelector } from '@xstate/react'
import useMonitorConnection from './useMonitorConnection';
import { reloadApp } from 'src/utils'

/* CRM MACHINE */
import CRMMachine from 'src/state/machines/CRM/crmMachine'
import {
  CRM_AUTH_INFORMATION,
  CRM_STORAGE_UPDATE,
  CRMIntegrationSession,
  CRM_CODE,
  CRMUtil,
} from '../machines/CRM/util'
import { CRMService } from '../machines/CRM/crmMachineTypes'
/* LOGOUT MACHINE */
import { logOutMachine, LogOutService } from 'src/state/machines/auth/logOutMachine'
/* VALIDATE USER MACHINE */
import { validateUserMachine, ValidateUserService } from '../machines/auth/validateUserMachine'
/* LOGGER MACHINE */
import LoggerStateProvider from '../machines/logger/LoggerProvider'
import * as logger from 'src/utils/logger'

// @ts-ignore -- this is injected at build time
// and we have backup optional chaining usage on it
const ENV_NAME = config.ENV_NAME as string
const crmUtil = new CRMUtil();
const originalSearchParams = window.location.search
export enum DeviceMode {
  'desktop' = 'desktop',
  'tablet' = 'tablet',
}

type AppSettingsState = {
  deviceMode: DeviceMode | keyof typeof DeviceMode
  /** NOTE: This serves a similar purpose as deviceMode but allows for the additional distinction between desktop
   * and tablet PWA. Ideally, we'd use a single approach but we'll keep deviceMode for now to mitigate refactor risk.
   */
  platform: Platform
  isWebPlatform: boolean
  isTabletPlatform: boolean
  isDeviceSwitchEnabled: boolean
  isPWAStandalone: boolean
  isOfflineEnabled?: boolean
  isCRMEnabled?: boolean
  isPWAInstalled?: boolean
  isIdleDisabled: boolean
  isSSO: boolean
  isOnline: boolean
  initialUrlSearchParams: URLSearchParams
  crmIntegrationSession?: CRMIntegrationSession
}

type AppSettingsAction =
  | { type: 'toggleDeviceMode' }
  | { type: 'setIsSSO' }
  | { type: 'setIsPWAStandalone', payload: AppSettingsState['isPWAStandalone'] }
  | { type: 'setIsOfflineEnabled', payload: AppSettingsState['isOfflineEnabled'] }
  | { type: 'setIsPWAInstalled', payload: AppSettingsState['isPWAInstalled'] }
  | { type: 'setIsIdleDisabled', payload: AppSettingsState['isIdleDisabled'] }
  | { type: 'setIsOnline', payload: AppSettingsContextValue['isOnline'] }
  | { type: 'setCRMAuthInformation', payload: AppSettingsState['crmIntegrationSession'] }
  | { type: 'setIsCRMEnabled', payload: AppSettingsState['isCRMEnabled'] }

type AppSettingsContextValue = AppSettingsState & {
  dispatch: React.Dispatch<AppSettingsAction>
  crmMachine: React.MutableRefObject<CRMService>['current']
  logOutMachine: React.MutableRefObject<LogOutService>['current']
  validateLogInUserMachine: React.MutableRefObject<ValidateUserService>['current']
}

const AppSettingsContext = createContext<AppSettingsContextValue>(null!)
AppSettingsContext.displayName = 'AppSettingsContext'
export const useAppSettings = () => useContext(AppSettingsContext)

const appSettingsReducer = (state: AppSettingsState, action: AppSettingsAction) => {
  switch (action.type) {
    case 'toggleDeviceMode': {
      return im(state, draft => {
        draft.deviceMode = state.deviceMode === 'desktop'
          ? 'tablet'
          : 'desktop'
        logger.appInit.appSettings.info('Device mode toggled', draft.deviceMode)
      })
    }
    case 'setIsSSO': {
      return im(state, draft => {
        draft.isSSO = true
        logger.appInit.appSettings.info('SSO set to true')
      })
    }
    case 'setIsOfflineEnabled': {
      return im(state, draft => {
        draft.isOfflineEnabled = !!action.payload
        logger.appInit.appSettings.info('Offline enabled set to', draft.isOfflineEnabled)
        if (action.payload &&
          !state.isPWAStandalone &&
          state.isPWAInstalled === undefined &&
          !isIOS) {
          // If we don't know for sure the PWA is not installed we assume it is until we
          // get confirmation (via onbeforeinstallprompt that it isn't)
          // This is only for non-iOS since safari doesn't support onbeforeinstallprompt
          logger.appInit.appSettings.debug('Disabling Idle')
          draft.isIdleDisabled = true
        }
      })
    }
    case 'setIsPWAStandalone': {
      return im(state, draft => {
        // If we are in PWA mode we ignore changing the value.
        // This means that if a user goes from PWA mode back to the browser, they will remain in PWA
        // This was a workaround in the case the user goes to PWA and goes to fullscreen ( in the previous implmentation the user would switch to deskptop mode from pwa)
        if (!state.isPWAStandalone) {
          draft.isPWAStandalone = !!action.payload
          draft.deviceMode = action.payload ? 'tablet' : 'desktop'
          logger.appInit.appSettings.info('PWA standalone set to', draft.isPWAStandalone)
        }
      })
    }
    case 'setIsPWAInstalled': {
      return im(state, draft => {
        draft.isPWAInstalled = !!action.payload
        logger.appInit.appSettings.info('PWA installed set to', draft.isPWAInstalled)
        if (action.payload === false && state.isOfflineEnabled === true) {
          logger.appInit.appSettings.debug('re-enabling idle')
          draft.isIdleDisabled = false
        }
      })
    }
    case 'setIsIdleDisabled': {
      return im(state, draft => {
        draft.isIdleDisabled = !!action.payload
        logger.appInit.appSettings.info('Idle disabled set to', draft.isIdleDisabled)
      })
    }
    case 'setIsOnline': {
      return im(state, draft => {
        draft.isOnline = action.payload
        logger.appInit.appSettings.info('Online status set to', draft.isOnline)
      })
    }
    case 'setCRMAuthInformation': {
      return im(state, draft => {
        draft.crmIntegrationSession = action.payload
      })
    }
    case 'setIsCRMEnabled': {
      return im(state, draft => {
        draft.isCRMEnabled = action.payload
        logger.appInit.appSettings.info('CRM enabled set to', draft.isCRMEnabled)
      })
    }
    default: return state
  }
}

export const AppSettings: React.FC<PropsWithChildren> = (props) => {
  const { children } = props
  const userTenant = useUserTenant()
  const isPWAStandalone = window.matchMedia('(display-mode: standalone)').matches
  const isTabletIOS = isTablet && isIOS;
  const queryParams = QueryString.parse(window.location.search, { ignoreQueryPrefix: true })
  const reduxDispatch = useDispatch()
  const enableDeviceSwitch = (
    ((process.env.NODE_ENV === 'development' ||
      ENV_NAME?.includes('develop')) && isPWAStandalone) ||
    queryParams?.enableDeviceSwitch === 'true'
  )
  const isTabletQueryString = queryParams?.tabletMode
  const initialUrlSearchParams = new URLSearchParams(originalSearchParams);
  const mockOffline = queryParams?.mockOffline === 'true';
  const isCRMEnabled = !!userTenant?.config.crmIntegration

  /** This assumes we only have the two devicemodes desktop/tablet but
   * should be reworked if we ever add detection for additional devices.
   * Additionally, isDesktop appears to be returning true even on the iOS
   * simulator 🤷🏽‍♂️ */
  const currentPlatform: AppSettingsState['platform'] = isPWAStandalone
    ? isIOS
      ? 'tabletPWA'
      : 'desktopPWA'
    : isIOS
      ? 'tabletWEB'
      : 'desktopWEB'

  const deviceMode = (isTabletIOS || isPWAStandalone || isTabletQueryString)
    ? DeviceMode.tablet
    : DeviceMode.desktop
  const [
    appSettings,
    dispatch,
  ] = useReducer(
    appSettingsReducer,
    {
      deviceMode,
      platform: currentPlatform,
      isWebPlatform: desktopPlatforms.includes(currentPlatform),
      isTabletPlatform: mobilePlatforms.includes(currentPlatform),
      isDeviceSwitchEnabled: enableDeviceSwitch,
      isOfflineEnabled: undefined,
      isSSO: false,
      isPWAStandalone: isPWAStandalone,
      isIdleDisabled: isPWAStandalone,
      isOnline: mockOffline ? false : navigator.onLine,
      initialUrlSearchParams,
      crmIntegrationSession: crmUtil.loadAuthInformation(),
      isCRMEnabled: isCRMEnabled,
    },
  )
  const isOnline = useMonitorConnection(appSettings.isOfflineEnabled);
  const onlineStatus = mockOffline ? false : isOnline;

  /* CRM STATE MACHINE START */
  // [TODO-BEAC-6355] - Upgrade to latest state machine patterns
  const CRMService = useRef(
    useInterpret(
      () => CRMMachine(),
    ),
  )

  // [TODO-BEAC-6355] - This should not be in the render statement, please move to useEffect, or replace above with `useLazyRef` and set the transition there
  CRMService.current.onTransition((_state) => {
    // Commented for now to prevent logging of state
    // which includes all CRM accounts (might be causing OOM with lots of accts)
    // logger.appInit.appSettings.debug('CRM Machine Transition', state);
  })
  /* CRM STATE MACHINE END */

  /* LOGOUT STATE MACHINE START */
  // [TODO-BEAC-6355]
  const signOutMachine = useRef(
    useInterpret(
      () => logOutMachine({
        isOfflineEnabled: appSettings.isOfflineEnabled,
        isSSO: appSettings.isSSO,
      }),
    ),
  )

  // [TODO-BEAC-6355] - The selector should be memoized
  const isLoggingOut = useSelector(signOutMachine.current, (state) => state.tags.has('IS_LOGGING_OUT'));
  /* LOGOUT STATE MACHINE END */

  /* VALIDATE USER STATE MACHINE START */
  // [TODO-BEAC-6355]
  const validateLogInUserMachine = useRef(
    useInterpret(
      () => validateUserMachine,
    ),
  )
  /* VALIDATE USER STATE MACHINE END */

  // [TODO] - Break this into two discrete operations for better readability
  useEffect(() => {
    const checkSSOStatus = async () => {
      try {
        const idToken = await (await Auth.currentSession()).getIdToken()
        if (idToken.payload.identities && idToken.payload.identities.length > 0) {
          dispatch({ type: 'setIsSSO' })
          logger.appInit.appSettings.info('SSO status checked and set to true')
        }
      } catch (error) {
        // Apparently this is the official way to check if a user is logged in
        logger.appInit.appSettings.warn('Error checking SSO status', error)
      }
    }
    checkSSOStatus();
    let isRefreshing = false;
    let updateInterval = 0;

    // Adds an Interval and a Listener to handle SW updates
    const handleServiceWorkerUpdates = (registration: ServiceWorkerRegistration) => {
      logger.offline.serviceWorker.debug('Detected ServiceWorker. Adding listener for new versions');
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        logger.offline.serviceWorker.debug('serviceworker change detected')
        if (!isRefreshing) {
          reloadApp()
          isRefreshing = true
        }
      });
      // Every 30 mins we check to see if there's a new version
      updateInterval = window.setInterval(() => {
        logger.offline.serviceWorker.debug('Checking for SW Updates')
        registration.update()
      }, 30 * 60 * 1000)
    };

    // Update service worker status
    const checkIsServiceWorkerRegistered = async () => {
      const registration = await navigator.serviceWorker.getRegistration('/')
      if (registration) {
        handleServiceWorkerUpdates(registration);
      }
      dispatch({
        type: 'setIsOfflineEnabled',
        payload: !!registration,
      })
      logger.offline.serviceWorker.info('Service worker registration status checked')
    }
    // Service worker is always registered on iOS or when running standalone
    if (isPWAStandalone || isIOS) {
      logger.offline.serviceWorker.debug('registering service worker')
      navigator.serviceWorker
        .register('/worker.js')
        .then(registration => {
          logger.offline.serviceWorker.info('SW registered: ', registration);
          handleServiceWorkerUpdates(registration);
          dispatch({
            type: 'setIsOfflineEnabled',
            payload: true,
          })
        })
        .catch(error => {
          logger.offline.serviceWorker.error('SW Registration failed:', error)
          throw Error('SW registration failed: ' + error)
        });
    } else {
      checkIsServiceWorkerRegistered()
    }
    const beforeInstallListener = (e) => {
      logger.appInit.appSettings.debug('beforeInstall Event Triggered', e)
      dispatch({
        type: 'setIsPWAInstalled',
        payload: false,
      })
      // if this event occurs then the PWA is not installed so we should re-enable the idle timer
      dispatch({
        type: 'setIsIdleDisabled',
        payload: false,
      })
    }
    window.addEventListener('beforeinstallprompt', beforeInstallListener);
    logger.appInit.appSettings.debug('adding event listener for display-mode')
    // Watch for changes in install mode (chrome install does not reload the page)
    // Safari only supports addListener even though its deprecated
    window.matchMedia('(display-mode: standalone)').addListener((evt) => {
      logger.appInit.appSettings.debug('Display mode event listener fired', evt.matches)
      dispatch({
        type: 'setIsPWAStandalone',
        payload: evt.matches,
      })
    });
    return () => {
      if (updateInterval) { clearInterval(updateInterval) }
      window.removeEventListener('beforeinstallprompt', beforeInstallListener)
    }
  }, [])

  useEffect(() => {
    // ಠ_ಠ
    if (isOnline !== undefined && appSettings.isOnline !== isOnline) {
      dispatch({ type: 'setIsOnline', payload: onlineStatus })
      reduxDispatch(cacheActions.setOnline(onlineStatus))
      // [TODO-PWA]
      //  - Move into separate useEffect?
      //  - We probably shouldn't rely on the client
      //    instead the worker should have its own listener
      const networkStatus = onlineStatus ? 'ONLINE' : 'OFFLINE'
      logger.PWALogger.info('Sending Network Signal -', networkStatus)
      workerChannel.postMessageExtended({
        type: 'NETWORK_STATUS',
        value: networkStatus,
      })
    }
  }, [isOnline])

  // We mirror a copy of the isOnline value to Redux for selector purposes
  useEffect(
    () => { reduxDispatch(cacheActions.setOnline(appSettings.isOnline)) },
    [appSettings.isOnline],
  )

  // Update the CRM connection status
  useEffect(() => {
    const handleStorageChange = async (e) => {
      if (e.key === CRM_AUTH_INFORMATION || e.type === CRM_STORAGE_UPDATE) {
        const CRMSettings = crmUtil.loadAuthInformation()
        dispatch({
          type: 'setCRMAuthInformation',
          payload: CRMSettings,
        })
      }
      if (e.key === CRM_CODE) {
        const crmCode = localStorage.getItem(CRM_CODE)

        if (!crmCode) {
          logger.appInit.appSettings.warn('CRM code not found in local storage')
          return
        }

        const authInformation = await crmUtil.getCRMAuthInformation(crmCode)
        localStorage.setItem(CRM_AUTH_INFORMATION, JSON.stringify(authInformation))
        localStorage.removeItem(CRM_CODE)
        logger.appInit.appSettings.info('CRM code processed and auth information updated')
      }
    }

    // [TODO] - This is randomnly started in here, there's another useEffect where this can be more co-located
    // init the validate user machine
    validateLogInUserMachine.current.start()
    // this is need it in case the clean up is made from other tab
    window.addEventListener('storage', handleStorageChange);
    // this will be used in case the clean up is made from the same tab
    window.addEventListener(CRM_STORAGE_UPDATE, handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange)
      window.removeEventListener(CRM_STORAGE_UPDATE, handleStorageChange)
    };
  }, []);

  useEffect(() => {
    dispatch({
      type: 'setIsCRMEnabled',
      payload: isCRMEnabled,
    })
    logger.appInit.appSettings.info('CRM enabled status set to', isCRMEnabled)
  }, [isCRMEnabled])

  return (
    <AppSettingsContext.Provider
      value={{
        ...appSettings,
        dispatch,
        crmMachine: CRMService.current,
        logOutMachine: signOutMachine.current,
        validateLogInUserMachine: validateLogInUserMachine.current,
      }}
    >
      <LoggerStateProvider
        deviceMode={deviceMode}
        currentPlatform={currentPlatform}
        isLoggingOut={isLoggingOut}
      >
        {children}
      </LoggerStateProvider>
    </AppSettingsContext.Provider>
  )
}

export default AppSettings
