import React from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { DNABox, DNAButton, DNAText, DNACheckbox, DNADivider, Iffy } from '@alucio/lux-ui';
import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail';
import { Page } from '@alucio/aws-beacon-amplify/src/models';
import useMachineSelector, { composite } from 'src/hooks/useSelector';
import * as slideSettingsSelector from 'src/state/machines/publisherVersioning/SlideSettings/slideSettings.selectors';
import { useSlideSettingsState as useSlideSettingsStateProxy }
  from 'src/state/machines/publisherVersioning/SlideSettings/SlideSettingsProvider.proxy';
import { getThumbURL } from 'src/utils/thumbnailHelpers';
import AssociatedSlidesThumbnails from 'src/components/DNA/Thumbnail/AssociatedSlidesThumbnails';
import { styles } from '../../SlideSettings';
import LeftSideBar from './LeftSideBar';

export const S = StyleSheet.create({
  associatedSlidesContent: {
    marginBottom: 32,
  },
  associatedSlidesContainer: {
    marginRight: 12,
    marginBottom: 8,
  },
  // [TODO-ASSOCIATED] - Figure out a better way to avoid shifting scrollbar
  associatedSlidesShiftingScrollbar: {
    marginRight: 12,
  },
  associatedSlidesCheckBox: {
    marginLeft: 5,
    marginRight: 7,
  },
});

interface SlideProps {
  page: Page
}

const Slide: React.FC<SlideProps> = (props) => {
  const { page } = props
  const {
    service,
    currentDocumentVersionORM,
    thumbnailSizes,
    pageMap,
    getSlideTitle,
    selectAssociatedSlide,
    getHasSlideTextInsertionEnabled,
  } = useSlideSettingsStateProxy()

  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      slideSettingsSelector.versionDraft,
      slideSettingsSelector.associatedSlides,
      slideSettingsSelector.isAddAssociatedSlidesMode,
      slideSettingsSelector.isAddAssociatedSlidesStepOne,
      slideSettingsSelector.isAddAssociatedSlidesStepTwo,
    ),
  )

  const coverThumbnailPageNumber = currentDocumentVersionORM.model.selectedThumbnail ?? 1
  const thumbURL = getThumbURL(currentDocumentVersionORM, page.number)

  const isChecked = cond.isAddAssociatedSlidesStepOne
    ? cond.associatedSlides.child[page.pageId]
    : cond.associatedSlides.parent[page.pageId]

  const associatedPages = Object
    .values(page.linkedSlides ?? {})
    ?.map(linkedSlideId => pageMap[linkedSlideId]) ?? []

  const allChildSlide = {}
  cond.versionDraft.pages?.forEach(page => {
    page.linkedSlides?.forEach(id => {
      allChildSlide[id] = true
    })
  })

  // Disabled: 1. If user at stepOne and the slide already has linkedslides
  //           2. If user at stepTwo and the slide is already some other slide's associated slide
  //           3. If user at stepTwo and the slide is already selected at stepOne
  const disabled = (cond.isAddAssociatedSlidesStepOne && !!page.linkedSlides?.length) ||
    (cond.isAddAssociatedSlidesStepTwo && !!allChildSlide[page.pageId]) ||
    (cond.isAddAssociatedSlidesStepTwo && cond.associatedSlides.child[page.pageId])

  // [TODO-ASSOCIATED] - Make a generic context to synchronize thumbnail loading
  //                   - Add support for generic interactive/badge rendering
  //                   - Add variant supports for different styling types

  return (
    <DNABox
      key={page.pageId}
      appearance="col"
      spacing="sm"
      childFill={1}
      style={S.associatedSlidesContainer}
    >
      <DNABox style={S.associatedSlidesShiftingScrollbar}>
        <DNAThumbnail
          key={thumbURL}
          s3URL={thumbURL}
          useLoadingIndicator
          size={thumbnailSizes.thumbnailSize}
          mode={DNAThumbnail.Modes.SELECTABLE}
          variant={DNAThumbnail.Variants.INFO}
          pageNumber={page.number}
          checked={isChecked}
          disabled={disabled}
          thumbnailTitle={getSlideTitle(page.number)}
          isTextInsertionEnabled={getHasSlideTextInsertionEnabled(page.number)}
          disabledMessage={
            cond.isAddAssociatedSlidesMode
              // eslint-disable-next-line max-len
              ? 'This slide cannot have associated slides because it is already associated with another slide.'
              : undefined
          }
          isCover={page.number === coverThumbnailPageNumber}
          isRequired={page.isRequired}
          onCheck={() => selectAssociatedSlide(page.pageId)}
        />
      </DNABox>
      <AssociatedSlidesThumbnails
        docVerORM={currentDocumentVersionORM}
        pages={associatedPages}
        size={thumbnailSizes.thumbnailSize}
      />
    </DNABox>
  )
}

const Content: React.FC = () => {
  const {
    service,
    thumbnailSizes,
    selectAllAssociatedSlides,
  } = useSlideSettingsStateProxy()

  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      slideSettingsSelector.isAddAssociatedSlidesMode,
      slideSettingsSelector.isAddAssociatedSlidesStepOne,
      slideSettingsSelector.isAddAssociatedSlidesStepTwo,
      slideSettingsSelector.versionDraft,
      slideSettingsSelector.associatedSlides,
      slideSettingsSelector.addAssociatedSlidesSelectedChild,
      slideSettingsSelector.addAssociatedSlidesNumberOfSlidesSelected,
      slideSettingsSelector.addAssociatedSlidesSelectableNumberOfSlides,
    ),
  )

  const isAllSlidesChecked =
    cond.addAssociatedSlidesNumberOfSlidesSelected === cond.addAssociatedSlidesSelectableNumberOfSlides;

  return (
    <DNABox fill>
      <LeftSideBar selectedChild={cond.addAssociatedSlidesSelectedChild} />
      {/* ACTION BAR */}
      <DNABox fill appearance="col">
        <DNABox style={styles.rowWrapper} alignY="center">
          <Iffy is={cond.isAddAssociatedSlidesMode}>
            <DNABox spacing="md" alignY="center">
              <DNACheckbox
                checked={isAllSlidesChecked}
                onChange={selectAllAssociatedSlides}
                style={S.associatedSlidesCheckBox}
              />
              <DNAText status="flatAlt">Select all</DNAText>
              <DNADivider style={styles.verticalDivider} />
              <DNAText status="flatAlt">
                {/* eslint-disable-next-line max-len */}
                {`${cond.addAssociatedSlidesNumberOfSlidesSelected} of ${cond.addAssociatedSlidesSelectableNumberOfSlides} Selected`}
              </DNAText>
            </DNABox>
          </Iffy>
          <DNABox fill alignX="end">
            <DNAButton
              appearance="ghostLink"
              status="tertiary"
              size="md"
              padding="none"
              onPress={thumbnailSizes.cycleThumbnailSize}
              iconLeft={thumbnailSizes.thumbnailSize === 'lg' ? 'view-comfy' : 'view-grid'}
            />
          </DNABox>
        </DNABox>

        {/* SLIDES */}
        <DNABox fill>
          <ScrollView style={styles.content}>
            <Iffy is={cond.isAddAssociatedSlidesMode}>
              <DNABox
                appearance="row"
                wrap="start"
                childStyle={S.associatedSlidesContent}
                alignX="center"
              >
                {cond.versionDraft.pages?.map((page) => <Slide key={page.pageId} page={page} />)}
              </DNABox>
            </Iffy>
          </ScrollView>
        </DNABox>
      </DNABox>
    </DNABox>
  )
}

export default Content;
