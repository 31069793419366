import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import ContentProvider from 'src/state/context/ContentProvider/ContentProvider';
import MeetingsStateProvider from 'src/state/context/Meetings/MeetingsStateProvider';
import MeetingsContent from '../MeetingsContent';
import useMeetingExternalWindowDependencies from 'src/state/context/Meetings/Hooks/useExternalWindowDependencies';
import { DNALoaderEvents } from 'src/components/DNA/Loader/DNALoader';
import LoadingScreen from '../../Loading';

interface ContentProps {
  meetingId: string
}

const Content: React.FC<ContentProps> = ({ meetingId }) => {
  return (
    <ContentProvider meetingId={meetingId}>
      <MeetingsStateProvider meetingId={meetingId}>
        <MeetingsContent />
      </MeetingsStateProvider>
    </ContentProvider>
  );
};

const PopoutContent: React.FC = () => {
  const { meetingId } = useParams<{ meetingId: string }>();
  const { getExternalWindowDependencies } = useMeetingExternalWindowDependencies();
  const [isLoadingMeeting, setIsLoadingMeeting] = useState<boolean>(true);

  const handleInit = () => {
    const handleLoadExternalWindow = async () => {
      // CLEANS THE URL TO REMOVE THE MEETING_ID //
      const newUrl = window.location.href.split(`/${meetingId}`)[0];
      window.history.pushState({}, document.title, newUrl);
      if (!meetingId) {
        window.close();
      }

      // GET EXTERNAL DEPENDENCIES //
      await getExternalWindowDependencies(meetingId);
      setIsLoadingMeeting(false);
    };
    handleLoadExternalWindow();
  }

  useEffect(handleInit, [])

  return isLoadingMeeting
    ? <LoadingScreen
        analyticsEventType={DNALoaderEvents.MEETING}
        context="3PC External Window"
    />
    : <Content meetingId={meetingId} />
}

export default PopoutContent
