import React from 'react';
import { StyleSheet } from 'react-native';
import { DNABox } from '@alucio/lux-ui';
import { useMediaQuery } from 'react-responsive';
import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail';
import { getSlideTitle, getThumbURL } from 'src/utils/thumbnailHelpers';
import { usePublisherVersioningState }
  from 'src/state/machines/publisherVersioning/PublisherVersioningProvider';
import { useMatchSlidesState } from 'src/state/machines/publisherVersioning/MatchSlides/MatchSlidesProvider';
// Dnd
import { useSingleItemDnd } from 'src/components/DnD/DNASingleItemDnd';
import { useDispatch } from 'src/state/redux';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import MatchSlidesPreviewModal from '../MatchSlidesPreviewModal';

const S = StyleSheet.create({
  newVersionContainer: {
    paddingBottom: 78,
  },
})

interface NewVersionContainerProps {
  pageId: string,
  newPageNum: number
}

const NewVersionContainer: React.FC<NewVersionContainerProps> = ({ pageId, newPageNum }) => {
  const {
    latestContentPageData,
    latestPublishedContentPageData,
  } = usePublisherVersioningState();
  const {
    currentDocumentVersionORM,
    latestPublishedDocumentVersionORM,
  } = useMatchSlidesState();
  const { groupedTargetItems } = useSingleItemDnd()
  const dispatch = useDispatch()
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1300px)' })

  const previousPageId = groupedTargetItems[pageId]?.[0]?.itemId || '';
  const previousPageNum = previousPageId?.split('_')?.pop();

  const togglePreviewModal = ({ previousPageNum, newPageNum }) => {
    dispatch(DNAModalActions.setModal({
      isVisible: true,
      allowBackdropCancel: true,
      component: (props) => (
        <MatchSlidesPreviewModal
          newDocumentVersionORM={currentDocumentVersionORM}
          previousDocumentVersionORM={latestPublishedDocumentVersionORM}
          newPageNum={newPageNum}
          previousPageNum={previousPageNum}
          latestContentPageData={latestContentPageData}
          latestPublishedContentPageData={latestPublishedContentPageData}
          currentDocumentVersionORM={currentDocumentVersionORM}
          {...props}
        />
      ),
    }))
  }

  const thumbURL = getThumbURL(currentDocumentVersionORM, newPageNum)
  const thumbnailTitle = getSlideTitle(
    currentDocumentVersionORM,
    newPageNum,
    latestContentPageData,
  )
  return (
    <DNABox
      fill
      childFill={1}
      appearance="col"
      alignX="center"
      alignY="start"
      key={`last-version-thumbnail-${pageId}`}
      testID={`last-version-thumbnail-${pageId}`}
      style={S.newVersionContainer}
    >
      <DNAThumbnail.Lazy
        variant={DNAThumbnail.Variants.MATCH_SLIDE}
        key={thumbURL}
        s3URL={thumbURL}
        useLoadingIndicator
        thumbnailTitle={thumbnailTitle}
        style={{ margin: 0 }}
        size={isLargeScreen ? 'lg' : 'md'}
        pageNumber={newPageNum}
        onCheck={() => togglePreviewModal({
          newPageNum,
          previousPageNum,
        })}
      />
    </DNABox>
  )
}

export default NewVersionContainer
