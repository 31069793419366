import React from 'react';
import { Auth } from '@aws-amplify/auth'
import 'src/state/machines/auth/validateUserMachine';
import { Hub } from '@aws-amplify/core'
import { Provider as ReduxProvider } from 'react-redux';
import { WebLinking as Linking } from '@alucio/core'
import { store } from 'src/state/redux';
import {
  LuxTheming,
  AlucioTheme,
  BrowserChecker,
  withToastProvider,
} from '@alucio/lux-ui';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { Router } from './router';
import Routes, { RoutesListener } from './router/routes';
import DevLogger from 'src/components/DevLogger/DevLogger'
import config from 'src/config/app.json';

// COMPONENTS
import AppContainer from 'src/AppContainer';
import { ErrorBoundary } from 'react-error-boundary'
import Error from 'src/components/DNA/Error'
import Authenticator from './components/Authenticator';
import Idle from 'src/components/IdleComponent/IdleComponent';
import ContentPreviewModal from './components/ContentPreviewModal/ContentPreviewModal';
import UserInit from 'src/components/UserInit/UserInit';
import RightSideBar from 'src/components/Sidebar/RightSideBar';
import DNAConnectedModal from 'src/components/DNA/Modal/DNAConnectedModal'
import AppSettings from 'src/state/context/AppSettings'
import DNAAppContainer from 'src/components/DNA/AppContainer/DNAAppContainer'
import SlideSelector from './components/SlideSelector/SlideSelector';
import CacheDB from './worker/db/cacheDB';
import PresentationBuilder from './components/PresentationBuilder/PresentationBuilder';
import ContentPreviewModalV2 from './components/ContentPreviewModalV2/ContentPreviewModalV2';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import { pageFunctionIntercept } from './utils/analytics';
import { CRMUtil } from './state/machines/CRM/util';
import { SignOutProvider } from './components/Authenticator/LogOut';
import { useIsExternalPlayer } from './screens/Loading';
import QuotaErrorMessage, { LoggingOutLoader } from './components/ErrorMessages/QuotaErrorMessage';
import * as logger from 'src/utils/logger'
import ReduxMonitor from './components/ReduxMonitor/ReduxMonitor';
import ContentSyncMonitor from './components/ContentSyncMonitor/ContentSyncMonitor';

const { interceptCRMAuthCode }  = new CRMUtil()
const UnsupportedBrowserImage = require('../../beacon/assets/images/UnsupportedBrowser.svg');

// override the default behavior of the page event in segment
analytics.ready(() => {
  window.analytics.page = pageFunctionIntercept(window.analytics.page);
})

// [TODO] - Consider centralizing logout relating functions in one place (e.g. src/components/Authenticator)
// [TODO-PWA] - Consider moving this to the service worker layer instead
export const logout = async () => {
  logger.auth.signOut.info('Logout path detected')
  const isPWAStandalone = window.matchMedia('(display-mode: standalone)').matches
  window.localStorage.clear();
  analytics?.track('LOGIN_LOGOUT_FORCED', {
    action: 'LOGOUT_FORCED',
    category: 'LOGIN',
  });

  // Nuke all IndexDBs we use this function instead of datastore as a fail safe
  const dbsToDelete = ['amplify-datastore', 'analyticsOffline', 'loggerOffline']
  if (!isPWAStandalone) {
    dbsToDelete.push('CacheDB')
    // De-register the service worker if it's registered
    const registration = await navigator.serviceWorker.getRegistration('/')
    if (registration) {
      logger.serviceWorker.info('Unregistering service worker', registration)
      const isUnregistered = await registration?.unregister()
      logger.serviceWorker.info('SW Unregistered', isUnregistered)
    }
  } else {
    try {
      // [TODO] - Is this needed? The above if block adds `CacheDB` to delete anyways, isn't this the same thing?
      logger.auth.signOut.info('Attempting to delete cacheDB due to PWA mode')
      const cacheDB = new CacheDB()
      await cacheDB.open()
      await cacheDB.purge()
      await cacheDB.close()
    } catch (e) {
      console.warn('Error occurred purging cacheDB', e)
    }
  }
  for (const idbName of dbsToDelete) {
    try {
      const deleteRequest = await indexedDB.deleteDatabase(idbName);
      deleteRequest.onblocked = () => {
        logger.auth.signOut.info(`${idbName} is blocked and can't be deleted.`);
      };
    } catch (e) {
      console.warn(`Error deleting indexDB ${idbName}`, e)
    }
    logger.auth.signOut.info('Deleted the following IDBs - ', dbsToDelete)
  }
  try {
    await Auth.signOut();
    Hub.dispatch('logout', { event: 'logout' });
    logger.auth.signOut.info('Cleared AWS Auth')
  } catch (e) {
    console.warn(e)
  }

  // Use a refresh query to possibly avoid a cached app reload
  Linking.openURL(`/?refresh=${Date.now()}`, '_self')
}

const ExternalPlayer: React.FC = () => {
  return (
    <>
      <Idle />
      <DNAConnectedModal />
      <Routes />
    </>
  );
};

const BeaconApp: React.FC = () => {
  const enableNewContentPreviewModal = useFeatureFlags('enableNewContentPreviewModal');
  return (
    <>
      <RightSideBar>
        <DNAAppContainer>
          <Idle />
          <DNAConnectedModal />
          <PresentationBuilder />
          <RoutesListener />
          <Routes />
        </DNAAppContainer>
      </RightSideBar>
      <SlideSelector />
      {enableNewContentPreviewModal ? <ContentPreviewModalV2 /> : <ContentPreviewModal />}
    </>
  );
};

const App: React.FC = () => {
  const isExternalPlayer = useIsExternalPlayer();

  return (
    <SignOutProvider>
      <UserInit>
        {isExternalPlayer
          ? <ExternalPlayer />
          : <BeaconApp />}
      </UserInit>
    </SignOutProvider>
  );
};

const withProviders = ChildComponent => () => {
  interceptCRMAuthCode();

  const notifyError = (error) => {
    console.warn('Error encountered:', error)

    const { NODE_ENV } = process.env

    if (NODE_ENV && NODE_ENV !== 'development') {
      analytics?.track('APP_ERROR', {
        action: 'ERROR',
        category: 'APP',
        name: error.name,
        message: error.message,
      });

      const attributes = {
        segment_anonymous_id: analytics?.user().anonymousId(),
        user_id: analytics?.user().id() ?? '',
      }

      newrelic?.noticeError(error, attributes)
    }
  }

  // We keep this at the very top so that we can always get to /logout to force reload
  // the session
  if (window.location.pathname.startsWith('/logout')) {
    logout();
    return (<LoggingOutLoader />);
  } else if (window.location.pathname.startsWith('/quota-exceeded')) {
    return (<QuotaErrorMessage />);
  }
  return (
    <ErrorBoundary
      onError={notifyError}
      FallbackComponent={() => (
        <Error
          message={'Something has gone wrong, please reload this page!'}
        />
      )}
    >
      <ReduxProvider store={store}>
        <AppSettings>
          <Router>
            <AppContainer>
              <LuxTheming config={AlucioTheme}>
                <>
                  <BrowserChecker
                    unsupportedBrowserImage={UnsupportedBrowserImage}
                  >
                    {/* [TODO-PWA] - ALWAYS DISABLE THIS IN PRODUCTION */}
                    <ReduxMonitor />
                    <ContentSyncMonitor />
                    <DevLogger disable={true} />
                    <Authenticator>
                      <ChildComponent />
                    </Authenticator>
                  </BrowserChecker>
                </>
              </LuxTheming>
            </AppContainer>
          </Router>
        </AppSettings>
      </ReduxProvider>
    </ErrorBoundary>
  );
};

export default withLDProvider({
  clientSideID: config.LaunchDarklyKey,
  context: {
    kind: 'user',
    key: 'AnonymousUser',
  },
},
)(withToastProvider(withProviders(App)));
