import { DocumentAccessLevel, DocumentStatus, DocumentVersionChangeType } from '@alucio/aws-beacon-amplify/src/models';
import { PayloadGroup } from 'src/components/PresentationBuilder/state/PresentationBuilderStateProvider';
import { ReplaceGroupDraft, GroupDraftStatus }
  from 'src/components/PresentationBuilder/PresentationEditor/FindReplacement/FindReplacementProvider';
import { DocumentVersionORM } from 'src/types/orms';
import { ContentPageData } from 'src/hooks/useContentPageData/useContentPageData';

export function assignReplacementGroups(
  groups: PayloadGroup[],
): ReplaceGroupDraft[] {
  return groups.map((group): ReplaceGroupDraft => ({
    groupId: group.id,
    pages: group.pages,
    visible: group.visible,
    isGroup: group.pages.length > 1,
    // NOTE: IF A NEW STATUS IS ADDED TO THE GROUPSTATUSENUM, WE'LL NEED TO HANDLE IT IN GROUPDRAFTSTATUS
    status: GroupDraftStatus[group.groupStatus],
    documentVersionORM: group.documentVersionORM,
    name: group.name ?? 'Untitled Group',
    locked: group.locked ?? false,
  }));
}

export const findPage = (pageId: string, docVerORM: DocumentVersionORM | undefined) => {
  return docVerORM?.meta.allPages.find(page => page.pageId === pageId )
}

/**
 * getValidMappedSingleSlidesGroup calculates the mapped pageId of single slide group if there is any
 * @param group
 * @param allContentPageDataVersions
 * @returns mapped value (pageId) or null if there is no valid mapping found
 */
export const getValidMappedSingleSlidesGroup = (
  group: PayloadGroup,
  allContentPageDataVersions: {[documentVersionId: string] : ContentPageData[]},
): string | null => {
  const isUserFile = group.docAccessLevel === DocumentAccessLevel.USER;
  if (isUserFile) return null;
  const pageNumber = group.pages[0].number;
  const documentORM = group.documentVersionORM?.relations.documentORM;
  const latestDocumentVersionORM = documentORM?.relations.version.latestPublishedDocumentVersionORM;
  const latestDocumentVersionId = documentORM?.relations.version.latestPublishedDocumentVersionORM?.model.id;
  const isCurrentVersionTheLatest = group.documentVersionORM?.model.id === latestDocumentVersionORM?.model.id;
  const isDocumentMinorUpdated = !isCurrentVersionTheLatest &&
    latestDocumentVersionORM?.model.changeType === DocumentVersionChangeType.MINOR;
  const latestVersionContentPageDatas = latestDocumentVersionId
    ? allContentPageDataVersions[latestDocumentVersionId]
    : undefined;
  const filteredVersions = documentORM?.relations.documentVersions
    .filter(docVer => docVer.model.status !== DocumentStatus.NOT_PUBLISHED) ?? []
  const currentVersionIndex = filteredVersions
    .findIndex(docVer => docVer.model.id === group.documentVersionORM?.model.id) ?? -1;
  const latestPublishedVersionIndex = filteredVersions
    .findIndex(docVer => docVer.model.id === latestDocumentVersionId) ?? -1;
  const isSkipVersionUpdate = Math.abs(currentVersionIndex - latestPublishedVersionIndex) > 1;
  const hasAnyMapping = !isSkipVersionUpdate && !!latestVersionContentPageDatas
    ?.some(pageData => !!pageData.mapping && pageData.mapping !== 'USER_UNMATCHED')
  const newReplacementPageId = latestVersionContentPageDatas?.find(pageData => {
    return pageData.mapping === group.pages[0].pageId;
  })?.pageId ?? (isDocumentMinorUpdated && !hasAnyMapping ? `${latestDocumentVersionId}_${pageNumber}` : null);
  return newReplacementPageId;
}

export const isValidMinorUpdatedMultiSlidesGroup = (group: PayloadGroup): boolean => {
  const documentORM = group.documentVersionORM?.relations.documentORM;
  const latestDocumentVersionORM = documentORM?.relations.version.latestPublishedDocumentVersionORM;
  const isCurrentVersionTheLatest = group.documentVersionORM?.model.id === latestDocumentVersionORM?.model.id;
  const isDocumentMinorUpdated = !isCurrentVersionTheLatest &&
    latestDocumentVersionORM?.model.changeType === DocumentVersionChangeType.MINOR;
  const filteredVersions = documentORM?.relations.documentVersions
    .filter(docVer => docVer.model.status !== DocumentStatus.NOT_PUBLISHED) ?? []
  const currentVersionIndex = filteredVersions
    .findIndex(docVer => docVer.model.id === group.documentVersionORM?.model.id) ?? -1;
  const latestPublishedVersionIndex = filteredVersions
    .findIndex(docVer => docVer.model.id === latestDocumentVersionORM?.model.id) ?? -1;
  const isSkipVersionUpdate = Math.abs(currentVersionIndex - latestPublishedVersionIndex) > 1;
  const isMatchingGroupId = !!latestDocumentVersionORM?.model.pageGroups
    ?.find(pageGroup => pageGroup.id === group.groupSrcId);
  return isDocumentMinorUpdated &&
    !isSkipVersionUpdate &&
    !!group.groupSrcId &&
    isMatchingGroupId
}
