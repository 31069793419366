/* eslint-disable max-len */
import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import type { SvgProps } from 'react-native-svg';
const SvgFolderVector = (props: SvgProps) => (
  <Svg
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={21}
    fill="none"
    {...props}
  >
    <Path
      fill="#44A2DA"
      d="M26 5v14.111A1.89 1.89 0 0 1 24.111 21H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h6.666c.433.001.854.141 1.2.4L13.334 3H24a2 2 0 0 1 2 2"
    />
  </Svg>
);
export default SvgFolderVector;
