import { DocumentAccessLevel, DocumentStatus, FileType } from '@alucio/aws-beacon-amplify/src/models'

import { Variant, DocumentORM } from 'src/types/types'
import { DocumentContextAction } from '../DNADocument.actions'

import useCurrentPage from '../../hooks/useCurrentPage'
import { useAppSettings } from 'src/state/context/AppSettings'
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags'

/** These types can be removed and replaced with DocumentStatus if we want to
 * define context menu actions for all statuses in both publisher and versioning */
type PublisherDocumentStatus =
  DocumentStatus.ARCHIVED |
  DocumentStatus.REVOKED |
  DocumentStatus.NOT_PUBLISHED |
  DocumentStatus.PUBLISHED

type VersioningDocumentStatus =
  DocumentStatus.ARCHIVED |
  DocumentStatus.PUBLISHED |
  DocumentStatus.REVOKED

/**
 * This helper allows you to conditionally insert elements into an array based on the evaluated condition.
 * Additionally, allows for the TS compiler to properly recognize the returned array type.
 * @example
 * const someDocumentContextActionArray = [
          'version',
          'archive',
          'revoke',
          ...insertIf(someCondition, ['delete']),
        ]
 */
export const insertIf = (condition: boolean, elements: DocumentContextAction[]) => {
  return condition ? elements : []
}

const useDNADocumentContextMenuActions = (documentORM: DocumentORM, variant: Variant) => {
  const { status, accessLevel } = documentORM.model
  const { permissions, tenantFields: { labelCompletion } } = documentORM.meta;

  const { isOnline } = useAppSettings()
  const route = useCurrentPage({ exact: true })
  const disablePublisherFolder = useFeatureFlags('disablePublisherFolder');
  const isPublisherRoute = route?.configOptions?.modules?.includes('publisher')

  const canAddFolder = !isPublisherRoute || (isPublisherRoute && !disablePublisherFolder)

  const actionsToUse: DocumentContextAction[] = []

  switch (variant) {
    /** SECTION - MSL Context Menu Items */
    case Variant.MSL: {
      const isWebDoc = documentORM.model.type === FileType.WEB

      /** Based on the document access level, define a mapping of arrays containing available context menu actions */
      const documentAccessLevelActions: { [K in DocumentAccessLevel]: DocumentContextAction[] } = {
        TENANT: [
          ...insertIf(permissions.MSLDownload && isOnline && !isWebDoc, ['download']),
          ...insertIf(permissions.MSLShare && isOnline, ['shareEmail']),
          ...insertIf(permissions.addToFolder && canAddFolder, ['addToFolder']),
        ],
        USER: ['rename', 'delete'],
      }

      /** Push the appropriate context menu action array based on the document access level */
      actionsToUse.push(...documentAccessLevelActions[accessLevel])
      break
    }
    /** !SECTION - End MSL Context Menu Items */

    /** SECTION - Publisher Context Menu Items */
    case Variant.publisher: {
      const { conversionStatus } = documentORM
        .relations
        .version
        .latestDocumentVersionORM
        .model

      /** Based on the document status, define a mapping of arrays containing available context menu actions */
      const documentStatusActions: { [K in PublisherDocumentStatus]: DocumentContextAction[] } = {
        ARCHIVED: ['revoke', 'delete', 'unarchive'],
        REVOKED: ['delete', 'unrevoke'],
        NOT_PUBLISHED: [
          'beaconLink',
          ...insertIf(labelCompletion, ['publish']),
          ...insertIf(canAddFolder, ['addToFolder']),
          'delete',
        ],
        PUBLISHED: [
          'beaconLink',
          'version',
          ...insertIf(canAddFolder, ['addToFolder']),
          'archive',
          'revoke',
          'delete',
        ],
      }

      switch (conversionStatus) {
        case 'PROCESSED':
          /** If a document has processed successfully, push the appropriate context menu action array based on the document status  */
          actionsToUse.push(...documentStatusActions[status])
          break
        case 'ERROR':
          /** Otherwise just push the delete option */
          actionsToUse.push('delete')
          break
      }
      break
    }
    /** !SECTION - Publisher Context Menu Items */

    /** SECTION - Versioning Context Menu Items */
    case Variant.versioning: {
      // [TODO-2126] - There's a key on the ORM that exposes this (latestPublishedDocumentVersionORM)
      const isFirstVersionUnpublished = documentORM.relations.documentVersions.length === 1 &&
        status === DocumentStatus.NOT_PUBLISHED

      /** Based on the document status, define a mapping of arrays containing available context menu actions */
      const documentStatusActions: { [K in VersioningDocumentStatus]: DocumentContextAction[] } = {
        REVOKED: ['unrevoke'],
        ARCHIVED: ['revoke', 'unarchive'],
        PUBLISHED: ['archive', 'revoke'],
      }

      /** Push the appropriate context menu action array based on the document status */
      !isFirstVersionUnpublished && actionsToUse.push(...documentStatusActions[status])

      /** Additionally, always add a delete menu option */
      actionsToUse.push('delete')
      break
    }
  }
  /** !SECTION - Versioning Context Menu Items */
  return actionsToUse
}

export default useDNADocumentContextMenuActions
