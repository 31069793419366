import React, { useMemo } from 'react';
import { DNABox } from '@alucio/lux-ui';
import CustomFormRecordFooter, { FORM_VARIANT } from './CustomFormRecordFooter';
import CustomFormRecordHeader from './CustomFormRecordHeader';
import CustomForm, { CustomFormRecordWrapper } from 'src/components/CustomForms/CustomForm';
import { useAddMeeting } from '../AddMeetingProvider';
import { useStandaloneFormConfig } from 'src/state/redux/selector/crm';
import { useComposableForm } from '../../CustomFields/ComposableForm';
import { LoadingMeeting } from '../LoadingMeeting';
import useStandaloneFormAdditionalSetting, {
  FORM_SOURCE,
} from 'src/hooks/useStandaloneFormAdditionalSetting/useCustomFormAdditionalSetting';
import { CustomFieldUsage, CustomFormRecordRelationshipModel } from '@alucio/aws-beacon-amplify/src/models';
import { useCustomFormRecordById } from 'src/state/redux/selector/customFormRecord';

const MeetingCustomFormRecord = () => {
  const { customFormsSettings, meetingORM, setCustomFormsSettings } = useAddMeeting();
  const { customFields, defaultValues } = useStandaloneFormConfig(
    customFormsSettings?.standaloneForm.id || '', customFormsSettings?.standaloneForm.crmFormSetting?.id);
  // THIS IS USED TO CREATE/ADD A PICKLIST CUSTOM FIELD WITH THE ATTENDEES //
  const crmAccountIds = useGetAttendeesCRMAccountIds(customFormsSettings?.customFormRecordId);
  const additionalSettingsPayload = useMemo(() => ({
    customFields,
    standaloneForm: customFormsSettings?.standaloneForm,
    formSource: FORM_SOURCE.MEETING,
    additionalProps: {
      crmAccountIds,
    },
  }), [customFields, defaultValues, customFormsSettings?.standaloneForm, crmAccountIds]);
  const { isLoading, customFields: overwrittenFields } = useStandaloneFormAdditionalSetting(additionalSettingsPayload);

  if (isLoading) {
    return (
      <LoadingMeeting />
    );
  } else if (!customFormsSettings?.standaloneForm) {
    // eslint-disable-next-line no-console
    console.log('StandaloneForm not found', customFormsSettings);
    setCustomFormsSettings(undefined);
    return null;
  }

  const onClose = (): void => {
    setCustomFormsSettings(undefined);
  };

  return (
    <DNABox fill>
      {/* PROVIDERS WRAPPER */}
      <CustomFormRecordWrapper
        customFormRecordId={customFormsSettings?.customFormRecordId}
        parentModel={CustomFormRecordRelationshipModel.MEETING}
        parentId={meetingORM?.model.id}
        standaloneForm={customFormsSettings?.standaloneForm}
        usage={{ internalUsages: [CustomFieldUsage.MEETING] }}
        defaultValues={defaultValues}
        customFields={overwrittenFields}
        onSave={onClose}
      >
        <DNABox appearance="col" fill>
          {/* FORM COMPONENTS */}
          <CustomFormRecordHeader onConfirmCancel={onClose} variant={FORM_VARIANT.DEPENDENT} />
          <CustomForm />
          <CustomFormRecordFooter onConfirmCancel={onClose} variant={FORM_VARIANT.DEPENDENT} />
        </DNABox>
      </CustomFormRecordWrapper>
    </DNABox>
  )
}

// RETURNS THE IDS OF THE ATTENDEES
function useGetAttendeesCRMAccountIds(recordToBeEditedId?: string): string[] {
  const { rhForm } = useComposableForm();
  const { primaryAttendee, additionalAttendees } = rhForm.getValues();
  const crmAccountIds: string[] = [];
  // UPON EDITING A RECORD, IF THE ATTENDEE WAS REMOVED, SINCE THE RECORD STILL HAS IT,
  // WE'LL KEEP SHOWING THEM ON THE ACCOUNTS' LIST FIELD OF THE FORM
  const recordORM = useCustomFormRecordById(recordToBeEditedId);

  if (recordORM && recordORM.model.crmFields?.accountId) {
    crmAccountIds.push(recordORM.model.crmFields.accountId);
  }

  if (Array.isArray(primaryAttendee) && Array.isArray(additionalAttendees)) {
    additionalAttendees.forEach((attendee) => {
      attendee.crmAccountId && crmAccountIds.push(attendee.crmAccountId);
    });
    primaryAttendee.forEach((attendee) => {
      attendee.crmAccountId && crmAccountIds.push(attendee.crmAccountId);
    });
  }

  return [...new Set(crmAccountIds)];
}

export default MeetingCustomFormRecord;
