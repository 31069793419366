import React, { useEffect } from 'react';
import { StyleSheet, useWindowDimensions } from 'react-native';
import { DNABox, Iffy } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import { useInPersonActionBarSharedComponents } from './useInPersonActionBarSharedComponents';
import { ActionBarState } from '../../SharedComponents/PresentationMenu';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider';

const S = StyleSheet.create({
  actionsContainer: {
    backgroundColor: colors['color-black'],
    paddingHorizontal: 8,
    height: 64,
    borderTopLeftRadius: 4,
  },
})

const DESKTOP_PWA_MIN_WINDOW_WIDTH = 1050;

const TabletVariant: React.FC = () => {
  const dimensions = useWindowDimensions();
  const { setPresentationMenu } = useMeetingsState();
  const {
    presentationNav,
    myContentButton,
    presentationsButton,
    slidesButton,
    highlighterContextMenu,
    textSearchButton,
    sentimentsContextMenu,
    myNotesButton,
    fullScreenButton,
    endMeetingButton,
    changeToVirtualModeButton,
    collapseButton,
    actionBarState,
  } = useInPersonActionBarSharedComponents();

  useEffect(() => {
    if (dimensions.width < DESKTOP_PWA_MIN_WINDOW_WIDTH) {
      setPresentationMenu(ActionBarState.Collapsed)
    }
  }, [dimensions])

  return (
    <DNABox
      spacing={actionBarState === ActionBarState.PresentationMenu ? 'between' : 'sm'}
      style={S.actionsContainer}
      alignY="center"
      alignX={actionBarState === ActionBarState.Collapsed ? 'end' : undefined}
      fill={false}
    >
      <Iffy is={actionBarState === ActionBarState.PresentationMenu} >
        {/* LEFT */}
        <DNABox spacing="sm">
          {myContentButton}
          {presentationsButton}
          {slidesButton}
        </DNABox>
        {/* MIDDLE */}
        <DNABox>
          {presentationNav}
        </DNABox>
        {/* RIGHT */}
        <DNABox spacing="sm">
          {highlighterContextMenu}
          {textSearchButton}
          {sentimentsContextMenu}
          {myNotesButton}
          {fullScreenButton}
          {endMeetingButton}
          {changeToVirtualModeButton}
          {collapseButton}
        </DNABox>
      </Iffy>
      <Iffy is={actionBarState === ActionBarState.Collapsed} >
        {presentationNav}
        {collapseButton}
      </Iffy>
    </DNABox>
  )
}

export default TabletVariant
