import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  FlatList,
  LayoutAnimation,
  LayoutChangeEvent,
  ScrollView,
  StyleSheet,
  ViewStyle,
  useWindowDimensions,
} from 'react-native';
import {
  DNABox,
  DNAButton,
  DNAChip,
  DNAIcon,
  DNAText,
  Icon,
  Iffy,
  util,
} from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { Page, PageGroup } from '@alucio/aws-beacon-amplify/src/models';
import { detectArchivedFileKeyPath } from 'src/components/SlideSelector/useThumbnailSelector';
import { getSlideContentPageDataTitle } from 'src/hooks/useContentPageData/useContentPageData';
import { DocumentVersionORM } from 'src/types/types';
import useSelector, { composite } from 'src/hooks/useSelector';
import * as findReplacementSelector from 'src/state/machines/findReplacement/FindReplacement.selectors';
import { useFindReplacementState, GroupDraftStatus, ReplaceGroupDraft } from '../FindReplacementProvider';
import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail';
import CollapsibleCard, { CollapsibleActionMenu } from 'src/components/CollapsibleCard/CollapsibleCard';
import RightContentStateProvider, { useRightContentState } from './RightContentProvider';
import {
  RecyclerListView,
  DataProvider,
  LayoutProvider,
  RecyclerListViewProps,
} from 'recyclerlistview';
import { RecyclerListViewState } from 'recyclerlistview/dist/reactnative/core/RecyclerListView';

const THUMBNAIL_RATIO = 66 / 120;
const GROUP_CARD_HEIGHT = 64;
const MIN_HORIZONTAL_PADDING = 30;
const SHOW_RIGHT_CONTENT_STATUSES = [
  GroupDraftStatus.MINOR_UPDATE_WITH_AUTO_MAPPING_SLIDES,
  GroupDraftStatus.MAJOR_UPDATE,
  GroupDraftStatus.REPLACED,
];

const S = StyleSheet.create({
  groupCard: {
    marginBottom: 24,
    maxWidth: 745,
  },
  groupCardSlide: {
    marginBottom: 24,
  },
  groupTitleHeader: {
    width: 100,
  },
  flex: {
    flex: 1,
  },
  rightContentContainer: {
    borderLeftColor: colors['color-gray-80'],
    borderLeftWidth: 2,
  },
  cardContainer: {
    padding: 12,
  },
  card: {
    padding: 12,
    minHeight: 52,
    backgroundColor: colors['color-text-white'],
    borderColor: colors['color-gray-100'],
    borderWidth: 1,
    borderRadius: 4,
  },
  releaseNoteContainer: {
    marginTop: 12,
  },
  previewContainer: {
    padding: 20,
  },
  thumbnailContainer: {
    borderColor: colors['color-gray-80'],
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    borderWidth: 3,
    padding: 3,
  },
  replacedThumbnailContainer: {
    borderColor: colors['color-success-500'],
    borderWidth: 3,
    borderRadius: 6,
    padding: 3,
  },
  thumbnailBackground: {
    backgroundColor: colors['color-gray-100'],
  },
  collapsibleCard: {
    minWidth: 300,
  },
  replacedCollapsibleCard: {
    minHeight: 'fit-content',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    borderWidth: 3,
    borderColor: colors['color-success-500'],
  },
  replacedCollapsibleCardBanner: {
    height: 32,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    top: 3,
    backgroundColor: colors['color-success-500'],
  },
  cardContentContainer: {
    paddingHorizontal: 20,
  },
  checkIconBackground: {
    borderRadius: 50,
    backgroundColor: colors['color-text-white'],
  },
  checkIcon: {
    height: 17,
    width: 17,
    color: colors['color-success-500'],
  },
});

function calculateIndexToScrollTo (
  allPageGroups: PageGroup[],
  allSingleSlide: Page[],
  activeGroup?: ReplaceGroupDraft,
) {
  if (!activeGroup) return 0;
  if (activeGroup.isGroup) {
    /* IF IT IS A GROUP, WE TRY TO SCROLL TO A GROUP WITH THE SAME GROUP NAME
    OR WE WILL FIND A GROUP WITH THE SAME FIRST SLIDE TO SCROLL TO */
    const groupWithSameName = allPageGroups.findIndex(group => group.name === activeGroup?.name);
    if (groupWithSameName !== -1) return groupWithSameName;
    const groupWithSameFirstSlide = allPageGroups.findIndex(group => {
      const pageNum = group.pageIds?.[0].split('_').pop();
      return pageNum && Number(pageNum) === activeGroup?.pages[0].number;
    })
    if (groupWithSameFirstSlide !== -1) return groupWithSameFirstSlide;
    else return 0;
  } else {
    const activePageNum = activeGroup?.pages[0].number;
    const targetIndex = allPageGroups.length + activePageNum - 1;
    /* IF IT IS A SINGLE SLIDE, AND THE NEW DOCUMENT HAS FEWER SLIDE COUNT AND THE PAGE NUM IS OUT OF BOUND, WE SCROLL TO THE END */
    if (allSingleSlide.length < activePageNum) return -1;
    else return targetIndex;
  }
}

const Slide: React.FC<{
  page: Page,
  documentVersionORM: DocumentVersionORM,
  isGroup?: boolean,
}> = ({ page, documentVersionORM, isGroup }) => {
  const {
    service,
    thumbnailSize,
    thumbnailDimensions,
    replaceGroup,
    unreplaceGroup,
    autoUpdateMapping,
    allContentPageDataVersions,
  } = useFindReplacementState();
  const dimensions = useWindowDimensions();
  const pageGroupRef = useRef<null | HTMLDivElement>(null)

  const cond = useSelector(
    service,
    (state) => composite(
      state,
      findReplacementSelector.activeGroup,
      findReplacementSelector.needReviewGroups,
    ),
  );

  const s3URL = detectArchivedFileKeyPath(documentVersionORM.model, page);
  const contentPageData = allContentPageDataVersions[documentVersionORM.model.id] || []
  const pageTitle = contentPageData.length > 0
    ? getSlideContentPageDataTitle(page.number - 1, contentPageData)
    : '';
  const slideFooterText = `${page.number}. ` + pageTitle;

  const thumbnailWidth = useMemo(() => {
    let width: number;

    if (isGroup) {
      width = thumbnailSize === 'lg'
        ? dimensions.width / 3
        : thumbnailDimensions.width;
    }
    else {
      width = thumbnailSize === 'lg'
        ? (dimensions.width / 2) - 80
        : thumbnailDimensions.width;
    }

    return width > 650
      ? 650
      : width;
  }, [dimensions, isGroup, thumbnailSize, thumbnailDimensions]);

  const isReplacedGroup = useMemo(() => {
    return !isGroup && cond.activeGroup?.groupReplacement?.groupSrcId === page.pageId;
  }, [cond.activeGroup, page])

  const onReplaceGroup = useCallback(() => {
    replaceGroup(
      [page],
      page.pageId,
      documentVersionORM,
    );
  }, [page, documentVersionORM, replaceGroup, cond.activeGroup])

  const thumbnailStyle = useMemo(() => ({
    parentContainer: {
      maxWidth: thumbnailWidth + 12,
    },
    innerContainer: [
      S.thumbnailBackground,
      {
        height: thumbnailWidth * THUMBNAIL_RATIO,
        width: thumbnailWidth,
      },
    ],
    outerContainer: util.mergeStyles(
      undefined,
      [S.thumbnailContainer, !isReplacedGroup],
      [S.replacedThumbnailContainer, isReplacedGroup],
    ),
  }), [thumbnailWidth, THUMBNAIL_RATIO, isReplacedGroup])

  const slideHasBeenMapped = Object.values(autoUpdateMapping).includes(page.pageId)

  return (
    <DNABox fill alignX="center" alignY="start">
      <DNABox
        appearance="col"
        ref={pageGroupRef}
        key={`slide-selector-single-slide-${page.pageId}`}
        style={thumbnailStyle.parentContainer}
      >
        <Iffy is={isReplacedGroup}>
          <DNABox alignX="center" alignY="center" spacing="sm" style={S.replacedCollapsibleCardBanner}>
            <DNABox style={S.checkIconBackground}>
              <Icon style={S.checkIcon} name="check" />
            </DNABox>
            <DNAText status="basic">Replaced by:</DNAText>
          </DNABox>
        </Iffy>
        <DNABox appearance="col" spacing="sm" fill childFill={1}>
          {/* THUMBNAIL */}
          <DNABox style={thumbnailStyle.outerContainer}>
            <DNABox
              testID="new-version-thumbnail"
              style={thumbnailStyle.innerContainer}
            >
              <DNAThumbnail
                height={thumbnailWidth * THUMBNAIL_RATIO}
                width={thumbnailWidth}
                s3URL={s3URL}
              />
            </DNABox>
          </DNABox>
          {/* THUMBNAIL FOOTER */}
          <DNAText numberOfLines={1} style={S.flex}>
            {slideFooterText}
          </DNAText>
          <Iffy is={!isGroup && !isReplacedGroup}>
            <DNAButton
              testID="replacement-btn"
              status="warning"
              onPress={onReplaceGroup}
              children="Select as replacement"
              stretch
            />
          </Iffy>
          <Iffy is={isReplacedGroup}>
            <DNAButton
              appearance="outline"
              status="tertiary"
              onPress={unreplaceGroup}
              stretch
              children="Select another replacement"
            />
          </Iffy>
          <Iffy is={slideHasBeenMapped && isReplacedGroup}>
            <DNABox alignX="center">
              <DNAIcon
                testID="updated_slide_icon"
                name="check-bold"
                size="md"
                style={{ fontSize: 18, color: colors['color-success-500'], marginRight: 6 }}
              />
              <DNAText p1 status="success" style={{ fontWeight: '700' }}>Updated</DNAText>
            </DNABox>
          </Iffy>
        </DNABox>
      </DNABox>
    </DNABox>
  )
}

export const GroupCard: React.FC<{
  idx: number,
  pageGroup: PageGroup,
  documentVersionORM: DocumentVersionORM,
}> = ({ idx, pageGroup, documentVersionORM }) => {
  const { service, replaceGroup, unreplaceGroup } = useFindReplacementState();
  const { setGroupCardExpandState } = useRightContentState();
  const pageGroupRef = useRef<null | HTMLDivElement>(null)
  const groupCardContentHeight = useRef<number>(0);
  const [isExpanded, setIsExpanded] = useState(false);

  const cond = useSelector(
    service,
    (state) => composite(
      state,
      findReplacementSelector.activeGroup,
    ),
  );

  const pages: Page[] = pageGroup.pageIds?.map(pageId => {
    return documentVersionORM.meta.allPages.find(page => page.pageId === pageId)!
  }) || []
  const isReplacedGroup = useMemo(() => {
    return cond.activeGroup?.groupReplacement?.groupSrcId === pageGroup.id;
  }, [cond.activeGroup, pageGroup])

  const onReplaceGroup = useCallback(() => {
    replaceGroup(
      pages,
      pageGroup.id,
      documentVersionORM,
      pageGroup.name,
      pageGroup.locked,
    );
  }, [cond.activeGroup, pageGroup, documentVersionORM, pages])

  const onLayout = useCallback((e: LayoutChangeEvent) => {
    groupCardContentHeight.current = e.nativeEvent.layout.height || 0
    setGroupCardExpandState(pre => ({
      ...pre,
      [idx]: isExpanded ? groupCardContentHeight.current : 0,
    }))
  }, [isExpanded])

  const onToggleChanged = useCallback((preState) => {
    setIsExpanded(!preState)
    setGroupCardExpandState(pre => ({
      ...pre,
      [idx]: preState ? 0 : groupCardContentHeight.current,
    }))
  }, [setIsExpanded, setGroupCardExpandState])

  const renderItem = useCallback(({ item: page }: { item: Page }) => {
    return (
      <DNABox fill appearance="col" style={S.groupCardSlide}>
        <Slide
          page={page}
          documentVersionORM={documentVersionORM}
          isGroup
        />
      </DNABox>
    )
  }, [documentVersionORM, pageGroup])

  const keyExtractor = useCallback((item, idx) => `${pageGroup.id}-${item.pageId}-${idx}`, [pageGroup])

  const groupHeaderTitle = (
    <DNABox fill appearance="col" alignY="center" style={S.groupTitleHeader}>
      <DNAText h5 numberOfLines={1}>{pageGroup.name}</DNAText>
    </DNABox>
  );

  return (
    <DNABox fill alignX="center">
      <DNABox
        fill
        appearance="col"
        ref={pageGroupRef}
        style={S.groupCard}
        key={`slide-selector-group-card-${pageGroup.id}`}
      >
        <Iffy is={isReplacedGroup}>
          <DNABox alignX="center" alignY="center" spacing="sm" style={S.replacedCollapsibleCardBanner}>
            <DNABox style={S.checkIconBackground}>
              <Icon style={S.checkIcon} name="check" />
            </DNABox>
            <DNAText status="basic">Replaced by:</DNAText>
          </DNABox>
        </Iffy>
        <CollapsibleCard
          headerTitle={groupHeaderTitle}
          isCollapsed={true}
          cardStyle={util.mergeStyles(
            undefined,
            S.collapsibleCard,
            [S.replacedCollapsibleCard, isReplacedGroup],
          )}
          onToggleChanged={onToggleChanged}
        >
          <CollapsibleActionMenu>
            <Iffy is={!isReplacedGroup}>
              <DNAButton
                status="warning"
                onPress={onReplaceGroup}
                children="Select as replacement"
              />
            </Iffy>
            <Iffy is={isReplacedGroup}>
              <DNAButton
                appearance="outline"
                status="tertiary"
                onPress={unreplaceGroup}
              >
                Select another replacement
              </DNAButton>
            </Iffy>
          </CollapsibleActionMenu>
          <DNABox
            fill
            appearance="row"
            alignX="center"
            spacing="md"
            wrap="start"
            style={S.cardContentContainer}
            onLayout={onLayout}
          >
            <FlatList
              data={pages}
              renderItem={renderItem}
              keyExtractor={keyExtractor}
            />
          </DNABox>
        </CollapsibleCard>
      </DNABox>
    </DNABox>
  )
}

/** RECYCLERLISTVIEW TYPES */
type RViewProps = {
  layoutProvider: LayoutProvider,
  dataProvider: DataProvider,
  rowRenderer: RecyclerListViewProps['rowRenderer'],
  extendedState: ExtendedState,
  style: ViewStyle,
  canChangeSize: boolean
  itemAnimator: RecyclerListViewProps['itemAnimator']
}

type ExtendedState = {
  isReplacedGroup: boolean,
  activeGroup?: ReplaceGroupDraft,
  documentVersionORM: DocumentVersionORM,
}

enum ItemType {
  GROUP,
  SLIDE,
  NONE,
}

const SlideSelector: React.FC = () => {
  const {
    service,
    thumbnailSize,
    thumbnailDimensions,
  } = useFindReplacementState();
  const {
    documentVersionORM,
    isReplacedGroup,
    allPageGroups,
    allSingleSlide,
    groupCardExpandState,
  } = useRightContentState();
  const dimensions = useWindowDimensions();
  const scrollViewRef = useRef<RecyclerListView<RViewProps, RecyclerListViewState>>(null)
  const [scrollTo, setScrollTo] = useState<number | undefined>(undefined);
  const [shouldScroll, setShouldScroll] = useState<boolean>(false);

  const cond = useSelector(
    service,
    (state) => composite(
      state,
      findReplacementSelector.activeGroup,
    ),
  );

  const dataProviderInit = useMemo(() => {
    return new DataProvider((r1: string, r2: string) => {
      return r1 !== r2
    })
  }, [])

  const replacedIndex = useMemo(() => {
    if (!isReplacedGroup || !cond.activeGroup?.groupReplacement) return -1
    else {
      const replacementId = cond.activeGroup?.groupReplacement?.groupSrcId || ''
      const isGroup = cond.activeGroup.groupReplacement.pages.length > 1
      return [...allPageGroups, ...allSingleSlide].findIndex(item => {
        if (isGroup) return (item as PageGroup).id === replacementId
        else return (item as Page).pageId === replacementId
      })
    }
  }, [isReplacedGroup, cond.activeGroup, allPageGroups, allSingleSlide])

  const layoutProvider = useMemo(() => {
    //  Right container width is 50% of window width minus 20px padding each side
    const containerWidth = (dimensions.width / 2) - 40;
    const thumbnailWidth = thumbnailDimensions.width;
    const numOfColumn = Math.floor(containerWidth / (thumbnailWidth + MIN_HORIZONTAL_PADDING))

    // If thumbnailSize is large, the thumbnail will scale dynamically based on containerWidth
    const slideWidth = thumbnailSize === 'lg'
      ? containerWidth
      : Math.floor((containerWidth - 3) / numOfColumn);

    // The extra 100px is slide footer/button
    const slideHeight = (thumbnailSize === 'lg'
      ? (Math.min(containerWidth, 650) * THUMBNAIL_RATIO)
      : (thumbnailWidth * THUMBNAIL_RATIO)
    ) + 100;

    const _layoutProvider = new LayoutProvider((idx) => {
      const isGroup = idx < allPageGroups.length
      const shouldHide = replacedIndex !== -1 && replacedIndex !== idx
      if (shouldHide) return ItemType.NONE;
      else if (isGroup) return ItemType.GROUP;
      else return ItemType.SLIDE;
    },
    (type, dim, idx) => {
      switch (type) {
        case ItemType.GROUP: {
          const isSelected = replacedIndex === idx
          const selectedCardHeight = isSelected ? 48 : 0
          dim.width = containerWidth;
          dim.height = GROUP_CARD_HEIGHT + 24 + groupCardExpandState[idx] + selectedCardHeight;
          break;
        }
        case ItemType.SLIDE: {
          const isSelected = replacedIndex === idx
          const selectedCardHeight = isSelected ? 48 : 0
          dim.width = slideWidth;
          dim.height = slideHeight + selectedCardHeight;
          break;
        }
        case ItemType.NONE: {
          dim.width = 0;
          dim.height = 0;
          break;
        }
      }
    })

    _layoutProvider.shouldRefreshWithAnchoring = false;
    return _layoutProvider;
  }, [
    dimensions,
    thumbnailSize,
    thumbnailDimensions,
    allPageGroups,
    allSingleSlide,
    groupCardExpandState,
    replacedIndex,
  ])

  // ROW DATA
  const dataProvider = useMemo(() => dataProviderInit.cloneWithRows([...allPageGroups, ...allSingleSlide]),
    [allPageGroups, allSingleSlide])

  // UI DATA (Highlights) - Optimize re-renders by not updating all other row components
  const extendedState = useMemo<ExtendedState>(() => ({
    isReplacedGroup,
    activeGroup: cond.activeGroup,
    documentVersionORM,
  }), [isReplacedGroup, cond.activeGroup, documentVersionORM])

  const rowRenderer = useCallback<RecyclerListViewProps['rowRenderer']>(
    // @ts-expect-error
    (type, data, idx, extendedState: ExtendedState) => {
      switch (type) {
        case ItemType.GROUP: {
          const pageGroup = data as PageGroup
          if (
            extendedState.isReplacedGroup &&
            extendedState.activeGroup?.groupReplacement?.groupSrcId !== data.id
          ) return null
          return (
            <GroupCard
              idx={idx}
              pageGroup={pageGroup}
              documentVersionORM={extendedState.documentVersionORM}
            />
          )
        }
        case ItemType.SLIDE: {
          const page = data as Page
          if (
            extendedState.isReplacedGroup &&
            extendedState.activeGroup?.groupReplacement?.groupSrcId !== page.pageId
          ) return null
          return (
            <Slide
              page={page}
              documentVersionORM={extendedState.documentVersionORM}
            />
          )
        }
        default: {
          return null
        }
      }
    },
    [layoutProvider, dataProvider])

  useEffect(() => {
    if (shouldScroll && scrollTo !== undefined) {
      if (scrollTo === -1) scrollViewRef.current?.scrollToEnd();
      else scrollViewRef.current?.scrollToIndex(scrollTo, true);
      setScrollTo(undefined)
      setShouldScroll(false)
    } else {
      const indexToScrollTo = calculateIndexToScrollTo(allPageGroups, allSingleSlide, cond.activeGroup)
      if (indexToScrollTo === -1) scrollViewRef.current?.scrollToEnd();
      else scrollViewRef.current?.scrollToIndex(indexToScrollTo, true);
    }
  }, [cond.activeGroup, allPageGroups, allSingleSlide, scrollTo, shouldScroll])

  const itemAnimator = useMemo((): RecyclerListViewProps['itemAnimator'] => {
    return {
      animateWillMount(_atX, _atY, _itemIndex) {
        return undefined;
      },
      animateDidMount(_atX, _atY, _itemRef, _itemIndex) {},
      animateWillUpdate(_fromX, fromY, _toX, toY, _itemRef, itemIndex) {
        // When viewing a replaced slide and then jumping to view one that has not been replaced,
        // We need this block to help calculate the correct scroll
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        if (scrollTo !== undefined && itemIndex === 1 && fromY === toY) setShouldScroll(true)
        else if (itemIndex === 1 && fromY === 0 && toY !== 0) {
          const indexToScrollTo = calculateIndexToScrollTo(allPageGroups, allSingleSlide, cond.activeGroup)
          setScrollTo(indexToScrollTo)
        }
      },
      animateShift(_fromX, _fromY, _toX, _toY, _itemRef, _itemIndex) {
        return false;
      },
      animateWillUnmount(_atX, _atY, _itemRef, _itemIndex) {},
    };
  }, [cond.activeGroup, allPageGroups, allSingleSlide, scrollTo]);

  const initialIndex = useMemo(() => {
    const indexToScrollTo = calculateIndexToScrollTo(allPageGroups, allSingleSlide, cond.activeGroup)
    if (indexToScrollTo === -1) return allPageGroups.length + allSingleSlide.length - 1;
    else return indexToScrollTo;
  }, [documentVersionORM, allPageGroups, allSingleSlide])

  return (
    <DNABox fill appearance="col">
      <RecyclerListView
        // WE NEED KEY HERE TO HELP RERENDER WHEN DOCUMENT CHANGES, SO THE SCROLLING WORKS CORRECTLY
        key={documentVersionORM.model.id}
        layoutProvider={layoutProvider}
        dataProvider={dataProvider}
        rowRenderer={rowRenderer}
        extendedState={extendedState}
        style={S.flex}
        canChangeSize
        ref={scrollViewRef}
        initialRenderIndex={initialIndex}
        itemAnimator={itemAnimator}
      />
    </DNABox>
  )
}

const RightContent: React.FC = () => {
  const {
    service,
    cycleThumbnailSize,
  } = useFindReplacementState();
  const [isReleaseNoteOpen, setIsReleaseNoteOpen] = useState(false)

  const cond = useSelector(
    service,
    (state) => composite(
      state,
      findReplacementSelector.activeGroup,
    ),
  );

  const activeDocumentVersion = cond.activeGroup?.documentVersionORM
  const latestPublishedDocumentVersionORM =
    activeDocumentVersion?.relations.documentORM.relations.version.latestPublishedDocumentVersionORM
  const hideComponent = !cond.activeGroup ||
    !SHOW_RIGHT_CONTENT_STATUSES.includes(cond.activeGroup.status) ||
    !latestPublishedDocumentVersionORM

  if (hideComponent) return null
  return (
    <DNABox fill appearance="col" style={S.rightContentContainer}>
      <DNABox appearance="col" style={S.cardContainer}>
        {/* TOP BAR */}
        <DNABox appearance="col" style={S.card}>
          <DNABox fill alignY="center">
            {/* LEFT SIDE */}
            <DNABox fill childFill={1} alignY="center" spacing="sm">
              <DNAChip status="success">NEW VERSION</DNAChip>
              <DNAText bold numberOfLines={1}>{latestPublishedDocumentVersionORM.model.title}</DNAText>
            </DNABox>
            {/* RIGHT SIDE */}
            <DNABox spacing="sm" alignY="center">
              <DNAButton
                testID="release-notes-btn"
                appearance="outline"
                status="tertiary"
                size="sm"
                padding="xs"
                onPress={() => setIsReleaseNoteOpen(p => !p)}
              >
                {isReleaseNoteOpen ? 'Hide' : 'Release notes'}
              </DNAButton>
              <DNAButton
                appearance="ghost"
                status="tertiary"
                padding="xs"
                iconLeft="view-grid"
                onPress={cycleThumbnailSize}
              />
            </DNABox>
          </DNABox>
          {/* RELEASE NOTE */}
          <Iffy is={isReleaseNoteOpen}>
            <DNABox style={S.releaseNoteContainer}>
              <DNAText status="flat">
                {latestPublishedDocumentVersionORM.model.releaseNotes}
              </DNAText>
            </DNABox>
          </Iffy>
        </DNABox>
      </DNABox>
      {/* MAIN CONTENT */}
      <ScrollView
        style={S.previewContainer}
        contentContainerStyle={S.flex}
        testID="find-replacement-right-content-scroll-view"
      >
        <DNABox alignX="center" fill>
          <RightContentStateProvider documentVersionORM={latestPublishedDocumentVersionORM}>
            <SlideSelector />
          </RightContentStateProvider>
        </DNABox>
      </ScrollView>
    </DNABox>
  )
}

export default RightContent
