import React, { useEffect } from 'react'
import { useDocVerSliceErrors } from 'src/state/redux/selector/root'
import { useToast, GenericToast, ToastOrientations } from '@alucio/lux-ui'

const ReduxMonitor: React.FC = () => {
  // [TODO] - Only monitors DocVer for now, but ideally it'd monitor all slices
  const error = useDocVerSliceErrors()
  const toast = useToast()

  useEffect(
    () => {
      if (error?.error) {
        toast.add(
          <GenericToast
            title={error.message ?? 'An error has occurred. please contact support'}
            status="error"
          />,
          ToastOrientations.TOP_RIGHT,
          true,
          0,
        )
      }
    },
    [error],
  )

  return null
}

export default ReduxMonitor
