import React from 'react';
import { StyleSheet } from 'react-native';
import { DNABox, DNAButton, DNAText, DNAIcon, Iffy } from '@alucio/lux-ui';
import useMachineSelector, { composite } from 'src/hooks/useSelector';
import * as slideSettingsSelector from 'src/state/machines/publisherVersioning/SlideSettings/slideSettings.selectors';
import { useSlideSettingsState as useSlideSettingsStateProxy }
  from 'src/state/machines/publisherVersioning/SlideSettings/SlideSettingsProvider.proxy';
import { styles } from '../../SlideSettings';

export const S = StyleSheet.create({
  headerStepTwoIcon: {
    marginLeft: 50,
  },
});

const Header: React.FC = () => {
  const {
    service,
    backToIdle,
    saveAssociatedSlides,
    navToPreviousStepAssociatedSlides,
    navToNextStepAssociatedSlides,
  } = useSlideSettingsStateProxy()

  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      slideSettingsSelector.isAddAssociatedSlidesStepOne,
      slideSettingsSelector.isAddAssociatedSlidesStepTwo,
      slideSettingsSelector.canGoToNextStepInAssociatedSlidesModal,
      slideSettingsSelector.canSaveAssociatedSlides,
    ),
  )

  return (
    <DNABox style={ styles.headerBar } spacing="between">
      <Iffy is={cond.isAddAssociatedSlidesStepOne}>
        <DNAButton
          appearance="outline"
          status="tertiary"
          padding="sm"
          onPress={backToIdle}
        >
          Cancel
        </DNAButton>
      </Iffy>
      <Iffy is={cond.isAddAssociatedSlidesStepTwo}>
        <DNAButton
          appearance="outline"
          status="tertiary"
          padding="sm"
          onPress={navToPreviousStepAssociatedSlides}
        >
          Back
        </DNAButton>
      </Iffy>
      <DNABox
        alignY="center"
        spacing="sm"
      >
        <DNAIcon.Styled
          appearance="outline"
          name={'numeric-1-box'}
          size="xl"
          status={cond.isAddAssociatedSlidesStepOne ? 'primary' : 'gray'}
        />
        <DNAText
          h4
          status={cond.isAddAssociatedSlidesStepOne ? 'primary' : 'subtle'}
          testID="add-associated-step-one"
        >
          Which slides are required?
        </DNAText>
        <DNAIcon.Styled
          appearance="outline"
          name={'numeric-2-box'}
          size="xl"
          status={cond.isAddAssociatedSlidesStepTwo ? 'primary' : 'gray'}
          style={S.headerStepTwoIcon}
        />
        <DNAText
          h4
          status={cond.isAddAssociatedSlidesStepTwo ? 'primary' : 'subtle'}
          testID="add-associated-step-two"
        >
          Where should they be attached?
        </DNAText>
      </DNABox>
      <Iffy is={cond.isAddAssociatedSlidesStepOne}>
        <DNAButton
          padding="sm"
          disabled={!cond.canGoToNextStepInAssociatedSlidesModal}
          onPress={navToNextStepAssociatedSlides}
        >
          Next
        </DNAButton>
      </Iffy>
      <Iffy is={cond.isAddAssociatedSlidesStepTwo}>
        <DNAButton
          padding="sm"
          disabled={!cond.canSaveAssociatedSlides}
          onPress={saveAssociatedSlides}
        >
          Save
        </DNAButton>
      </Iffy>
    </DNABox>
  )
}

export default Header;
