import { createMachine, spawn, InvokeCallback } from 'xstate';
import { v4 as uuid } from 'uuid';
import { Hub } from '@aws-amplify/core'
import { assign } from '@xstate/immer';
import isEmpty from 'lodash/isEmpty';
import { Auth } from '@aws-amplify/auth';
import * as Logger from './logger.types';
import {
  LoggerError,
  push,
  withStore,
  retentionConfig,
  peek,
  shift,
  patchAllLoggers,
  unpatchAllLoggers,
  callPushLogsLambda,
  createLog,
  truncateLogMessage,
} from './util'
import * as logger from 'src/utils/logger'
import { LogLevel } from './logger.types';

const MACHINE_MEMORY_LOG_LIMIT = 29
// UNIQUE_KEY_REGEX format "{timeStamp}_{uuid}"
const UNIQUE_KEY_REGEX = /^\d{13}_[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

export const loggerSM = createMachine(
  {
    id: 'LoggerSM',
    tsTypes: {} as import('./logger.machine.typegen').Typegen0,
    predictableActionArguments: true,
    preserveActionOrder: true,
    schema: {
      context: {} as Logger.SMContext,
      events: {} as Logger.SMEvents,
      services: {} as Logger.SMServices,
    },
    context: {
      deviceMode: undefined!,
      platform: undefined!,
      logs: [],
    },
    initial: 'prepare',
    states: {
      prepare: {
        after: {
          0: {
            target: 'determine',
            actions: ['spawnAuthActor'],
          },
        },
      },
      determine: {
        description: 'Check if user has signed in or not.',
        always: [
          {
            cond: 'isSignedIn',
            target: 'getUserData',
          },
          {
            target: 'idle',
          },
        ],
      },
      idle: {
        description: 'Waiting for user to sign in',
        on: {
          USER_SIGNED_IN: {
            target: 'getUserData',
          },
        },
      },
      getUserData: {
        invoke: {
          src: 'getUserData',
          onDone: {
            actions: 'setUser',
            target: 'getUserTimeZone',
          },
          onError: {
            actions: 'setErr',
            target: 'idle',
          },
        },
      },
      getUserTimeZone: {
        always: {
          actions: 'getUserTimeZone',
          target: 'ready',
        },
      },
      ready: {
        initial: 'prepare',
        states: {
          prepare: {
            after: {
              0: {
                actions: ['spawnLogActor', 'patchLogger'],
                target: 'processing',
              },
            },
          },
          processing: {
            tags: [Logger.StateTags.MACHINE_READY],
            type: 'parallel',
            states: {
              idle: {
                on: {
                  LOG: [
                    {
                      description: 'If user is online and an Error log sent through',
                      cond: 'shouldSendErrorLog',
                      actions: 'log',
                      target: 'sendErrorLog.active',
                    },
                    {
                      description: 'If user meets the machine log limit or user is offline and Error log sent through',
                      cond: 'shouldBatchStoreToIndexdb',
                      actions: ['log', 'moveLogToBatchStoreList'],
                      target: 'batchStore.active',
                    },
                    {
                      actions: 'log',
                    },
                  ],
                  SEND_LOGS: {
                    cond: 'isOnline',
                    target: 'batchSend.batchStore',
                  },
                },
              },
              batchStore: {
                initial: 'idle',
                states: {
                  idle: { },
                  active: {
                    invoke: {
                      src: 'batchStoreToIndexdb',
                      onDone: {
                        actions: 'clearLogsToBatchStore',
                        target: 'idle',
                      },
                    },
                  },
                },
              },
              batchSend: {
                initial: 'idle',
                states: {
                  idle: { },
                  batchStore: {
                    description: 'Store any remaining logs in the machine to idb store.',
                    entry: 'moveLogToBatchStoreList',
                    invoke: {
                      src: 'batchStoreToIndexdb',
                      onDone: {
                        actions: 'clearLogsToBatchStore',
                        target: 'active',
                      },
                    },
                  },
                  active: {
                    invoke: {
                      src: 'batchSendUserLogs',
                      onDone: {
                        actions: 'updateSentStatus',
                        target: 'successfullySentLogs',
                      },
                      onError: {
                        actions: 'restoreLogs',
                        target: 'failedToSendLogs',
                      },
                    },
                  },
                  successfullySentLogs: {
                    tags: [Logger.StateTags.SUCCESSFULLY_SENT_LOGS],
                    after: {
                      3000: { target: 'idle' },
                    },
                  },
                  failedToSendLogs: {
                    tags: [Logger.StateTags.FAILED_TO_SEND_LOGS],
                    after: {
                      3000: { target: 'idle' },
                    },
                  },
                },
              },
              sendErrorLog: {
                initial: 'idle',
                states: {
                  idle: { },
                  active: {
                    invoke: {
                      src: 'sendErrorLogToCloudWatch',
                      onDone: {
                        actions: 'sendErrorLogResult',
                        target: 'idle',
                      },
                      onError: {
                        actions: 'sendErrorLogResult',
                        target: 'idle',
                      },
                    },
                  },
                },
              },
            },
            on: {
              USER_SIGNING_OUT: {
                target: 'signingOut',
              },
              USER_SIGNED_OUT: {
                target: 'cleanUp',
              },
            },
          },
          signingOut: {
            description: 'Transition state to avoid interacting with IndexDB while signing out',
            on: {
              USER_SIGNED_OUT: {
                target: 'cleanUp',
              },
            },
          },
          cleanUp: {
            always: {
              description: 'We clear the indexdb data in the logout function.',
              actions: [
                'unpatchLogger',
                'clearOutUserData',
                'cleanLogActor',
              ],
              target: '#LoggerSM.idle',
            },
          },
        },
      },
    },
  },
  {
    guards: {
      shouldBatchStoreToIndexdb: (ctx, evt) => {
        return ctx.logs.length >= MACHINE_MEMORY_LOG_LIMIT || evt.payload.logLevel === LogLevel.ERROR
      },
      isOnline: () => {
        return navigator.onLine
      },
      isSignedIn: () => {
        return localStorage.getItem('amplify-authenticator-authState') === 'signedIn';
      },
      shouldSendErrorLog: (_ctx, evt) => {
        return evt.payload.logLevel === LogLevel.ERROR && navigator.onLine
      },
    },
    actions: {
      patchLogger: (ctx, _evt) => {
        const logFunction = (loggerLevel: LogLevel, logLevel: LogLevel, logCategory: string, logMessage: any[]) => {
          ctx.logActor?.send({ type: 'LOG', payload: { loggerLevel, logLevel, logCategory, logMessage } })
        }

        patchAllLoggers(logFunction)
      },
      unpatchLogger: () => {
        unpatchAllLoggers()
      },
      setUser: assign((ctx, evt) => {
        ctx.user = evt.data
      }),
      getUserTimeZone: assign((ctx) => {
        // Get the current date
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const _now = new Date();
        // Use Intl.DateTimeFormat to get the time zone
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        ctx.timeZone = timeZone
      }),
      log: assign((ctx, evt) => {
        try {
          const { logPayload, key } = createLog(ctx, evt)

          const newLog: Logger.Log = {
            key,
            payload: logPayload,
          }

          ctx.logs = [...ctx.logs, newLog]
        } catch (e) {
          const errorMessage = [
            // [TODO] - Check the error message before assuming it's a circular dep error
            // eslint-disable-next-line max-len
            'An unexpected error occurred while trying to save log data, please make sure to remove any object that contains circular dependencies',
            {
              logCategory: evt.payload.logCategory,
              logMessage: evt.payload.logMessage,
            },
            e,
          ]
          logger.loggerMachine.error(...errorMessage)
        }
      }),
      moveLogToBatchStoreList: assign((ctx) => {
        ctx.logsToBatchStore = [...ctx.logs]
        ctx.logs = []
      }),
      clearLogsToBatchStore: assign((ctx) => {
        ctx.logsToBatchStore = []
      }),
      setErr: assign((ctx, evt) => {
        if (evt.data instanceof LoggerError) {
          ctx.err = evt.data
        } else {
          ctx.err = new LoggerError('UNKNOWN_ERROR', 'Failed to set in machine')
        }
      }),
      updateSentStatus: (ctx) => {
        logger.loggerMachine.info('User logs sent successfully')
        ctx.logsToBatchSend = undefined
      },
      restoreLogs: assign((ctx) => {
        logger.loggerMachine.info(`restoring ${ctx.logsToBatchSend ? ctx.logsToBatchSend.length : 0} log(s)...`)
        if (ctx.logsToBatchSend) {
          const logsToRestore = ctx.logsToBatchSend.map(string => {
            const logPayload = JSON.parse(string) as Logger.logPayload;
            const uniqueKey = `${Date.parse(logPayload.timeStamp)}_${uuid()}`;
            return { key: uniqueKey, payload: logPayload }
          })
          ctx.logs = [...ctx.logs, ...logsToRestore]
          ctx.logsToBatchSend = undefined
        }
      }),
      clearOutUserData: assign((ctx) => {
        ctx.logs = [];
        ctx.logsToBatchSend = [];
        ctx.logsToBatchStore = [];
        ctx.timeZone = undefined;
        ctx.user = undefined;
      }),
      spawnAuthActor: assign((ctx) => {
        if (!ctx.authActor) {
          const authActor: InvokeCallback<
            any,
            Logger.EVT_USER_SIGNED_IN | Logger.EVT_USER_SIGNED_OUT
          > = (send) => {
            const unregister = Hub.listen('auth', (capsule) => {
              switch (capsule.payload.event) {
                case 'signIn': {
                  send('USER_SIGNED_IN')
                  break;
                }
                case 'signedOut': {
                  send('USER_SIGNED_OUT')
                  break;
                }
              }
            })

            return unregister
          }
          const authActorInstance = spawn(authActor, 'authActor')
          ctx.authActor = authActorInstance
        }
      }),
      spawnLogActor: assign((ctx) => {
        if (!ctx.logActor) {
          const callback: InvokeCallback<Logger.EVT_LOG, Logger.EVT_LOG> = (send, receive) => {
            receive(send)
          }

          ctx.logActor = spawn(
            callback,
            'logActor',
          );
        }
      }),
      cleanLogActor: assign((ctx) => {
        if (ctx.logActor) {
          ctx.logActor.stop?.()
          ctx.logActor = undefined
        }
      }),
      sendErrorLogResult: assign((_ctx, evt) => {
        const result = evt.data ? 'successful' : 'failed'
        logger.loggerMachine.info(`Send error log to CloudWatch ${result}`)
      }),
    },
    services: {
      getUserData: async (_ctx) => {
        /** Retrieve current cognito user */
        try {
          const data = await Auth.currentSession()
          if (!data || !data?.getIdToken) {
            throw new LoggerError('UNKNOWN_ERROR', 'Failed to fetch current session')
          }
          return data.getIdToken().payload as Logger.User
        } catch (err: any) {
          throw new LoggerError(err)
        }
      },
      batchStoreToIndexdb: async (ctx) => {
        try {
          ctx.logsToBatchStore?.forEach(log => {
            push(log, retentionConfig, withStore);
          })
        } catch (err: any) {
          throw new LoggerError(err)
        }
      },
      batchSendUserLogs: async (ctx) => {
        // According to AWS Cloudwatch documentation:
        // A batch of log events in a single request cannot span more than 24 hours. Otherwise, the operation fails.
        // So we need to ensure that the logs are split into batches and each batch only contains logs within a 24-hour period.
        logger.loggerMachine.info('attemping to batch send user logs...')
        const batchSizeLimit = 4 * 1024 * 1024; // 4MB
        const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // 24 hours
        let itemsToSend: string[] = []
        let totalPayloadSize = 0;
        let attempt = 0;
        let firstLogTimeStamp: number | null = null;

        const rawItemArray = await peek(1, withStore) as Logger.Log[];
        let rawItem = rawItemArray[0]
        let range = 1
        while (!isEmpty(rawItem)) {
          // Validate the user that enqueued it is the same that send the event
          const item = rawItem.payload;
          const sanitizedPayload = JSON.stringify(item);
          if (ctx.user?.email === item.email) {
            const itemPayloadSize = Buffer.byteLength(sanitizedPayload, 'utf-8');
            // Extract and parse the timeStamp from key "{timeStamp}_{uuid}"
            if (!UNIQUE_KEY_REGEX.test(rawItem.key)) {
              logger.loggerMachine.warn(
                `Invalid key format: ${rawItem.key}, discarding log...`,
                { logCategory: item.logCategory, truncateLogMessage: truncateLogMessage(item.logMessage[0], 200) },
              );
              await shift<Logger.Log>(1, withStore);
              const newRawItemArray = await peek(1, withStore) as Logger.Log[];
              rawItem = newRawItemArray[0];
              continue;
            }
            const itemTimeStamp = parseInt(rawItem.key.split('_')[0], 10);

            // Initialize the timestamp for the first log in the batch
            if (firstLogTimeStamp === null) firstLogTimeStamp = itemTimeStamp;

            // Check if adding the log item would exceed the batch size limit or 24-hour span
            const isBatchSizeExceeded = totalPayloadSize + itemPayloadSize > batchSizeLimit;
            const is24HourSpanExceeded = (itemTimeStamp - firstLogTimeStamp) > oneDayInMilliseconds;
            if (isBatchSizeExceeded || is24HourSpanExceeded) {
              // Batch size exceeded or logs span more than 24 hours, send the current batch
              ctx.logsToBatchSend = itemsToSend;
              try {
                attempt++
                logger.loggerMachine.info(`${attempt} attempt to pushLogs, payloadSize: ${totalPayloadSize}`)
                const result = await callPushLogsLambda(itemsToSend)
                logger.loggerMachine.info(`result of ${attempt} attempt: ${result?.pushLogs}`)
                if (result?.pushLogs === false) throw new LoggerError('UNKNOWN_ERROR', 'Failed to send logs')
              } catch (err: any) {
                throw new LoggerError(err)
              }

              // Reset for the next batch
              itemsToSend = [];
              totalPayloadSize = 0;
              firstLogTimeStamp = itemTimeStamp;
            }

            await shift<Logger.Log>(1, withStore);
            // If a single log size exceed batchSizeLimit, we want to just discard it
            if (itemPayloadSize >= batchSizeLimit) {
              logger.loggerMachine.warn(
                `Item payload size ${itemPayloadSize} exceed batch side limit ${batchSizeLimit}, discarding log...`,
                { logCategory: item.logCategory, truncateLogMessage: truncateLogMessage(item.logMessage[0], 200) },
              );
            }
            else {
              itemsToSend.push(sanitizedPayload)
              totalPayloadSize += itemPayloadSize
            }
            const newRawItemArray = await peek(1, withStore) as Logger.Log[];
            rawItem = newRawItemArray[0]
          } else {
            range++
            const newRawItemArray = await peek(range, withStore) as Logger.Log[];
            rawItem = newRawItemArray[range - 1]
          }
        }
        if (itemsToSend.length) {
          ctx.logsToBatchSend = itemsToSend;
          try {
            attempt++
            logger.loggerMachine.info(`${attempt} attempt to pushLogs, payloadSize: ${totalPayloadSize}`)
            const result = await callPushLogsLambda(itemsToSend)
            logger.loggerMachine.info(`result of ${attempt} attempt: ${result?.pushLogs}`)
            if (result?.pushLogs === false) throw new LoggerError('UNKNOWN_ERROR', 'Failed to send logs')
          } catch (err: any) {
            throw new LoggerError(err)
          }
        }
      },
      sendErrorLogToCloudWatch: async (ctx, evt) => {
        const { logPayload } = createLog(ctx, evt, true)

        try {
          const sanitizedPayload = JSON.stringify(logPayload)
          logger.loggerMachine.info('Attemping to send error log to CloudWatch...')
          const result = await callPushLogsLambda([sanitizedPayload], true)
          if (result?.pushLogs === false) throw new LoggerError('UNKNOWN_ERROR', 'Failed to send logs')
          return result?.pushLogs
        } catch (err: any) {
          throw new LoggerError(err)
        }
      },
    },
  },
)

export default loggerSM
