import React from 'react';
import { StyleSheet } from 'react-native';
import { DNABox, DNAButton, DNAText, Iffy, util } from '@alucio/lux-ui';
import { useMediaQuery } from 'react-responsive';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail';
import { getSlideTitle, getThumbURL } from 'src/utils/thumbnailHelpers';
import { usePublisherVersioningState } from 'src/state/machines/publisherVersioning/PublisherVersioningProvider';
import { useMatchSlidesState } from 'src/state/machines/publisherVersioning/MatchSlides/MatchSlidesProvider';
import useMachineSelector, { composite } from 'src/hooks/useSelector';
import * as matchSlidesSelector from 'src/state/machines/publisherVersioning/MatchSlides/matchSlides.selector';
// Dnd
import { DroppableContainer, SortableItem, TargetItem, useSingleItemDnd } from 'src/components/DnD/DNASingleItemDnd';
import { useDispatch } from 'src/state/redux';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import MatchSlidesPreviewModal from '../MatchSlidesPreviewModal';
import { thumbnailSizeDimensions } from 'src/hooks/useThumbnailSize/useThumbnailSize';
import DNAPopover from 'src/components/DNA/Popover/DNAPopover';

const S = StyleSheet.create({
  emptyMatchingContainer: {
    width: thumbnailSizeDimensions.lg.width,
    height: thumbnailSizeDimensions.lg.height,
    borderWidth: 1,
    borderColor: colors['color-gray-200'],
    borderRadius: 4,
    backgroundColor: colors['color-gray-10'],
  },
  emptyMatchingContainerEditMode : {
    borderStyle: 'dashed',
    borderWidth: 3,
  },
  hoverStateEmptyMatchingContainer: {
    borderColor: colors['color-primary-500'],
  },
  emptyMatchingContainerText: {
    color: colors['color-text-tertiary'],
  },
  emptyOverlay: {
    position: 'absolute',
    top: 4,
    right: 4,
  },
  revertButton: {
    opacity: 0.64,
  },
  matchSlidePopover:{
    color: colors['color-text-white'],
    marginHorizontal: 2,
    marginVertical: 2,
  },
})

interface EmptySlideContainerProps {
  pageId: string,
  index: number,
}

interface SelectedMatchingSlideProps {
  matchingSlide: TargetItem[],
  containerId: string,
  newPageNumber: number,
}

interface PreviousVersionContainerProps {
  pageId: string,
  index: number,
  newPageNumber: number,
}

const EmptySlideContainer: React.FC<EmptySlideContainerProps> = ({ pageId, index }) => {
  const {
    service,
    onScrollToIndexInSlideGroupPool,
    onRevert,
    canRevert,
    overContainer,
  } = useMatchSlidesState();
  const { groupedTargetItems } = useSingleItemDnd();
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1300px)' })
  const enableRevert = canRevert(pageId)

  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      matchSlidesSelector.slidesGroupPoolVisible,
    ),
  )

  return (
    <>
      <DNABox
        fill
        appearance="col"
        alignX="center"
        alignY="center"
        spacing="md"
        style={util.mergeStyles(
          undefined,
          S.emptyMatchingContainer,
          [S.emptyMatchingContainerEditMode, cond.slidesGroupPoolVisible],
          [S.hoverStateEmptyMatchingContainer, overContainer === pageId],
          { width: isLargeScreen ? thumbnailSizeDimensions.lg.width : thumbnailSizeDimensions.md.width },
          { height: isLargeScreen ? thumbnailSizeDimensions.lg.height : thumbnailSizeDimensions.md.height },
        )}
      >
        <DNABox fill appearance="col" alignX="center" spacing="sm">
          <DNAText b1 style={S.emptyMatchingContainerText}>
            Drag slide here if there's a match
          </DNAText>
        </DNABox>
        <DNAButton
          onPress={() => onScrollToIndexInSlideGroupPool(index)}
          appearance="outline"
        >
          Find slide
        </DNAButton>
      </DNABox>
      <Iffy is={enableRevert}>
        <DNABox style={S.emptyOverlay} fill>
          <DNAPopover placement="left">
            <DNAPopover.Anchor>
              <DNAButton
                appearance="filled"
                status="dark"
                size="md"
                iconLeft="undo"
                padding="sm"
                onPress={() => onRevert(groupedTargetItems, pageId)}
                style={S.revertButton}
              />
            </DNAPopover.Anchor>
            <DNAPopover.Content>
              <DNAText style={S.matchSlidePopover}>
                Revert to original
              </DNAText>
            </DNAPopover.Content>
          </DNAPopover>
        </DNABox>
      </Iffy>
    </>
  )
}

const SelectedMatchingSlide: React.FC<SelectedMatchingSlideProps> = ({ matchingSlide, containerId, newPageNumber }) => {
  const {
    latestContentPageData,
    latestPublishedContentPageData,
  } = usePublisherVersioningState();
  const {
    currentDocumentVersionORM,
    latestPublishedDocumentVersionORM,
  } = useMatchSlidesState();
  const { groupedTargetItems } = useSingleItemDnd()
  const { onUnmatch, onRevert, canRevert } = useMatchSlidesState()
  const dispatch = useDispatch()
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1300px)' })

  const togglePreviewModal = ({ previousPageNum }) => {
    dispatch(DNAModalActions.setModal({
      isVisible: true,
      allowBackdropCancel: true,
      component: (props) => (
        <MatchSlidesPreviewModal
          newDocumentVersionORM={currentDocumentVersionORM}
          previousDocumentVersionORM={latestPublishedDocumentVersionORM}
          newPageNum={newPageNumber}
          previousPageNum={previousPageNum}
          latestContentPageData={latestContentPageData}
          latestPublishedContentPageData={latestPublishedContentPageData}
          currentDocumentVersionORM={currentDocumentVersionORM}
          {...props}
        />
      ),
    }))
  }

  return (
    <>
      {
        matchingSlide.map(({ id, itemId }, idx) => {
          // since itemid or id could be duplicated if we add the same slide multiple times
          // we need to add a unique key to the sortable item
          // so that it can be sorted and remove correctly if whe have the same slides multiple times
          // the keys will be itemId, itemId-2, etc
          const slideLength = matchingSlide.slice(0, idx + 1).filter(i => i.itemId === itemId).length
          const key = slideLength > 1 ? `${itemId}-${slideLength}` : `${itemId}`
          const previousPageNum = Number(itemId.split('_').pop() ?? 0);
          const newPageNum = Number(groupedTargetItems[containerId][0]?.itemId.split('_').pop() ?? 0);
          const enableRevert = canRevert(containerId, itemId)

          const thumbnailTitle = getSlideTitle(
            latestPublishedDocumentVersionORM,
            newPageNum,
            latestPublishedContentPageData,
          )

          return (
            <SortableItem
              key={key}
              id={id}
              itemId={itemId}
              containerId={containerId}
            >
              <DNABox appearance="col">
                <DNAThumbnail.Lazy
                  s3URL={getThumbURL(latestPublishedDocumentVersionORM, previousPageNum)}
                  useLoadingIndicator
                  size={isLargeScreen ? 'lg' : 'md'}
                  mode={DNAThumbnail.Modes.REMOVABLE_AND_PREVIEW}
                  thumbnailTitle={thumbnailTitle}
                  variant={DNAThumbnail.Variants.MATCH_SLIDE}
                  onClose={() => onUnmatch(containerId)}
                  pageNumber={previousPageNum}
                  onCheck={() => togglePreviewModal({
                    previousPageNum,
                  })}
                  onRevert={enableRevert ? () => onRevert(groupedTargetItems, containerId) : undefined}
                  style={{ margin: 0 }}
                />
              </DNABox>
            </SortableItem>
          )
        })
      }
    </>
  )
}

const PreviousVersionContainer: React.FC<PreviousVersionContainerProps> = ({ pageId, index, newPageNumber }) => {
  const { activeId, groupedTargetItems } = useSingleItemDnd();
  const { mappedValues } = usePublisherVersioningState();
  const debugMode = localStorage.getItem('debugMode') === 'true';
  const matchingSlide: TargetItem[] | undefined = groupedTargetItems[pageId]

  // Disable dropable container when it contains item and NOT during dragging.
  // So user cannot drag item within this container but only able to replace it during dnd.
  const disableDropable = !!matchingSlide?.length && !activeId

  return (
    <DroppableContainer
      id={pageId}
      items={matchingSlide}
      strategy={'verticalListSortingStrategy'}
      disabled={disableDropable}
    >
      <Iffy is={matchingSlide?.length}>
        <SelectedMatchingSlide matchingSlide={matchingSlide} containerId={pageId} newPageNumber={newPageNumber} />
      </Iffy>
      <Iffy is={!matchingSlide?.length}>
        <EmptySlideContainer pageId={pageId} index={index} />
      </Iffy>
      { debugMode && (<DNAText>{`mapping: ${mappedValues[pageId].mapping}`}</DNAText>) }
    </DroppableContainer>
  )
}

export default PreviousVersionContainer
