import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FlatList, ScrollView, StyleSheet, useWindowDimensions } from 'react-native';
import { DNABox, DNAButton, DNASelect, DNAText, Iffy, IndexPath, useMouseEvent } from '@alucio/lux-ui';
import ThumbnailFooterButton from '../Thumbnail/ThumbnailFooterButton';
import { useAppSettings } from 'src/state/context/AppSettings';
import {
  isPageGroupORM,
  Presentable,
  PresentablePage,
  ORMTypes,
  isFolderItemORM,
  DocumentVersionORM,
} from 'src/types/types';
import DNASlideRollThumbnail from './DNASlideRollThumbnail';
import { SLIDE_ROLL_VARIANT } from './StyleSetting';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { FileType, HubSectionItemStatus, PresentedMeta } from '@alucio/aws-beacon-amplify/src/models';
import { PresentableModelORM } from 'src/state/context/ContentProvider/ContentProvider';
import { useIsSlideRollVisible } from './useIsSlideRollVisible';
import useContentPageData, { getSlideContentPageDataTitle } from 'src/hooks/useContentPageData/useContentPageData';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import { NOTATION_TYPE } from '@alucio/aws-beacon-amplify/src/API';

const styles = StyleSheet.create({
  closeButtonContainer: {
    marginTop: 12,
    marginBottom: 12,
    borderRadius: 4,
    backgroundColor: colors['color-gray-600'],
  },
});

interface SlideRollProps {
  activeSlide: PresentablePage,
  addPresentation?: (item: PresentableModelORM, pageNumber?: number) => void,
  closeButtonOnPress?: () => void,
  insertTextBtnOnPress?: () => void,
  horizontal?: boolean,
  ignoreSelected?: boolean,
  isContentUnavailable?: boolean,
  isGridView?: boolean,
  itemHeight?: number,
  itemWidth?: number,
  onSelect?: (presentationNumber: number) => void,
  presentable: Presentable,
  presentedMeta?: PresentedMeta[],
  scrollToSelected?: boolean,
  showPreview?: boolean,
  variant?: SLIDE_ROLL_VARIANT,
}

/**
 * Hook to handle item dimensions for the SlideRoll component.
 * This hook is responsible for measuring the actual dimensions of the items
 * after they have been rendered, ensuring accurate layout calculations.
 */
const useItemDimensions = (showNumOfColumns: number, horizontal: boolean, itemWidth?: number, itemHeight?: number) => {
  /**
   * Ref to store actual measured item dimensions.
   * This is used to provide accurate measurements for FlatList's getItemLayout
   * instead of relying on fixed/estimated values which can vary across different contexts.
   */
  const itemDimensionsRef = useRef<{ width: number; height: number; spacing: number } | null>(null);

  /**
   * Callback to store measured dimensions of rendered items.
   * Called after the first item renders to capture actual dimensions including margins/padding.
   */
  const measureItemDimensions = useCallback((width: number, height: number, spacing: number) => {
    // Only store dimensions once to maintain consistent measurements throughout component lifecycle
    // This prevents any potential layout shifts that could occur from re-measuring
    if (!itemDimensionsRef.current) {
      itemDimensionsRef.current = { width, height, spacing };
    }
  }, []);

  const handleLayout = useCallback(({ nativeEvent }) => {
    const { layout } = nativeEvent;
    // Validate that the item has actual dimensions before measuring
    // This check ensures we only store valid measurements and avoid zero/invalid dimensions
    const isValidItem = layout.width > 0 && layout.height > 0
    // Only measure and store dimensions once, when we have valid measurements
    // This optimization prevents unnecessary recalculations since item dimensions rarely change
    if (isValidItem && !itemDimensionsRef.current) {
      // Measure actual dimensions after render to ensure accurate layout calculations
      measureItemDimensions(layout.width, layout.height, 6);
    }
  }, [measureItemDimensions]);

  /**
   * Provides layout information to FlatList for optimized rendering and scrolling.
   * Uses actual measured dimensions when available, falling back to estimates if items haven't rendered yet.
   */
  const getItemLayout = useCallback((_, index) => {
    const itemSize = itemDimensionsRef.current || {
      width: itemWidth || 200,
      height: itemHeight || 150,
      spacing: 6,
    };

    const length = horizontal ? itemSize.width + itemSize.spacing : itemSize.height + itemSize.spacing;
    return {
      length,
      offset: length * Math.floor(index / showNumOfColumns),
      index,
    };
  }, [horizontal, itemHeight, itemWidth, showNumOfColumns]);

  return {
    handleLayout,
    measureItemDimensions,
    getItemLayout,
  };
};

const DNASlideRoll: React.FC<SlideRollProps> = ({
  activeSlide,
  isContentUnavailable,
  scrollToSelected = true,
  ignoreSelected,
  isGridView,
  itemHeight,
  itemWidth,
  onSelect,
  presentedMeta = [],
  presentable,
  showPreview,
  horizontal = true,
  variant = SLIDE_ROLL_VARIANT.DEFAULT,
  closeButtonOnPress,
  insertTextBtnOnPress,
  addPresentation,
}) => {
  const enableSlideTextInsertion = useFeatureFlags('BEAC_6389_slide_text_insertion');
  const { deviceMode } = useAppSettings();
  const isTablet = deviceMode === 'tablet';
  const flatListRef = useRef<FlatList<PresentablePage> | null>(null);
  const dimensions = useWindowDimensions();
  const [showNumOfColumns, setShowNumOfColumns] = useState<1 | 2 | 3>(1);
  const hasPageGroup = !!activeSlide.documentVersionORM.relations.pageGroups.length
  const isCustomDeck = isFolderItemORM(presentable.orm) && presentable.orm.model.type === ORMTypes.CUSTOM_DECK
  const groups = [...activeSlide.documentVersionORM.relations.pageGroups]
    .sort((a, b) => a.model.name.localeCompare(b.model.name))
  const selectedIndex: IndexPath = useMemo(() => {
    if (isPageGroupORM(presentable.orm)) {
      const group = presentable.orm.model
      const index = groups.findIndex(g => g.model.id === group?.id)
      return new IndexPath(index + 1)
    }
    return new IndexPath(0)
  }, [presentable.orm])
  const { isIntersecting, setObserverRef } = useIsSlideRollVisible();
  const { contentPageData } = useContentPageData(presentable, true)
  const onGroupSelect = e => {
    if (groups) {
      const { row } = e
      if (row === 0) {
        if (addPresentation) {
          addPresentation(activeSlide.documentVersionORM)
        }
      } else {
        if (addPresentation) {
          const group = groups[row - 1]
          addPresentation(group)
        }
      }
    }
  }

  const selectedValue = () => {
    if (selectedIndex.row === 0) {
      return 'All slides'
    } else if (groups && groups.length && isPageGroupORM(presentable.orm)) {
      return groups[selectedIndex.row - 1].model.name
    }
  }
  const groupOptions = groups?.map(group => {
    return (
      <DNASelect.Item
        key={group.model.id}
        title={group.model.name}
      />
    )
  })

  const { handleLayout, getItemLayout } = useItemDimensions(
    showNumOfColumns,
    horizontal,
    itemWidth,
    itemHeight,
  );

  const scrollToItem = useCallback((): void => {
    if (scrollToSelected && flatListRef.current) {
      const index = activeSlide.presentationPageNumber - 1;
      // Calculate actual index in grid layout
      const indexInList = Math.floor(index / showNumOfColumns);

      flatListRef.current.scrollToIndex({
        index: indexInList,
        animated: true,
        viewPosition: 0,
      });
    }
  }, [activeSlide.presentationPageNumber, scrollToSelected, showNumOfColumns]);

  useEffect(scrollToItem, [activeSlide, presentable.presentablePages]);

  useEffect(() => {
    isIntersecting && setTimeout(scrollToItem, 500);
  }, [isIntersecting, activeSlide, presentable.presentablePages, showNumOfColumns])

  const handleMouseEvent = useCallback(
    (e: MouseEvent | Event | TouchEvent) => {
      if (e instanceof WheelEvent) {
        const scrollEl = flatListRef.current?.getScrollableNode() as HTMLElement
        if (scrollEl) { scrollEl.scrollLeft += e.deltaY }
      }
    },
    [],
  )
  useMouseEvent(handleMouseEvent);

  const renderThumbnail = ({ item: page }: { item: PresentablePage }) => {
    const title = contentPageData && contentPageData.length > 0
      ? getSlideContentPageDataTitle(page.presentationPageNumber - 1, contentPageData) : ''
    const isSelected = !ignoreSelected && activeSlide.presentationPageNumber === page.presentationPageNumber;
    const presentedSlide = presentedMeta?.find(({ pageId }) => pageId === page.page.pageId);
    function onSelectPage(): void {
      onSelect?.(page.presentationPageNumber);
    }
    const tintOverlay = presentedMeta && presentedMeta.length > 0
    const visibleTypeBadges:DocumentVersionORM['model']['type'][] = [FileType.WEB, FileType.HTML, FileType.MP4]
    const isVisibleTypeBadge =
      visibleTypeBadges.includes(page.documentVersionORM.model.type) &&
      isCustomDeck

    // [TODO]: Verify with product if, regardless of the context, the icon should always be displayed if the slide has notes.
    //         For some reason, the activePresentation's object (ContentProvider) might not accurately have them.
    const hasNotation = !!presentable.notations?.find(notation => {
      return notation.pageId === page.page.pageId &&
        notation.status === HubSectionItemStatus.ACTIVE &&
        notation.type === NOTATION_TYPE.CALLOUT
    });

    const showInsertTextBtn = enableSlideTextInsertion &&
      isCustomDeck &&
      insertTextBtnOnPress &&
      !!contentPageData?.[page.presentationPageNumber - 1]?.enableSlideTextInsertion;

    const onInsertTextButtonPressed = () => {
      onSelectPage()
      insertTextBtnOnPress && insertTextBtnOnPress()
    }

    return (
      <DNABox
        onLayout={handleLayout}
        appearance="col"
        spacing={showInsertTextBtn ? 'sm' : undefined}
        style={{ marginBottom: isGridView ? 10 : 0 }}
      >
        <DNASlideRollThumbnail
          key={page.presentationPageNumber}
          isContentUnavailable={isContentUnavailable}
          isTablet={isTablet}
          page={page}
          variant={variant}
          presentedMeta={presentedSlide}
          isSelected={isSelected}
          height={itemHeight}
          width={itemWidth}
          onSelect={onSelectPage}
          showPreview={showPreview}
          tintOverlay={tintOverlay}
          title={title}
          fileTypeVisible={isVisibleTypeBadge}
          hasNotation={hasNotation}
        />
        <Iffy is={showInsertTextBtn}>
          <ThumbnailFooterButton
            variant="InsertText"
            onPress={onInsertTextButtonPressed}
          />
        </Iffy>
      </DNABox>
    );
  };

  const firstOption = <DNASelect.Item key="allSlides" title="All slides" />

  groupOptions?.unshift(firstOption)

  const onToggleSlideWidth = useCallback(() => {
    if (showNumOfColumns !== 1) setShowNumOfColumns(1)
    else if (dimensions.width > 1072) setShowNumOfColumns(3)
    else setShowNumOfColumns(2)
  }, [dimensions, showNumOfColumns])

  useEffect(() => {
    if (dimensions.width < 720 && showNumOfColumns !== 1) setShowNumOfColumns(1)
    else if (dimensions.width < 1072 && showNumOfColumns !== 1) setShowNumOfColumns(2)
  }, [dimensions])

  if (isGridView) {
    return (
      <ScrollView>
        <DNABox wrap="start" spacing="md">
          {presentable.presentablePages.map((page) => renderThumbnail({
            item: page,
          }))}
        </DNABox>
      </ScrollView>
    );
  }
  const isInpreviewModal =
    [SLIDE_ROLL_VARIANT.PREVIEW_MODAL, SLIDE_ROLL_VARIANT.MEETING_PRESENTED_EMPTY].includes(variant)

  return (
    <DNABox fill={isTablet} appearance="col" >
      <DNABox alignX="end" spacing="sm">
        <Iffy is={!isInpreviewModal}>
          <DNABox
            testID="slide-roll-exit-button"
            appearance="row"
            alignX="center"
            alignY="center"
            style={styles.closeButtonContainer}
          >
            <DNAButton
              status="gray"
              size="lg"
              appearance="ghostLink"
              padding="none"
              iconLeft={ showNumOfColumns === 1 ? 'chevron-double-right' : 'chevron-double-left'}
              onPress={onToggleSlideWidth}
            />
          </DNABox>
        </Iffy>
        <DNABox
          testID="slide-roll-exit-button"
          appearance="row"
          alignX="center"
          alignY="center"
          style={[styles.closeButtonContainer, isInpreviewModal && { backgroundColor: colors['color-text-white'] }]}
        >
          <DNAButton
            status={isInpreviewModal ? 'tertiary' : 'gray'}
            size="lg"
            appearance={isInpreviewModal ? 'outline' : 'ghostLink'}
            padding={isInpreviewModal ? 'xs' : 'none'}
            iconLeft="close"
            onPress={closeButtonOnPress}
          />
        </DNABox>
      </DNABox>
      <Iffy is={variant === SLIDE_ROLL_VARIANT.MEETING_PRESENTED_EMPTY}>
        <DNABox style={{ width: 248 }}>
          <DNAText status="flatAlt">No presented slides</DNAText>
        </DNABox>
      </Iffy>
      <Iffy is={!isCustomDeck && hasPageGroup && variant === SLIDE_ROLL_VARIANT.MEETINGS}>
        <DNABox alignY="center" childFill>
          <DNASelect
            style={{ marginBottom: 10, width: 340 }}
            selectedIndex={selectedIndex}
            value={selectedValue()}
            onSelect={onGroupSelect}
          >
            {groupOptions}
          </DNASelect>
        </DNABox>
      </Iffy>
      <Iffy is={variant !== SLIDE_ROLL_VARIANT.MEETING_PRESENTED_EMPTY}>
        <FlatList
          key={`slide-roll-${showNumOfColumns}`}
          scrollEnabled={true}
          scrollEventThrottle={200}
          keyExtractor={(item) => `${item.id}-${item.presentationPageNumber}`}
          ref={(e) => {
            flatListRef.current = e;
            setObserverRef(e);
          }}
          ItemSeparatorComponent={() => <DNABox style={{ marginVertical: 6 }} />}
          horizontal={horizontal}
          numColumns={showNumOfColumns}
          columnWrapperStyle={ showNumOfColumns > 1 ? { justifyContent: 'space-between', gap: 6 } : undefined}
          data={presentable.presentablePages}
          renderItem={renderThumbnail}
          getItemLayout={getItemLayout}

          /**
           * Handles cases where scrollToIndex fails (usually due to virtualization)
           * Implements a two-step scroll:
           * 1. Quick jump to approximate position
           * 2. Precise scroll to exact position
           */
          onScrollToIndexFailed={info => {
            // Calculate approximate position
            const offset = info.averageItemLength * Math.floor(info.index / showNumOfColumns);

            // Step 1: Jump to approximate position without animation
            flatListRef.current?.scrollToOffset({
              offset,
              animated: false,
            });

            // Step 2: After a short delay, try to scroll to exact position
            // The delay gives time for items to render after the initial jump
            setTimeout(() => {
              if (isIntersecting && flatListRef.current) {
                flatListRef.current.scrollToIndex({
                  index: Math.floor(info.index / showNumOfColumns),
                  animated: true,
                });
              }
            }, 500);
          }}
        />
      </Iffy>
    </DNABox>
  );
}

export default DNASlideRoll;
