import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UseFormReturn } from 'react-hook-form';
import { useBeforeunload } from 'react-beforeunload';
import { useInterval } from '@alucio/lux-ui';
import { Meeting, MeetingType, PresentedMeta, Sentiment } from '@alucio/aws-beacon-amplify/src/models';
import {
  DocumentVersionORM,
  isDocumentVersionORM,
  isFolderItemORM,
  isPageGroupORM,
  MeetingORM,
} from 'src/types/types';
import workerChannel from 'src/worker/channels/workerChannel';
import { RootState } from 'src/state/redux';
import { useMeeting } from 'src/state/redux/selector/meeting';
import { useSyncState } from 'src/state/redux/selector/cache';
import { contentPreviewModalActions } from 'src/state/redux/slice/contentPreviewModal';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import { meetingActions } from 'src/state/redux/slice/meeting';
import useFullScreen from 'src/hooks/useFullScreen/useFullScreen';
import useScreenNav from 'src/components/DNA/hooks/useScreenNav';
import { FormValuesType } from 'src/components/CustomFields/ComposableForm';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider';
import ConfirmationModal from 'src/screens/Meetings/PresentationControls/TabContent/MeetingDetails/ConfirmationModal';
import { Tab } from 'src/screens/Meetings/PresentationControls/PresentationControls';
import { ActionBarState, useMenuActionBar } from 'src/screens/Meetings/SharedComponents/PresentationMenu';
import usePresentationControlsState from './Hooks/usePresentationControlsState';
import useMeetingsComponentVisibility, { MeetingComponentsVisibility } from './Hooks/useMeetingsComponentVisibility';
import useSyncContentPresented, { getContentId } from './Hooks/useSyncContentPresented';
import useMeetingsPopoutContentState, {
  PopoutContentBCPayloads,
  PopoutContentBCTypes,
} from './Hooks/useMeetingsPopoutContentState';
import useKeyPressedEventHandler, {
  KeyEventSettings,
} from 'src/hooks/useKeyPressedEventHandler/useKeyPressedEventHandler';
import { useIsExternalPlayer } from 'src/screens/Loading';
import DNACommonConfirmation from 'src/components/DNA/Modal/DNACommonConfirmation';
import { DRAWER_ENTITIES, drawerActions } from '../../redux/slice/drawer';
import { BroadcastChannel } from 'broadcast-channel';
import useHandleOfflineMeeting from './Hooks/useHandleOfflineMeeting';

export interface MeetingsStateType extends MeetingComponentsVisibility {
  meetingORM: MeetingORM | undefined,
  endMeeting: () => void,
  editMeeting: (meeting: Meeting) => void,
  isExternalPlayer?: boolean,
  isMainPlayer: boolean,
  meetingForm: React.MutableRefObject<UseFormReturn<FormValuesType>>,
  isVirtual: boolean,
  isInPerson: boolean,
  popoutContentHidden: boolean,
  toggleMeetingType: () => void,
  checkFormDiscard: (callback: () => void) => void,
  setIsSubmitting: (isSubmitting: boolean) => void,
  currentTab: Tab,
  setCurrentTab: Dispatch<SetStateAction<Tab>>,
  focusPopoutWindow: () => void,
  previousTab: React.MutableRefObject<Tab>,
  canEndMeeting: boolean,
  setCanEndMeeting: Dispatch<SetStateAction<boolean>>,
  addNotes: (notes: string) => void,
  updateReaction: (sentiment: Sentiment) => void,
  updateFollowUp: () => void,
  presentedMeta: PresentedMeta | undefined,
  setPresentationMenu: (state: ActionBarState) => void;
  actionBarState: ActionBarState;
  selectedAssociatedDocument: DocumentVersionORM | undefined;
  setSelectedAssociatedDocument: Dispatch<SetStateAction<DocumentVersionORM | undefined>>;
  selectedPresentableId: string | undefined;
  setSelectedPresentableId: Dispatch<SetStateAction<string | undefined>>;
  popoutReference: React.MutableRefObject<Window | null | undefined>;
}

export const MeetingsStateContext = createContext<MeetingsStateType>(null!);
MeetingsStateContext.displayName = 'MeetingsContext';

interface InitialValues {
  meetingId: string
}

interface UtilitiesProps {
  meetingForm: React.MutableRefObject<UseFormReturn<FormValuesType>>,
  currentTab: Tab,
}

const useFormUtilities = (props: UtilitiesProps) => {
  const { meetingForm, currentTab } = props;
  const isMeetingTab = useRef<boolean>(currentTab === 'MEETING_DETAILS');

  useEffect(() => {
    isMeetingTab.current = currentTab === 'MEETING_DETAILS';
  }, [currentTab]);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const latestSavedMeetingDetails = useRef(meetingForm.current?.getValues());
  const dispatch = useDispatch();

  useEffect(() => {
    // IF THE USER WANTS TO DISCARD THE FORM CHANGES, THE FORM'S STATE IT HAD WHEN
    // THE TAB WAS OPENED, IS THE ONE THAT WILL BE SET UPON DISCARDING CHANGES
    if ((currentTab === 'MEETING_DETAILS' && meetingForm.current) || isSubmitting) {
      latestSavedMeetingDetails.current = meetingForm.current.getValues();
    }
  }, [currentTab, isSubmitting]);

  // THIS FUNCTION WILL BE TRIGGERED FROM ANY PLACE THAT CAN CAUSE A DISCARD OF THE FORM CHANGES
  // SO THE USER CAN DECIDE WHETHER DISCARDING THEM OR SAVE THEM (CONTINUE IN THE CURRENT SCREEN)
  const checkFormDiscard = (callback: () => void) => {
    function onConfirm(): void {
      callback();
      meetingForm.current.reset({ ...latestSavedMeetingDetails.current }, { keepDirty: false });
    }

    const isKeyDirty = Object.keys(meetingForm?.current?.formState?.dirtyFields || []).length;
    if (isMeetingTab.current && isKeyDirty) {
      dispatch(
        DNAModalActions.setModal({
          isVisible: true,
          allowBackdropCancel: true,
          component: (props) => (<ConfirmationModal
            {...props}
            onConfirmAction={onConfirm}
          />),
        }));
    } else {
      callback();
    }
  }

  return {
    checkFormDiscard,
    setIsSubmitting,
  };
};

const useMeetingStateConditionals = (meetingORM?: MeetingORM) => {
  const isExternalPlayer = useIsExternalPlayer();
  const { isFullScreen } = useFullScreen();
  const isVirtual = meetingORM?.model.type === MeetingType.VIRTUAL;
  const isInPerson = meetingORM?.model.type === MeetingType.IN_PERSON;
  const isMainPlayer = !isVirtual || (isVirtual && isExternalPlayer);

  return {
    isExternalPlayer,
    isFullScreen,
    isVirtual,
    isInPerson,
    isMainPlayer,
  };
};

const MeetingsStateProvider: React.FC<PropsWithChildren<InitialValues>> = (props) => {
  const dispatch = useDispatch();
  const broadcastChannel = useRef<BroadcastChannel<PopoutContentBCPayloads>>();
  const meetingORM = useMeeting(props.meetingId);
  const {
    isExternalPlayer,
    isFullScreen,
    isVirtual,
    isInPerson,
    isMainPlayer,
  } = useMeetingStateConditionals(meetingORM);
  const meetingORMRef = useRef<MeetingORM | undefined>(meetingORM);
  const syncState = useSyncState()
  const { goTo } = useScreenNav();
  const {
    presentations,
    nextStepPage,
    prevStepPage,
    setContentPresented,
    setIsPlayerReady,
    initPresentation,
    contentPresented,
    activePresentation,
  } = useContent();
  const {
    addNotes,
    updateReaction,
    updateFollowUp,
    presentedMeta,
  } = useSyncContentPresented(isMainPlayer, meetingORM);
  useHandleOfflineMeeting(meetingORM?.model.id);
  const { initialMeetingContent } = useSelector((state: RootState) => state.contentPreviewModal);
  const meetingsComponentsVisibility = useMeetingsComponentVisibility();
  const [canEndMeeting, setCanEndMeeting] = useState<boolean>(true);
  const presentationControlState = usePresentationControlsState('BROWSE_CONTENT');
  const meetingForm = useRef<UseFormReturn<FormValuesType>>(null!);
  const { setIsSubmitting, checkFormDiscard } =
    useFormUtilities({ meetingForm, currentTab: presentationControlState.currentTab });
  const menuPresentationBar = useMenuActionBar();
  const [selectedAssociatedDocument, setSelectedAssociatedDocument] = useState<DocumentVersionORM>();
  const [selectedPresentableId, setSelectedPresentableId] = useState<string>();
  const {
    focusPopoutWindow,
    handleClearExternalWindow,
    popoutReference,
    popoutContentHidden,
  } = useMeetingsPopoutContentState(
    meetingORM,
    endMeeting,
    toggleMeetingType,
    isMainPlayer,
    meetingsComponentsVisibility);

  useEffect(() => {
    meetingORMRef.current = meetingORM;
  }, [meetingORM]);

  useEffect(() => {
    if (meetingORM) {
      if (!isVirtual && !isExternalPlayer) {
        document.title = 'Beacon';
      } else if (isVirtual && !isExternalPlayer) {
        document.title = 'Beacon Share';
      } else if (isExternalPlayer) {
        document.title = 'Beacon Content';
        if (meetingORM?.model.type !== MeetingType.VIRTUAL) {
          window.close();
        }
      }
    }
    if (!activePresentation && !initialMeetingContent && !isExternalPlayer) {
      meetingsComponentsVisibility.setPresentationControlsVisible(true);
      setIsPlayerReady(true);
    }
    return () => {
      document.title = 'Beacon';
    };
  }, [meetingORM?.model.type]);

  const keyEventSettings: KeyEventSettings[] = useMemo(() => isMainPlayer ? [{
    keyEvent: 'keydown',
    keys: ['ArrowLeft', 'ArrowUp', 'Home', 'PageUp'],
    onAction: prevStepPage,
  }, {
    keyEvent: 'keydown',
    keys: ['ArrowRight', 'ArrowDown', 'Enter', 'Space', 'End', 'PageDown'],
    onAction: nextStepPage,
  }] : [], [isMainPlayer]);

  useKeyPressedEventHandler(keyEventSettings);

  useEffect(() => {
    if (meetingORM && meetingORM.model.contentPresented.length) {
      setContentPresented(meetingORM.model.contentPresented);
    }
  }, []);

  const resumeOfflineSyncing = async() => {
    // check if service worker is registered
    const registration = await navigator.serviceWorker.getRegistration('/')
    if (registration && syncState?.matches('online.paused')) {
      // resume offline syncing
      workerChannel.postMessageExtended({ type: 'RESUME_SYNC' })
    }
  }

  function endMeeting() {
    handleClearExternalWindow();
    if (isExternalPlayer && meetingORM) {
      broadcastChannel.current?.postMessage({ type: PopoutContentBCTypes.endMeeting, meetingId: meetingORM.model.id });
      return;
    }

    if (meetingORM && contentPresented) {
      dispatch(meetingActions.endMeeting(meetingORM?.model,
        { contentPresented }))
    }
    goTo.MEETING_HISTORY();
    meetingORM && dispatch(drawerActions.toggle({
      entity: DRAWER_ENTITIES.MEETING,
      entityId: meetingORM.model.id,
    }));

    resumeOfflineSyncing()
  }

  const editMeeting = (meeting: Meeting) => {
    meetingORM && dispatch(meetingActions.updateMeeting(meetingORM.model, meeting));
  }

  function toggleMeetingType() {
    if (meetingORMRef.current?.model) {
      const isVirtual = meetingORMRef.current?.model.type === MeetingType.VIRTUAL;
      if (isExternalPlayer && isVirtual) {
        displayOnExitExternalPlayerConfirmationModal();
      } else {
        dispatch(meetingActions.toggleMeetingType(meetingORMRef.current?.model, contentPresented || []));
        if (isVirtual) {
          popoutReference.current && (popoutReference.current.onunload = null)
          popoutReference.current?.window.close();
        }
        meetingsComponentsVisibility.toggleOffComponents();
      }
    }
  }

  function displayOnExitExternalPlayerConfirmationModal(): void {
    if (meetingORM) {
      const sendToggleMeetingTypeMessage = () => {
        broadcastChannel.current?.postMessage({
          type: PopoutContentBCTypes.toggleMeetingType,
          meetingId: meetingORM.model.id,
        })
      };

      dispatch(
        DNAModalActions.setModal({
          isVisible: true,
          allowBackdropCancel: true,
          component: () => (<DNACommonConfirmation
            cancelText="Cancel"
            confirmActionText="Exit Presenter View"
            onConfirmAction={sendToggleMeetingTypeMessage}
            title="Exit Presenter View?"
            descriptionText="You will return to single-window presentation mode."
          />),
        }));
    }
  }

  const saveContentEveryMinute = () => {
    if (meetingORM && contentPresented) {
      const contentId = getContentId(activePresentation?.presentable.orm);
      const groupId = isPageGroupORM(activePresentation?.presentable.orm)
        ? activePresentation?.presentable.orm.model.id
        : undefined;
      const folderItemId = isFolderItemORM(activePresentation?.presentable.orm)
        ? activePresentation?.presentable.orm.model.id : undefined;

      const presentationPageNumber  = activePresentation?.currentPresentablePage.presentationPageNumber
      const newContentPresented = contentPresented.map((content) => {
        if (content.contentId === contentId && (content.groupId === groupId || !groupId) &&
        (content.folderItemId === folderItemId || !folderItemId) ) {
          const events = content.events ?? [];
          return {
            ...content,
            events: events.map((event, index) => {
              if (index === events.length - 1 && event.pageNumber === presentationPageNumber) {
                return {
                  ...event,
                  end: new Date().toISOString(),
                }
              }
              return event;
            }),
          };
        }
        return content;
      });
      const payload = {
        ...meetingORM?.model,
        contentPresented: newContentPresented,
      }
      setContentPresented(newContentPresented);
      editMeeting(payload);
    }
  }

  /** Periodic Save of presented content */
  useInterval(() => {
    isMainPlayer &&
    saveContentEveryMinute()
  }, 60000);

  // prevents the window/tab from closing
  useBeforeunload((e) => {
    saveContentEveryMinute();
    if (!isFullScreen && !isExternalPlayer) {
      e.preventDefault();
    }
  })

  useEffect(() => {
    if (meetingORM?.model && presentations.length < meetingORM?.model.contentPresented.length) {
      const contendPresentedIds = presentations.map(({ presentable: { orm } }) => {
        return (isFolderItemORM(orm) && orm.relations?.itemORM?.model.id) ||
          (isDocumentVersionORM(orm) && orm.model.id)
      })
      const payload = {
        ...meetingORM?.model,
        contentPresented: meetingORM?.model.contentPresented
          .map(p => {
            if (contendPresentedIds.includes(p.contentId) || p.closedAt) {
              return p
            }
            return { ...p, closedAt: new Date().toISOString() }
          }),

      }
      editMeeting(payload);
    }

    if (!presentations.length && meetingsComponentsVisibility.openNotes) {
      meetingsComponentsVisibility.setOpenNotes([]);
    }
  }, [presentations])

  useEffect(() => {
    broadcastChannel.current = new BroadcastChannel<PopoutContentBCPayloads>('MEETING_CONTENT');
    if (initialMeetingContent) {
      initPresentation(initialMeetingContent.presentableModelORM, initialMeetingContent.page);
      dispatch(contentPreviewModalActions.setInitialContent(undefined));
    }
    return () => {
      broadcastChannel.current?.close();
      broadcastChannel.current = undefined;
    };
  }, [])

  const contextValue: MeetingsStateType = {
    endMeeting,
    editMeeting,
    focusPopoutWindow,
    meetingORM,
    meetingForm,
    popoutContentHidden,
    ...presentationControlState,
    ...meetingsComponentsVisibility,
    ...menuPresentationBar,
    checkFormDiscard,
    setIsSubmitting,
    canEndMeeting,
    setCanEndMeeting,
    isExternalPlayer,
    isMainPlayer,
    isVirtual,
    isInPerson,
    toggleMeetingType,
    addNotes,
    updateReaction,
    updateFollowUp,
    presentedMeta,
    selectedAssociatedDocument,
    setSelectedAssociatedDocument,
    selectedPresentableId,
    setSelectedPresentableId,
    popoutReference,
  }

  return (
    <MeetingsStateContext.Provider value={contextValue}>
      {props.children}
    </MeetingsStateContext.Provider>
  )
}
MeetingsStateProvider.displayName = 'MeetingsStateProvider';

export const useMeetingsState = () => {
  const context = useContext(MeetingsStateContext)
  if (!context) {
    throw new Error('useMeetingsState must be used within the MeetingsStateProvider')
  }
  return context;
}

export default MeetingsStateProvider;
