export function getStatePath(currentHistoryValue: any): string {
  const path: string[] = [];

  function traverse(currentObj: any) {
    if (typeof currentObj === 'string') {
      path.push(currentObj); // Add the leaf node to the path
      return;
    }

    if (typeof currentObj === 'object') {
      const keys = Object.keys(currentObj);
      keys.forEach(key => {
        path.push(key); // Add the key to the path
        traverse(currentObj[key]); // Recurse into the nested object
      });
    }
  }

  traverse(currentHistoryValue); // Start traversal from `current`
  return 'PublisherVersioningSM.' + path.join('.'); // Return the dot-separated path
}

export default {
  getStatePath,
}
