import React, { useState } from 'react';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { Pressable, StyleSheet } from 'react-native';
import { StandaloneFormUsage } from '@alucio/aws-beacon-amplify/src/models';
import { CUSTOM_FORM_RECORD_ENTITY } from '@alucio/aws-beacon-amplify/src/API';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { DNAButtonProps } from '@alucio/lux-ui/lib/components/controls/DNAButton/DNAButton';
import { DNABox, DNAButton, DNAText, Hoverable, Iffy, util } from '@alucio/lux-ui';
import { FORMS, StandaloneForm, useTenantStandaloneForms } from 'src/state/redux/selector/crm';
import { DeviceMode, useAppSettings } from 'src/state/context/AppSettings';
import { DRAWER_ENTITIES, drawerActions } from 'src/state/redux/slice/drawer';
import DNAPopover from '../Popover/DNAPopover';

const styles = StyleSheet.create({
  hoveredOption: {
    backgroundColor: colors['color-gray-50'],
  },
  option: {
    height: 40,
    paddingHorizontal: 20,
  },
  optionsWrapper: {
    backgroundColor: colors['color-text-white'],
    borderColor: colors['color-gray-100'],
    borderRadius: 4,
    borderWidth: 1,
    shadowColor: colors['color-gray-900'],
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.16,
    shadowRadius: 11.22,
  },
});

type DeviceModeButtonProps = Record<DeviceMode, DNAButtonProps>;

export const deviceModeButtonProps: DeviceModeButtonProps = {
  desktop: {
    appearance: 'outline',
    status: 'tertiary',
    size: 'lg',
    padding: 'sm',
    iconLeft: 'format-list-bulleted-square',
  },
  tablet: {
    padding:'none',
    rounded:'full',
    appearance:'ghostLink',
    status:'dark',
    size:'xl',
    iconLeft: 'format-list-bulleted-square',
  },
}

const FORMS_ENTITY_MAP = {
  [CUSTOM_FORM_RECORD_ENTITY.BEACON_TENANT_FORM]: DRAWER_ENTITIES.TENANT_FORM,
  [CUSTOM_FORM_RECORD_ENTITY.CRM_OBJECT]: DRAWER_ENTITIES.CRM_FORM,
};

const onOpenForm = (standaloneForm: StandaloneForm, dispatch: Dispatch): void => {
  const isCRMForm = standaloneForm.type === CUSTOM_FORM_RECORD_ENTITY.CRM_OBJECT;
  analytics?.track('TENANT_FORM_OPEN', {
    category: 'TENANT_FORM',
    context: isCRMForm ? 'CRM_FORM' : 'TENANT_FORM',
    formID: standaloneForm.id,
  });

  dispatch(drawerActions.toggle({
    entity: FORMS_ENTITY_MAP[standaloneForm.type],
    entityId: isCRMForm ? undefined : standaloneForm.id,
    standaloneForm,
  }));
};

const StandaloneFormsButton = () => {
  const { deviceMode } = useAppSettings();
  const [areOptionsDisplayed, setAreOptionsDisplayed] = useState<boolean>(false);
  const customForms = useTenantStandaloneForms([FORMS.BEACON, FORMS.CRM], StandaloneFormUsage.STANDALONE);
  const currentDeviceModeButtonProps = deviceModeButtonProps[deviceMode];
  const { isOnline } = useAppSettings();

  if (!customForms.length) {
    return null;
  }

  const displayFormsOptions = (): void => {
    setAreOptionsDisplayed((areDisplayed) => !areDisplayed);
  };

  return (
    <DNAPopover
      lazyMount={isOnline}
      interactive
      placement="bottom-end"
      type={isOnline ? 'menu' : 'tooltip'}
      onBackdropPress={displayFormsOptions}
      {...(isOnline ? { visible: areOptionsDisplayed } : {})}
    >
      <DNAPopover.Anchor>
        <DNAButton
          disabled={!isOnline}
          onPress={displayFormsOptions}
          testID="forms-button"
          {...currentDeviceModeButtonProps}
        />
      </DNAPopover.Anchor>
      <DNAPopover.Content>
        <Iffy is={isOnline}>
          <FormsOptions customForms={customForms} setAreOptionsDisplayed={setAreOptionsDisplayed} />
        </Iffy>
        <Iffy is={!isOnline}>
          <DNAText status="basic">Please connect to the internet to access forms</DNAText>
        </Iffy>
      </DNAPopover.Content>
    </DNAPopover>
  );
};

interface FormsListProps {
  customForms: StandaloneForm[];
  setAreOptionsDisplayed: (isOpen: boolean) => void;
}

const FormsOptions = (props: FormsListProps) => {
  const { customForms, setAreOptionsDisplayed } = props;

  return (
    <DNABox style={styles.optionsWrapper} appearance="col">
      {
        customForms.map((form) =>
          (<FormOptionRow
            key={form.id}
            setAreOptionsDisplayed={setAreOptionsDisplayed}
            form={form}
          />))
      }
    </DNABox>
  );
};

interface FormRowProps {
  form: StandaloneForm;
  setAreOptionsDisplayed: (isOpen: boolean) => void;
}

const FormOptionRow = (props: FormRowProps) => {
  const { form, setAreOptionsDisplayed } = props;
  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const onPressed = (): void => {
    setAreOptionsDisplayed(false);
    onOpenForm(form, dispatch);
  };

  const onHoverIn = (): void => {
    setIsHovered(true);
  };

  const onHoverOut = (): void => {
    setIsHovered(false);
  };

  return (
    <Hoverable onHoverIn={onHoverIn} onHoverOut={onHoverOut}>
      <Pressable onPress={onPressed}>
        <DNABox
          alignY="center"
          style={util.mergeStyles(
            undefined, styles.option, [styles.hoveredOption, isHovered])}
        >
          <DNAText>{form.name}</DNAText>
        </DNABox>
      </Pressable>
    </Hoverable>
  )
};

export default StandaloneFormsButton;
