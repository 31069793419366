import { StateFrom } from 'xstate';
import type { publisherVersioningSM } from './publisherVersioning.machine';
import { getStatePath } from '../util';

type State = StateFrom<typeof publisherVersioningSM>

// CONTEXT
export const availabletabs = (state: State) => ({ availabletabs: state.context.availabletabs })
export const documentVersionId = (state: State) => ({ documentVersionId: state.context.documentVersionId })
export const hasOptimizedFinishedThisSession = (state: State) =>
  ({ hasOptimizedFinishedThisSession: state.context.hasOptimizedFinishedThisSession })
export const isCancellingNewVersionUpload = (state: State) =>
  ({ isCancellingNewVersionUpload: state.context.cancelUpload })
export const documentInfoIsDirty = (state: State) => ({ documentInfoIsDirty: state.context.documentInfoIsDirty })
export const documentSettingsIsDirty = (state: State) =>
  ({ documentSettingsIsDirty: state.context.documentSettingsIsDirty })
export const documentPublishIsDirty = (state: State) =>
  ({ documentPublishIsDirty: state.context.documentPublishIsDirty })
export const documentSlidesDataIsDirty = (state: State) =>
  ({ documentSlidesDataIsDirty: state.context.documentSlidesDataIsDirty })
export const isDraftDirty = (state: State) => {
  const _documentInfoIsDirty = documentInfoIsDirty(state).documentInfoIsDirty;
  const _documentSettingsIsDirty = documentSettingsIsDirty(state).documentSettingsIsDirty;
  const _documentPublishIsDirty = documentPublishIsDirty(state).documentPublishIsDirty;
  const _documentSlidesDataIsDirty = documentSlidesDataIsDirty(state).documentSlidesDataIsDirty;
  const isDraftDirty = _documentInfoIsDirty ||
    _documentSettingsIsDirty ||
    _documentPublishIsDirty ||
    _documentSlidesDataIsDirty
  return { isDraftDirty }
}
export const selectedTabIndex = (state: State) => ({ selectedTabIndex: state.context.selectedTabIndex })
export const errors = (state: State) => ({ errors: state.context.errors })
export const changesCheckResult = (state: State) => ({ changesCheckResult: state.context.changesCheckResult })
export const versionForm = (state: State) => ({ versionForm: state.context.versionForm })
export const enableMatchSlides = (state: State) => ({ enableMatchSlides: state.context.enableMatchSlides })
export const hasNeedReviewSlide = (state: State) => ({ hasNeedReviewSlide: state.context.hasNeedReviewSlide })
export const hasEmptyMatchSlide = (state: State) => ({ hasEmptyMatchSlide: state.context.hasEmptyMatchSlide })

// TAGS
export const isExitBlocked = (state: State) => ({ isExitBlocked: state.hasTag('DISABLE_EXIT') })
export const isNavBlocked = (state: State) => ({ isNavBlocked: state.hasTag('DISABLE_NAV') })
export const hasProcessingError = (state: State) => ({ hasProcessingError: state.hasTag('PROCESSING_ERROR') })
export const isModifying = (state: State) => ({ isModifying: state.hasTag('DISABLE_MODIFY') })

// META
export const hasInfoMessage = (state: State) => {
  const statePath = getStatePath(state.historyValue?.current)
  return { hasInfoMessage: !!state.meta?.[statePath]?.infoMessage }
}
export const infoMessage = (state: State) => {
  const statePath = getStatePath(state.historyValue?.current)
  return { infoMessage: state.meta?.[statePath]?.infoMessage }
}
export const cancelMessage = (state: State) => {
  const statePath = getStatePath(state.historyValue?.current)
  return { cancelMessage: state.meta?.[statePath]?.cancelMessage }
}

// STATE
export const isUploadingNewVersion = (state: State) =>
  ({ isUploadingNewVersion: state.matches('draft.documentInfo.processing.upload.uploading') })
export const isPublishedViewMode = (state: State) => ({ isPublishedViewMode: state.matches('published') })
export const isScheduledViewMode = (state: State) => ({ isScheduledViewMode: state.matches('schedule') })
export const isInDraftingState = (state: State) => {
  const isInDraftingState = !(state.matches('published') || state.matches('schedule'))
  return { isInDraftingState }
}
export const isProcessingNewVersion = (state: State) =>
  ({ isProcessingNewVersion: state.matches('draft.documentInfo.processing') })
export const isUpdatingThumbnail = (state: State) =>
  ({ isUpdatingThumbnail: state.matches('draft.documentInfo.uploadThumbnail') })
export const isCreatingFromExisting = (state: State) =>
  ({ isCreatingFromExisting: state.matches('draft.documentInfo.processing.existing.optimizing') })
export const isPublishing = (state: State) => {
  const isPublishing = state.matches('draft.documentPublish.publishing') ||
    state.matches('draft.documentPublish.setPublishStatus')
  return { isPublishing }
}
export const canDeleteDraft = (state: State) => {
  const _isInDraftingState = isInDraftingState(state).isInDraftingState;
  const _isProcessingNewVersion = isProcessingNewVersion(state).isProcessingNewVersion;
  const _hasProcessingError = hasProcessingError(state).hasProcessingError;
  const canDeleteDraft = (_isInDraftingState && !_isProcessingNewVersion) || _hasProcessingError;
  return { canDeleteDraft }
}
export const canSaveDraft = (state: State) => {
  const _isInDraftingState = isInDraftingState(state).isInDraftingState;
  const _hasProcessingError = hasProcessingError(state).hasProcessingError;
  const _isProcessingNewVersion = isProcessingNewVersion(state).isProcessingNewVersion;
  const canSaveDraft = _isInDraftingState && !_hasProcessingError && !_isProcessingNewVersion;
  return { canSaveDraft }
}
export const disableDeleteDraftButton = (state: State) => {
  const _isModifying = isModifying(state).isModifying;
  const _isPublishing = isPublishing(state).isPublishing;
  const disableDeleteDraftButton = _isModifying || _isPublishing;
  return { disableDeleteDraftButton }
}
export const disableSaveDraftButton = (state: State) => {
  const _isModifying = isModifying(state).isModifying;
  const _isDraftDirty = isDraftDirty(state).isDraftDirty;
  const _isPublishing = isPublishing(state).isPublishing;
  const disableSaveDraftButton = _isModifying || !_isDraftDirty || _isPublishing;
  return { disableSaveDraftButton }
}

// CAN PERFORM
export const canCreateFromExisting = (state: State) =>
  ({ canCreateFromExisting: state.can('CREATE_FROM_EXISTING') })
export const canCreateFromUpload = (state: State) =>
  // @ts-ignore - We don't need to have a valid File Payload to do this check
  ({ canCreateFromUpload: state.can({ type: 'CREATE_FROM_UPLOAD', payload: { file: undefined } }) })
export const canCreateNewVersion = (state: State) => {
  const canCreateNewVersion = state.can('CREATE_FROM_EXISTING') ||
    // @ts-ignore - We don't need to have a valid File Payload to do this check
    state.can({ type: 'CREATE_FROM_UPLOAD', payload: { file: undefined } })
  return { canCreateNewVersion }
}
export const canPublish = (state: State) =>
  ({ canPublish: state.can({ type: 'CREATE_FROM_UPLOAD', payload: 'f' as any }) })
export const canUploadNewVersion = (state: State) => {
  const _canCreateNewVersion = canCreateNewVersion(state).canCreateNewVersion;
  const _isUploadingNewVersion = isUploadingNewVersion(state).isUploadingNewVersion;
  const canUploadNewVersion = _canCreateNewVersion && !_isUploadingNewVersion;
  return { canUploadNewVersion }
}
