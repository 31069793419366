import React, { PropsWithChildren } from 'react';
import { StyleSheet } from 'react-native';
import { DNABox, DNACard, DNAChip, DNAText, Iffy } from '@alucio/lux-ui';

const styles = StyleSheet.create({
  actionsCount: {
    borderRadius: 2,
  },
  container: {
    borderRadius: 8,
    padding: 16,
  },
});

interface Props {
  actionsCount?: number
  headerRightSideComponent?: React.ReactElement
  title: string
}

const WidgetContainer: React.FC<PropsWithChildren<Props>> = (props) => {
  const { actionsCount, children, headerRightSideComponent, title } = props;

  return (
    <DNABox>
      <DNACard appearance="flat" style={styles.container}>
        <DNABox appearance="col" spacing="md">
          {/* HEADER */}
          <DNABox fill spacing="between">
            <DNABox spacing="sm">
              <DNAText h5>{title}</DNAText>
              <Iffy is={actionsCount}>
                <DNAChip appearance="tag" status="primary" style={styles.actionsCount}>
                  {`${actionsCount}`}
                </DNAChip>
              </Iffy>
            </DNABox>
            <DNABox>
              {headerRightSideComponent}
            </DNABox>
          </DNABox>
          {/* CONTENT */}
          {children &&
            <DNABox>{children}</DNABox>
          }
        </DNABox>
      </DNACard>
    </DNABox>
  );
};

export default WidgetContainer;
