import React from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { DNABox, DNAText } from '@alucio/lux-ui';
import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import useMachineSelector, { composite } from 'src/hooks/useSelector';
import * as slideSettingsSelector from 'src/state/machines/publisherVersioning/SlideSettings/slideSettings.selectors';
import { useSlideSettingsState as useSlideSettingsStateProxy }
  from 'src/state/machines/publisherVersioning/SlideSettings/SlideSettingsProvider.proxy';
import { getThumbURL } from 'src/utils/thumbnailHelpers';

export const S = StyleSheet.create({
  attachmentSlides: {
    backgroundColor: colors['color-gray-10'],
    borderRightColor: colors['color-gray-80'],
    borderRightWidth: 1,
    width: 412,
  },
  attachmentSlidesHeader: {
    borderBottomColor: colors['color-gray-80'],
    borderBottomWidth: 1,
    height: 50,
    paddingVertical: 12,
    paddingHorizontal: 16,
  },
  attachmentSlidesContent: {
    padding: 35,
  },
});

type LeftSideBarProps = {
  selectedChild: string[]
}

const LeftSideBar: React.FC<LeftSideBarProps> = ({ selectedChild }) => {
  const slideSettingsState = useSlideSettingsStateProxy()
  const {
    service,
    currentDocumentVersionORM,
    getSlideTitle,
    getHasSlideTextInsertionEnabled,
  } = slideSettingsState

  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      slideSettingsSelector.associatedSlides,
      slideSettingsSelector.isAddAssociatedSlidesStepTwo,
    ),
  )

  if (!cond.isAddAssociatedSlidesStepTwo) return null
  return (
    <DNABox style={ S.attachmentSlides } appearance="col">
      <DNABox style= { S.attachmentSlidesHeader }>
        <DNAText status="flatAlt" bold>
          Required Slides ({Object.values(cond.associatedSlides.child)
          .filter(b => b).length})
        </DNAText>
      </DNABox>
      <ScrollView>
        <DNABox appearance="col" alignX="center" style={ S.attachmentSlidesContent } spacing="lg">
          {selectedChild.map(id => {
            const coverThumbnailPageNumber = currentDocumentVersionORM.model.selectedThumbnail ?? 1
            const page = currentDocumentVersionORM.meta.allPages.find(page => page.pageId === id)
            const thumbURL = page?.number ? getThumbURL(currentDocumentVersionORM, page.number) : ''

            return (
              <DNAThumbnail
                key={thumbURL}
                s3URL={thumbURL}
                variant={DNAThumbnail.Variants.INFO}
                useLoadingIndicator
                size="lg"
                pageNumber={page?.number}
                isCover={page?.number === coverThumbnailPageNumber}
                isRequired={page?.isRequired}
                thumbnailTitle={getSlideTitle(page?.number!)}
                isTextInsertionEnabled={getHasSlideTextInsertionEnabled(page?.number)}
              />
            )
          })}
        </DNABox>
      </ScrollView>
    </DNABox>
  )
}

export default LeftSideBar;
