import React from 'react';
import { useDispatch } from 'react-redux';
import { DNABox, Iffy, InformationMessage } from '@alucio/lux-ui';
import { CustomFieldUsage } from '@alucio/aws-beacon-amplify/src/models';
import { useCustomFormRecordProvider } from 'src/state/context/CustomFormProvider/CustomFormProvider';
import { StandaloneForm, useStandaloneFormConfig } from 'src/state/redux/selector/crm';
import { DRAWER_ENTITIES, drawerActions } from 'src/state/redux/slice/drawer';
import { useAppSettings } from 'src/state/context/AppSettings';
import { useCRMStatus } from 'src/screens/Profile/CRMIntegration';
import CustomForm, { CustomFormRecordWrapper, styles } from '../CustomForm';
import CustomFormRecordHeader from '../../Meeting/CustomForms/CustomFormRecordHeader';
import CustomFormRecordFooter from '../../Meeting/CustomForms/CustomFormRecordFooter';
import { LoadingMeeting } from '../../Meeting/LoadingMeeting';
import MeetingCRMAuth from '../../Meeting/MeetingCRMAuth';
import { GLOBAL_TENANT_FORM_ERROR } from '../../TenantForm/TenantFormProvider';

interface ConnectorProps {
  recordId?: string,
  standaloneForm?: StandaloneForm
}

interface Props extends ConnectorProps {
  standaloneForm: StandaloneForm
}

const CustomFormDrawer = () => {
  const { displayCRMConnectButton } = useCRMStatus();
  const dispatch = useDispatch();

  const onConfirmCancel = (): void => {
    dispatch(drawerActions.toggle({}));
  };

  return (
    <DNABox appearance="col" fill>
      <CustomFormRecordHeader onConfirmCancel={onConfirmCancel} />
      <Iffy is={displayCRMConnectButton}>
        <CRMButtonWrapper />
      </Iffy>
      <Iffy is={!displayCRMConnectButton}>
        <OfflineWarning />
        <CustomForm />
      </Iffy>
      <CustomFormRecordFooter onConfirmCancel={onConfirmCancel} />
    </DNABox>
  );
};

// ** TEMPORARY COMPONENT: PLACEHOLDER WHILE WE ALLOW EDITING ** //
const OfflineWarning = () => {
  const { isOnline } = useAppSettings();
  const { isReadOnly } = useCustomFormRecordProvider();

  if (isOnline || isReadOnly) {
    return null;
  }

  return (
    <DNABox style={styles.wrapper}>
      <DNABox style={styles.contentWrapper} fill>
        <InformationMessage
          text={GLOBAL_TENANT_FORM_ERROR.offline}
          variance="warning"
          customWrapperStyle={{ marginBottom: 0 }}
        />
      </DNABox>
    </DNABox>
  );
};

const CRMButtonWrapper = () => {
  const { errorMessage } = useCustomFormRecordProvider();
  return (
    <DNABox style={styles.wrapper} fill>
      <DNABox style={styles.contentWrapper} fill>
        <MeetingCRMAuth isValid={true} errorMessage={errorMessage} />
      </DNABox>
    </DNABox>
  );
};

const CustomFormWrapper = (props: Props) => {
  const { standaloneForm, recordId } = props;
  const dispatch = useDispatch();
  const crmSyncingStatus = useCRMStatus();
  const { customFields, defaultValues } = useStandaloneFormConfig(
    standaloneForm.id || '', standaloneForm?.crmFormSetting?.id);
  const { isOnline } = useAppSettings();

  if (crmSyncingStatus.isCRMSyncing || !customFields.length) {
    return <LoadingMeeting onlyBody />
  }

  // ** REFRESH THE DRAWER WITH THE NEW RECORD ID ** //
  const onSave = (newRecordId: string): void => {
    if (!recordId && newRecordId) {
      dispatch(drawerActions.toggle({
        entity: DRAWER_ENTITIES.CRM_FORM,
        entityId: newRecordId,
        standaloneForm,
        keepOpen: true,
      }));
    }
  };

  return (
    <CustomFormRecordWrapper
      disableForm={!isOnline}
      customFormRecordId={recordId}
      standaloneForm={standaloneForm}
      defaultValues={defaultValues}
      onSave={onSave}
      customFields={customFields}
      usage={{ internalUsages: [CustomFieldUsage.MEETING] }}
    >
      <CustomFormDrawer />
    </CustomFormRecordWrapper>
  );
};

const CustomFormDrawerConnector = (props: ConnectorProps) => {
  const { recordId, standaloneForm } = props;

  if (!standaloneForm) {
    // eslint-disable-next-line no-console
    console.log('No standalone form was received');
    return null;
  }

  return (
    <CustomFormWrapper
      recordId={recordId}
      standaloneForm={standaloneForm}
    />);
};

export default CustomFormDrawerConnector;
