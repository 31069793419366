import React, { useEffect, useLayoutEffect, useReducer, useRef } from 'react';
import { canvasId, channelName, HighligterBroadcastPayloads } from './HighlighterProvider';
import { BroadcastChannel } from 'broadcast-channel';
import * as logger from 'src/utils/logger';

const canvasPlayerId = 'highlight-player'

type HighlightProps = {
  iFrameRef: React.MutableRefObject<HTMLIFrameElement | null>;
  popoutReference?: React.MutableRefObject<Window | null | undefined>;
}

type ReducerAction = (
  {
    type: 'APPEND_STYLE',
    styles: React.CSSProperties
  } |
  {
    type: 'SET_SOURCE_DIMENSIONS',
    width: number,
    height: number,
  }
)

type ReducerState ={
  style: React.CSSProperties,
  width: number,
  height: number,
}

export const HighlightPlayer = (props:HighlightProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvasInterval = useRef<NodeJS.Timeout | undefined>(undefined);
  const [statePlayer, distpatchPlayer] = useReducer((state: ReducerState, action: ReducerAction) : ReducerState => {
    switch (action.type) {
      case 'APPEND_STYLE':
        return { ...state, style: { ...state.style, ...action.styles } };
      case 'SET_SOURCE_DIMENSIONS':
        return { ...state, width: action.width, height: action.height };
      default:
        return state;
    }
  }, {
    width: 0,
    height: 0,
    style: { opacity: 0.8, display: 'none' },
  })
  const channel = useRef<BroadcastChannel<HighligterBroadcastPayloads>>()
  const { iFrameRef, popoutReference } = props;

  useLayoutEffect(() => {
    if (iFrameRef.current) {
      const { width, height } = iFrameRef.current.getBoundingClientRect();
      distpatchPlayer({ type: 'SET_SOURCE_DIMENSIONS', width, height });
    }
  }, [iFrameRef.current?.clientWidth, iFrameRef.current?.clientHeight]);

  const cloneParentCanvasElement = () => {
    cleanCanvasRef()
    clearCanvasRefresh()

    const rootWindow = popoutReference?.current;
    const canvas = rootWindow?.document.getElementById(canvasId) as HTMLCanvasElement | null;
    if (!canvas) return;

    canvasInterval.current = setInterval(() => {
      if (!canvasRef.current) return;

      canvasRef.current.width = canvas.width;
      canvasRef.current.height = canvas.height;
      const playerCanvas = canvasRef.current.getContext('2d');
      // clear canvas
      playerCanvas?.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

      if (canvas.width === 0 || canvas.height === 0) return;

      playerCanvas?.drawImage(canvas, 0, 0);
    }, 10)

    distpatchPlayer({ type: 'APPEND_STYLE', styles: { display: 'block' } });
  }

  const cleanCanvasRef = () => {
    if (!canvasRef.current) return;

    const ctx = canvasRef.current.getContext('2d');
    if (!ctx) return;

    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
  }

  const clearCanvasRefresh = () => {
    if (canvasInterval.current) {
      clearInterval(canvasInterval.current)
    }
  }

  const stopCanvas = () => {
    clearCanvasRefresh()

    if (!canvasRef.current) return;
    distpatchPlayer({ type: 'APPEND_STYLE', styles: { display: 'none' } });
    cleanCanvasRef()
  }

  useEffect(() => {
    logger.highlighterPlayer.debug('Highlighter Player Init')

    channel.current = new BroadcastChannel<HighligterBroadcastPayloads>(channelName)

    const handleMessage = (e:HighligterBroadcastPayloads) => {
      if (!e) return;

      if (e.type === 'play') {
        cloneParentCanvasElement()
      }

      if (e.type === 'stop') {
        stopCanvas()
      }
    }

    // listen for message from parent
    channel.current.onmessage = handleMessage
    return () => {
      channel.current?.close()
      // clean canvas ref
      if (canvasRef.current) {
        cleanCanvasRef()
      }

      clearCanvasRefresh()
    }
  }, [])

  return (
    <canvas
      id={canvasPlayerId}
      ref={canvasRef}
      style={statePlayer.style}
      object-fit="cover"
    />
  )
}

export default HighlightPlayer;
