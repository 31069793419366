import React, { useState } from 'react';
import { DNABox, DNAButton, DNADivider, Iffy } from '@alucio/lux-ui';
import WidgetContainer from '../WidgetContainer';
import { useAllDocuments } from 'src/state/redux/selector/document';
import docFilters from 'src/state/redux/document/query';
import { useCurrentUserORM } from 'src/state/redux/selector/user';
import DNAFlatList from 'src/components/DNA/FlatList/DNAFlatList';
import { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty';
import { DocumentORM } from 'src/types/orms';
import { useDNADocumentActions } from 'src/components/DNA/Document/DNADocument.actions';

export enum DocumentWidgetVariant {
  MOST_VIEWED = 'Most Viewed Documents',
  RECENTLY_VIEWED = 'Recently Viewed Documents'
}

interface Props {
  variant: DocumentWidgetVariant
}

const Documents: React.FC<Props> = (props) => {
  const [isViewMoreActive, setIsViewMoreActive] = useState<boolean>(false);

  /* [TODO]: ACCURATE DATA WILL BE HANDLED IN BEAC-6869 AND BEAC-6870 */
  const userORM = useCurrentUserORM();
  const recentlyUpdatedFilter = docFilters.merge(
    docFilters.filters.published,
    docFilters.sorts.updatedAtDesc,
    docFilters.filters.defaultFilters(userORM?.meta.customFilterValues.defaultFilters || {}));
  const recentlyUpdatedDocuments = useAllDocuments(recentlyUpdatedFilter)
  const documents = recentlyUpdatedDocuments.slice(0, isViewMoreActive ? 10 : 5);
  /* END [TODO] */
  const documentActions = useDNADocumentActions();

  const onMoreDocumentsPressed = (): void => {
    setIsViewMoreActive((isActive) => !isActive);
  };

  const onPress = (documentORM: DocumentORM): void => {
    documentActions.preview(documentORM)();
  };

  return (
    <WidgetContainer title={props.variant}>
      <DNABox appearance="col" fill>
        <DNAFlatList
          items={documents}
          variant="dashboard"
          onPress={onPress}
          emptyVariant={EmptyVariant.DocumentDashboardEmpty}
        />
        <Iffy is={recentlyUpdatedDocuments.length > 5}>
          <DNABox appearance="col" spacing="md">
            <DNADivider />
            <DNAButton
              appearance="ghost"
              onPress={onMoreDocumentsPressed}
              padding="xs"
              size="xs"
            >
              { isViewMoreActive ? 'View less' : 'View more' }
            </DNAButton>
          </DNABox>
        </Iffy>
      </DNABox>
    </WidgetContainer>
  );
};

export default Documents;
