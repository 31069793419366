import React, { useMemo } from 'react'
import { useDispatch } from 'src/state/redux'
import im from 'immer'
import { GenericToast, ToastOrientations, useToast } from '@alucio/lux-ui'
import { Dispatch } from 'redux'
import { AssociatedFileORM, DocumentORM, DocumentVersionORM, EmailTemplateORM } from 'src/types/types'
import { CurrentUser, useCurrentUser } from 'src/state/redux/selector/user'
import { useRouteMatch, match } from 'src/router'

import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal'
import { contentPreviewModalActions } from 'src/state/redux/slice/contentPreviewModal'

import { handleBookmark } from 'src/components/Library/Util/util'
import { downloadContentFromCloudfront } from 'src/utils/loadCloudfrontAsset/loadCloudfrontAsset'
import DNADocumentStatusChangeModal from 'src/components/DNA/Modal/DNADocumentStatusChange'

import DNADocumentAddToFolder from 'src/components/DNA/Modal/DNADocumentAddToFolder'
import DNAFileRenameModal from '../Modal/DNAFileRenameModal'
import { ToastActions } from '@alucio/lux-ui/lib/components/Toast/useToast'
import { ContentShareModal, ShareVariantOptions } from '../Modal/ContentShareModal/ContentShareModal'
import { useEmailTemplateListForMSL } from 'src/state/redux/selector/emailTemplate'
import { sortCollection } from 'src/state/redux/selector/common'
import { useTenantCustomFields } from 'src/state/redux/selector/tenant'
import emailTemplateQuery from 'src/state/redux/emailTemplate/query'

import { API, graphqlOperation } from '@aws-amplify/api'
import {
  downloadDocumentVersionAsPdfFile,
} from '@alucio/aws-beacon-amplify/src/graphql/mutations'
import {
  CustomFieldDefinition,
  CustomFieldUsage,
  DocumentAccessLevel,
  FileType,
} from '@alucio/aws-beacon-amplify/src/models'
import { downloadAsLink } from 'src/screens/Documents/Export'
import { useAppSettings } from 'src/state/context/AppSettings'
import omit from 'lodash/omit'
import { copyDocPageUrlHandler } from 'src/utils/shareLink/shareLink.web'
import useCurrentPage from '../hooks/useCurrentPage'
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags'
import * as logger from 'src/utils/logger'

/**
 * [TODO] - Create safer structure/typings with Folder equivalent
 */

export type MatchFolderParams = {
  page: string,
  folderId: string
}

export type MatchNestedFolderParams = MatchFolderParams & {
  nestedFolderId: string
}

// [TODO-2126]  - Currently we use onCallback to allow any action to have allow deferred execution
//                for actions. (i.e. First dismiss a prompt THEN execute an action in here)
//              - ActionCallbacks are non-deferred but accessible to the action
//              - Consider combining onCallback into ActionsCallback, where we may accept both
//                deferred and non-deferred in one argument (instead of two separate ones)
export type ActionCallbacks = Partial<Record<DocumentContextAction, (documentORM: DocumentORM) => void>>

// [TODO]
//  - Can probably refactor this into action specific arguments
//    That is, arguments are starting to get crazy and aren't need for all actions
export type DocumentContextOptionsWithoutBulkActions = {
  [key in DocumentContextAction]: {
    icon: string,
    title: string,
    popoverText?: string,
    onPress: (
      d: DocumentORM,
      dispatch: Dispatch,
      // [TODO] - Not the cleanest, starting to deviate ...
      user: CurrentUser,
      match: {
        folder: match<MatchFolderParams> | null
        nestedFolder: match<MatchNestedFolderParams> | null
      },
      toast: ToastActions,
      isOnline?: boolean,
      emailTemplates?: EmailTemplateORM[],
      onCallback?: (cb: () => void) => void,
      actionCallback?: (docORM: DocumentORM) => void,
      customFields?: CustomFieldDefinition[],
      downloadBeaconFile?: boolean,
      pageNumber?: number,
    ) => () => void
  }
}

type BulkShareAndCopyOnPress = {
  onPress: (
    d: DocumentORM[],
    dispatch: Dispatch,
    // [TODO] - Not the cleanest, starting to deviate ...
    user: CurrentUser,
    match: {
      folder: match<MatchFolderParams> | null
      nestedFolder: match<MatchNestedFolderParams> | null
    },
    toast: ToastActions,
    isOnline?: boolean,
    emailTemplates?: EmailTemplateORM[],
    onCallback?: (cb: () => void) => void,
    actionCallback?: (docORM: DocumentORM) => void,
    customFields?: CustomFieldDefinition[],
  ) => () => void
}

interface DocumentContextOptions extends DocumentContextOptionsWithoutBulkActions {
  bulkAddToFolder: {
    onPress: (
      d: DocumentORM[],
      dispatch: Dispatch,
      // [TODO] - Not the cleanest, starting to deviate ...
      user: CurrentUser,
      match: {
        folder: match<MatchFolderParams> | null
        nestedFolder: match<MatchNestedFolderParams> | null
      },
      toast: ToastActions,
    ) => () => void
  },
  bulkShareEmail: BulkShareAndCopyOnPress,
}

type BindDocumentContextActionsWithoutBulkActions = {
  [key in DocumentContextAction]: (d: DocumentORM, pageNumber?: number) => () => void
}

export interface BindDocumentContextActions extends BindDocumentContextActionsWithoutBulkActions {
  bulkAddToFolder: (d: DocumentORM[]) => () => void
  bulkShareEmail: (d: DocumentORM[]) => () => void
  bulkCopyShareLink: (d: DocumentORM[]) => () => void
}

export enum DocumentContextActions {
  addToFolder = 'Add to folder',
  rename = 'Rename',
  duplicate = 'Duplicate',
  bookmark = 'Add to bookmarks',
  unbookmark = 'Remove bookmark',
  beaconLink = 'Copy link',
  publish = 'Publish',
  archive = 'Archive',
  unarchive = 'Unarchive',
  delete = 'Delete',
  downloadAsPDF = 'Download as PDF',
  shareEmail = 'Share',
  revoke = 'Revoke',
  present = 'View',
  preview = 'View',
  publisherPreview = 'Publisher Preview',
  download = 'Download',
  version = 'Version',
  unrevoke = 'Unrevoke',
}

/**
 * Get the file type from the documentORM or an array of documentORMs
 * @param doc - The documentORM or an array of documentORMs
 * @returns The file type
 */
const getFileType = (doc: DocumentORM | DocumentORM[]): FileType | undefined => {
  if (Array.isArray(doc)) {
    return doc.length > 0 ? doc[0]?.model?.type as FileType : undefined
  }
  return doc?.model?.type as FileType
}

/**
 * Checks if a document should be forced to download as PDF
 * @param action - The action being performed
 * @param fileType - The type of file
 * @param forceDownloadAsPDF - Flag to force PDF download
 */
const shouldForceDownloadAsPDF = (
  action: string,
  fileType?: FileType,
  forceDownloadAsPDF?: boolean,
): boolean => {
  const isPPTXFile = fileType !== undefined && fileType === FileType.PPTX;
  const isDownloadAction = action === 'download';

  return Boolean(forceDownloadAsPDF && isDownloadAction && isPPTXFile);
}

/**
 * takes the original document and remove the circular dependencies
 *  IMPORTANT: this document is intended for read only purposes and should not be used to update the original document
 * @param documentVersion
 * @returns  cloned objec
 */
const removeCircularDependencies = (documentVersion: DocumentVersionORM) => {
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value
  const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };

  const cloneDocVersion = (docVersion: DocumentVersionORM) : DocumentVersionORM => {
    const cloned = JSON.parse(JSON.stringify(docVersion, getCircularReplacer()))

    return {
      ...cloned,
      model: docVersion.model,
      meta: docVersion.meta,
      relations: {
        ...cloned.relations,
        pageGroups: docVersion.relations.pageGroups,
        pages: docVersion.relations.pages.map(page => omit(page, 'relations')),
        associatedFiles: docVersion.relations.associatedFiles,
      },
    }
  }

  return cloneDocVersion(documentVersion)
}

export type DocumentContextAction = keyof typeof DocumentContextActions

/** [TODO] WE CAN PROBABLY CONSOLIDATE BOTH FOLDER AND DOCUMENT */
/** This is a bit more convenient than using the raw contextActions below */
export const useDNADocumentActions = (
  onCallback?: (cb: () => void) => void,
  actionCallbacks?: ActionCallbacks,
): BindDocumentContextActions => {
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()
  const folder = useRouteMatch<MatchFolderParams>('/:page/:folderId')
  const nestedFolder = useRouteMatch<MatchNestedFolderParams>('/:page/:folderId/:nestedFolderId')
  const route = useCurrentPage({ exact: false })
  const isPublisher = route?.configOptions?.modules?.includes('publisher')
  const enablePowerPointAddin = useFeatureFlags('enablePowerPointAddin');
  const forceDownloadAsPDF = useFeatureFlags('BEAC_5882_MSL_Download_PPTX_as_PDF') && !isPublisher;
  const toast = useToast()
  const { isOnline } = useAppSettings()
  const emailTemplates = useEmailTemplateListForMSL()
  const customFieldsConfig = useMemo(() => ({ usages: { internalUsages: [CustomFieldUsage.CONTENT_SHARE] } }), [])
  const orderedTemplates = sortCollection(
    emailTemplates,
    emailTemplateQuery.sorts.titleAsc,
  )
  const customFieldsContentShare = useTenantCustomFields(customFieldsConfig)

  const bound = useMemo(() => im(documentContextOptions, draft => {
    for (const action in documentContextOptions) {
      draft[action] = (
        documentORM: DocumentORM | DocumentORM[],
        pageNumber?: number,
      ) => {
        /* Get the action callback and options, but override them to force PDF download
           if forceDownloadAsPDF flag is enabled and this is a download action */
        let actionCallback = actionCallbacks?.[action]
        let actionOptions = documentContextOptions[action]

        const fileType = getFileType(documentORM)
        if (shouldForceDownloadAsPDF(action, fileType, forceDownloadAsPDF)) {
          actionCallback = actionCallbacks?.downloadAsPDF
          actionOptions = documentContextOptions.downloadAsPDF
        }

        return actionOptions.onPress(
          documentORM,
          dispatch,
          currentUser,
          { folder, nestedFolder },
          toast,
          isOnline,
          orderedTemplates,
          onCallback,
          actionCallback,
          customFieldsContentShare,
          !isPublisher && enablePowerPointAddin,
          pageNumber,
        )
      }
    }
  }) as unknown as BindDocumentContextActions,
  [
    dispatch,
    currentUser,
    folder,
    nestedFolder,
    toast,
    isOnline,
    emailTemplates,
    orderedTemplates,
    customFieldsContentShare,
    onCallback,
    actionCallbacks,
    forceDownloadAsPDF,
  ],
  )
  return bound
}

export const documentContextOptions: DocumentContextOptions = {
  bulkAddToFolder: {
    onPress: (docORMs, dispatch, _, __, toast) => () => {
      dispatch(DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: true,
        component: (props) => (
          <DNADocumentAddToFolder
            {...props}
            itemORM={docORMs}
            toast={toast}
          />
        ),
      }),
      )
    },
  },
  addToFolder: {
    icon: 'folder-plus-outline',
    title: DocumentContextActions.addToFolder,
    onPress: (docORM, dispatch, _, __, toast) => () => {
      dispatch(DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: true,
        component: (props) => (
          <DNADocumentAddToFolder
            {...props}
            itemORM={docORM}
            toast={toast}
          />
        ),
      }),
      )
    },
  },
  rename: {
    icon: 'pencil',
    title: DocumentContextActions.rename,
    onPress: (documentORM, dispatch) => () => {
      dispatch(DNAModalActions.setModal(
        {
          isVisible: true,
          allowBackdropCancel: false,
          component: (props) => (
            <DNAFileRenameModal
              {...props}
              documentORM={documentORM}
            />
          ),
        },
      ))
    },
  },
  duplicate: {
    icon: 'content-copy',
    title: DocumentContextActions.duplicate,
    onPress: () => () => {
      console.warn('Not implemented yet')
    },
  },
  bookmark: {
    icon: 'bookmark',
    title: DocumentContextActions.bookmark,
    onPress: (documentORM, dispatch, user) => () => {
      if (!user) throw new Error('Could not bookmark')

      handleBookmark(
        documentORM.model,
        user,
        dispatch,
      )
    },
  },
  unbookmark: {
    icon: 'bookmark',
    title: DocumentContextActions.unbookmark,
    onPress: () => () => {
      console.warn('Not implemented yet')
    },
  },
  beaconLink: {
    icon: 'link-variant',
    title: DocumentContextActions.beaconLink,
    onPress: (documentORM, dispatch, _, __, toast ) => () => {
      copyDocPageUrlHandler(toast, documentORM.model.id)
    },
  },
  publish: {
    icon: 'publish',
    title: DocumentContextActions.publish,
    onPress: (documentORM, dispatch, _, __, toast) => () => {
      dispatch(DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: true,
        component: (modalProps) => (
          <DNADocumentStatusChangeModal
            {...modalProps}
            documentORM={documentORM}
            action="PUBLISHED"
            toast={toast}
          />
        ),
      }))
    },
  },
  archive: {
    icon: 'archive',
    title: DocumentContextActions.archive,
    // eslint-disable-next-line max-len
    popoverText: 'This action will make files invisible to viewers, however publishers can still view or un-archive the file in future. Links shared with access to this file will still work until they expire.',
    onPress: (documentORM, dispatch, _, __, toast, ___, ____, onCallback) => () => {
      dispatch(DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: true,
        component: (modalProps) => (
          <DNADocumentStatusChangeModal
            {...modalProps}
            documentORM={documentORM}
            action={documentORM.relations.documentVersions.length > 1 ? 'ARCHIVED_WITH_VERSIONS' : 'ARCHIVED'}
            toast={toast}
            onConfirmCallback={onCallback}
          />
        ),
      }))
    },
  },
  unarchive: {
    icon: 'archive',
    title: DocumentContextActions.unarchive,
    onPress: (documentORM, dispatch, _, __, toast, ___, ____, onCallback) => () => {
      dispatch(DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: true,
        component: (modalProps) => (
          <DNADocumentStatusChangeModal
            {...modalProps}
            documentORM={documentORM}
            action="UNARCHIVE"
            toast={toast}
            onConfirmCallback={onCallback}
          />
        ),
      }))
    },
  },
  bulkShareEmail: {
    onPress: (
      documentORMs,
      dispatch,
    ) => () => {
      // Merge all associated files into one array
      const allAssociatedFiles: AssociatedFileORM[] = []
      documentORMs.forEach(docORM => {
        const associatedFiles = docORM.relations.version.latestUsableDocumentVersionORM?.relations.associatedFiles ?? []
        allAssociatedFiles.push(...associatedFiles)
      })

      const entityShareIds: string[] = []
      documentORMs.forEach(docORM => {
        if (docORM.relations.version.latestPublishedDocumentVersionORM) {
          entityShareIds.push(docORM.relations.version.latestPublishedDocumentVersionORM.model.id)
        }
      })

      dispatch(DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: true,
        backdropVisible: true,
        component: (modalProps) => (
          <ContentShareModal
            entityShareIds={entityShareIds}
            {...modalProps}
            variant={ShareVariantOptions.DOC_VER_SHARE}
          />
        ),
      }))
    },
  },
  shareEmail: {
    icon: 'email-send-outline',
    title: DocumentContextActions.shareEmail,
    onPress: (
      documentORM,
      dispatch,
    ) => () => {
      const targetDocumentVersionORM = documentORM.relations.version.latestUsableDocumentVersionORM
      const entityShareIds = [targetDocumentVersionORM.model.id]

      dispatch(DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: false,
        backdropVisible: true,
        component: (modalProps) => (
          <ContentShareModal
            entityShareIds={entityShareIds}
            {...modalProps}
            variant={ShareVariantOptions.DOC_VER_SHARE}
          />
        ),
      }))
    },
  },
  delete: {
    icon: 'trash-can-outline',
    title: DocumentContextActions.delete,
    // eslint-disable-next-line max-len
    popoverText: 'This action will permanently remove the file from all Beacon locations. Links shared with access to this file will no longer work.',
    onPress: (documentORM, dispatch, _, __, toast, ___, ____, onCallback) => () => {
      dispatch(DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: true,
        component: (modalProps) => (
          <DNADocumentStatusChangeModal
            {...modalProps}
            documentORM={documentORM}
            action={documentORM.relations.documentVersions.length > 1 ? 'DELETED_WITH_VERSIONS' : 'DELETED'}
            toast={toast}
            onConfirmCallback={onCallback}
          />
        ),
      }))
    },
  },
  revoke: {
    icon: 'undo-variant',
    title: 'Revoke',
    // eslint-disable-next-line max-len
    popoverText: 'This action will make files invisible to viewers, however publishers can still view or un-revoke the file in future. Links shared with access to this file will no longer work.',
    onPress: (documentORM, dispatch, _, __, toast, ___, ____, onCallback) => () => {
      dispatch(DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: true,
        component: (modalProps) => (
          <DNADocumentStatusChangeModal
            {...modalProps}
            documentORM={documentORM}
            action={documentORM.relations.documentVersions.length > 1 ? 'REVOKED_WITH_VERSIONS' : 'REVOKED'}
            toast={toast}
            onConfirmCallback={onCallback}
          />
        ),
      }))
    },
  },
  unrevoke: {
    icon: 'undo-variant',
    title: DocumentContextActions.unrevoke,
    onPress: (documentORM, dispatch, _, __, toast, ___, ____, onCallback) => () => {
      dispatch(DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: true,
        component: (modalProps) => (
          <DNADocumentStatusChangeModal
            {...modalProps}
            documentORM={documentORM}
            action="UNREVOKE"
            toast={toast}
            onConfirmCallback={onCallback}
          />
        ),
      }))
    },
  },
  present: {
    icon: 'eye-outline',
    title: DocumentContextActions.present,
    onPress: (
      documentORM,
      dispatch,
      _, __, ___, ____, ______, _______,
      actionCallback,
      ___________, ____________,
      pageNumber,
    ) => () => {
      const documentVersion = documentORM.relations.version.latestUsableDocumentVersionORM

      dispatch(
        contentPreviewModalActions.setModalVisibility({
          documentVersionId: documentVersion.model.id,
          isFullWindow: true,
          isOpen: true,
          content: documentVersion,
          fromEllipsis: true,
          pageNumber,
        }),
      )
      actionCallback?.(documentORM);
    },
  },
  preview: {
    icon: 'eye-outline',
    title: DocumentContextActions.present,
    onPress: (
      documentORM,
      dispatch, _, __,
      toast,
      isOnline,
      ______, ________, _________, __________, ___________,
      pageNumber,
    ) => () => {
      const isPersonalFile = documentORM.model.accessLevel === DocumentAccessLevel.USER

      // [TODO-2126] - Use the ORM's latestUsableDocumentVersion instead
      const documentVersion =
        (!isOnline && documentORM.relations.version.cachedDocumentVersionORM) ||
        documentORM.relations.version.latestPublishedDocumentVersionORM ||
        documentORM.relations.version.latestDocumentVersionORM

      dispatch(
        contentPreviewModalActions.setModalVisibility({
          documentVersionId: documentVersion.model.id,
          isFullWindow: false,
          isOpen: true,
          toast: toast,
          content: isPersonalFile ? removeCircularDependencies(documentVersion) : documentVersion,
          pageNumber,
        }),
      )
    },
  },
  publisherPreview: {
    icon: 'play',
    title: DocumentContextActions.publisherPreview,
    onPress: (documentORM, dispatch, _, __, toast, isOnline) => () => {
      const documentVersion = (!isOnline && documentORM.relations.version.cachedDocumentVersionORM) ||
        documentORM.relations.version.latestDocumentVersionORM

      dispatch(
        contentPreviewModalActions.setModalVisibility({
          documentVersionId: documentVersion.model.id,
          isFullWindow: false,
          isOpen: true,
          toast: toast,
          content : documentVersion,
        }),
      )
    },
  },
  download: {
    icon: 'download',
    title: DocumentContextActions.download,
    onPress: (
      documentORM, _, __, ___, ____, _____, ______, _______, ________, _________,
      enablePowerPointAddin,
    ) => () => {
      const currentDocVersion = documentORM.relations.version.latestPublishedDocumentVersionORM ||
        documentORM.relations.version.latestDocumentVersionORM

      analytics?.track('DOCUMENT_DOWNLOAD', {
        action: 'DOWNLOAD',
        category: 'DOCUMENT',
        documentId: documentORM.model.id,
        documentVersionId: currentDocVersion.model.id,
      })

      const getAddinBeaconFile = (documentVersion: DocumentVersionORM) => {
        if ((documentVersion.model.converterVersion && documentVersion.model.converterVersion < 3) ||
          documentVersion.model.type !== 'PPTX'
        ) {
          return currentDocVersion.meta.srcFileDownloadURL;
        }

        const tenant = documentORM.model.tenantId;
        const documentId = documentVersion.model.documentId;
        const documentVersionId = documentVersion.model.id;

        return `${tenant}/${documentId}/${documentVersionId}/${documentVersionId}_beacon.pptx`;
      }

      const newUrl = enablePowerPointAddin
        ? getAddinBeaconFile(currentDocVersion)
        : currentDocVersion.meta.srcFileDownloadURL

      downloadContentFromCloudfront(
        newUrl,
        currentDocVersion.model.srcFilename!,
        documentORM.model.type,
      )
    },
  },
  version: {
    icon: 'layers-triple',
    title: DocumentContextActions.version,
    onPress: (documentORM, _, __, ___, ____, ______, _______, ________, versionCallback) => () => {
      // [NOTE] - We rely on parent component to do some action to enable the versioning panel
      //          i.e. at a component level
      versionCallback?.(documentORM)
    },
  },
  downloadAsPDF: {
    icon: 'file-pdf-box',
    title: DocumentContextActions.downloadAsPDF,
    onPress: (documentORM, _, __, ___, toast) => async () => {
      logger.util.info('Starting DocVer as PDF download')
      const currentDocVersion = documentORM.relations.version.latestPublishedDocumentVersionORM ||
        documentORM.relations.version.latestDocumentVersionORM
      const processingToastId = toast.add(
        <GenericToast
          title="Generating PDF download..."
          status="information"
        />,
        ToastOrientations.TOP_RIGHT,
      )
      try {
        const { data } = await API.graphql(
          graphqlOperation(downloadDocumentVersionAsPdfFile, {
            documentVersionId: currentDocVersion.model.id,
          }),
        ) as { data: { downloadDocumentVersionAsPdfFile: string } }

        logger.util.info('Generated download URL -- Starting download')
        toast.add(
          <GenericToast
            title="Download Started..."
            status="download"
          />,
          ToastOrientations.TOP_RIGHT,
          true,
        )
        downloadAsLink(data.downloadDocumentVersionAsPdfFile)
      } catch (e) {
        logger.util.error(e)
        toast.add(
          <GenericToast
            title="There was an error downloading this Document. Please contact support"
            status="error"
          />,
          ToastOrientations.TOP_RIGHT,
          true,
          0,
        )
      } finally {
        toast.remove(processingToastId)
      }
    },
  },
}
