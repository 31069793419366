import { createSelector, Selector } from '@reduxjs/toolkit';
import lunr from 'lunr';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import {
  allDocumentsSortedFilteredPaginated,
  indexedDocumentList,
  IndexedDocumentORM,
  selOpts,
} from '../document';
import {
  FilterAndSortOptions,
  paginateCollection,
  sortCollection,
} from 'src/state/redux/selector/common';
import { DocumentORM, CustomDeckORM } from 'src/types/types';
import { userTenant } from '../user';
import {
  DocumentStatus,
  Tenant,
  CustomFieldDefinition,
  FieldStatus,
  ControlType,
} from '@alucio/aws-beacon-amplify/src/models';
import stopWordsList from './stopWordsList.json';
import { merge, filters } from 'src/state/redux/document/query';
import { useMemo } from 'react';
import { allCustomDecks, customDecksORMMap, folderItemORMMap } from '../folder';

export interface DocumentSearchOptions {
  text: string;
  isPublisher?: boolean;
  status?: (DocumentStatus | keyof typeof DocumentStatus)[];
}

// [NOTE] - These warnings are relevant if we're serializing for loading/exporting indexes (which we are not)
//          per the docs https://lunrjs.com/docs/lunr.Pipeline.html
//        - We can probably safely ignore these (as we've been ignoring them for the past 3+ years)
const ignoreWarningsPartials = [
  'Overwriting existing registered function',
  'Function is not registered with pipeline',
]
const originalWarn = lunr.utils.warn
const newWarn: typeof lunr.utils.warn = (message) => {
  if (ignoreWarningsPartials.some(warning => message.includes(warning))) return
  originalWarn(message)
}

lunr.utils.warn = newWarn

export const searchText = (_: RootState, documentSearchOptions: DocumentSearchOptions) => documentSearchOptions;

const LUNR_RESERVED_CHARACTERS = ['*', '-', '+', '~', ':', '^', '/'];
const SPECIAL_CHARACTERS_REGEX = /-|\/|\+|\*|~|\^|:/;

type IndexedLunrDocumentResults = { index: lunr.Index, documents: IndexedDocumentORM }
type IndexedLunrCustomDeckResults = { index: lunr.Index, customDeckORMList: CustomDeckORM[] }

// RETURNS AN INDEXED LUNR'S OBJECT BASED ON PUBLISHED
const indexedSearchList: Selector<RootState, IndexedLunrDocumentResults> = createSelector(
  indexedDocumentList,
  (_: RootState, opts: { isPublisher: boolean }) => opts.isPublisher,
  useIndexedSearchList,
);

/** TODO: Identify why lunr is complaining about overwriting registered functions */

/** Register custom trimmer and stopwordfilter functions */
const trimmer = function (token) {
  return token.update(function (s) {
    return s.trim();
  })
};
lunr.Pipeline.registerFunction(trimmer, 'trimmer')

const stopWordFilter = lunr.generateStopWordFilter(stopWordsList);
lunr.Pipeline.registerFunction(stopWordFilter, 'stopWordFilter')

// RETURNS AN INDEXED LUNR'S OBJECT BASED ON THE RECEIVED DOCUMENTS
function useIndexedSearchList(
  documents: IndexedDocumentORM,
  isPublisher?: boolean,
) {
  // TO SEPARATE STRINGS INTO TOKEN ONLY BY SPACES
  lunr.tokenizer.separator = /[\s]+/;

  /** TODO: This convienience function appears to be registering default pipeline functions
   * which are then overwritten by our custom implementations. Should look into instantiating
   * in a way that does not register default functions to avoid the lunr warnings regarding
   * overwriting existing registered pipeline functions. Possibly using lunr.Builder? */
  const index = lunr(function () {
    /** Remove default pipeline functions */
    this.pipeline.reset()
    /** Pipeline function to remove whitespaces */
    this.pipeline.add(trimmer)
    /** Pipeline function to prevent selected words from being searched against using our
     * custom stopwords list
     */
    this.pipeline.add(stopWordFilter)

    this.field('title', { boost: 5 });
    this.field('releaseNotes');
    this.ref('id');

    const ids = Object.keys(documents);

    const fieldIDs = Object.values(documents[ids[0]]?.meta.customValues.configsMap || {})
      .reduce<string[]>(
        (prevMap, {
          field,
        }) => {
          const {
            controlType,
            status,
            id,
          } = field

          const isEnabled = status === 'ENABLED'
          const ignoredControlTypes: CustomFieldDefinition['controlType'][] = ['CHECKBOX', 'DATEPICKER']
          const isRelevantControl = !ignoredControlTypes.includes(controlType)

          // Ensure we are only working with enabled fields
          if (isEnabled && isRelevantControl) {
            // Add id of the enabled field to the field id list
            prevMap.push(id)
          }
          return prevMap
        },
        [],
      )

    fieldIDs.forEach((fieldID) => this.field(fieldID))

    /** For lunr, we need to generate first a list of "fields" to search against and then populate those fields with data based on the labels for each document */
    ids.forEach((id) => {
      const documentModel = documents[id].relations.version.latestUsableDocumentVersionORM?.model;

      if (!documentModel) {
        return;
      }

      const {
        title,
      } = documentModel

      const { configsMap } = documents[id].meta.customValues

      // Note: when lunr receives a field with an array of strings, it handles it as if it is already tokenized
      // This combines the array of strings into just one so lunr can tokenize it
      const mappedCustomValues = fieldIDs.reduce((acc, id) => {
        acc[id] = configsMap[id]?.displayValues.join(' ') ?? '';
        return acc;
      }, {});

      const documentVersion = isPublisher ? documents[id].relations.version.latestUsableDocumentVersionORM
        : documents[id].relations.version.latestPublishedDocumentVersionORM;

      this.add({
        id,
        title,
        releaseNotes: documentVersion?.model.releaseNotes,
        ...mappedCustomValues,
      });
    });
  })

  return { index, documents };
}

const twoLettersSearchableValues: Selector<RootState, string[]> = createSelector(
  userTenant,
  (tenant: Tenant | undefined): string[] => {
    const values: string[] = [];

    tenant?.config?.customFields?.forEach((field) => {
      if (field.status === FieldStatus.ENABLED && field.controlType !== ControlType.CHECKBOX) {
        field.fieldValueDefinitions.forEach(({ value }) => {
          if (value.length === 2) {
            values.push(value)
          }
        })
      }
    });

    return values;
  },
);

// IT PERFORMS A SEARCH OVER THE RECEIVED INDEXED LIST
const performDocumentSearch = (
  canPerformSearch: (text: string) => boolean,
  searchIndex: IndexedLunrDocumentResults,
  searchOptions: DocumentSearchOptions,
) => {
  const { text, status } = searchOptions

  // THE SEARCH WILL BE PERFORMED IF THE TEXT IS LONGER THAN 2 CHARACTERS OR IF THE TEXT IS PURPOSE
  if (!canPerformSearch(text)) {
    return [];
  }

  const { index, documents } = searchIndex
  const query = queryBuilder(searchOptions)

  if (!query) {
    return [];
  }

  // PERFORM THE SEARCH
  const searchResult = index.search(query)

  // IF WE'RE NOT ADDING ANY FILTER BY STATUS, WE CAN AVOID CHECKING IT
  if (!status || !status.length) {
    // FIND THE DOCUMENTORM OBJECTS FROM THE RESULT
    return searchResult.map(({ ref }) => documents[ref])
  }

  return searchResult.reduce<DocumentORM[]>((acc, { ref }) => {
    const documentORM = documents[ref]

    if (documentORM && status.includes(documentORM.model.status)) {
      acc.push(documentORM)
    }

    return acc
  }, [])
}

export const isDuplicatedDocumentTitle = (
  lunrIndex: IndexedLunrDocumentResults,
  title: string,
  targetDocumentID: string,
): boolean => {
  const canSearchPlacerHolder = (_: string) => true
  const searchResult = performDocumentSearch(canSearchPlacerHolder, lunrIndex, { text: title, isPublisher: true })
  const lowerCaseTitle = title.toLowerCase()

  if (!searchResult.length) {
    return false;
  }

  const duplicateExists: boolean = searchResult.some(({
    relations: {
      version: {
        latestDocumentVersionORM,
        latestPublishedDocumentVersionORM,
      },
    },
  }) => {
    const documentVersionToCompare = latestPublishedDocumentVersionORM || latestDocumentVersionORM
    const { title } = documentVersionToCompare.model
    const { id } = documentVersionToCompare.relations.documentORM.model
    const isDuplicate =
      title?.toLowerCase() === lowerCaseTitle &&
      id !== targetDocumentID

    return isDuplicate
  })

  return duplicateExists
}

const handleCanPerformSearch: Selector<RootState, (s: string) => boolean> = createSelector(
  twoLettersSearchableValues,
  (values: string[]): (searchText: string) => boolean => {
    return (searchText: string) => (searchText && searchText.length > 2) || values.includes(searchText.toUpperCase());
  },
);

const handleDocumentSearch: Selector<RootState, DocumentORM[]> = createSelector(
  handleCanPerformSearch,
  indexedSearchList,
  searchText,
  performDocumentSearch,
);

const allDocumentsSortedFilteredSearched: Selector<RootState, DocumentORM[]> = createSelector(
  handleDocumentSearch,
  selOpts,
  sortCollection<DocumentORM>,
)

const allDocumentsSortedFilteredSearchedPaginated: Selector<
  RootState,
  ReturnType<typeof paginateCollection<DocumentORM>>
> = createSelector(
  allDocumentsSortedFilteredSearched,
  selOpts,
  paginateCollection<DocumentORM>,
)

function queryBuilder(searchOptions: DocumentSearchOptions) {
  const tokens = lunr.tokenizer(searchOptions.text);
  let query = ''

  // CREATE THE QUERY. ("OR" CONDITION WILL BE APPLIED)
  tokens.forEach((token) => {
    let cleanedToken = '';
    const unModifiedToken = token.toString().trim();

    // TO AVOID ITERATING OVER EVERY CHAR OF THE TOKENS,
    // WE FIRST CHECK IF THE TOKEN HAS A SPECIAL CHARACTER
    if (unModifiedToken.match(SPECIAL_CHARACTERS_REGEX)) {
      // WE NEED TO ESCAPE THE SPECIAL CHARACTERS SO LUNR DOESN'T CONSIDER THEM AS MODIFIERS
      [...(unModifiedToken)].forEach((char) => {
        cleanedToken += LUNR_RESERVED_CHARACTERS.includes(char) ? `\\${char}` : char;
      });
    } else {
      cleanedToken = unModifiedToken;
    }

    const tokenLowercased = cleanedToken.toLowerCase();
    const isRequired = unModifiedToken.charAt(0) === '!';

    // WE REMOVE THE "!"
    if (isRequired) {
      cleanedToken = cleanedToken.substring(1, cleanedToken.length);
    }

    if (cleanedToken) {
      const tokenCheck = new lunr.Token(cleanedToken, {})
      if (tokenLowercased === 'us') {
        // IF THE TOKEN IS "US" TREAT IT AS A WHOLE WORD (NOT LIKE A SUFFIX/PREFIX)
        query += `${isRequired ? '+' : ''}${tokenLowercased} `;
      } else if (lunr.stopWordFilter(tokenCheck)) {
        // IF IT'S NOT A STOP WORD, ADD IT TO THE QUERY
        query += `${isRequired ? '+' : ''}${cleanedToken}* `;
      }
    }
  });

  return query;
}

export const useAllDocumentsPaginated =
  (searchOptions?: DocumentSearchOptions, opts?: FilterAndSortOptions<DocumentORM>):
    ReturnType<typeof allDocumentsSortedFilteredPaginated | typeof allDocumentsSortedFilteredSearchedPaginated> =>
    useSelector((state: RootState) =>
      searchOptions != null
        ? allDocumentsSortedFilteredSearchedPaginated(state, searchOptions, opts)
        : allDocumentsSortedFilteredPaginated(state, undefined, opts))

export const useMSLDocumentSearch =
  (searchOptions: DocumentSearchOptions, opts?: FilterAndSortOptions<DocumentORM>) => {
    const layeredFilters = useMemo(
      () => {
        return opts
          ? merge(opts, filters.published)
          : filters.published
      },
      [opts],
    )

    return useSelector((state: RootState) => allDocumentsSortedFilteredSearched(state, searchOptions, layeredFilters));
  }

export const useDocumentSearch = (searchOptions: DocumentSearchOptions, opts?: FilterAndSortOptions<DocumentORM>) =>
  useSelector((state: RootState) => allDocumentsSortedFilteredSearched(state, searchOptions, opts));

export const useCanPerformSearch = () =>
  useSelector((state: RootState) => handleCanPerformSearch(state));

function useIndexedCustomDecks(customDeckORMList: CustomDeckORM[]) {
  // TO SEPARATE STRINGS INTO TOKEN ONLY BY SPACES
  lunr.tokenizer.separator = /[\s]+/;
  lunr.trimmer = function (token) {
    return token.update(function (s) {
      return s.trim();
    })
  };
  const index = lunr(function () {
    this.field('title', { boost: 5 });
    this.ref('id');
    this.pipeline.reset()
    this.pipeline.remove(lunr.stemmer);
    this.pipeline.add(stopWordFilter)

    customDeckORMList.forEach((customDeck) => {
      // Since the custom deck search is only used in the meeting add content (where we only want presentable custom decks), we can do this approach for now.
      // Custom deck filtering to be implemented in tech debt ticket BEAC-3779
      if (customDeck.meta.permissions.MSLPresent) {
        this.add({
          id: customDeck.model.id,
          title: customDeck.model.title,
        })
      }
    })
  });

  return {
    index,
    customDeckORMList,
  }
}

const indexedCustomDecks: Selector<RootState, IndexedLunrCustomDeckResults> = createSelector(
  allCustomDecks,
  folderItemORMMap,
  (customDecks, folderItemORMMap) => {
    // workaround to filter deleted custom decks since the custom deck has no status field
    // custom deck filtering to be implemented in tech debt ticket BEAC-3779
    const existingCustomDecks = customDecks.filter((customDeck) => folderItemORMMap.has(customDeck.model.id));
    return useIndexedCustomDecks(existingCustomDecks);
  },
)
const customDeckSearch: Selector<RootState, CustomDeckORM[]> = createSelector(
  indexedCustomDecks,
  customDecksORMMap,
  searchText,
  (customDecksIndex, customDecksORMMap, searchText) => {
    if (searchText.text.length < 3) {
      return [];
    }
    const query = queryBuilder(searchText);
    const results = customDecksIndex.index.search(query);

    const searchResults = results.map(({ ref }) => {
      return customDecksORMMap[ref];
    });

    return searchResults;
  },
);

export const useCustomDeckSearch = (text: string) => {
  const searchParams = useMemo(
    () => ({ text }),
    [text],
  )

  return useSelector((state: RootState) => customDeckSearch(state, searchParams));
};

export const useIndexedLunrDocumentList = (isPublisher: boolean) =>
  useSelector((state: RootState) => indexedSearchList(state, { isPublisher }));
