import React, { useMemo, useState } from 'react';
import { StyleSheet } from 'react-native';
import { useHistory } from 'react-router';
import { DNABox, DNAButton, DNAText } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { useAppSettings } from 'src/state/context/AppSettings';
import { useCRMStatus } from 'src/screens/Profile/CRMIntegration';
import WidgetContainer from '../WidgetContainer';

const styles = StyleSheet.create({
  actionRowTitle: {
    fontSize: 15,
    fontWeight: '600',
  },
  actionRow: {
    paddingTop: 16,
    borderTopWidth: 1,
    borderTopColor: colors['color-gray-50'],
  },
});

type ActionRowProps = {
  title: string,
  description: string,
  actionButtonText: string,
  actionButtonFunction: () => void,
}

const ActionRow: React.FC<ActionRowProps> = (props) => {
  const { title, description, actionButtonText, actionButtonFunction } = props;

  return (
    <DNABox fill appearance="col" spacing="md" style={styles.actionRow}>
      <DNAText style={styles.actionRowTitle}>{title}</DNAText>
      <DNAText status="neutral">{description}</DNAText>
      <DNAButton
        appearance="outline"
        padding="sm"
        children={actionButtonText}
        onPress={actionButtonFunction}
      />
    </DNABox>
  )
};

const RecommendedActions: React.FC = () => {
  const history = useHistory();
  const { displayCRMConnectButton } = useCRMStatus();
  const { isOfflineEnabled, isOnline } = useAppSettings();
  const [displayActions, setDisplayActions] = useState(false);

  const availableActionCount = useMemo(() =>
    [displayCRMConnectButton, !isOfflineEnabled].filter(displayAction => displayAction).length,
  [displayCRMConnectButton, isOfflineEnabled]);

  const handleConnectToCRM = () => {
    history.push('/profile/crm-integration');
  };

  const handleActivateOfflineMode = () => {
    history.push('/profile/offline');
  };

  if (!isOnline || !availableActionCount) return null;
  // [NOTE]: Avoid using Iffy here, as a null child will render extra spacing.
  return (
    <WidgetContainer
      title="Recommended Actions"
      actionsCount={availableActionCount}
      headerRightSideComponent={<DNAButton
        appearance="ghost"
        padding="xs"
        children={displayActions ? 'Hide' : 'View'}
        onPress={() => setDisplayActions(p => !p)}
      />}
    >
      {displayActions &&
        <DNABox fill appearance="col" spacing="md">
          {
            displayCRMConnectButton && (
              <ActionRow
                title="Connect to CRM"
                // eslint-disable-next-line max-len
                description="Your organization has enabled CRM integration. Please log into your CRM to sync the latest data."
                actionButtonText="Connect"
                actionButtonFunction={handleConnectToCRM}
              />
            )
          }
          {
            !isOfflineEnabled && (
              <ActionRow
                title="Activate Offline Mode"
                description="Install Beacon for offline use on your computer as an application."
                actionButtonText="Activate"
                actionButtonFunction={handleActivateOfflineMode}
              />
            )
          }
        </DNABox>
      }
    </WidgetContainer>
  );
};

export default RecommendedActions;
