import React, { useMemo } from 'react'
import { store, useDispatch } from 'src/state/redux'
import im from 'immer'
import { DocumentVersionORM, EmailTemplateORM, FolderItemORM } from 'src/types/types'
import { CurrentUser, useCurrentUser } from 'src/state/redux/selector/user'
import { folderActions } from 'src/state/redux/slice/folder'
import { UpdateType, sharedFolderActions } from 'src/state/redux/slice/sharedFolder'
import { contentPreviewModalActions } from 'src/state/redux/slice/contentPreviewModal'
import { downloadContentFromCloudfront } from 'src/utils/loadCloudfrontAsset/loadCloudfrontAsset'
import { handleBookmark } from 'src/components/Library/Util/util'
import { GenericToast, useToast, ToastOrientations, LuxStatusEnum, LuxStatusV2Enum } from '@alucio/lux-ui'
import { ToastActions } from '@alucio/lux-ui/lib/components/Toast/useToast'
import { slideSelectorModalActions } from 'src/state/redux/slice/SlideSelectorModal/SlideSelectorModal'
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal'
import { ShareVariantOptions } from 'src/components/DNA/Modal/ContentShareModal/ContentShareModal'
import { useEmailTemplateListForMSL } from 'src/state/redux/selector/emailTemplate'
import DNADocumentAddToFolder from '../Modal/DNADocumentAddToFolder'
import emailTemplateQuery from 'src/state/redux/emailTemplate/query'
import { sortCollection } from 'src/state/redux/selector/common'
import { API, graphqlOperation } from '@aws-amplify/api'
import {
  downloadDocumentVersionAsPdfFile,
} from '@alucio/aws-beacon-amplify/src/graphql/mutations'
import { downloadAsLink } from 'src/screens/Documents/Export'
import { useTenantCustomFields } from 'src/state/redux/selector/tenant'
import { CustomFieldDefinition, CustomFieldUsage, FileType } from '@alucio/aws-beacon-amplify/src/models'
import { ContentShareModal } from '../Modal/ContentShareModal/ContentShareModal'
import { isSharedEditableFolder } from 'src/utils/foldersHelpers'
import useCurrentPage from '../hooks/useCurrentPage'
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags'
import * as logger from 'src/utils/logger'

/**
 * [TODO] - Create safer structure/typings with Folder equivalent
 */

export type MatchFolderParams = {
  page: string,
  folderId: string
}

export type MatchNestedFolderParams = MatchFolderParams & {
  nestedFolderId: string
}

export type DocumentVersionContextOptions = {
  [key in keyof typeof DocumentVersionContextActions]: {
    icon: string,
    title: DocumentVersionContextActions,
    status?: LuxStatusV2Enum
    iconStatus?: LuxStatusEnum
    onPress: (
      d: DocumentVersionORM,
      dispatch: typeof store.dispatch,
      // [TODO] - Not the cleanest, starting to deviate ...
      user: CurrentUser,
      toast: ToastActions,
      folderItemORM?: FolderItemORM,
      emailTemplates?: EmailTemplateORM[],
      meetingId?: string,
      customFieldsContentShare?: CustomFieldDefinition[],
      isReadOnly? : boolean,
      downloadBeaconFile?: boolean,
    ) => () => void
  }
}

export type BindDocumentVersionContextActions = {
  [key in keyof typeof DocumentVersionContextActions]: (d: DocumentVersionORM, f?: FolderItemORM,
    meetingId?: string, isReadOnly?: boolean) => () => void
}

export enum DocumentVersionContextActions {
  addToFolder = 'Add to folder',
  download = 'Download',
  downloadAsPDF = 'Download as PDF',
  duplicate = 'Duplicate',
  shareEmail = 'Share',
  present = 'Present',
  preview = 'View',
  removeFromFolder = 'Remove from folder',
  bookmark = 'Bookmark',
  selectSlides = 'Select slides',
  rename = 'Rename'
}

export type DocumentVersionActions = (keyof typeof DocumentVersionContextActions)

/** [TODO] WE CAN PROBABLY CONSLIDATE BOTH FOLDER AND DOCUMENT */
/** This is a bit more convenient than using the raw contextActions below */
export const useDNADocumentVersionActions = (): BindDocumentVersionContextActions => {
  const customTenantFieldsConfig = useMemo(() => ({ usages: { internalUsages: [CustomFieldUsage.CONTENT_SHARE] } }), [])

  const dispatch = useDispatch()
  const currentUser = useCurrentUser()
  const route = useCurrentPage({ exact: false })
  const isPublisher = route?.configOptions?.modules?.includes('publisher')
  const enablePowerPointAddin = useFeatureFlags('enablePowerPointAddin');
  const forceDownloadAsPDF = useFeatureFlags('BEAC_5882_MSL_Download_PPTX_as_PDF') && !isPublisher;
  const toast = useToast()
  const emailTemplates = useEmailTemplateListForMSL()
  const orderedTemplates = sortCollection(
    emailTemplates,
    emailTemplateQuery.sorts.titleAsc,
  )
  const customFieldsContentShare = useTenantCustomFields(customTenantFieldsConfig)

  const bound = useMemo(() => im(documentVersionContextOptions, draft => {
    for (const action in documentVersionContextOptions) {
      const actionTyped = action as keyof typeof DocumentVersionContextActions
      draft[action] = (
        documentVersionORM: DocumentVersionORM,
        folderItemORM?: FolderItemORM,
        meetingId?: string,
        isReadOnly?: boolean,
      ) => {
        /* Get the action callback and options, but override them to force PDF download
           if forceDownloadAsPDF flag is enabled and this is a download action */
        const fileType = documentVersionORM.model.type
        let actionOptions = documentVersionContextOptions[actionTyped]
        if (forceDownloadAsPDF && action === 'download' && fileType === FileType.PPTX) {
          actionOptions = documentVersionContextOptions.downloadAsPDF
        }

        return actionOptions.onPress(
          documentVersionORM,
          dispatch,
          currentUser,
          toast,
          folderItemORM,
          orderedTemplates,
          meetingId,
          customFieldsContentShare,
          isReadOnly,
          !isPublisher && enablePowerPointAddin,
        )
      }
    }
  }) as unknown as BindDocumentVersionContextActions,
  [dispatch, currentUser, toast, emailTemplates, customFieldsContentShare, orderedTemplates],
  )

  return bound
}

export const documentVersionContextOptions: DocumentVersionContextOptions = {
  download: {
    icon: 'download',
    title: DocumentVersionContextActions.download,
    onPress: (currentDocVersionORM, _, __, ___, ____, _____, ______, _______, ________,
      enablePowerPointAddin) => () => {
      analytics?.track('DOCUMENT_DOWNLOAD', {
        action: 'DOWNLOAD',
        category: 'DOCUMENT',
        documentId: currentDocVersionORM.model.documentId,
        documentVersionId: currentDocVersionORM.model.id,
      });

      const getAddinBeaconFile = (documentVersion: DocumentVersionORM) => {
        if ((documentVersion.model.converterVersion && documentVersion.model.converterVersion < 3) ||
          documentVersion.model.type !== 'PPTX'
        ) {
          return documentVersion.meta.srcFileDownloadURL;
        }

        const tenant = documentVersion.model.tenantId;
        const documentId = documentVersion.model.documentId;
        const documentVersionId = documentVersion.model.id;

        return `${tenant}/${documentId}/${documentVersionId}/${documentVersionId}_beacon.pptx`;
      }

      const newUrl = enablePowerPointAddin
        ? getAddinBeaconFile(currentDocVersionORM)
        : currentDocVersionORM.meta.srcFileDownloadURL

      downloadContentFromCloudfront(
        newUrl,
        currentDocVersionORM.model.srcFilename!,
        currentDocVersionORM.model.type,
      )
    },
  },
  duplicate: {
    icon: 'content-copy',
    title: DocumentVersionContextActions.duplicate,
    onPress: (d, dispatch, u, t, fiORM) => () => {
      const defaultTitle = d.model.title
      const targetFolderORM = fiORM?.relations.parentORM
      const isSharedEditFolder = targetFolderORM && isSharedEditableFolder(targetFolderORM, u.userProfile?.id)
      if (!defaultTitle || !targetFolderORM || !fiORM) return
      if (isSharedEditFolder) {
        dispatch(sharedFolderActions.duplicateItem({
          targetFolderORM,
          itemToDupe: fiORM,
        }))
      }
      else {
        dispatch(folderActions.duplicateItem({
          targetFolderORM,
          itemToDupe: fiORM,
        }))
      }
    },
  },
  present: {
    icon: 'eye-outline',
    title: DocumentVersionContextActions.preview,
    onPress: (docVersionORM, dispatch, _, __, folderItemORM) => () => {
      dispatch(
        contentPreviewModalActions.setModalVisibility({
          documentVersionId: docVersionORM.model.id,
          folderItemORM: folderItemORM,
          content: folderItemORM || docVersionORM,
          isFullWindow: true,
          fromEllipsis: true,
          isOpen: true,
        },
        ))
    },
  },
  preview: {
    icon: 'eye-outline',
    title: DocumentVersionContextActions.preview,
    onPress: (docVersionORM, dispatch, _, __, folderItemORM, ___, ____, _____, isReadOnly ) => () => {
      dispatch(
        contentPreviewModalActions.setModalVisibility({
          documentVersionId: docVersionORM.model.id,
          folderItemORM: folderItemORM,
          content: folderItemORM || docVersionORM,
          isFullWindow: false,
          isOpen: true,
          isReadOnly,
        },
        ))
    },
  },
  removeFromFolder: {
    icon: 'folder-remove',
    title: DocumentVersionContextActions.removeFromFolder,
    status: LuxStatusV2Enum.danger,
    iconStatus: LuxStatusEnum.danger,
    onPress: (docVersionORM, dispatch, user, toast, folderItemORM) => () => {
      // [TODO-279] This is a pretty hacky workaround
      //  Context menus don't allow a good path to pass in arbitrary values in right now
      //  So determine the target folder by checking what folder we're currently in
      const targetFolderId = folderItemORM?.relations.parentORM?.model.id

      if (!targetFolderId) {
        console.error('Could not determine the current folder path')
        return;
      }
      analytics?.track('FOLDER_REMOVE_DOCUMENT_VERSION', {
        action: 'REMOVE_DOCUMENT_VERSION',
        category: 'FOLDER',
        folderId: targetFolderId,
        documentId: docVersionORM.model.documentId,
        documentVersionId: docVersionORM.model.id,
      });
      const folderORM = folderItemORM?.relations.parentORM
      if (!folderORM) return

      if (isSharedEditableFolder(folderORM, user.userProfile?.id)) {
        dispatch(sharedFolderActions.updateItems(
          {
            folder: folderORM,
            folderItems: [folderItemORM],
            action: UpdateType.DELETE_ITEM,
          },
        ))
      } else {
        dispatch(folderActions.removeItem(folderItemORM!))
      }
      toast.add(
        <GenericToast
          title="File removed from My Folders."
          status="success"
        />,
        ToastOrientations.TOP_RIGHT,
      )
    },
  },
  shareEmail: {
    icon: 'email-send-outline',
    title: DocumentVersionContextActions.shareEmail,
    onPress: (docVersionORM, dispatch, user, toast, _, emailTemplates, meetingId) => () => {
      const entityShareIds = [docVersionORM.model.id]

      // [TODO] - Needs analytics?

      dispatch(DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: false,
        backdropVisible: true,
        component: (modalProps) => (
          <ContentShareModal
            entityShareIds={entityShareIds}
            {...modalProps}
            variant={ShareVariantOptions.DOC_VER_SHARE}
            meetingId={meetingId}
          />
        ),
      }))
    },
  },
  // Bookmark the parent document
  bookmark: {
    icon: 'bookmark',
    title: DocumentVersionContextActions.bookmark,
    onPress: (docVersionORM, dispatch, user) => () => {
      if (!user) throw new Error('Could not bookmark')

      handleBookmark(
        docVersionORM.relations.documentORM.model,
        user,
        dispatch,
      )
    },
  },
  /**
   * This property adds the 'Select slides' option to a document context menu. A
   * redux dispatch is called and the folderitemORM is passed as the payload
   */
  selectSlides: {
    icon: 'pencil',
    title: DocumentVersionContextActions.selectSlides,
    onPress: (d, dispatch, user, toast, folderItemORM) => () => {
      dispatch(
        slideSelectorModalActions.setActiveFolderItem({
          activeFolderItemId: folderItemORM?.model.id,
          folderId: folderItemORM?.relations.parentORM?.model.id,
          isOpen: true,
        },
        ),
      )
    },
  },
  addToFolder: {
    icon: 'folder-plus',
    title: DocumentVersionContextActions.addToFolder,
    onPress: (_, dispatch, __, toast, folderItemORM) => () => {
      dispatch(DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: true,
        component: (props) => (
          <DNADocumentAddToFolder
            {...props}
            itemORM={folderItemORM!}
            toast={toast}
          />
        ),
      }),
      )
    },
  },
  downloadAsPDF: {
    icon: 'file-pdf-box',
    title: DocumentVersionContextActions.downloadAsPDF,
    onPress: (currentDocVersionORM, _, __, toast) => async () => {
      const processingToastId = toast.add(
        <GenericToast
          title="Generating PDF download..."
          status="information"
        />,
        ToastOrientations.TOP_RIGHT,
        true,
        0,
      )
      try {
        logger.util.info('Downloading Document as PDF')
        const { data } = await API.graphql(
          graphqlOperation(downloadDocumentVersionAsPdfFile, {
            documentVersionId: currentDocVersionORM.model.id,
          }),
        ) as { data: { downloadDocumentVersionAsPdfFile: string } };

        logger.util.info('Generated download URL -- Starting download')
        toast.add(
          <GenericToast
            title="Download Started..."
            status="download"
          />,
          ToastOrientations.TOP_RIGHT,
          true,
        )
        downloadAsLink(data.downloadDocumentVersionAsPdfFile)
      } catch (e) {
        logger.util.error(e)
        toast.add(
          <GenericToast
            title="There was an error downloading this Document. Please contact support"
            status="error"
          />,
          ToastOrientations.TOP_RIGHT,
          true,
          0,
        )
      } finally {
        toast.remove(processingToastId)
      }
    },
  },
  rename: {
    icon: 'pencil',
    title: DocumentVersionContextActions.rename,
    onPress: () => () => {
      console.warn('not yet implemented')
    },
  },
}
