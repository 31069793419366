import React from 'react'
import { DocumentVersionORM, FolderItemORM } from 'src/types/types'
import {
  useDNADocumentVersionActions,
  documentVersionContextOptions,
  DocumentVersionActions,
} from './DNADocumentVersion.actions'
import { DNAContextMenu } from '@alucio/lux-ui'
import { useAppSettings } from 'src/state/context/AppSettings'
import { DocumentStatus, FileType } from '@alucio/aws-beacon-amplify/src/models'
import useCurrentPage from '../hooks/useCurrentPage'
import { isSharedEditableFolder } from 'src/utils/foldersHelpers'
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags'

export interface DNADocumentContextMenuProps {
  folderItemORM: FolderItemORM,
  children: React.ReactElement,
  actions?: DocumentVersionActions[],
  unavailableContent?: boolean,
}

/**
 * Start with an empty context menu and push actions based on permissions defined in the document.ts selector
 * @param props folder item / children / actions
 */
export const DNADocumentVersionContextMenu: React.FC<DNADocumentContextMenuProps> = (props) => {
  const {
    folderItemORM,
    actions,
    children,
  } = props

  const { deviceMode } = useAppSettings()
  const DNADocumentVersionActions = useDNADocumentVersionActions()
  const route = useCurrentPage({ exact: false })
  const { isModified } = folderItemORM.meta
  /** TODO: Refactor improper use of as assertion */
  const documentVersionORM = folderItemORM.relations.itemORM as DocumentVersionORM
  const actionsToUse: DocumentVersionActions[] = []
  const isDesktop = deviceMode === 'desktop'
  const { isOnline } = useAppSettings()
  const enableEditSharedFolder = useFeatureFlags('enableEditSharedFolder');
  const rootFolderORM =
  folderItemORM.relations.parentORM?.relations.parentFolderORM ?? folderItemORM.relations.parentORM;
  const isSharedEditFolder = rootFolderORM && isSharedEditableFolder(rootFolderORM) && enableEditSharedFolder
  const isPublisherRoute = route?.configOptions?.modules?.includes('publisher')
  const isSharedFolders = route?.PATH.includes('shared_folders');
  const isWebDoc = documentVersionORM.model.type === FileType.WEB
  const canDownload =
    documentVersionORM.relations.documentORM.meta.permissions.MSLDownload &&
    isOnline &&
    !isModified &&
    !isWebDoc
  /**
   * Currently no constraints defined for when a file can be duplicated.
   * This is here to serve as a place holder for when they are and to
   * preserve the existing coding pattern.
   * */
  const canDuplicate = true
  const canShare =
    documentVersionORM.meta.permissions.MSLShare &&
    !folderItemORM.model.customTitle &&
    !folderItemORM.model.visiblePages?.length &&
    isOnline &&
    !isModified &&
    !isPublisherRoute
  const canSelectSlides = documentVersionORM.relations.documentORM.meta.permissions.MSLSelectSlides &&
    !isPublisherRoute
  const canDownloadPdfFile = false // TODO: REACTIVATE THIS IN A FUTURE STORY isOnline && documentVersionORM.relations.documentORM.model.type === FileType.PPTX

  // NOTE: We're assuming this is only used in an MSL related context
  canDownload && actionsToUse.push('download')
  canDownloadPdfFile && actionsToUse.push('downloadAsPDF');
  canDuplicate && (!isSharedFolders || isSharedEditFolder) && actionsToUse.push('duplicate')
  canShare && actionsToUse.push('shareEmail')
  /** Currently INT files can not use selectSlides option. Additionally,
   * docs have a property which enables this functionality */
  canSelectSlides && (!isSharedFolders || isSharedEditFolder) && actionsToUse.push('selectSlides');

  // Add to folder for Folder view
  const isArchived: boolean =
    [DocumentStatus.ARCHIVED, DocumentStatus.ARCHIVED_WITH_VERSIONS].includes(
      DocumentStatus[documentVersionORM.relations.documentORM.model.status],
    );
  !isArchived && actionsToUse.push('addToFolder');

  // This context menu option is only used in MyFolders currently
  (!isSharedFolders || isSharedEditFolder) && enableEditSharedFolder &&
  actionsToUse.push('removeFromFolder');

  const MenuItems = actions?.length || actionsToUse.length
    ? (actions ?? actionsToUse)
      .map(action => {
        const { onPress, ...rest } = documentVersionContextOptions[action]
        return (
          <DNAContextMenu.Item
            disabled={ actions ? !actions.length : !actionsToUse.length}
            key={action}
            status={isDesktop ? 'neutral' : 'dark'}
            onPress={DNADocumentVersionActions[action](documentVersionORM, folderItemORM)}
            {...rest}
          />
        )
      })
    : (<DNAContextMenu.Item
        title="(No actions available)"
        onPress={() => {}}
    />)

  return (
    <DNAContextMenu>
      <DNAContextMenu.Anchor>
        { children }
      </DNAContextMenu.Anchor>
      <DNAContextMenu.Items>
        { MenuItems }
      </DNAContextMenu.Items>
    </DNAContextMenu>
  )
}

export default DNADocumentVersionContextMenu
