import { DNAFlatListRows } from 'src/components/DNA/FlatList/Rows/types'
import { DNADivider } from '@alucio/lux-ui'
import DNADocumentFolderItemDefaultRow from '../default/DNADocumentFolderItemDefaultRow'
import DNADocumentFolderItemCustomDeckDefaultRow from '../default/DNADocumentFolderItemCustomDeckDefaultRow'
import DNAFileDrawer from '../fileDrawer/DNAFileDrawerDefaultRow'
import DNADocumentDashboardRow from './DNADocumentDashboardRow'
import DNAFolderDashboardRow from './DNAFolderDashboardRow'

export const DNADefaultRows: DNAFlatListRows = {
  DNADocumentRow: DNADocumentDashboardRow,
  DNADocumentFolderItemRow: DNADocumentFolderItemDefaultRow,
  DNADocumentFolderItemCustomDeckRow: DNADocumentFolderItemCustomDeckDefaultRow,
  DNAFolderRow: DNAFolderDashboardRow,
  DNAListDivider: DNADivider,
  DNAFileDrawerRow: DNAFileDrawer,
}

export default DNADefaultRows
