import React, { useCallback, useMemo } from 'react';
import DNAEmpty, { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty';
import MeetingTable from 'src/components/Meeting/MeetingTable';
import { useMeetingList } from 'src/state/redux/selector/meeting';
import orderBy from 'lodash/orderBy'
import { MeetingORM } from 'src/types/orms';
import { DeviceMode, useAppSettings } from 'src/state/context/AppSettings';
import {
  DNABox,
  DNADivider,
  DNAText,
  DNAButton,
  DNAHeader,
  Iffy,
} from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { useDispatch } from 'src/state/redux';
import { drawerActions, DRAWER_ENTITIES } from 'src/state/redux/slice/drawer';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import { StyleSheet } from 'react-native';

export const isNotDeleted = (item: unknown) : item is MeetingORM => item
  ? (item as MeetingORM).model.status !== 'DELETED'
  : false

const S = StyleSheet.create({
  header: {
    borderBottomColor: colors['color-gray-80'],
    borderBottomWidth: 1,
    padding: 0,
    shadowOpacity: 0,
  },
  desktopPagePadding: {
    paddingHorizontal: 48,
    paddingVertical: 20,
  },
  tabletContainer: {
    backgroundColor: colors['color-text-white'],
  },
});

const useDNAMeetingsSharedComponents = () => {
  const meetingList = useMeetingList();
  const dispatch = useDispatch();
  const enableAddNewMeeting = useFeatureFlags('enableAddNewMeeting');

  const sortedMeetings = useMemo(
    () => {
      const visibleMeetings = meetingList.filter(isNotDeleted)
      return orderBy(visibleMeetings, ['model.startTime'], ['desc'])
    },
    [meetingList],
  )

  const onAddMeeting = useCallback(
    () => {
      dispatch(drawerActions.toggle({ entity: DRAWER_ENTITIES.MEETING }));
    },
    [],
  );
  return {
    sortedMeetings,
    onAddMeeting,
    enableAddNewMeeting,
  }
}

const DNAMeetingsHistoryTablet: React.FC = () => {
  const { sortedMeetings, onAddMeeting, enableAddNewMeeting } = useDNAMeetingsSharedComponents()
  return (
    <DNABox appearance="col" fill>
      <Iffy is={enableAddNewMeeting}>
        <DNAHeader status="flat" size="medium" style={S.header} >
          <DNAHeader.Right>
            <DNAButton
              size="lg"
              status="tertiary"
              appearance="outline"
              padding="sm"
              iconLeft="plus"
              onPress={onAddMeeting}
            >
              Create meeting
            </DNAButton>
          </DNAHeader.Right>
        </DNAHeader>
      </Iffy>
      <DNABox
        fill
        style={S.tabletContainer}
      >
        {/* TODO: Adjust this width properly after discussing expected behavior with Thai & Kenny */}
        <DNABox fill>
          {
            sortedMeetings.length
              ? <MeetingTable meetingORM={sortedMeetings}/>
              : <DNAEmpty
                  useContainer={false}
                  emptyVariant={EmptyVariant.MeetingsHistoryEmpty}
              />
          }
        </DNABox>
      </DNABox>
    </DNABox>
  )
}

const DNAMeetingsHistoryDesktop: React.FC = () => {
  const { sortedMeetings, onAddMeeting, enableAddNewMeeting } = useDNAMeetingsSharedComponents()

  return (
    <DNABox
      alignX="center"
      fill
    >
      <DNABox
        fill
        style={S.desktopPagePadding}
        alignX="center"
      >
        <DNABox fill spacing="md" appearance="col" testID="page-container" childFill={2}>
          {/* Header */}
          <DNABox alignY="center" spacing="between">
            <DNAText testID="page-title" h5 status="secondary">Meeting History</DNAText>
            <Iffy is={enableAddNewMeeting}>
              <DNAButton
                appearance="outline"
                status="secondary"
                size="md"
                onPress={onAddMeeting}
                iconLeft="plus"
                testID="create-meeting-button"
              >
                Create meeting
              </DNAButton>
            </Iffy>
          </DNABox>
          <DNADivider />
          {
            sortedMeetings.length
              ? <MeetingTable meetingORM={sortedMeetings} />
              : <DNAEmpty
                  useContainer={false}
                  emptyVariant={EmptyVariant.MeetingsHistoryEmpty}
              />
          }
        </DNABox>
      </DNABox>
    </DNABox>
  )
}

type DNAMeetingsHistoryVariants = Record<DeviceMode, React.ElementType>
const dnaMeetingsHistoryVariants:DNAMeetingsHistoryVariants = {
  desktop: DNAMeetingsHistoryDesktop,
  tablet: DNAMeetingsHistoryTablet,
}

const DNAMeetingsHistory:React.FC = () => {
  const { deviceMode } = useAppSettings()
  const CurrentVariant = dnaMeetingsHistoryVariants[deviceMode]

  return <CurrentVariant/>
}

export default DNAMeetingsHistory
